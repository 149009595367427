import './sidebar.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import usac_logo from '../../img/usac_logo.png';
import manualUsuario from '../manual_usuario/manual_usuario.pdf';

const CuotasSidebar = () => {
    const [visibleLeft, setVisibleLeft] = useState(false);

    const descargarManual = async () => {
        try {
            const link = document.createElement('a');
            link.href = manualUsuario;
            link.download = 'Manual Cuotas.pdf';
            link.click();
        } catch (error) {
            console.error('Error al descargar el PDF.');
        }
    }

    return (
        <div className="container__sidebar">
            <Sidebar className='menu__sidebar' visible={visibleLeft} position='left'
                onHide={() => setVisibleLeft(false)}>
                <div className='content__sidebar' >
                    <img src={usac_logo} alt='logo' />
                    <h3>Cuotas</h3>
                    <br />
                    <ul style={{ listStyle: 'none' }}>
                        <li>
                            <Link to="/cuotas/">
                                <i className="pi pi-history"></i>
                                {' Historial de cuotas'}
                            </Link>
                        </li>
                        <li>
                            <Link to="/cuotas/gestion_trabajadores">
                                <i className="pi pi-user"></i>
                                {' Gestión de trabajadores'}
                            </Link>
                        </li>
                        <li>
                            <Link to="/cuotas/menu_calculos">
                                <i className="pi pi-calculator"></i>
                                {' Cálculo de cuotas pendientes'}
                            </Link>
                        </li>
                        <li>
                            <Link to="/cuotas/reintegros">
                                <i className="pi pi-money-bill"></i>
                                {' Reintegros de cuotas'}
                            </Link>
                        </li>
                        <li>
                            <Link to="/cuotas/convenios">
                                <i className="pi pi-file-edit"></i>
                                {' Convenios de pago'}
                            </Link>
                        </li>
                        <li>
                            <Link to="/cuotas/certificaciones">
                                <i className="pi pi-file-word"></i>
                                {' Certificaciones'}
                            </Link>
                        </li>
                        <li>
                            <Link to="/cuotas/periodos">
                                <i className="pi pi-chart-line"></i>
                                {' Periodos y tasas de interés'}
                            </Link>
                        </li>
                        <li>
                            <Link to="/cuotas/logs">
                                <i className="pi pi-list"></i>
                                {' Logs'}
                            </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={descargarManual}>
                                <i className="pi pi-question-circle"></i>
                                {' Manual de usuario'}
                            </Link>
                        </li>
                        <br />
                        <li><Link to="/" onClick={() => sessionStorage.clear()}>
                            <i className="pi pi-sign-out"></i>
                            {' Ir a Dashboard'}
                        </Link>
                        </li>
                    </ul>
                </div>
            </Sidebar>
            <Button id='btn__menu_cuotas' icon="pi pi-bars" onClick={() => setVisibleLeft(true)} className="mr-2" />
        </div>
    );
}

export default CuotasSidebar;