import { requestOptions } from "./requestOptions";
const api = process.env.REACT_APP_API_URL_CUOTAS || "http://localhost:8091/cuotas/"

export async function getUltimaFechaLogs() {
    const url = api + "logs/get_ultima_fecha_logs"
    const response = await fetch(url, requestOptions("GET", null));
    return response.json();
}

export async function getLogs(data = {}) {
    const url = api + "logs/get_logs"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function eliminarLogs(data = {}) {
    const url = api + "logs/eliminar_logs"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}
