import '../gestion_trabajadores.css';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Card } from 'primereact/card';
import { Checkbox } from "primereact/checkbox";
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';


import { DepUniItemTemplate } from '../constants';
import { headerForm, mostrarError } from '../../constants/bodiesYFiltos';
import { DatosTrabajador } from '../../constants/datos_trabajador';
import buscarObjetoPorId from '../../tools/buscarObjetoPorId';
import { editarContrato, getDependenciasUnidades, getPuestosTrabajo } from '../../endpoints/gestion_trabajadores';


export default function EditarContrato() {
    // Puestos de trabajo
    const [puestosTrabajo, setPuestosTrabajo] = useState([]);
    // Dependencias y unidades ejecutoras
    const [dependenciasUnidades, setDependenciasUnidades] = useState([]);

    // Datos trabajador
    const [trabajador, setTrabajador] = useState([{}]);

    // Datos contrato
    const [idContratoTrabajador, setIdContratoTrabajador] = useState(null);
    const [noContrato, setNoContrato] = useState(null);
    const [partida, setPartida] = useState(null);
    const [numPlaza, setNumPlaza] = useState(null);
    const [clasificacion, setClasificacion] = useState(null);
    const [puestoTrabajo, setPuestoTrabajo] = useState(null);
    const [horasContratacion, setHorasContratacion] = useState(null);
    const [dependenciaUnidad, setDependenciaUnidad] = useState(null);
    const [sueldoNominal, setSueldoNominal] = useState(null);
    const [afectaDescuento, setAfectaDescuento] = useState(false);
    // otros
    const [otroPuestoStatus, setOtroPuestoStatus] = useState(false);
    const [otroPuesto, setOtroPuesto] = useState(null);
    const [otraDepUniStatus, setOtraDepUniStatus] = useState(false);
    const [otroCodDepUni, setOtroCodDepUni] = useState(null);
    const [otraDepUni, setOtraDepUni] = useState(null);

    // inicio y fin de contrato
    const [inicioContrato, setInicioContrato] = useState(null);
    const [finContrato, setFinContrato] = useState(null);

    const toast = useRef(null);
    const navigate = useNavigate()

    useEffect(() => {
        // obtener el trabajador seleccionado
        var trabajador_seleccionado = JSON.parse(sessionStorage.getItem('trabajador_seleccionado'));
        setTrabajador([trabajador_seleccionado]);

        // obtener el contrato seleccionado
        var contrato_seleccionado = JSON.parse(sessionStorage.getItem('contrato_seleccionado'));

        ObtenerPuestosDependencias(contrato_seleccionado);

        // colocar datos del contrato
        setIdContratoTrabajador(contrato_seleccionado.idContratoTrabajador);
        setNoContrato(contrato_seleccionado.NoContrato);
        setPartida(contrato_seleccionado.Partida);
        setNumPlaza(contrato_seleccionado.NumPlaza);
        setClasificacion(contrato_seleccionado.Clasificacion);
        setHorasContratacion(contrato_seleccionado.HorasContratacion);
        setSueldoNominal(contrato_seleccionado.SueldoNominal);
        setAfectaDescuento(contrato_seleccionado.AfectaDescuento === 1);
        setInicioContrato(contrato_seleccionado.InicioContrato && new Date(contrato_seleccionado.InicioContrato));
        setFinContrato(contrato_seleccionado.FinContrato && new Date(contrato_seleccionado.FinContrato));
    }, []);

    function ObtenerPuestosDependencias(contrato_seleccionado) {
        // obtener puestos de trabajo
        getPuestosTrabajo().then((resp) => {
            setPuestosTrabajo(resp.data);
            // colocar el puesto de trabajo
            if (contrato_seleccionado) {
                setPuestoTrabajo(
                    buscarObjetoPorId(resp.data, contrato_seleccionado.idPuestoTrabajo, 'idPuestoTrabajo')
                );
            }
        }).catch(err => {
            mostrarError(err);
        });

        // obtener dependencia y unidades
        getDependenciasUnidades().then((resp) => {
            setDependenciasUnidades(resp.data);
            // colocar la dependencia y unidad
            if (contrato_seleccionado) {
                setDependenciaUnidad(
                    buscarObjetoPorId(
                        resp.data,
                        contrato_seleccionado.idDependenciaUnidadEjecutora,
                        'idDependenciaUnidadEjecutora'
                    )
                );
            }
        }).catch(err => {
            mostrarError(err);
        });
    }

    function EditarContrato() {
        var obj = {
            idContratoTrabajador: idContratoTrabajador,
            NoContrato: noContrato,
            Partida: partida,
            NumPlaza: numPlaza,
            Clasificacion: clasificacion,
            idPuestoTrabajo: puestoTrabajo ? puestoTrabajo.idPuestoTrabajo : null,
            HorasContratacion: horasContratacion,
            idDependenciaUnidadEjecutora: dependenciaUnidad ?
                dependenciaUnidad.idDependenciaUnidadEjecutora : null,
            SueldoNominal: sueldoNominal,
            AfectaDescuento: afectaDescuento ? 1 : 0,
            // para los otros
            OtroPuestoStatus: otroPuestoStatus,
            OtroPuesto: otroPuesto,
            OtraDepUniStatus: otraDepUniStatus,
            OtroCodDepUni: otroCodDepUni,
            OtraDepUni: otraDepUni,
            // fechas
            InicioContrato: inicioContrato,
            FinContrato: finContrato
        }

        editarContrato(obj).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                const redirectTimer = setTimeout(() => { navigate('/cuotas/ver_contratos', { replace: true }) }, 1000);
                return () => { clearTimeout(redirectTimer) };
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
                ObtenerPuestosDependencias();
            }
        }).catch(err => {
            mostrarError(err);
        });
    }

    const confirm = (event) => {
        event.preventDefault();
        EditarContrato();
    }

    return (
        <div className='primario-crear-registro'>
            <Toast ref={toast} />
            {/* DATOS DEL TRABAJADOR */}
            <Card header={headerForm("Datos del trabajador", true)} className="card">
                <DatosTrabajador trabajador={trabajador} />
            </Card>
            <br />
            {/* CONTRATO */}
            <Card header={headerForm("Datos del contrato")} className="card">
                <form onSubmit={confirm} noValidate={false}>
                    <div className="grid p-fluid mt-1">
                        {/* NO. CONTRATO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Número de Contrato</label>
                            <InputNumber
                                value={noContrato} onValueChange={(e) => setNoContrato(e.value)}
                                mode="decimal" showButtons min={0} useGrouping={false} />
                        </div>
                        {/* PARTIDA */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Partida</label>
                            <InputText
                                value={partida} onChange={(e) => setPartida(e.target.value)} />
                        </div>
                        {/* NUMERO DE PLAZA */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Número de Plaza *</label>
                            <InputNumber required
                                value={numPlaza} onValueChange={(e) => setNumPlaza(e.value)}
                                mode="decimal" showButtons min={0} useGrouping={false} />
                        </div>
                        {/* CLASIFICACION */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Clasificación</label>
                            <InputNumber
                                value={clasificacion} onValueChange={(e) => setClasificacion(e.value)}
                                mode="decimal" showButtons min={0} useGrouping={false} />
                        </div>
                    </div>
                    <div className="grid p-fluid mt-1">
                        {/* HORAS DE CONTRATACION */}
                        <div className="field col-12 md:col-4 col_toggle">
                            <label>Horas de Contratación</label>
                            <InputNumber value={horasContratacion}
                                onValueChange={(e) => setHorasContratacion(e.value)}
                                minFractionDigits={2} maxFractionDigits={2} showButtons min={0} />
                        </div>
                        {/* SUELDO NOMINAL */}
                        <div className="field col-12 md:col-4 col_toggle">
                            <label>Sueldo Nominal (Q)</label>
                            <InputNumber value={sueldoNominal}
                                onValueChange={(e) => setSueldoNominal(e.value)}
                                minFractionDigits={2} maxFractionDigits={2} showButtons min={0} />
                        </div>
                        {/* AFECTA A DESCUENTO? */}
                        <div className="field col-12 md:col-4 col_toggle">
                            <label>Afecta a descuento?</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <Checkbox onChange={e => setAfectaDescuento(e.checked)}
                                        checked={afectaDescuento}></Checkbox>
                                </span>
                                <InputText readOnly value={afectaDescuento ? 'Sí' : 'No'} />
                            </div>
                        </div>
                    </div>

                    <div className="grid p-fluid mt-1">
                        {/* PUESTO DE TRABAJO */}
                        <div className="field col-12 md:col-6 col_toggle">
                            <label>Puesto de Trabajo</label>
                            <Dropdown
                                disabled={otroPuestoStatus}
                                value={puestoTrabajo}
                                options={puestosTrabajo}
                                placeholder="Puesto de trabajo"
                                optionLabel="Titulo"
                                onChange={(e) => setPuestoTrabajo(e.target.value)}
                            />
                        </div>
                        {/* OTRO */}
                        <div className="field col-12 md:col-6 col_toggle">
                            <label>Otro: Titulo de Puesto de Trabajo</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <Checkbox onChange={e => setOtroPuestoStatus(e.checked)}
                                        checked={otroPuestoStatus}></Checkbox>
                                </span>
                                <InputText disabled={!otroPuestoStatus}
                                    value={otroPuesto} onChange={(e) => setOtroPuesto(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    {/* NOMBRE DE DEPENDENCIA Y UNIDAD EJECUTORA */}
                    <div className='divider'></div>

                    <div className="grid p-fluid mt-1">
                        <h4 style={{ textAlign: 'center', width: '100%', paddingBottom: '1%' }}>
                            Dependencia y Unidad Ejecutora
                        </h4>
                        <div className="field col-12 md:col-12 col_toggle">
                            <label>Código Dependencia - Nombre de Dependencia y Unidad Ejecutora</label>
                            <Dropdown
                                disabled={otraDepUniStatus}
                                value={dependenciaUnidad}
                                options={dependenciasUnidades}
                                placeholder="Dependencia y unidad ejecutora"
                                optionLabel="Nombre"
                                itemTemplate={DepUniItemTemplate}
                                valueTemplate={DepUniItemTemplate}
                                onChange={(e) => {
                                    setDependenciaUnidad(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="grid p-fluid mt-1">
                        {/* OTRO */}
                        <div className="field col-12 md:col-6 col_toggle">
                            <label>Otro: Código Dependencia</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <Checkbox onChange={e => setOtraDepUniStatus(e.checked)}
                                        checked={otraDepUniStatus}></Checkbox>
                                </span>
                                <InputNumber disabled={!otraDepUniStatus}
                                    value={otroCodDepUni} onValueChange={(e) => setOtroCodDepUni(e.value)}
                                    mode="decimal" showButtons min={0} useGrouping={false} />
                            </div>
                        </div>
                        <div className="field col-12 md:col-6 col_toggle">
                            <label>Nombre de Dependencia y Unidad Ejecutora</label>
                            <InputText disabled={!otraDepUniStatus}
                                value={otraDepUni} onChange={(e) => setOtraDepUni(e.target.value)} />
                        </div>
                    </div>

                    <div className="grid p-fluid mt-1 ">
                        {/* FECHA DE INICIO */}
                        < div className="field col-12 md:col-4 col_toggle">
                            <label>Fecha de Inicio (opcional)</label>
                            <Calendar value={inicioContrato} preventDefault
                                onChange={(e) => setInicioContrato(e.target.value)} showButtonBar showIcon />
                        </div>
                        {/* FECHA DE FIN */}
                        <div className="field col-12 md:col-4 col_toggle">
                            <label>Fecha de Fin (opcional)</label>
                            <Calendar value={finContrato} preventDefault
                                onChange={(e) => setFinContrato(e.target.value)} showButtonBar showIcon />
                        </div>
                    </div>

                    {/* BOTONES */}
                    <br />
                    <div className="grid p-fluid">
                        <div className='col-12 md:col-4' style={{ marginLeft: 'auto', marginRight: '0' }}>
                            <Button type='submit' icon="pi pi-check"
                                label="Guardar Cambios" className='p-button-success' />
                        </div>
                    </div>
                </form>
            </Card>
        </div>
    );
}
