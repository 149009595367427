import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { TabPanel, TabView } from 'primereact/tabview';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { new_payment_route } from '../../constants/backend_routes';

import './formulario_pagos.css'; // Importa el archivo CSS para estilizar el formulario

const FormularioPagoMoras = () => {
    const regex_alfa = /[a-zA-zñÑáÁéÉíÍóÓúÚ0-9 ]/;

    const [capital, setCapital] = useState(0);
    const [mora, setMora] = useState(0);
    const [interes, setInteres] = useState(0);
    const [clavePrestamo, setClavePrestamo] = useState('');
    const [fechaPago, setFechaPago] = useState('');
    const [cuotasPendientes, setCuotasPendientes] = useState('');
    const [numero_dpi, setDPI] = useState('');


    const reset_form = () => {
        setCapital(0);
        setMora(0);
        setInteres(0);
        setClavePrestamo('');
        setFechaPago('');
        setDPI('');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Realiza acciones con los datos del formulario
    };

    const submitPayment = async (payment) => {
        const response = await fetch(new_payment_route, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payment)
        })
        if (!response.ok) {
            return false
        } else {
            return true;
        }
    }

    function formatDateToYYYYMMDD(date) {
        const year = date.getFullYear();
        // Se agrega 1 al mes porque los meses en JavaScript van de 0 a 11
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
      
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      }

    const confirm = (event) => {
        confirmPopup({
            target: document.getElementById('pagos_confirm'),
            message: '¿Seguro que desea continuar?',
            icon: 'pi pi-question-circle',
            acceptLabel: 'Sí',
            accept: async () => {
                const formatedDate = formatDateToYYYYMMDD(fechaPago)
                console.log(capital)
                const success = await submitPayment({
                    capital: parseFloat(capital),
                    mora: parseFloat(mora),
                    interes: parseFloat(interes),
                    clave_prestamo: clavePrestamo,
                    cuotas_pendientes: cuotasPendientes,
                    fecha: formatedDate
                })
                if (success){
                    reset_form();
                    toast.current.show({ severity: 'success', summary: 'Pago registrado.' });
                }else{
                    toast.current.show({ severity: 'error', summary: 'El pago no pudo ser registrado.' });
                }
            }
        });
        event.preventDefault();
    }

    const toast = useRef(null);
    return (
        <div className='container'>
            <TabView>
                <TabPanel header="Formulario de pago" leftIcon='mr-2 pi pi-money-bill'>
                    <form id='pagos_form' onSubmit={confirm} onValidate={false}>
                        <Toast ref={toast}></Toast>
                        <div className='grid p-fluid formulario'>
                            {/* CODIGO PRESTAMO */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-id-card"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={true} value={clavePrestamo} onChange={(e) => setClavePrestamo(e.target.value)} />
                                        <label >Clave de prestamo</label>
                                    </span>
                                </div>
                            </div>
                            {/* FECHA */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-id-card"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <Calendar dateFormat="dd/mm/yy" value={fechaPago} onChange={(e) => setFechaPago(e.value)}></Calendar>
                                        <label >Fecha de pago</label>
                                    </span>
                                </div>
                            </div>
                            {/* CAPITAL */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-id-card"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="number" keyfilter="num" required={true} value={capital} onChange={(e) => setCapital(e.target.value)} />
                                        <label >Cantidad de capital</label>
                                    </span>
                                </div>
                            </div>
                            {/* INTERES */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-id-card"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="number" keyfilter="num" required={true} value={interes} onChange={(e) => setInteres(e.target.value)} />
                                        <label >Cantidad de interes</label>
                                    </span>
                                </div>
                            </div>
                            {/* MORA */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-id-card"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="number" keyfilter="num" required={true} value={mora} onChange={(e) => setMora(e.target.value)} />
                                        <label >Cantidad de mora</label>
                                    </span>
                                </div>
                            </div>
                            {/* CUOTAS PENDIENTES */}
                            <div className='field col-12 md:col-3'>
                                <div className='p-inputgroup'>
                                    <span className='p-inputgroup-addon'>
                                        <i className='pi pi-id-card'></i>
                                    </span>
                                    <span className='p-float-label'>
                                        <InputText type="number" keyfilter="num" required={false} value={cuotasPendientes} onChange={(e) => setCuotasPendientes(e.target.value)}/>
                                        <label>Cuotas pendientes</label>
                                    </span>
                                </div>
                            </div>
                            {/* DPI */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-id-card"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter="pint" required={true} value={numero_dpi} onChange={(e) => setDPI(e.target.value)} />
                                        <label >Número de DPI</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='confirm'>
                            <Button id='pagos_confirm' type='submit' icon="pi pi-check-circle" label="Registrar Pago" className="mt-2 p-button-raised font-weight-bold" />
                            <ConfirmPopup />
                        </div>
                    </form>
                </TabPanel>
            </TabView>
        </div>
    );
};

export default FormularioPagoMoras;
