import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { requestOptions } from '../misc/requestOptions';

function ListadoGeneral(props) {

    const { id } = useParams();
    const [listado, setListado] = useState([]);
    const [tipo_nomina, setTipo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [loaded, setLoaded] = useState(false);


    /* Filters */
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(20);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'registro': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    }

    const get_listado = () => {
        setLoading(true);
        setLoaded(false);
        fetch((process.env.REACT_APP_API_URL_LISTADO_NOMINA),
            requestOptions('POST', { idnomina: id }))
            .then((response) => response.json())
            .then((data) => {
                props.toast.current.show({ severity: data.severity, summary: data.message || data.error });
                const x = data.data;
                if (x && x.listado) {
                    setListado(x.listado);
                    setTipo(x.tipo_nomina);
                    setLoaded(true);
                }
                setLoading(false);
            });
    }

    const get_detalle = (idpd) => {
        fetch((process.env.REACT_APP_API_URL_LISTADO_NOMINA + '/detalle'),
            requestOptions('POST', { idpd: idpd, idtiponomina: tipo_nomina }))
            .then((response) => response.json())
            .then((data) => {
                if (data.error)
                    props.toast.current.show({ severity: data.severity, summary: data.error });
                const x = data.data;
                if (x && x.detalle_pago) {
                    const content_detalle = (<div className="table__detalle">
                        <DataTable value={x.detalle_pago} emptyMessage='Sin detalle.' selectionMode='single'>
                            <Column header="Concepto" field='nombre'></Column>
                            <Column header="Tipo" field='tipo'></Column>
                            {tipo_nomina === 2 && <Column header="Descripción" field='descripcion'></Column>}
                            <Column header="Monto (Q.)" field='monto'></Column>
                        </DataTable>
                    </div>);
                    props.setDisplayDialog({ header: `Detalle`, content: content_detalle });
                }
            });
    }

    useEffect(() => {
        get_listado();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const nominalBadge = (rowData) => {
        return <span className={`status-badge-nom status-nominal`}>{rowData.nominal}</span>;
    }
    const bonosBadge = (rowData) => {
        return <span className={`status-badge-nom status-bono`}>{rowData.bono}</span>;
    }
    const descuentosBadge = (rowData) => {
        return <span className={`status-badge-nom status-descuento`}>{rowData.descuento}</span>;
    }
    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }

    const pg_template = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 }
            ];
            return (
                <React.Fragment>
                    <span className="mx-0">Elementos por página: </span>
                    <Dropdown className="mr-4 table__paginator" value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        'CurrentPageReport': (options) => {
            return (
                <span style={{ width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} de {options.totalRecords}
                </span>
            )
        }
    };

    const exportExcel = async () => {
        setLoadingExport(true);
        try {
            const response = await fetch(process.env.REACT_APP_API_URL_REPORTERIA,
                requestOptions('POST', {
                    idnomina: id,
                    reportSelected: 5, // NÓMINA EXCEL
                    bankSelected: null,
                    fileType: null,
                    encabezadoAnulacion: null
                }))
            if (!response.ok) throw new Error('Error: No fue posible generar el reporte.');
            const pdfBlob = await response.blob();
            // Crear un objeto URL a partir del Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);
            // Crear un enlace temporal para descargar el PDF
            const a = document.createElement('a');
            a.href = pdfUrl;
            a.download = `REPORTE_VALIDA_ID${id}`;
            a.click();
            // Liberar la URL del objeto Blob
            URL.revokeObjectURL(pdfUrl);
            props.toast.current.show({ severity: 'success', summary: 'Reporte para validación generado.' });
            // resetVars();
        } catch (error) {
            console.error('Error al descargar el reporte.');
            props.toast.current.show({ severity: 'error', summary: error.toString() });
        }
        setLoadingExport(false);
    }

    const header = (
        <div className="table-header flex">
            <div className='my-auto'>Listado general</div>
            <div className='ml-auto'>
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className='excel_btn_report mr-4 h-100' label='Validar reporte' loading={loadingExport} />
                <span className="p-input-icon-right">
                    <i className="pi pi-search" />
                    <InputText style={{ width: '100%' }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Buscar" />
                </span>
            </div>
        </div>
    );

    return (
        <div className="container__listado">
            {
                loading ?
                    <ProgressBar mode="indeterminate" style={{ height: '8px', marginTop: '200px' }} />
                    :
                    <>
                        <DataTable value={listado} header={header} paginator paginatorTemplate={pg_template} first={first} rows={rows} onPage={onCustomPage}
                            selectionMode='single' emptyMessage="Sin resultados." paginatorClassName="justify-content-end" className="mt-6"
                            filters={filters} globalFilterFields={['registro', 'nombre']}
                            onRowDoubleClick={(e) => get_detalle(e.data.id)}>
                            <Column field="partida" header="Partida"></Column>
                            <Column field="plaza" header="Plaza"></Column>
                            <Column field="registro" header="Registro"></Column>
                            <Column field="nombre" header="Nombre"></Column>
                            <Column align={'left'} header="Nominal" style={{ width: '12%' }} body={nominalBadge}></Column>
                            <Column align={'left'} header="Bonificación" style={{ width: '12%' }} body={bonosBadge}></Column>
                            <Column align={'left'} header="Descuentos" style={{ width: '12%' }} body={descuentosBadge}></Column>
                        </DataTable>
                        {
                            loaded &&
                            <div className='btn__nextSetp'>
                                <Button onClick={() => props.setActiveIndex()} className='p-button-rounded p-button-raised font-weight-bold'
                                    icon='pi pi-arrow-right' label='Ver líquido' iconPos='right' />
                            </div>
                        }
                    </>
            }
        </div>
    );
}

export default ListadoGeneral;
