import './sidebar.css';
import usac_logo from '../img/usac_logo.png'
import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NominaSidebar from '../modulo_nominas/sidebar/sidebar';
import { Avatar } from 'primereact/avatar';
import { Image } from 'primereact/image';
import { logout } from '../auth/logout';
import img_navidad from '../img/santa.png'

const SidebarComponent = () => {

    const navigate = useNavigate();
    const pathname = useLocation().pathname;

    const [visibleLeft, setVisibleLeft] = useState(false);
    const [visibleRight, setVisibleRight] = useState(false);

    const confirm_logout = () => {
        confirmDialog({
            message: '¿Desea cerrar sesión?',
            header: 'Salir',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sí',
            rejectLabel: 'No',
            resizable: false,
            draggable: false,
            accept: () => { logout(); setVisibleRight(false); navigate("/login", { relative: true }); },
            reject: () => null
        });
    }

    const left_sidebar_component = () => {
        let title = 'Dashboard';
        if (pathname.includes('/nominas')) /* Retorna el SideBar de nóminas */
            return <NominaSidebar title={'Nóminas'} />;
        if (pathname.includes('/jubilados'))
            return <NominaSidebar title={'Jubilados y pensionados'} />;
        if (pathname.includes('/moras'))
            title = 'Moras';
        return <>{/* Sidebar por defecto */}
            <Sidebar className='menu__sidebar' visible={visibleLeft} position='left' onHide={() => setVisibleLeft(false)}>
                <div className='content__sidebar' >
                    <img src={usac_logo} alt='logo' />
                    <h3 style={{ width: 'min-content' }}>{title}</h3>
                    <br />
                    <ul className='text-left'>
                        <li><Link to="/">Ir a Dashboard</Link></li>
                    </ul>
                </div>
            </Sidebar><Button id='btn__menu' icon="pi pi-bars" onClick={() => setVisibleLeft(true)} className="mr-2" />
        </>
    }
    const right_sidebar_component = () => {
        return <>{/* Sidebar derecho para sesión de usuario */}
            <Sidebar className='user__sidebar' visible={visibleRight} position='right' onHide={() => setVisibleRight(false)}>
                <h2 className='font-weight-light text-center' style={{ letterSpacing: '1px' }}>Usuario activo:</h2>
                {
                    localStorage.getItem('foto_usuario') ?
                        <div className='py-4 text-center' >
                            <Image className='foto_usuario' src={`data:image/plain;base64,${localStorage.getItem('foto_usuario')}`} alt="Foto" height="125" />
                        </div>
                        :
                        <div className='text-center my-4'><Avatar icon="pi pi-user" size="xlarge" /></div>
                }
                <h4 className='font-weight-bold'>{localStorage.getItem('nombre_usuario') || 'Usuario'}</h4>
                <hr />
                <Button onClick={confirm_logout} label="Cerrar sesión" icon="pi pi-sign-out" className="p-button-danger mt-2 w-100 font-weight-bold" /><ConfirmDialog />
                <div className='text-center' style={{ position: 'absolute', bottom: '40px', left: 0, right: 0 }}><img src={img_navidad} style={{ width: '250px' }} alt='christmas' /></div>
            </Sidebar> <Button id='btn__user' icon="pi pi-user" onClick={() => setVisibleRight(true)} className="pt-0 mr-2" />
        </>
    }

    return (
        <>
            {(pathname !== '/login' && pathname !== '/signup') &&/* Para que no aparezcan en el login */
                <div className="container__sidebar">
                    {left_sidebar_component()}
                    {right_sidebar_component()}
                </div>
            }
        </>
    );
}

export default SidebarComponent;