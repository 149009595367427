import './reintegros.css';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { periodoSeleccionadoItem } from './constants';
import { BotonExportarReporte } from '../constants/exportacion';
import { headerForm, mostrarError } from '../constants/bodiesYFiltos';
import { getTrabajador } from '../endpoints/generales';
import { exportarReintegro, getPeriodosReintegro } from '../endpoints/reintegros';

export default function VerReintegro() {
    // Datos trabajador
    const [selectedTrabajador, setSelectedTrabajador] = useState({ CUI: '', Registro: '', Nombre: '' });

    // Datos del reintegro
    const [idReintegro, setIdReintegro] = useState(null);
    const [cantidad, setCantidad] = useState(null);
    const [motivo, setMotivo] = useState('');
    const [numCheque, setNumCheque] = useState(null);
    const [fechaCheque, setFechaCheque] = useState(null);
    const [periodos, setPeriodos] = useState(null);

    const toast = useRef(null);

    useEffect(() => {
        // obtener el reintegro seleccionado
        var seleccionado = JSON.parse(sessionStorage.getItem('reintegro_seleccionado'));
        setIdReintegro(seleccionado.idReintegro);
        // obtener datos del trabajador
        getTrabajador({ Registro: seleccionado.RegistroTrabajador }).then((resp) => {
            if (resp.data) {
                setSelectedTrabajador(resp.data);
            }
        }).catch(err => {
            mostrarError(err);
        });
        // llenar los datos en el formulario
        setCantidad(seleccionado.Cantidad);
        setMotivo(seleccionado.Motivo);
        setNumCheque(seleccionado.NumCheque);
        setFechaCheque(new Date(seleccionado.FechaCheque));

        // obtener los periodos del reintegro
        getPeriodosReintegro({ idReintegro: seleccionado.idReintegro }).then((resp) => {
            setPeriodos(resp.data);
        }).catch(err => {
            mostrarError(err);
        });
    }, []);

    return (
        <div className='primario-crear-registro'>
            <Toast ref={toast} />
            <div className="grid p-fluid">

                <Card header={headerForm("Trabajador", true)} className="card">
                    <div className="grid p-fluid mt-1">
                        {/* CUI */}
                        <div className="field col-12 md:col-4 col_toggle">
                            <label>CUI</label>
                            <InputText type="text" value={selectedTrabajador.CUI} readOnly />
                        </div>
                        {/* REGISTRO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Registro</label>
                            <InputText type="text" value={selectedTrabajador.Registro} readOnly />
                        </div>
                        {/* NOMBRE */}
                        <div className="field col-12 md:col-5 col_toggle">
                            <label>Nombre</label>
                            <InputText type="text" value={selectedTrabajador.Nombre} readOnly />
                        </div>
                    </div>
                    <h2 style={{ textAlign: 'center', paddingTop: '2%' }}>Datos del reintegro</h2>
                    <div className="grid p-fluid mt-1">
                        {/* CANTIDAD */}
                        <div className="field col-12 md:col-6 col_toggle">
                            <label>Cantidad (Q)</label>
                            <InputNumber readOnly value={cantidad}
                                minFractionDigits={2} maxFractionDigits={2} min={0} />
                        </div>
                        {/* MOTIVO */}
                        <div className="field col-12 md:col-6 col_toggle">
                            <label>Motivo</label>
                            <InputText readOnly value={motivo} />
                        </div>
                    </div>
                    <div className="grid p-fluid mt-1">
                        {/* NUM. CHEQUE */}
                        <div className="field col-12 md:col-6 col_toggle">
                            <label>Número de Cheque</label>
                            <InputNumber readOnly value={numCheque}
                                mode="decimal" min={0} useGrouping={false} />
                        </div>
                        {/* FECHA CHEQUE */}
                        <div className="field col-12 md:col-6 col_toggle">
                            <label>Fecha del Cheque</label>
                            <Calendar readOnly value={fechaCheque} />
                        </div>
                    </div>
                    <br />
                    <div style={{ height: '175px' }}>
                        <DataTable value={periodos} scrollable scrollHeight="175px"
                            emptyMessage="Agregar periodos">
                            <Column field='Periodos agregados' header='Periodos agregados'
                                body={(rowData) => periodoSeleccionadoItem(rowData)} />
                        </DataTable>
                    </div><br />
                    {/* BOTONES */}
                    <br /><br /><br /><br /><br />
                    <div className="grid p-fluid">
                        <div className='col-12 md:col-3' style={{ marginLeft: 'auto', marginBottom: '0' }}>
                            <Link to="/cuotas/editar_reintegro">
                                <Button type='button' icon="pi pi-pencil"
                                    label="Editar Reintegro" className='p-button-warning' />
                            </Link>
                        </div>
                        <div className='col-12 md:col-2' style={{ width: 'fit-content', marginRight: '0' }}>
                            <BotonExportarReporte
                                Endpoint={exportarReintegro}
                                datos={{
                                    Trabajador: selectedTrabajador,
                                    Cantidad: cantidad,
                                    Motivo: motivo,
                                    NumCheque: numCheque,
                                    FechaCheque: fechaCheque,
                                    Periodos: periodos
                                }}
                                nombreArchivo={'reintegro'}
                                size="medium"
                                style={null}
                                ocultar={['XLSX']}
                            />
                        </div>
                    </div>
                </Card>

            </div>
        </div>
    );
}
