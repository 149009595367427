import { BarAlzasVsBajas, BarDescuentos, BarPartidas, DoughnutFormasPago, Plugin_horizontal, Plugin_title, RangosPensiones } from './charts_service';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Chart } from 'primereact/chart';
import { Calendar } from 'primereact/calendar';
import { requestOptions } from '../misc/requestOptions';
import { ProgressBar } from 'primereact/progressbar';

function Estadisticas() {

    const [loading, setLoading] = useState(false);
    const [fecha, setFecha] = useState(new Date());
    const [reports, setReports] = useState(null);
    const toast = useRef(null);
    const navigate = useNavigate();

    // Para BreadCrumb
    const items = [
        { label: 'NÓMINA', command: () => { navigate("/nominas") } },
        { label: 'ESTADÍSTICAS', command: () => { navigate("/nominas/estadisticas") } }
    ];
    const home = { icon: 'pi pi-home', command: () => { navigate("/") } };

    useEffect(() => {
        obtener_estadisticas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fecha]);

    const obtener_estadisticas = () => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL_REPORTERIA_JUBILADOS + '/stats',
            requestOptions('POST', {
                fecha: fecha
            }))
            .then((response) => response.json())
            .then((data) => {
                const x = data.data;
                if (data.error || !x)
                    toast.current.show({ severity: data.severity, summary: data.error || 'Error: No fue posible obtener algunos reportes.' });
                else {
                    setReports(x);
                }
                setLoading(false);
            });
    }


    return (
        <div>
            <Toast ref={toast}></Toast>
            <BreadCrumb model={items} home={home} />
            <div style={{ maxWidth: '1200px', margin: 'auto' }} className='mt-6 card px-6'>
                <div className='stats text-center'>
                    <h1 className='text-dark pt-4 pb-2' style={{ letterSpacing: '4px', fontWeight: 'bold' }}>DASHBOARD GRÁFICO</h1>
                    <hr />
                    {
                        loading ?
                            <ProgressBar mode="indeterminate" style={{ height: '8px', marginTop: '30px', marginBottom: '30px' }} />
                            :
                            reports &&
                            <>
                                <div className='text-right'>
                                    <Calendar tooltip='Filtro por fecha' showIcon icon='pi pi-calendar' required value={fecha} onChange={(e) => setFecha(e.value)} view="month" dateFormat="mm/yy" placeholder='Seleccione mes y año' />
                                </div>
                                <div className='row pt-2'>
                                    <div className='col-12 md:col-4 my-auto'>
                                        <Chart type="doughnut" data={DoughnutFormasPago(reports.formas_pago)} options={Plugin_title('Formas de pago'.toUpperCase())} />
                                    </div>
                                    <div className='col-12 md:col-8'>
                                        <Chart type="bar" data={BarPartidas(reports.partidas, 'Cantidad')} options={Plugin_title('Distribución de partidas'.toUpperCase(), 20, `Total: ${reports.total_partidas}`)} />
                                    </div>
                                </div>
                                <div className='row pt-5'>
                                    <div className='col-12'>
                                        <Chart type="line" data={RangosPensiones(reports.rangos_pensiones, reports.rangos_pensiones_bonif)} options={Plugin_title('Rangos de pensiones'.toUpperCase())} style={{ width: '95%', margin: 'auto' }} />
                                    </div>
                                </div>
                                <div className='row pt-5 align-items-center'>
                                    <div className='col-12 md:col-6'>
                                        <Chart type="pie" data={BarDescuentos(reports.liquidos_descuentos)} options={Plugin_title('Totales por descuentos'.toUpperCase())} style={{ width: '90%', margin: 'auto' }} />
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <Chart type="bar" data={BarAlzasVsBajas(reports.alzas_vs_bajas)} options={Plugin_horizontal('Alzas y bajas del mes'.toUpperCase())} />
                                    </div>
                                </div>
                                <hr />
                            </>
                    }
                </div>
            </div>
        </div >
    );

}

export default Estadisticas;