import './convenios.css';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import { fechaBody, headerForm, moneyBody, mostrarError } from '../constants/bodiesYFiltos';
import { BotonExportarReporte } from '../constants/exportacion';
import { DatosConvenio } from './pagos_convenio/constants';
import { getTrabajador } from '../endpoints/generales';
import { exportarConvenio, getFirmaConvenio, getPagosConvenio } from '../endpoints/convenios';


export default function VerConvenio() {
    // Datos trabajador
    const [selectedTrabajador, setSelectedTrabajador] = useState({ CUI: '', Registro: '', Nombre: '' });

    // --------------- Datos del convenio
    const [convenio, setConvenio] = useState([{}]);
    // Pagos
    const [pagos, setPagos] = useState([]);
    // Calculos
    const [totalPagado, setTotalPagado] = useState(0);

    // codeudor
    const [CUICodeudor, setCUICodeudor] = useState(null);
    const [nombreCodeudor, setNombreCodeudor] = useState('');
    const [telefonoCodeudor, setTelefonoCodeudor] = useState(null);

    const [firma, setFirma] = useState(null);
    const [hayImagen, setHayImagen] = useState(true);

    const toast = useRef(null);

    useEffect(() => {
        // obtener el convenio seleccionado
        var seleccionado = JSON.parse(sessionStorage.getItem('convenio_seleccionado'));
        setConvenio([seleccionado])
        // obtener datos del trabajador
        getTrabajador({ Registro: seleccionado.RegistroTrabajador }).then((resp) => {
            if (resp.data) {
                setSelectedTrabajador(resp.data);
            }
        }).catch(err => {
            mostrarError(err);
        });
        // obtener los pagos del convenio
        getPagosConvenio({ idConvenio: seleccionado.idConvenio }).then((resp) => {
            setPagos(resp.data);

            var TotalPagado = resp.data.reduce((accumulator, currentValue) => accumulator + currentValue.Monto, 0);
            setTotalPagado(TotalPagado);
        }).catch(err => {
            mostrarError(err);
        });

        // datos del codeudor
        setCUICodeudor(seleccionado.CUICodeudor);
        setNombreCodeudor(seleccionado.NombreCodeudor);
        setTelefonoCodeudor(seleccionado.TelefonoCodeudor);

        getFirmaConvenio({ idConvenio: seleccionado.idConvenio })
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                setFirma(url);
            });
    }, []);

    return (
        <div >
            <Toast ref={toast} />
            <form noValidate={false}>
                <Card header={headerForm("Trabajador", true)} className="card">
                    <div className="grid p-fluid mt-1">
                        {/* CUI */}
                        <div className="field col-12 md:col-4 col_toggle">
                            <label>CUI</label>
                            <InputText type="text" value={selectedTrabajador.CUI} readOnly />
                        </div>
                        {/* REGISTRO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Registro</label>
                            <InputText type="text" value={selectedTrabajador.Registro} readOnly />
                        </div>
                        {/* NOMBRE */}
                        <div className="field col-12 md:col-5 col_toggle">
                            <label>Nombre</label>
                            <InputText type="text" value={selectedTrabajador.Nombre} readOnly />
                        </div>
                    </div>
                    <h2 style={{ textAlign: 'center', paddingTop: '2%' }}>Datos del convenio</h2>
                    <DatosConvenio convenio={convenio} />
                    <h2 style={{ textAlign: 'center', paddingTop: '2%' }}>Pagos del convenio</h2>
                    <DataTable value={pagos} scrollable scrollHeight='200px'
                        tableStyle={{ minWidth: '50rem' }} size='small'>
                        <Column header="Fecha de Pago"
                            body={(rowData) => fechaBody(rowData, "FechaPago")} ></Column>
                        <Column field="Monto" header="Monto(Q)"
                            body={(rowData) => moneyBody(rowData, 'Monto')}></Column>
                        <Column field="NumCheque" header="Número de Cheque"
                            body={(rowData) => rowData.NumCheque ? rowData.NumCheque : "Pago en Efectivo"}></Column>
                    </DataTable><br />
                    <div className="grid p-fluid">
                        {/* TOTAL PAGADO */}
                        <div className="field col-12 md:col-3 col_toggle ml-auto md-0">
                            <label>Total pagado(Q): </label>
                            <InputNumber value={totalPagado}
                                minFractionDigits={2} maxFractionDigits={2} min={0} />
                        </div>
                        {/* MONTO PENDIENTE */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Monto pendiente de pago(Q): </label>
                            <InputNumber value={convenio[0].MontoTotal - totalPagado}
                                minFractionDigits={2} maxFractionDigits={2} min={0} />
                        </div>
                    </div>
                </Card><br />
                <div style={{ width: '100%' }}>
                    <Card header={headerForm("Datos de Codeudor")} className="card">
                        <div className="grid p-fluid mt-1">
                            {/* CUI */}
                            <div className="field col-12 md:col-4 col_toggle">
                                <label>CUI</label>
                                <InputNumber readOnly value={CUICodeudor}
                                    mode="decimal" min={0} useGrouping={false} />
                            </div>
                            {/* NOMBRE */}
                            <div className="field col-12 md:col-5 col_toggle">
                                <label>Nombre Completo</label>
                                <InputText readOnly value={nombreCodeudor} />

                            </div>
                            {/* TELEFONO */}
                            <div className="field col-12 md:col-3 col_toggle">
                                <label>Teléfono</label>
                                <InputNumber readOnly value={telefonoCodeudor}
                                    mode="decimal" min={0} useGrouping={false} />
                            </div>
                        </div>
                        <div className="grid p-fluid mt-1">
                            {/* IMAGEN */}
                            <div className='contenedor_imagen'>
                                <label>Firma: </label>
                                {hayImagen ? <img src={firma} alt={` `} width={300}
                                    onError={() => setHayImagen(false)} />
                                    :
                                    <div>No se encontró la firma correspondiente</div>
                                }
                            </div>
                        </div>
                        {/* BOTONES */}
                        <br />
                        <div className="grid p-fluid">
                            <div className='col-12 md:col-3' style={{ marginLeft: 'auto', marginBottom: '0' }}>
                                <Link to="/cuotas/ver_pagos_convenio">
                                    <Button type='button' icon="pi pi-money-bill"
                                        label="Ver Pagos Convenio" className='p-button-success' />
                                </Link>
                            </div>
                            <div className='col-12 md:col-2' style={{ marginBottom: '0' }}>
                                <Link to="/cuotas/editar_convenio">
                                    <Button type='button' icon="pi pi-pencil"
                                        label="Editar Convenio" className='p-button-warning' />
                                </Link>
                            </div>
                            <div className='col-12 md:col-2' style={{ width: 'fit-content', marginRight: '0' }}>
                                <BotonExportarReporte
                                    Endpoint={exportarConvenio}
                                    datos={{
                                        Trabajador: selectedTrabajador,
                                        Convenio: convenio,
                                        Pagos: pagos,
                                        TotalPagado: totalPagado,
                                        CUICodeudor: CUICodeudor,
                                        NombreCodeudor: nombreCodeudor,
                                        TelefonoCodeudor: telefonoCodeudor
                                    }}
                                    nombreArchivo={'convenio'}
                                    size="medium"
                                    style={null}
                                    ocultar={['XLSX']}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </form>
        </div>
    );
}
