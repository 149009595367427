import './certificaciones.css';
import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { OverlayPanel } from 'primereact/overlaypanel';

import { formats } from './editor/formats';
import { modules } from './editor/modules';
import listarVariables from '../tools/listarVariables';
import {
  cellEditor, onCellEditCompleteCertificacion,
  ultimaModificacionBody, confirmarEdicionLibre, exportacionCertificacion
} from './constants';
import { getPlantillas, getPlantilla } from '../endpoints/plantillas';
import { crearCertificacion, exportarCertificacion } from '../endpoints/certificaciones';
import { mostrarError } from '../constants/bodiesYFiltos';

const CrearCertificacion = () => {
  const { quill, quillRef, Quill } = useQuill({
    modules: modules,
    formats: { formats },
  });

  const [variables, setVariables] = useState([]);
  const [guardarCertVisible, setGuardarCertVisible] = useState(false);
  const [nombreCertificacion, setNombreCertificacion] = useState('');
  const [edicionLibre, setEdicionLibre] = useState(false);

  const [selectPlantillaVisible, setSelectPlantillaVisible] = useState(false);
  const [plantillas, setPlantillas] = useState([]);
  const [plantillaSeleccionada, setPlantillaSeleccionada] = useState(null);
  const [contenidoPlantilla, setContenidoPlantilla] = useState(null);

  const toast = useRef(null);
  const op = useRef(null);

  useEffect(() => {
    // obtener listado de plantillas
    getPlantillas().then((resp) => {
      setPlantillas(resp.data);
      quill.disable();
    }).catch(err => {
      console.log(err);
    });
  }, [quill, Quill])

  if (Quill && !quill) {
    Quill.register('modules/blotFormatter', BlotFormatter);
  }

  const FooterPlantillas = () => {
    return <Button label="Ok" icon="pi pi-check"
      onClick={() => {
        // cargar contenido plantilla
        getPlantilla({ idPlantilla: plantillaSeleccionada.idPlantilla }).then((resp) => {
          // variables
          var vars = listarVariables(resp.data.Contenido);
          setVariables(vars);

          // contenido
          quill.root.innerHTML = resp.data.Contenido;
          setSelectPlantillaVisible(false);
          setContenidoPlantilla(resp.data.Contenido);
        }).catch(err => {
          console.log(err);
        });
      }
      } disabled={!plantillaSeleccionada} />;
  };

  const GuardarCertificacion = () => {
    var data = {
      Nombre: nombreCertificacion,
      Contenido: quill.root.innerHTML,
      idPlantilla: (!plantillaSeleccionada) ? null : plantillaSeleccionada.idPlantilla,
      Variables: variables
    }

    crearCertificacion(data).then((resp) => {
      if (resp.severity === "success") {
        toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
        setGuardarCertVisible(false);
        setNombreCertificacion('');
      } else {
        toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
      }
    }).catch(err => {
      mostrarError(err);
    });

  }

  const ExportarCertificacion = async (tipo) => {
    var data = {
      Nombre: nombreCertificacion,
      Contenido: quill.root.innerHTML,
      Tipo: tipo
    }

    exportacionCertificacion(exportarCertificacion, data);
  }

  const acceptEdicionLibre = () => {
    setEdicionLibre(!edicionLibre);
    quill.enable();
    setPlantillaSeleccionada(null);
    setVariables([]);
    toast.current.show({
      severity: 'info', summary: 'Edición libre activada',
      detail: 'Modo Edición libre activado', life: 3000
    });
  }

  return (
    <>
      <Toast ref={toast} />
      <div className='primario_editar_cert'>
        <br />
        <Link to="/cuotas/certificaciones" style={{ position: 'absolute' }}>
          <Button type="button" icon="pi pi-arrow-left" label="Regresar" size="small"
            className='p-button-info' />
        </Link>
        <br />
        {!edicionLibre ?
          <>
            <div className="grid p-fluid" style={{ width: '85%', margin: 'auto' }}>
              <div className='col-12 md:col-6'>
                <h3 style={{ color: 'black', textAlign: 'center' }}>Plantilla</h3>
                <div className="grid p-fluid">
                  <div className='col-12 md:col-8' >
                    <label className="font-bold block mb-2 text-color">Plantilla Seleccionada</label>
                    <InputText size="large" value={plantillaSeleccionada ? plantillaSeleccionada.Nombre : ''} readOnly />
                  </div>

                  <div className='col-12 md:col-4' style={{ marginTop: '8px' }}>
                    <br />
                    <Button label="Seleccionar" icon="pi pi-external-link"
                      onClick={() => setSelectPlantillaVisible(true)} />
                    <Dialog header="Seleccionar Plantilla" visible={selectPlantillaVisible}
                      style={{ width: '75vw' }} maximizable
                      modal contentStyle={{ height: '300px' }}
                      onHide={() => setSelectPlantillaVisible(false)}
                      footer={FooterPlantillas}>
                      <DataTable value={plantillas} scrollable scrollHeight="flex"
                        tableStyle={{ minWidth: '50rem' }}
                        selectionMode="single" selection={plantillaSeleccionada}
                        onSelectionChange={(e) => setPlantillaSeleccionada(e.value)}
                        onRowClick={(e) => setPlantillaSeleccionada(null)}
                        metaKeySelection={false}>
                        <Column field="Nombre" header="Nombre de la Plantilla"></Column>
                        <Column field="UltimaModificacion" header="Ultima Modificacion" body={ultimaModificacionBody}></Column>
                      </DataTable>
                    </Dialog>
                  </div>
                </div>
              </div>
              <div className='col-12 md:col-6'>
                <h3 style={{ color: 'black', textAlign: 'center' }}>Variables</h3>
                <DataTable value={variables} editMode="cell" scrollable scrollHeight="150px">
                  <Column key='NombreVariable' field='NombreVariable' header='Nombre de variable'
                    style={{ width: '50%' }} />
                  <Column key='Valor' field='Valor' header='Valor'
                    style={{ width: '50%' }}
                    editor={(options) => cellEditor(options)}
                    onCellEditComplete={(e) => onCellEditCompleteCertificacion(e, contenidoPlantilla, variables, quill)} />
                </DataTable>
              </div>
            </div><br />
          </> :
          <h3 style={{ color: 'black', textAlign: 'center' }}>Edición Libre</h3>
        }
        <br />

        <div className='col-12 md:col-10 editor-wrapper'>
          <div className='editor-container'>
            <div className='encabezado-content'>
              <h3 style={{ color: 'black' }}>Editor de texto</h3>
              <span className="p-buttonset">
                <Button size="small" label="Guardar" icon="pi pi-save"
                  onClick={() => setGuardarCertVisible(true)} />
                <Button size="small" label="Exportar" icon="pi pi-print" severity="help"
                  onClick={(e) => op.current.toggle(e)} />
                <OverlayPanel ref={op} style={{ width: '25%' }}>
                  <h6 style={{
                    width: 'fit-content', margin: 'auto',
                    marginBottom: '2%'
                  }}>Seleccionar formato</h6>
                  <div className="grid p-fluid" >
                    <div className='col-12 md:col-6' >
                      <Button type="button" label="PDF" severity='danger'
                        onClick={() => ExportarCertificacion('pdf')} size='small' />
                    </div>
                    <div className='col-12 md:col-6'>
                      <Button type="button" label="DOCX"
                        onClick={() => ExportarCertificacion('docx')} size='small' />
                    </div>
                  </div>
                </OverlayPanel>
                {!edicionLibre ? <>
                  <Button size="small"
                    label="Edición libre" icon="pi pi-pencil" severity="warning"
                    onClick={() => confirmarEdicionLibre(acceptEdicionLibre)} />
                  <ConfirmDialog />
                </> : <></>}
              </span>
              <Dialog header="Guardar nueva certificacion" visible={guardarCertVisible} style={{ width: '30%' }} onHide={() => setGuardarCertVisible(false)}>
                <label className="font-bold block mb-2 text-color">Nombre para la nueva certificación</label>
                <InputText value={nombreCertificacion} onChange={(e) => setNombreCertificacion(e.target.value)} />
                <Button label="Guardar" icon="pi pi-save" style={{ float: 'right' }}
                  onClick={GuardarCertificacion} />
              </Dialog>
            </div>

            <div className='editor'>
              <div style={{
                paddingLeft: '8%', paddingRight: '8%', fontSize: 'medium',
                color: 'black'
              }} ref={quillRef} />
            </div>
            <br /><br />

          </div>
        </div>
      </div>
    </>
  );
};

export default CrearCertificacion;