import '../calculo_cuotas.css';
import { Button } from 'primereact/button';
import { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { Checkbox } from "primereact/checkbox";

import { DatosTrabajador } from '../../constants/datos_trabajador';
import { bodyCuotaAPagar, bodyDiferencia, fechaMesBody } from './constants';
import { fechaBody, headerForm, moneyBody, mostrarError } from '../../constants/bodiesYFiltos';
import { BotonExportarReporte } from '../../constants/exportacion';
import { CalculosTotales } from './components/calculos_totales';
import { agregarObservacion, exportarOrdenpago, getMesesCobro, getPeriodosOrden } from '../../endpoints/calculo_cuotas/orden_pago';

export default function VerOrdenPago() {
    // Datos trabajador
    const [trabajador, setTrabajador] = useState([{}]);

    // Datos de la orden de pago
    const [idOrdenPago, setIdOrdenPago] = useState(null);
    const [observacion, setObservacion] = useState(null);

    // Datos de la orden
    const [interesesHasta, setInteresesHasta] = useState(new Date(2023, 7 - 1, 1));
    const [periodos, setPeriodos] = useState([]);

    // --------------------------------------------------  ORDEN DE PAGO
    const [mesesCobro, setMesesCobro] = useState([]);
    const [selected, setSelected] = useState(null);

    const [totalCuotaAPagar, setTotalCuotaAPagar] = useState(0);
    const [totalIntereses, setTotalIntereses] = useState(0);

    const toast = useRef(null);

    const [mostrarDetalleIntereses, setMostrarDetalleIntereses] = useState(false);

    useEffect(() => {
        // obtener el trabajador seleccionado
        var seleccionado = JSON.parse(sessionStorage.getItem('trabajador_seleccionado'));
        setTrabajador([seleccionado]);

        // obtener la orden seleccionada
        var orden_seleccionada = JSON.parse(sessionStorage.getItem('orden_seleccionada'));
        setIdOrdenPago(orden_seleccionada.idOrdenPago);
        setObservacion(orden_seleccionada.Observacion);
        setTotalCuotaAPagar(orden_seleccionada.TotalCuotaAPagar);
        setTotalIntereses(orden_seleccionada.TotalIntereses);
        setInteresesHasta(new Date(orden_seleccionada.InteresesHasta));

        // obtener los periodos de la orden de pago
        getPeriodosOrden({ idOrdenPago: orden_seleccionada.idOrdenPago }).then((resp) => {
            setPeriodos(resp.data);
        }).catch(err => {
            mostrarError(err);
        });

        // obtener los meses de cobro de la orden de pago
        getMesesCobro({ idOrdenPago: orden_seleccionada.idOrdenPago }).then((resp) => {
            setMesesCobro(resp.data);
        }).catch(err => {
            mostrarError(err);
        });
    }, []);


    const AgregarObservacion = () => {
        var obj = {
            Observacion: observacion,
            idOrdenPago: idOrdenPago
        }

        agregarObservacion(obj).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err);
        });
    };

    return (
        <div className='primario-crear-registro'>
            <Toast ref={toast} />
            {/* DATOS DEL TRABAJADOR */}
            <Card header={headerForm("Datos del trabajador", true)} className="card">
                <DatosTrabajador trabajador={trabajador} />
            </Card>
            <br />
            {/* PERIODOS UTILIZADOS */}
            <Card header={headerForm("Periodos Utilizados")} className="card">
                <DataTable value={periodos ? periodos : []} scrollable scrollHeight="flex"
                    tableStyle={{ minWidth: '50rem' }}>
                    <Column header="#" body={(rowData, rowIndex) => rowIndex.rowIndex + 1} />
                    <Column field="Anio" header="Año" />
                    <Column field="FechaInicio" header="Fecha Inicio"
                        body={(rowData) => fechaMesBody(rowData, 'FechaInicio')}></Column>
                    <Column field="FechaFinal" header="Fecha Final"
                        body={(rowData) => fechaMesBody(rowData, 'FechaFinal')}></Column>
                    <Column field="TasaInteres" header="Tasa Interes(%)" />
                </DataTable>
            </Card><br />
            {/* ORDEN DE PAGO */}
            <Card header={headerForm("Anexo cálculo de cuotas")} className="card">
                <form noValidate={false}>
                    <div className="grid p-fluid mt-1">
                        {/* INTERESES HASTA */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Intereses Hasta</label>
                            <Calendar required={true} value={interesesHasta} showButtonBar showIcon />
                        </div>
                        {/* OBSERVACION */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Observación</label>
                            <InputText value={observacion}
                                onChange={(e) => setObservacion(e.target.value)} />
                        </div>
                        {/* BOTONES */}
                        <div className='col-12 md:col-3' style={{ marginTop: '29px' }}>
                            <Button type='button' icon="pi pi-check" severity='success'
                                label="Guardar Observacion" className='p-button-primary'
                                onClick={AgregarObservacion} />
                        </div>
                        <div className='col-12 md:col-2'
                            style={{
                                marginTop: '29px', width: 'fit-content',
                                marginLeft: 'auto', marginRight: '0'
                            }}>
                            <BotonExportarReporte
                                Endpoint={exportarOrdenpago}
                                datos={{
                                    Trabajador: trabajador,
                                    Periodos: periodos,
                                    InteresesHasta: interesesHasta,
                                    MesesCobro: mesesCobro,
                                    TotalCuotaAPagar: totalCuotaAPagar,
                                    TotalIntereses: totalIntereses,
                                    FechaElaboracion: JSON.parse(sessionStorage.getItem('orden_seleccionada')).FechaElaboracion,
                                    Observacion: observacion,
                                    DetalleIntereses: mostrarDetalleIntereses
                                }}
                                nombreArchivo={'orden_pago'}
                                size="medium"
                                style={null}
                                ocultar={['DOCX']}
                                bajarFirmas={false}
                            />
                        </div>
                    </div>
                    {/* MOSTRAR DETALLE INTERESES */}
                    <div className="flex align-items-center" style={{ marginTop: '5px' }}>
                        <Checkbox onChange={() => setMostrarDetalleIntereses(!mostrarDetalleIntereses)} checked={mostrarDetalleIntereses} />
                        <label className="ml-2" style={{ marginTop: 'auto', marginBottom: '2px' }}>Mostrar Detalle de Intereses</label>
                    </div>
                    <br />
                    <DataTable value={mesesCobro} paginator rows={10} paginatorPosition="top"
                        rowsPerPageOptions={[10, 25, 50]}
                        tableStyle={{ minWidth: '50rem' }}
                        size='small'
                        emptyMessage="No se obtuvieron meses de cobro"
                    >
                        <Column field="FechaDescripcion" header="FechaDescripcion"
                            body={(rowData) => fechaMesBody(rowData, 'FechaDescripcion', false)}></Column>
                        <Column field="Cuota" header="Cuota(%)"></Column>
                        <Column field="SNominal" header="S.Nominal(Q)"
                            body={(rowData) => moneyBody(rowData, 'SNominal')}></Column>
                        <Column field="SRecibido" header="S.Recibido(Q)"
                            body={(rowData) => moneyBody(rowData, 'SRecibido')}></Column>
                        <Column header="Diferencia(Q)" body={bodyDiferencia}></Column>
                        <Column header="Cuota a Pagar(Q)" body={bodyCuotaAPagar}></Column>
                        <Column field="TMes" header="TMes" ></Column>
                        <Column field="S" header="S(Q)"
                            body={(rowData) => moneyBody(rowData, 'S')}></Column>
                        <Column field="Intereses" header="Intereses(Q)"
                            body={(rowData) => moneyBody(rowData, 'Intereses')}></Column>
                        {/* DETALLE INTERESES */}
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((col, i) => (
                            mostrarDetalleIntereses && <Column key={col.field} field={`IPer${col}`} header={`I.Per${col}.`} />
                        ))}
                    </DataTable>
                    <br />
                    <div className="grid p-fluid mt-1">
                        <CalculosTotales
                            totalCuotaAPagar={totalCuotaAPagar}
                            totalIntereses={totalIntereses}
                        />
                    </div>
                </form>
            </Card>
            <br />
        </div>
    );
}
