
/* Obtener bancos de nóminas */
const get_bancos = async (format = 'none') => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_URL_OBTENER_BANCOS)
            .then((response) => response.json())
            .then((data) => {
                let tmp = [];
                if (data.error) { console.error(data.error); return resolve(tmp); }
                for (let i = 0; i < data.data.length; i++) {
                    const banco = data.data[i];
                    switch (format) {
                        case 'dropdown':
                        case 'radiobutton':
                            tmp.push({ label: banco.nombre, value: banco.idbanco });
                            break;
                        default:
                            tmp.push(banco);
                            break;
                    }
                }
                // console.log(tmp)
                return resolve(tmp);
            });
    });
}

export default get_bancos;
