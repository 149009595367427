import { Toast } from 'primereact/toast';
import './proceso.css';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useNavigate, useParams } from "react-router-dom";
import { Steps } from 'primereact/steps';
import { useEffect, useRef, useState } from 'react';
import Variaciones from './variaciones';
import ListadoGeneral from './listado';
import ListadoLiquido from './liquido';
import ResumenNomina from './resumen';
import { Dialog } from 'primereact/dialog';
import NominafileUpload from './fileUpload';
import EstadosNomina from './estados_bitacora';
import GuardarNomina from './guardar_nomina';
import { requestOptions } from '../misc/requestOptions';

function ProcesarNomina() {
    const navigate = useNavigate();
    const { id } = useParams();
    const rol = +localStorage.getItem('rol');

    const BreadCrumbItems = [
        { label: 'NÓMINA', command: () => { navigate("/nominas") } },
        { label: 'SELECCIONAR NÓMINA', command: () => { navigate("/nominas/buscar") } },
        { label: id }
    ];
    const home = { icon: 'pi pi-home', command: () => { navigate("/") } };

    const toast = useRef(null);

    const [activeIndex, setActiveIndex] = useState(0);
    const [validated, setValidated] = useState(false);
    const [displayDialog, setDisplayDialog] = useState(null);

    const [tipoNomina, setTipoNomina] = useState(null);
    const [estadoNomina, setEstadoNomina] = useState(null);

    const [StepsItems, setStepsItems] = useState(null);

    const validar_nomina = () => {
        setValidated(false);
        fetch((process.env.REACT_APP_API_URL_OBTENER_NOMINA),
            requestOptions('POST', { idnomina: id }))
            .then((response) => response.json())
            .then((data) => {
                const x = data.data;
                if (!x) {
                    navigate("/nominas", { replace: true });
                    return;
                }
                if (data.error) toast.current.show({ severity: data.severity, summary: data.error });
                if (x.estadonomina_idestadonomina >= 3) setActiveIndex(5);
                else if (x.estadonomina_idestadonomina > 1) setActiveIndex(4);
                else if (x.tiponomina_idtiponomina != 1) setActiveIndex(1);
                let steps = [
                    { label: 'Variaciones' },
                    { label: (x.tiponomina_idtiponomina === 1 ? 'Guardar nómina' : x.tiponomina_idtiponomina === 2 || x.tiponomina_idtiponomina === 7 ? 'Carga nómina' : 'Cálculos prestación') },
                    { label: 'Listado nómina' },
                    { label: 'Listado líquido' },
                    { label: 'Resumen nómina' },
                    { label: 'Estados nómina' }
                ];
                setStepsItems(steps);
                setEstadoNomina(x.estadonomina_idestadonomina);
                setTipoNomina(x.tiponomina_idtiponomina);
                setValidated(true);
            });
    }

    useEffect(() => {
        if (isNaN(id)) { // Validar que el parámetro :id sea un número
            navigate("/notfound", { replace: true });
        } else
            validar_nomina();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderComponent = () => {
        if (rol > 4) {
            if (estadoNomina >= 3)
                return <EstadosNomina toast={toast} />
            return <h1 className='mt-8 text-center'>La nómina no ha sido aprobada.</h1>
        }
        switch (activeIndex) {
            case 0:
                return <Variaciones toast={toast} estadoNomina={estadoNomina} setActiveIndex={() => setActiveIndex(1)} />
            case 1:
                if (tipoNomina == 1) return <GuardarNomina id={id} toast={toast} setActiveIndex={() => setActiveIndex(2)} />
                return <NominafileUpload toast={toast} setDisplayDialog={setDisplayDialog} tipoNomina={tipoNomina} setActiveIndex={() => setActiveIndex(2)} />
            case 2:
                return <ListadoGeneral toast={toast} setDisplayDialog={setDisplayDialog} setActiveIndex={() => setActiveIndex(3)} />
            case 3:
                return <ListadoLiquido toast={toast} setDisplayDialog={setDisplayDialog} setActiveIndex={() => setActiveIndex(4)} />
            case 4:
                return <ResumenNomina aprobable={rol <= 4} toast={toast} setActiveIndex={() => setActiveIndex(5)} />
            case 5:
                return <EstadosNomina toast={toast} />
            default:
                return <></>
        }
    };

    const validarStep = (onSelect) => {
        if (rol > 4) return;
        switch (onSelect) {
            case 0:
                if (tipoNomina != 1) return;
                break;
            case 1:
                if (estadoNomina >= 2) return;
                break;
            case 5:
                if (estadoNomina < 3) return;
                break;
            default:
                if (estadoNomina === 1)
                    if (onSelect > 1) return;
                break;
        }
        setActiveIndex(onSelect);
    };

    return (
        <div className="container__procesarNomina">
            <Toast ref={toast}></Toast>
            <BreadCrumb model={BreadCrumbItems} home={home} />
            {
                StepsItems &&
                <Steps className='steps__nominas' model={StepsItems} activeIndex={activeIndex} onSelect={(e) => { validarStep(e.index) }} readOnly={false} />
            }
            {
                validated && renderComponent()
            }
            <Dialog header={displayDialog && displayDialog.header} headerStyle={{ paddingTop: '8px', paddingBottom: '8px' }}
                visible={displayDialog !== null} position={'center'} modal style={{ minWidth: '20%' }}
                onHide={() => setDisplayDialog(null)} draggable={true} resizable={false}>
                <p className="m-0">{displayDialog && displayDialog.content}</p>
            </Dialog>
        </div>
    );
}

export default ProcesarNomina;
