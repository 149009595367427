import '../calculo_cuotas.css';
import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { useSessionStorage } from 'primereact/hooks';
import { locale, addLocale } from 'primereact/api';

import data from '../../diccionario.json';
import { fechaBody, columnaConFiltros, containsFilter, renderRedirect, BotonRegresar, mostrarError, moneyBody, moneyFormat } from '../../constants/bodiesYFiltos';
import { getIntegraciones, eliminarIntegracion } from '../../endpoints/calculo_cuotas/integraciones';

locale('es');
addLocale('es', data.es);

export default function Integraciones() {
    const [filters] = useState({
        RegistroTrabajador: containsFilter,
        CUI: containsFilter,
        Nombre: containsFilter,
    });
    const [integraciones, setIntegraciones] = useSessionStorage([], 'integraciones');
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        setLoading(true);

        getIntegraciones().then((resp) => {
            setIntegraciones(resp.data);
            setLoading(false);
        }).catch(err => {
            mostrarError(err, setLoading(false), setIntegraciones([]));
        });
    }, []);

    const aVerIntegracion = () => {
        if (selected === null)
            return
        sessionStorage.setItem('integracion_seleccionada', JSON.stringify(selected));
        setSelected(null);
        setRedirect(true);
    }

    const acceptDelete = () => {
        eliminarIntegracion({ idIntegracion: selected.idIntegracion }).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                // quitar la integracion
                var del = integraciones.filter(integracion => integracion.idIntegracion !== selected.idIntegracion);
                setIntegraciones(del);
                setSelected(null);
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err, setLoading(false), setIntegraciones([]));
        });

    }

    const EliminarIntegracion = () => {
        if (!selected)
            return

        confirmDialog({
            message: '¿Está seguro de eliminar la integración seleccionada?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptDelete(),
        });
    }

    return (
        <>
            {/* PARA LA CONFIRMACIÓN DE ELIMINAR REGISTRO */}
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className='primario'>
                <br />
                <div className="grid p-fluid">
                    <BotonRegresar absolute={false} />
                    <div className='col-12 md:col-2' style={{ marginLeft: 'auto' }}>
                        <Button type="button" icon="pi pi-eye" label="Ver Integración"
                            severity="success" disabled={selected === null}
                            onClick={(e) => aVerIntegracion()} />
                    </div>
                    <div className='col-12 md:col-3'>
                        <Button type="button" icon="pi pi-trash" label="Eliminar Integración"
                            severity="danger" disabled={selected === null}
                            onClick={EliminarIntegracion} />
                    </div>
                    <div className='col-12 md:col-3'>
                        <Link to="/cuotas/crear_integracion">
                            <Button type="button" icon="pi pi-plus" label="Agregar Integración" />
                        </Link>
                    </div>
                </div>
                <br />

                <DataTable value={integraciones} paginator rows={10} paginatorPosition="top"
                    rowsPerPageOptions={[10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                    filters={filters}
                    size='small'
                    loading={loading}
                    selectionMode='single'
                    selection={selected}
                    onSelectionChange={(e) => { setSelected(e.value) }}
                    onRowClick={() => { setSelected(null) }}
                    emptyMessage="No hay integraciones registrados"
                >
                    {columnaConFiltros("RegistroTrabajador", "Registro Trabajador", true, "Buscar por registro")}
                    {columnaConFiltros("CUI", "CUI", true, "Buscar por CUI")}
                    {columnaConFiltros("Nombre", "Nombre", true, "Buscar por nombre")}
                    <Column body={(rowData) => fechaBody(rowData, 'FechaElaboracion')}
                        header="Fecha Elaboración"></Column>
                    <Column header="Total salario no descontado(Q)" field="TotalSueldoNoDescontado"
                        body={(rowData) => moneyBody(rowData, 'TotalSueldoNoDescontado')}></Column>
                    <Column header="Total cuota no descontada(Q)"
                        body={(rowData) => moneyFormat(rowData.TotalSueldoNoDescontado * 10.58 / 100)}></Column>
                    <Column header="Total cuota descontada en código 14(Q)"
                        body={(rowData) => moneyFormat(rowData.TotalDescontado)}></Column>
                    <Column header="Monto pendiente de pago(Q)"
                        body={(rowData) => moneyFormat(
                            (rowData.TotalSueldoNoDescontado * 10.58 / 100) -
                            rowData.TotalDescontado)}
                    ></Column>
                </DataTable>
                <br />
                {renderRedirect(redirect, 'ver_integracion')}
            </div>
        </>
    );
}
