import './calculo_cuotas.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

export default function MenuCalculos() {
    return (
        <div className='menu-calculo-cuotas'>
            <br /><br /><br /><br />
            <div className="grid p-fluid">
                <Card className="card">
                    <h2 style={{ textAlign: 'center' }}>¿Qué cálculos desea realizar?</h2>
                    <div style={{ width: '80%', margin: 'auto', marginTop: '5%', marginBottom: '5%' }}>
                        <Link to="/cuotas/integraciones">
                            <Button type="button" icon="pi pi-calendar" label="Integraciones"
                                size='large' />
                        </Link>
                    </div>
                    <div style={{ width: '80%', margin: 'auto', marginTop: '5%', marginBottom: '5%' }}>
                        <Link to="/cuotas/seleccion_trabajador">
                            <Button type="button" icon="pi pi-money-bill" label="Ordenes de Pago"
                                severity="success" size='large' />
                        </Link>
                    </div>
                </Card>
            </div>
        </div>
    );
}
