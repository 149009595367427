import './menu_certificaciones.css';
import { useEffect, useState } from 'react';
import { TabView, TabPanel, } from 'primereact/tabview';
import Certificaciones from './certificaciones';
import Plantillas from './plantillas/plantillas';

export default function MenuCertificaciones() {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        var tab = sessionStorage.getItem('indice_menu_cert');
        tab && setActiveIndex(parseInt(tab));
    }, []);

    function changeTab(indice) {
        setActiveIndex(indice);
        sessionStorage.setItem('indice_menu_cert', indice);
    }

    return (
        <div className="menu__certificaciones">
            <TabView activeIndex={activeIndex} onTabChange={(e) => changeTab(e.index)}>
                <TabPanel header="Certificaciones emitidas" leftIcon='mr-2 pi pi-file-word'>
                    <Certificaciones />
                </TabPanel>
                <TabPanel header="Plantillas" leftIcon='mr-2 pi pi-file-edit'>
                    <Plantillas />
                </TabPanel>
            </TabView>
        </div>
    );
}
