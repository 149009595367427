import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressBar } from 'primereact/progressbar';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import ConfirmarNomina from './confirmar_nomina';
import { requestOptions } from '../misc/requestOptions';

function ResumenNomina(props) {

    const { id } = useParams();
    const navigate = useNavigate();

    const [resumen, setResumen] = useState([]);
    const [nominaInfo, setNominaInfo] = useState({ tipo_nomina: "", month: "", year: "", observacion: "" });
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [bancoIndex, setBancoIndex] = useState(0);
    const [estado_nomina, setEstado] = useState(null);

    const get_resumen = () => {
        setLoading(true);
        setLoaded(false);
        fetch((process.env.REACT_APP_API_URL_RESUMEN_NOMINA),
            requestOptions('POST', { idnomina: id }))
            .then((response) => response.json())
            .then((data) => {
                props.toast.current.show({ severity: data.severity, summary: data.message || data.error });
                const x = data.data;
                if (x.info) {
                    setNominaInfo(x.info);
                    setEstado(x.info.estado);
                }
                x.resumen && setResumen(x.resumen);
                setLoaded(true);
                setLoading(false);
            });
    }

    useEffect(() => {
        get_resumen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* PARA EXPORTAR A PDF */
    const exportPdf = () => {
        const banco = resumen.find(banco => banco.idbanco === bancoIndex + 1);
        const data = [banco.nominal, banco.bonos, banco.descuentos, banco.liquido];
        const fname = `${id}_${banco.nombre}`;
        const title = `RESUMEN ${banco.nombre}`;
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const encabezado = `${nominaInfo.tipo_nomina}
Mes: ${nominaInfo.month}
Año: ${nominaInfo.year}
ID: ${id}
Observaciones: ${nominaInfo.observacion}`;
                const doc = new jsPDF.default(0, 0);
                let textX = doc.internal.pageSize.getWidth() / 2; // Centrar horizontalmente
                let textY = 20; // Posición vertical
                doc.setFont('helvetica', 'bold'); // Establecer la fuente en negrita
                doc.text(title, textX, textY, { align: 'center' });
                doc.setFont('helvetica', 'normal'); // Restaurar el estilo de fuente normal
                textX = 15;
                textY = 35;
                doc.text(encabezado, textX, textY, { align: 'left' });
                doc.autoTable({
                    head: [['Nominal', 'Bonificaciones', 'Descuentos', 'Líquido']],
                    body: [data],
                    startY: 70,
                    theme: 'striped',
                    margin: { left: 10 },
                });
                doc.setFontSize(12);
                doc.text(new Date().toLocaleString(), doc.internal.pageSize.getWidth() - 50, doc.internal.pageSize.height - 10);
                doc.save(fname + '.pdf');
            })
        })
    }

    const header = (
        <div className="header__resumen">
            TOTALES
            <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} style={{ backgroundColor: '#930001', border: 'none' }} tooltip='Exportar PDF' />
        </div>
    );

    const montos_table = (data) => (
        <DataTable style={{ width: 'fit-content', margin: 'auto' }} value={data} header={header} emptyMessage="Sin resultados.">
            <Column align={'center'} field="nominal" header="Nominal"></Column>
            <Column align={'center'} field="bonos" header="Bonificaciones" ></Column>
            <Column align={'center'} field="descuentos" header="Descuentos" ></Column>
            <Column align={'center'} field="liquido" header="Líquido"></Column>
        </DataTable>
    );

    const tabpanels = () => {
        return resumen.map((banco, i) => {
            return <TabPanel key={`tp_${i}`} header={banco.nombre}>
                {montos_table([banco])}
            </TabPanel>
        })
    };

    const nextStep = () => {
        const className = `p-button-rounded p-button-raised font-weight-bold`;
        if (estado_nomina === 2 && props.aprobable)
            return <ConfirmarNomina id={id} toast={props.toast} setEstadoNomina={() => setEstado(3)} />
        if (estado_nomina >= 3)
            return <div className='flex justify-content-center'>
                <Button onClick={() => navigate("/nominas")} className={`${className} mr-4`} icon='pi pi-check-circle' label='Regresar a Menú Nóminas' />
                <Button onClick={() => props.setActiveIndex()} className={`p-button-success ${className}`} icon='pi pi-arrow-right' label='Bitácora de estados' iconPos='right' />
            </div>
        return <></>
    };

    return (
        <div className="container__listado">
            {
                loading ?
                    <ProgressBar mode="indeterminate" style={{ height: '8px', marginTop: '200px' }} />
                    :
                    <div style={{ marginTop: '26px' }}>
                        <div className='header__NominaInfo pt-2'>
                            <h1>{nominaInfo.tipo_nomina}</h1>
                            <h4>Mes: {nominaInfo.month}</h4>
                            <h4>Año: {nominaInfo.year}</h4>
                            <h4>Observaciones: {nominaInfo.observacion}</h4>
                        </div>
                        <TabView className='tabview__NominaMontos' panelContainerClassName='p-1' activeIndex={bancoIndex} onTabChange={(e) => setBancoIndex(e.index)}>
                            {tabpanels()}
                        </TabView>
                        {
                            loaded && <div className='btn__nextSetp'>{nextStep()}</div>
                        }
                    </div>

            }
        </div>
    );
}

export default ResumenNomina;
