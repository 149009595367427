import { requestOptions } from "./requestOptions";
const api = process.env.REACT_APP_API_URL_CUOTAS || "http://localhost:8091/cuotas/"

export async function getHistorialPeriodos() {
    const url = api + "periodos/get_historial_periodos"
    const response = await fetch(url, requestOptions("GET", null));
    return response.json();
}

export async function crearPeriodo(data = {}) {
    const url = api + "periodos/crear_periodo"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function editarPeriodo(data = {}) {
    const url = api + "periodos/editar_periodo"
    const response = await fetch(url, requestOptions("PUT", JSON.stringify(data)));
    return response.json();
}

export async function eliminarPeriodo(data = {}) {
    const url = api + "periodos/eliminar_periodo/" + data.idPeriodo
    const response = await fetch(url, requestOptions("DELETE", null));
    return response.json();
}


