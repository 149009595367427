export const estados_trabajador = [
  {
      "idEstadoTrabajador": 1,
      "Nombre": "Activo",
      "Afiliacion": 1,
      "RequiereFechaInicio": false,
      "RequiereFechaFin": false
  },
  {
      "idEstadoTrabajador": 2,
      "Nombre": "Permiso",
      "Afiliacion": 1,
      "RequiereFechaInicio": true,
      "RequiereFechaFin": true
  },
  {
      "idEstadoTrabajador": 3,
      "Nombre": "Suspensión",
      "Afiliacion": 1,
      "RequiereFechaInicio": true,
      "RequiereFechaFin": true
  },
  {
      "idEstadoTrabajador": 4,
      "Nombre": "Fallecimiento",
      "Afiliacion": 1,
      "RequiereFechaInicio": true,
      "RequiereFechaFin": false
  },
  {
      "idEstadoTrabajador": 5,
      "Nombre": "Retiro",
      "Afiliacion": 1,
      "RequiereFechaInicio": true,
      "RequiereFechaFin": false
  },
  {
      "idEstadoTrabajador": 6,
      "Nombre": "Contribuyente Voluntario",
      "Afiliacion": 1,
      "RequiereFechaInicio": true,
      "RequiereFechaFin": false
  },
  {
      "idEstadoTrabajador": 7,
      "Nombre": "Renuncia",
      "Afiliacion": 0,
      "RequiereFechaInicio": true,
      "RequiereFechaFin": false
  },
  {
      "idEstadoTrabajador": 8,
      "Nombre": "Ingreso con 45 años o más",
      "Afiliacion": 0,
      "RequiereFechaInicio": false,
      "RequiereFechaFin": false
  }
]