import './gestion_trabajadores.css';
import { useRef, useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { locale, addLocale } from 'primereact/api';
import data from '../diccionario.json';
import {
    generoBody, afiliacionTrabajadorBody,
    afiliacionTrabajadorFilter, estadoTrabajadorFilter
} from '../constants/datos_trabajador';
import {
    containsFilter, columnaConFiltros, fechaBody, renderRedirect, mostrarError
} from '../constants/bodiesYFiltos';
import { BotonExportarTabla } from '../constants/exportacion';
import {
    getTrabajadores, eliminarTrabajador, exportarTabla
} from '../endpoints/gestion_trabajadores';

locale('es');
addLocale('es', data.es);

export default function GestionTrabajadores() {
    const [filters] = useState({
        Registro: containsFilter,
        CUI: containsFilter,
        Nombre: containsFilter,
        Afiliacion: containsFilter,
        idEstadoTrabajador: containsFilter
    });
    const [trabajadores, setTrabajadores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [destino, setDestino] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        setLoading(true);
        // obtener trabajadores
        getTrabajadores().then((resp) => {
            setTrabajadores(resp.data);
            setLoading(false);
        }).catch(err => {
            mostrarError(err, setLoading(false));
        });
        //setLoading(false);
    }, []);

    const redireccionar = (destino) => {
        if (destino !== 'crear_trabajador') {
            if (!selected)
                return
            sessionStorage.setItem('trabajador_seleccionado', JSON.stringify(selected));
        }
        setDestino(destino);
        setSelected(null);
        setRedirect(true);
    }

    const accept = () => {
        eliminarTrabajador({ Registro: selected.Registro }).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                // quitar el trabajador
                var del = trabajadores.filter(trabajador => trabajador.Registro !== selected.Registro);
                setTrabajadores(del);
                setSelected(null);
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err, setLoading(false));
        });
    }

    const EliminarTrabajador = () => {
        if (!selected)
            return

        confirmDialog({
            message: 'Está seguro de eliminar el trabajador seleccionado?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept
        });
    }

    return (
        <>
            {/* PARA LA CONFIRMACIÓN DE ELIMINAR REGISTRO */}
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className='primario'>
                <div className="grid p-fluid" style={{ paddingTop: '2%' }}>
                    <div className='col-12 md:col-3' style={{ marginLeft: 'auto' }}>
                        <Button type="button" icon="pi pi-plus" label="Agregar Trabajadores"
                            onClick={(e) => redireccionar('crear_trabajador')} />
                    </div>
                    <div className='col-12 md:col-2'>
                        <Button type="button" icon="pi pi-eye" label="Ver Detalle"
                            severity="help" disabled={selected === null}
                            onClick={() => redireccionar('ver_trabajador')} />
                    </div>
                    <div className='col-12 md:col-2'>
                        <Button type="button" icon="pi pi-pencil" label="Editar Trabajador"
                            severity="warning" disabled={selected === null}
                            onClick={() => redireccionar('editar_trabajador')} />
                    </div>
                    <div className='col-12 md:col-2'>
                        <Button type="button" icon="pi pi-file" label="Ver Contratos"
                            severity="success" disabled={selected === null}
                            onClick={() => redireccionar('ver_contratos')} />
                    </div>
                    <div className='col-12 md:col-3'>
                        <Button type="button" icon="pi pi-trash" label="Eliminar Trabajador"
                            severity="danger" disabled={selected === null}
                            onClick={EliminarTrabajador} />
                    </div>
                </div>
                <br />
                <DataTable value={trabajadores} paginator rows={10} paginatorPosition="top"
                    rowsPerPageOptions={[10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                    filters={filters}
                    stateStorage="session" stateKey="dt-gestion_trabajadores"
                    size='small'
                    paginatorRight={
                        BotonExportarTabla(
                            exportarTabla,
                            trabajadores,
                            'dt-gestion_trabajadores',
                            'trabajadores')
                    }
                    loading={loading}
                    selectionMode='single'
                    selection={selected}
                    onSelectionChange={(e) => { setSelected(e.value) }}
                    onRowClick={() => { setSelected(null) }}
                >
                    {columnaConFiltros("Registro", "Registro Personal", true, "Buscar por registro")}
                    {columnaConFiltros("CUI", "CUI", true, "Buscar por CUI")}
                    {columnaConFiltros("Nombre", "Nombre", true, "Buscar por nombre")}
                    <Column field="Genero" header="Genero" body={generoBody}></Column>
                    <Column field="FechaNacimiento" header="Fecha de Nacimiento"
                        body={(rowData) => fechaBody(rowData, "FechaNacimiento")}></Column>
                    <Column field="FechaIngreso" header="Fecha de Ingreso"
                        body={(rowData) => fechaBody(rowData, "FechaIngreso")}></Column>
                    <Column field="EdadActual" header="Edad Actual"></Column>
                    <Column field="EdadIngreso" header="Edad de Ingreso"></Column>
                    <Column field="Afiliacion"
                        header="Afiliado a Plan de Prestaciones"
                        filter filterField="Afiliacion"
                        body={afiliacionTrabajadorBody}
                        filterElement={afiliacionTrabajadorFilter}
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showFilterOperator={false}
                        showApplyButton={false} showClearButton={false}>
                    </Column>
                    <Column field="NombreEstado"
                        header="Estado"
                        filter filterField="idEstadoTrabajador"
                        filterElement={estadoTrabajadorFilter}
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showFilterOperator={false}
                        showApplyButton={false} showClearButton={true}>
                    </Column>
                </DataTable>
                <br />
                {renderRedirect(redirect, destino)}
            </div>
        </>
    );
}
