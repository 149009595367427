import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import './busqueda_usuarios.css'
import { get_persona_nombre_registro_route } from '../../constants/backend_routes';

const SearchBar = ({ updateUsers }) => {
  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [
    { name: 'Nombre', code: 'NY' },
    { name: 'Identificacion', code: 'RM' },
  ];


  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const get_users = async (params) => {
    const request = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(params)
    }
    const response = await fetch(get_persona_nombre_registro_route, request)
    const responseJson = await response.json()
    return responseJson.data

  }

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    // Aquí puedes realizar la lógica de búsqueda
    const body = {
      "registro" : searchQuery,
      "nombre": searchQuery
    }
    let aux_users = []
    let users = await get_users(body)
    users.forEach(raw_user => {
      const user = {
        name: `${raw_user.nombres} ${raw_user.apellidos}`,
        comment: raw_user.comentario,
        address: raw_user.direccion,
        email: raw_user.correo,
        state: raw_user.estado,
        gender: raw_user.genero,
        start_date: raw_user.fecha_alza,
        id_number: raw_user.numero_dpi,
        ref_number: raw_user.registro,
        tel: raw_user.telefono,
        id_usuario: raw_user.id_persona
    }
    aux_users.push(user)
    updateUsers(aux_users)
    });    
  };

  return (
    <form className="search-bar" onSubmit={handleSearchSubmit}>
      {/* <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name"
      editable placeholder="Filtrar por" className="w-full md:w-14rem custom-filter" /> */}
      <input
        type="text"
        placeholder="Buscar..."
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <button type="submit">Buscar</button>
    </form>
  );
};

export default SearchBar;