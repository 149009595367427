import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { FileUpload } from 'primereact/fileupload';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dropdown } from 'primereact/dropdown';
import { errors } from './temporales';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import './migracion.css';
import { get_payment_requirements_route, attend_payment_requirement_route, next_payment_requirement_route, obtain_requirements_route, update_payment_requirements_massive_route } from '../../constants/backend_routes';

const columnClass = (field, header, filter, filterPlaceholder) => {
    return <Column
        filter={filter}
        filterPlaceholder={filterPlaceholder}
        field={field}
        showFilterMatchModes={false}
        showAddButton={false}
        showFilterOperator={false}
        header={header}></Column>
}

const PaymentsRequirementModule = ({ reportData }) => {
    const [atendidos, setAtendidos] = useState()
    const [desatendidos, setDesatendidos] = useState()
    const [info, setInfo] = useState()
    const [files, setFiles] = useState([]);
    const op = useRef(null);
    const fileUploaded = useRef(null)
    const [fecha, setFecha] = useState();


    function convertirFormato(fechaISO) {
        const fecha = new Date(fechaISO);

        // Obtener los componentes de la fecha
        const dia = fecha.getDate().toString().padStart(2, '0');
        const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        const año = fecha.getFullYear().toString().slice(-2);

        // Formatear la fecha como 'dd/mm/yy'
        const fechaFormateada = `${dia}/${mes}/${año}`;

        return fechaFormateada;
    }


    const button_style = {
        'color': "#fff",
        'backgroundColor': '#337ab7',
        'borderColor': '#2e6da4',
        'margin': '5px'
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(obtain_requirements_route)
            const responseJson = await response.json()
            setAtendidos(responseJson.atendidos)
            setDesatendidos(responseJson.desatendidos)
            setInfo(responseJson.informacion)
        }
        fetchData()
    }, [])

    const consumirActualizacion = async () => {
        const fetchData = async () => {
            const response = await fetch(get_payment_requirements_route)
            const responseJson = await response.json()
            setAtendidos(responseJson.atendidos)
            setDesatendidos(responseJson.desatendidos)
            setInfo(responseJson.informacion)
        }
        fetchData()
    }
    const atenderRequerimiento = async (partida) => {
        const fetchData = async () => {
            const response = await fetch(attend_payment_requirement_route, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'partida': partida })
            })
            const responseJson = await response.json()
        }
        fetchData()
    }
    const siguienteRequerimiento = async (partida) => {
        const fetchData = async () => {
            const response = await fetch(next_payment_requirement_route, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'partida': partida })
            })
            const responseJson = await response.json()
        }
        fetchData()
    }

    const onUpload = () => {
        const uploadedFiles = fileUploaded.current.getFiles();
        setFiles(uploadedFiles);

        // Acceder al contenido del archivo si es un archivo de texto plano
        uploadedFiles.forEach(file => {
            Papa.parse(file, {
                complete: async (result) => {
                    // El resultado es un objeto que contiene datos, errores, etc.
                    console.log('Resultado del parsing CSV:', result);

                    // Aquí puedes realizar cualquier manipulación de datos necesaria
                    const jsonData = result.data;
                    console.log(jsonData)
                    await submitLoans({ requirements: jsonData })
                },
                header: true, // Si tu archivo CSV tiene encabezados
            });
        });
    };

    const submitLoans = async (loans) => {
        const response = await fetch(update_payment_requirements_massive_route, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(loans)
        })
        if (!response.ok) {
            return false
        } else {
            return true
        }
    }

    const obtenerRequerimiento = (estado) => {
        switch (estado) {
            case 1:
                return '1er requerimiento (2 cuotas)'
            case 2:
                return '2do requerimiento (3 cuotas)'
            case 3:
                return '3er requerimiento (4 cuotas)'
            case 4:
                return 'Proceder a juridico (+4 cuotas)'
        }
        return 'indefinido'
    }

    const convertJsonToExcel = (jsonData, fileName) => {
        const worksheet = XLSX.utils.json_to_sheet(jsonData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

        // Crear el archivo Excel y guardarlo en el sistema de archivos
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    };


    const generarReporte = () => {
        let total = [...atendidos, ...desatendidos];

        for (let requerimiento of total) {
            let partidaObjetivo = requerimiento.partida
            const indiceTotal = total.findIndex(objeto => objeto.partida === partidaObjetivo);
            const indiceInfo = info.findIndex(objeto => objeto.partida === partidaObjetivo)

            if (indiceTotal !== -1 && indiceInfo !== -1) {
                for (let objetoInfo of info[indiceInfo].informacion){
                    const fechaIngresada = new Date(fecha);
                    const fechaObjeto = new Date(objetoInfo.fecha)
                    console.log(fechaObjeto)
                    console.log(fechaIngresada)
                    if (fechaObjeto > fechaIngresada) {
                        total[indiceTotal].requerimiento_de_pago = objetoInfo.requerimiento
                        total[indiceTotal].requerimiento_atendido = objetoInfo.atentido
                    }
                }
            }
        }

        convertJsonToExcel(total, 'ReporteRequerimientos')
    }

    return (
        <>
            {atendidos && desatendidos &&
                <div className='container'>

                    <h1>Visualizacion de Requerimientos de pago</h1>
                    <br />
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-key"></i>
                        </span>
                        <span className="p-float-label">
                            <Calendar dateFormat="dd/mm/yy" value={fecha} onChange={(e) => setFecha((e.value))}></Calendar>
                            <label>Fecha de reporte de comentarios</label>
                        </span>
                    </div>
                    <br />
                    <Button label='Actualizar requerimientos' severity='info' onClick={() => consumirActualizacion()} style={{ 'margin': '5px' }} />
                    <Button label='Generar Reporte' severity='info' onClick={() => generarReporte()} style={{ 'margin': '5px' }} />

                    <Button label="Carga masiva" style={button_style} icon="pi pi-check" onClick={(e) => op.current.toggle(e)} className="p-button-text" />
                    <OverlayPanel ref={op} showCloseIcon>
                        <FileUpload
                            name="demo"
                            multiple
                            accept="text/csv"
                            maxFileSize={1000000}
                            ref={fileUploaded}
                            // onSelect={(e) => setFiles(e.files)}
                            onClear={onUpload}
                            emptyTemplate={<p className="m-0">Carga de saldos</p>} />
                    </OverlayPanel>
                    <br />
                    <br />
                    <h2>Atendidos</h2>
                    <br />
                    {
                        <div className='row text-center'>
                            {atendidos.map((requerimiento) =>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-5">

                                    <Card>
                                        <h3>{requerimiento.partida}</h3>
                                        <p>Cuotas pendientes: {requerimiento.cuotas_pendientes}</p>
                                        <p>Atendido: {requerimiento.requerimiento_atendido ? '✅' : '❌'}</p>
                                        <p>Fase: {obtenerRequerimiento(requerimiento.requerimiento_de_pago)}</p>
                                        <Button label='Atender requerimiento' severity='info' onClick={() => atenderRequerimiento(requerimiento.partida)} />
                                        <Button label='Siguiente requerimiento' severity='info' onClick={() => siguienteRequerimiento(requerimiento.partida)} />
                                    </Card>
                                </div>
                            )}
                        </div>
                    }
                    <h2>Desatendidos</h2>
                    <br />
                    {
                        <div className='row text-center'>
                            {desatendidos.map((requerimiento) =>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
                                    <Card>
                                        <h3>{requerimiento.partida}</h3>
                                        <p>Cuotas pendientes: {requerimiento.cuotas_pendientes}</p>
                                        <p>Atendido: {requerimiento.requerimiento_atendido ? '✅' : '❌'}</p>
                                        <p>Fase: {obtenerRequerimiento(requerimiento.requerimiento_de_pago)}</p>
                                        <Button label='Atender requerimiento' severity='info' onClick={() => atenderRequerimiento(requerimiento.partida)} />
                                        <Button label='Siguiente requerimiento' severity='info' onClick={() => siguienteRequerimiento(requerimiento.partida)} />
                                    </Card>
                                </div>
                            )}
                        </div>
                    }
                </div>
            }

        </>
    );

}

export default PaymentsRequirementModule;