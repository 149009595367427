import './gestion_trabajadores.css';
import { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';

import {
    afiliacionItemTemplate, calcularEdadActual, calcularEdadIngreso
} from './constants';
import { headerForm, mostrarError } from '../constants/bodiesYFiltos';
import { estados_trabajador } from '../constants/estados_trabajador';
import mostrarFechaEstado from '../tools/mostrarFechaEstado';
import buscarObjetoPorId from '../tools/buscarObjetoPorId';
import { editarTrabajador } from '../endpoints/gestion_trabajadores';

export default function EditarTrabajador() {
    // Posibles estados para el trabajador
    const [estadosSeleccionables, setEstadosSeleccionables] =
        useState(estados_trabajador.filter(estado => estado.Afiliacion === 1));

    // Datos trabajador
    const [registro, setRegistro] = useState(null);
    const [registroOriginal, setRegistroOriginal] = useState(null);
    const [CUI, setCUI] = useState(null);
    const [nombre, setNombre] = useState('');
    const [genero, setGenero] = useState(null);
    const [fechaNacimiento, setFechaNacimiento] = useState(null);
    const [fechaIngreso, setFechaIngreso] = useState(null);
    const [afiliadoPlan, setAfiliadoPlan] = useState(1);
    const [estado, setEstado] = useState(estados_trabajador[0]);
    // si el estado aplica
    const [fechaInicioEstado, setFechaInicioEstado] = useState(null);
    const [fechaFinEstado, setFechaFinEstado] = useState(null);

    const toast = useRef(null);

    useEffect(() => {
        // obtener el trabajador seleccionado
        var seleccionado = JSON.parse(sessionStorage.getItem('trabajador_seleccionado'));

        // llenar los datos en el formulario
        setRegistro(seleccionado.Registro);
        setRegistroOriginal(seleccionado.Registro);
        setCUI(seleccionado.CUI);
        setNombre(seleccionado.Nombre);

        setGenero(seleccionado.Genero === 1 ? 'Masculino' : 'Femenino');
        setFechaNacimiento(seleccionado.FechaNacimiento ? new Date(seleccionado.FechaNacimiento) : null);
        setFechaIngreso(seleccionado.FechaIngreso ? new Date(seleccionado.FechaIngreso) : null);
        setAfiliadoPlan(seleccionado.Afiliacion);
        setEstado(
            buscarObjetoPorId(estados_trabajador,
                seleccionado.idEstadoTrabajador,
                'idEstadoTrabajador')
        );
        setFechaInicioEstado(
            seleccionado.FechaInicioEstado ? new Date(seleccionado.FechaInicioEstado) : null
        );
        setFechaFinEstado(
            seleccionado.FechaFinEstado ? new Date(seleccionado.FechaFinEstado) : null
        );

        // para mostrar los estados correspondientes a la afiliacion
        var del = estados_trabajador.filter(estado => estado.Afiliacion === seleccionado.Afiliacion);
        setEstadosSeleccionables(del);

    }, []);

    function EditarTrabajador() {
        var obj = {
            Registro: registro,
            RegistroOriginal: registroOriginal,
            CUI: CUI,
            Nombre: nombre,
            Genero: genero === 'Masculino' ? 1 : 0,
            FechaNacimiento: fechaNacimiento,
            FechaIngreso: fechaIngreso,
            IdEstadoTrabajador: estado ? estado.idEstadoTrabajador : null,
            FechaInicioEstado: mostrarFechaEstado(estado, 'inicio') ? fechaInicioEstado : null,
            FechaFinEstado: mostrarFechaEstado(estado, 'inicio') ? fechaFinEstado : null
        }

        editarTrabajador(obj).then((resp) => {
            if (resp.severity === "success") {
                setRegistroOriginal(registro);
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err);
        });
    }

    const confirm = (event) => {
        event.preventDefault();
        EditarTrabajador();
    }

    return (
        <div className='primario-crear-registro'>
            <Toast ref={toast} />
            <Card header={headerForm("Datos del trabajador", true)} className="card">
                <form onSubmit={confirm} noValidate={false}>
                    <div className="grid p-fluid mt-1">
                        {/* REGISTRO */}
                        <div className="field col-12 md:col-6 col_toggle">
                            <label>Registro *</label>
                            <InputNumber required
                                value={registro} onValueChange={(e) => setRegistro(e.value)}
                                mode="decimal" showButtons min={0} useGrouping={false} />
                        </div>
                        {/* CUI */}
                        <div className="field col-12 md:col-6 col_toggle">
                            <label>CUI</label>
                            <InputNumber
                                value={CUI} onValueChange={(e) => setCUI(e.value)}
                                mode="decimal" showButtons min={0} useGrouping={false} />
                        </div>
                    </div>
                    <div className="grid p-fluid mt-1">
                        {/* NOMBRE */}
                        <div className="field col-12 md:col-8 col_toggle">
                            <label>Nombre Completo</label>
                            <InputText value={nombre}
                                onChange={(e) => setNombre(e.target.value)} />
                        </div>
                        {/* GENERO */}
                        <div className="field col-12 md:col-4 col_toggle">
                            <label>Género</label>
                            <Dropdown
                                value={genero}
                                options={['Masculino', 'Femenino']}
                                placeholder="Género"
                                onChange={(e) => setGenero(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="grid p-fluid mt-1">
                        {/* FECHA DE NACIMIENTO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Fecha de Nacimiento</label>
                            <Calendar value={fechaNacimiento}
                                onChange={(e) => setFechaNacimiento(e.target.value)} showButtonBar showIcon />
                        </div>
                        {/* FECHA DE INGRESO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Fecha de Ingreso</label>
                            <Calendar value={fechaIngreso}
                                onChange={(e) => setFechaIngreso(e.target.value)} showButtonBar showIcon />
                        </div>

                        {/* EDAD ACTUAL */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Edad Actual</label>
                            <InputText readOnly value={calcularEdadActual(fechaNacimiento)} />
                        </div>
                        {/* EDAD DE INGRESO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Edad de Ingreso</label>
                            <InputText readOnly value={calcularEdadIngreso(fechaNacimiento, fechaIngreso)} />
                        </div>
                    </div>
                    <div className="grid p-fluid mt-1">
                        {/* AFILIACION AL PLAN */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Está afiliado al plan de prestaciones</label>
                            <Dropdown
                                value={afiliadoPlan}
                                options={[1, 0]}
                                placeholder="Afiliado al plan de prestaciones"
                                itemTemplate={afiliacionItemTemplate}
                                valueTemplate={afiliacionItemTemplate}
                                onChange={(e) => {
                                    var del = estados_trabajador.filter(estado => estado.Afiliacion === e.target.value);
                                    setEstadosSeleccionables(del);
                                    setAfiliadoPlan(e.target.value);
                                }}
                            />
                        </div>
                        {/* ESTADO O MOTIVO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>{afiliadoPlan === 1 ?
                                'Estado del trabajador *' :
                                'Motivo desafiliación *'}
                            </label>
                            <Dropdown
                                value={estado}
                                required
                                options={estadosSeleccionables}
                                optionLabel='Nombre'
                                placeholder="Estados del trabajador"
                                onChange={(e) => setEstado(e.target.value)}
                            />
                        </div>
                        {/* FECHA DE INICIO DE ESTADO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            {mostrarFechaEstado(estado, 'inicio') ?
                                <>
                                    <label>Fecha en que inicia el estado</label>
                                    <Calendar
                                        value={fechaInicioEstado}
                                        onChange={(e) => setFechaInicioEstado(e.target.value)}
                                        showButtonBar showIcon />
                                </>
                                : <></>}
                        </div>
                        {/* FECHA DE FIN DEL ESTADO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            {mostrarFechaEstado(estado, 'fin') ?
                                <>
                                    <label>Fecha en que finaliza el estado</label>
                                    <Calendar
                                        value={fechaFinEstado}
                                        onChange={(e) => setFechaFinEstado(e.target.value)}
                                        showButtonBar showIcon />
                                </>
                                : <></>}
                        </div>
                    </div>

                    {/* BOTONES */}
                    <br />
                    <div className="grid p-fluid">
                        <div className='col-4' style={{ marginLeft: 'auto', marginRight: '0' }}>
                            <Button type='submit' icon="pi pi-pencil"
                                label="Editar" className='p-button-warning' />
                        </div>
                    </div>
                </form>
            </Card>
        </div>
    );
}
