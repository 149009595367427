import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { requestOptions } from '../misc/requestOptions';

function NominafileUpload(props) {

    const { id } = useParams();
    const { toast, tipoNomina, setActiveIndex } = props;

    const [importedData, setImportedData] = useState([]);
    const [importedCols, setImportedCols] = useState([{ field: '', header: '' }]);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [infoDialog, setInfoDialog] = useState(null);

    const toCapitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    const clear = () => {
        setImportedData([]);
        setImportedCols([{ field: '', header: '' }]);
    }

    const iniciarCarga = () => {
        setLoading(true);
        setLoaded(false);
    }

    const process_xhr = (response, loaded) => {
        const jr = JSON.parse(response);
        toast.current.show({ life: 10000, severity: jr.severity, summary: jr.message || jr.error });
        setLoading(false);
        setLoaded(loaded);
        if (!loaded)
            alert(jr.error || 'Ocurrió un error durante la carga del archivo. Contacte al programador.');
    }

    const get_plantilla = () => {
        let plantilla = [
            ['partida', 'plaza', 'registro', 'descripcion', 'tipo', 'codigo_nomina', 'month', 'year', 'ptd_gasto', 'nominal', 'liquido', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'],
            ['', '', '', 'Ej: AGUINALDO 2022', 'Ej: ALZA, REINTEGRO, ACUERDO', 'Tipo de nómina que repone [1,3,4,5,6]', 'Mes que repone', 'Año que repone', 'Partida de gasto', '(Nominal)', '(Liquido)', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
            [], ['*NOTA: EL NÚMERO DEL ENCABEZADO DE COLUMNA HACE REFERENCIA AL IDENTIFICADOR DEL CONCEPTO, DONDE:'], [],
            ['', '1 = BONO SOLIDARIDAD COSTO DE VIDA'], ['', '2 = PRÉSTAMO FOX PP'], ['', '3 = SEGURO DE CRÉDITO PRÉSTAMO FOX'], ['', '4 = IVA SEGURO DE CRÉDITO PRÉSTAMO FOX'], ['', '5 = PRÉSTAMO INMEDIATO PP'], ['', '6 = SEGURO DE CRÉDITO PRÉSTAMO INMEDIATO'], ['', '7 = IVA SEGURO DE CRÉDITO PRÉSTAMO INMEDIATO'], ['', '8 = PRÉSTAMO BANTRAB'], ['', '9 = DESCUENTO JUDICIAL'], ['', '10 = COOPERATIVA DE VIVIENDA'], ['', '11 = CUOTA ASOCIACIÓN DE JUBILADOS'], ['', '12 = PRESTACIONES PAGADAS DE MÁS'], ['', '13 = DESCUENTOS VARIOS'], ['', '14 = CUOTAS PENDIENTES DE PAGO'],
            [], ['NO SE PUEDE CAMBIAR EL NOMBRE DE LAS COLUMNAS Y EVITAR USAR VALORES CON MÁS DE DOS DECIMALES, LOS VALORES SIEMPRE DEBEN IR POSITIVOS, EL SISTEMA AUTOMÁTICAMENTE VUELVE LOS DESCUENTOS COMO NEGATIVOS.'],
            ['SE DEBE USAR LA PRIMERA HOJA DEL DOCUMENTO. PARA EL CASO DE QUE NO CORRESPONDA NINGÚN VALOR DE BONIFICACIÓN O DESCUENTO; DEJAR LA CELDA VACÍA.'],
            [], ['*ES NECESARIO ELIMINAR TODAS LAS FILAS INFORMATIVAS DE ESTA PLANTILLA, SOLAMENTE DEBE QUEDAR LA FILA DE ENCABEZADOS.']
        ];
        if (tipoNomina === 7) { // Última pensión
            plantilla[1][4] = 'ÚLTIMA PENSIÓN'
            plantilla[0].splice(3, 0, 'nombre_beneficiario');
            plantilla[1].splice(3, 0, 'NOMBRES APELLIDOS');
        } 
        return plantilla;
    }

    const downloadPlantilla = () => {
        import('xlsx').then(XLSX => {
            const data = get_plantilla();
            const ws = XLSX.utils.aoa_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'CARGA EXCEL');
            // Genera un blob a partir del libro de Excel
            const blob = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
            // Crea un enlace para descargar el archivo
            const url = URL.createObjectURL(new Blob([blob]));
            const a = document.createElement('a');
            a.href = url;
            a.download = `PLANTILLA_${tipoNomina === 2 ? 'COMPLEMENTO' : 'ULTIMAS_PENSIONES'}.xlsx`;
            a.click();
            URL.revokeObjectURL(url);
        });
    }

    const request_calculos_prestacion = async () => {
        setLoading(true);
        try {
            const response = await fetch(process.env.REACT_APP_API_URL_CALCULOS_PRESTACION,
                requestOptions('POST', { idnomina: id }));
            if (!response.ok) throw new Error('Error: No fue posible obtener los cálculos.');
            const fileBlob = await response.blob();
            // Crear un objeto URL a partir del Blob
            const fileUrl = URL.createObjectURL(fileBlob);
            // Crear un enlace temporal para descargar el PDF
            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = ('CALCULOS_PRESTACION_' + id);
            a.click();
            // Liberar la URL del objeto Blob
            URL.revokeObjectURL(fileUrl);
            toast.current.show({ severity: 'success', summary: 'Cálculos de prestación generados.' });
        } catch (error) {
            console.error('Error al descargar el reporte.');
            toast.current.show({ severity: 'error', summary: error.toString() });
        }
        setLoading(false);
    }

    const importExcel = (e) => {
        const file = e.files[0];

        import('xlsx').then(xlsx => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, { type: 'array' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = xlsx.utils.sheet_to_json(ws, { header: 1 });

                // Prepare DataTable
                const cols = data[0];
                data.shift();

                let _importedCols = cols.map(col => ({ field: col.toString(), header: toCapitalize(col.toString()) }));
                let _importedData = data.map(d => {
                    return cols.reduce((obj, c, i) => {
                        obj[c] = d[i];
                        return obj;
                    }, {});
                });

                setImportedCols(_importedCols);
                setImportedData(_importedData);
                // console.log(_importedData)
            };
            reader.readAsArrayBuffer(file);
        });
        process_xhr(e.xhr.response, true);
    }

    const info_confirm = () => {
        setInfoDialog(null);
        request_calculos_prestacion();
    }

    const info_footer = () => {
        return (
            <div>
                <Button label="De acuerdo" icon="pi pi-check" onClick={() => info_confirm()} autoFocus />
            </div>
        );
    }

    return (
        <div className="container__fileUpload">
            <div className="card fileUpload mt-6">
                {/* <h5 className='text-dark'>Carga de archivo</h5> */}
                <div className="flex align-items-center p-2">
                    <FileUpload disabled={loaded} chooseOptions={{ label: 'Cargar Excel', icon: 'pi pi-file-excel', className: 'p-button-success' }}
                        mode="basic" name="excelNomina" auto accept=".xlsx"
                        url={process.env.REACT_APP_API_URL_FILE_UPLOAD + '/excelNomina/' + id}
                        onProgress={iniciarCarga} onUpload={importExcel}
                        onError={(e) => { process_xhr(e.xhr.response, false); }}
                    />
                    {
                        (tipoNomina === 2 || tipoNomina === 7) ?
                            <Button type="button" label="Descargar plantilla" icon="pi pi-download" onClick={downloadPlantilla} className="p-button-info ml-auto" />
                            :
                            <Button disabled={loading} type="button" label="Descargar cálculos del sistema" icon="pi pi-download" onClick={() => setInfoDialog(true)} className="p-button-info ml-auto" />
                    }
                    <Button type="button" label="Limpiar" icon="pi pi-times" onClick={clear} className="p-button-danger ml-auto" />
                </div>
                {
                    loading ?
                        <ProgressBar mode="indeterminate" style={{ height: '8px', margin: '20px' }} />
                        :
                        importedData.length > 0 &&
                        <DataTable value={importedData} emptyMessage="Sin datos." paginator paginatorPosition='top' rows={10} resizableColumns
                            columnResizeMode="expand" selectionMode="single">
                            {
                                importedCols.map((col, index) => <Column key={index} field={col.field} header={col.header} />)
                            }
                        </DataTable>
                }
            </div >
            {
                (loaded) && <div className='btn__nextSetp'>
                    <Button onClick={() => setActiveIndex()} className='p-button-rounded p-button-raised font-weight-bold' icon='pi pi-arrow-right' label='Ver listado' iconPos='right' />
                </div>
            }
            <Dialog header={'Información'} headerStyle={{ paddingTop: '8px', paddingBottom: '8px' }} footer={info_footer}
                visible={infoDialog} position={'center'} modal style={{ width: '40%' }}
                onHide={() => setInfoDialog(null)} draggable={false} maximizable resizable={false}>
                <p className="mb-2">El archivo generado muestra en la primer hoja los cálculos generados
                    por el sistema, y en el resto de hojas corresponde el detalle histórico utilizado.</p>
                <p className="mb-3">Es necesario cargar un archivo revisado, que debe contener en la <b>primera hoja</b> del
                    documento Excel la misma estructura que generó el sistema previamente (el histórico no se necesita subir).</p>
                <p className="text-center font-weight-light mb-0">| partida | plaza | registro | monto |</p>
            </Dialog>
        </div >
    );

}

export default NominafileUpload;