import buscarObjetoPorId from '../tools/buscarObjetoPorId';
import { meses } from '../constants/diccionarios';

// ------------------------------------------------- BODIES Y FILTROS TABLA
export const periodoSeleccionadoItem = (option) => {
    if (!option) {
        return ''
    }
    return option.Anio + " - " +
        buscarObjetoPorId(meses, option.MesInicio, "numero")['nombre'] + " - " +
        buscarObjetoPorId(meses, option.MesFinal, "numero")['nombre']
};
