import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { get_graphs_route } from '../../constants/backend_routes';
import { Chart } from "react-google-charts";
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf';

const Graficas = () => {

    const [graphs, setGraphs] = useState();
    const [anio, setAnio] = useState(2024);
    const [mes, setMes] = useState('');

    const meses = [
        {
            nombre: 'Enero',
            numero: 1
        },
        {
            nombre: 'Febrero',
            numero: 2
        },
        {
            nombre: 'Marzo',
            numero: 3
        },
        {
            nombre: 'Abril',
            numero: 4
        },
        {
            nombre: 'Mayo',
            numero: 5
        },
        {
            nombre: 'Junio',
            numero: 6
        },
        {
            nombre: 'Julio',
            numero: 7
        },
        {
            nombre: 'Agosto',
            numero: 8
        },
        {
            nombre: 'Septiembre',
            numero: 9
        },
        {
            nombre: 'Octubre',
            numero: 10
        },
        {
            nombre: 'Noviembre',
            numero: 11
        },
        {
            nombre: 'Diciembre',
            numero: 12
        }
    ]

    const obtenerMesAnterior = (mess) => {
        const mesActualIndex = meses.findIndex((mes_op) => mes_op.numero === mess.numero);

        // Si el mes actual no se encuentra en el arreglo o es enero, devolvemos diciembre
        if (mesActualIndex === -1 || mesActualIndex === 0) {
            return meses[11]
        }

        // Devolvemos el número del mes anterior
        return meses[mesActualIndex - 1];
    };

    const obtenerFecha = () => {
        let anio_util = anio
        let mes_util = mes.numero >= 12 ? 1 : mes.numero + 1
        let dia_util = '04'

        const fecha = `${dia_util}/${mes_util}/${anio_util}`
        return fecha
    }

    const generarReporte = async () => {
        const first_graph = document.getElementById('first_graph')
        const second_graph = document.getElementById('second_graph')
        const third_graph = document.getElementById('third_graph')
        const forth_graph = document.getElementById('forth_graph')
        const area_graph = document.getElementById('area_graph')

        const firts_canvas = await html2canvas(first_graph)
        const second_canvas = await html2canvas(second_graph)
        const third_canvas = await html2canvas(third_graph)
        const forth_canvas = await html2canvas(forth_graph)
        const area_canvas = await html2canvas(area_graph)

        const imgData1 = firts_canvas.toDataURL('img/png')
        const imgData2 = second_canvas.toDataURL('img/png')
        const imgData3 = third_canvas.toDataURL('img/png')
        const imgData4 = forth_canvas.toDataURL('img/png')
        const imgData5 = area_canvas.toDataURL('img/png')

        const doc = new jsPDF('p', 'mm', 'a4')
        const componentWidth = doc.internal.pageSize.getWidth()
        const componentHeight = doc.internal.pageSize.getHeight()

        doc.addImage(imgData1, 'PNG', 0, 0, componentWidth, componentHeight)
        doc.addPage();
        doc.addImage(imgData2, 'PNG', 0, 0, componentWidth, componentHeight)
        doc.addPage();
        doc.addImage(imgData3, 'PNG', 0, 0, componentWidth, componentHeight)
        doc.addPage();
        doc.addImage(imgData4, 'PNG', 0, 0, componentWidth, componentHeight)
        doc.addPage();
        doc.addImage(imgData4, 'PNG', 0, 0, componentWidth, componentHeight)
        doc.addPage();
        doc.addImage(imgData5, 'PNG', 0, 0, componentWidth, componentHeight / 2)

        doc.save('graficas.pdf')
    }

    const generarGraficos = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ fecha: obtenerFecha() })
        }
        const response = await fetch(get_graphs_route, requestOptions)
        const responseJson = await response.json()
        setGraphs(responseJson.data)
    }

    const obtenerInfoMes = (data) => {
        let reporte = {
            "fox": { "dia": 0, "mora": 0 },
            "inmediatos": { "dia": 0, "mora": 0 }
        }
        data.forEach(element => {
            if (element.cartera == 'FOX') {
                reporte.fox.dia += parseFloat(element.capital_faltante)
                if (element.cuotas_pendientes >= 1)
                    reporte.fox.mora += (parseFloat(element.cuotas_pendientes) * parseFloat(element.siguiente_cuota))
            }
            else if (element.cartera == 'INMEDIATOS') {
                reporte.inmediatos.dia += parseFloat(element.capital_faltante)
                if (element.cuotas_pendientes >= 1)
                    reporte.inmediatos.mora += (parseFloat(element.cuotas_pendientes) * parseFloat(element.siguiente_cuota))
            }
        });
        return reporte
    }

    const getPieGraphs = (data, mes_utilizar) => {
        console.log(data)
        let total_mora = data.fox.mora + data.inmediatos.mora
        let total_dia = data.fox.dia + data.inmediatos.dia

        let fox_total = data.fox.mora + data.fox.dia
        let inmediato_total = data.inmediatos.mora + data.inmediatos.dia

        let mora_fox = data.fox.mora
        let mora_inmediato = data.inmediatos.mora


        let data_return1 = [["Cartera", "Valores"], ["Al dia", total_dia], ["Mora", total_mora]]
        let data_return2 = [["Cartera", "Total"], ["FOX", fox_total], ["INMEDIATOS", inmediato_total]]
        let data_return3 = [["Cartera", "Mora"], ["FOX", mora_fox], ["INMEDIATOS", mora_inmediato]]
        return <div id={`reporte${mes_utilizar}`}>
            <Chart
                chartType="PieChart"
                data={data_return1}
                options={{ title: `Total de carteras de prestamos ${mes_utilizar}` }}
                width={"100%"}
                height={"400px"}
            />
            <Chart
                chartType="PieChart"
                data={data_return2}
                options={{ title: `Tipos de prestamos ${mes_utilizar}` }}
                width={"100%"}
                height={"400px"}
            />
            <Chart
                chartType="PieChart"
                data={data_return3}
                options={{ title: `Mora por tipo de prestamo ${mes_utilizar}` }}
                width={"100%"}
                height={"400px"}
            />
        </div>
    }

    const getAreaChart = (data) => {
        let mes1 = data.actual
        let mes2 = data.anterior
        let mes3 = data.anterior2
        let mes4 = data.anterior3

        let porcentaje1 = 0
        let porcentaje2 = 0
        let porcentaje3 = 0
        let porcentaje4 = 0

        let mora1 = 0
        let capital1 = 0
        let mora2 = 0
        let capital2 = 0
        let mora3 = 0
        let capital3 = 0
        let mora4 = 0
        let capital4 = 0


        mes1.forEach(element => {
            if (element.cuotas_pendientes >= 1)
                mora1 += (parseFloat(element.cuotas_pendientes) * parseFloat(element.siguiente_cuota))
            capital1 += parseFloat(element.capital_faltante)
            porcentaje1 = (mora1 / capital1) * 100
        })
        mes2.forEach(element => {
            if (element.cuotas_pendientes >= 1)
                mora2 += (parseFloat(element.cuotas_pendientes) * parseFloat(element.siguiente_cuota))
            capital2 += parseFloat(element.capital_faltante)
            porcentaje2 = (mora2 / capital2) * 100
        })
        mes3.forEach(element => {
            if (element.cuotas_pendientes >= 1)
                mora3 += (parseFloat(element.cuotas_pendientes) * parseFloat(element.siguiente_cuota))
            capital3 += parseFloat(element.capital_faltante)
            porcentaje3 = (mora3 / capital3) * 100
        })
        mes4.forEach(element => {
            if (element.cuotas_pendientes >= 1)
                mora4 += (parseFloat(element.cuotas_pendientes) * parseFloat(element.siguiente_cuota))
            capital4 += parseFloat(element.capital_faltante)
            porcentaje4 = (mora4 / capital4) * 100
        })

        porcentaje1 = parseFloat(porcentaje1.toFixed(5))
        mora1 = mora1.toFixed(2)
        capital1 = capital1.toFixed(2)
        porcentaje2 = parseFloat(porcentaje2.toFixed(5))
        mora2 = mora2.toFixed(2)
        capital2 = capital2.toFixed(2)
        porcentaje3 = parseFloat(porcentaje3.toFixed(5))
        mora3 = mora3.toFixed(2)
        capital3 = capital3.toFixed(2)
        porcentaje4 = parseFloat(porcentaje4.toFixed(5))
        mora4 = mora4.toFixed(2)
        capital4 = capital4.toFixed(2)

        let data_return = [
            ["Mes", "Porcentaje Mora"],
            [obtenerMesAnterior(obtenerMesAnterior(obtenerMesAnterior(mes))).nombre, porcentaje4],
            [obtenerMesAnterior(obtenerMesAnterior(mes)).nombre, porcentaje3],
            [obtenerMesAnterior(mes).nombre, porcentaje2],
            [mes.nombre, porcentaje1],
        ]

        let data_table = [
            ["Mes", "Porcentaje Mora", "Mora", "Capital"],
            [obtenerMesAnterior(obtenerMesAnterior(obtenerMesAnterior(mes))).nombre, porcentaje4, mora4, capital4],
            [obtenerMesAnterior(obtenerMesAnterior(mes)).nombre, porcentaje3, mora3, capital3],
            [obtenerMesAnterior(mes).nombre, porcentaje2, mora2, capital2],
            [mes.nombre, porcentaje1, mora1, capital1]
        ]

        console.log(data_return)
        const options = {
            isStacked: false,
            height: 300,
            legend: { position: "top", maxLines: 20 },
            vAxis: {
                viewWindow: {
                    min: 'auto', // El valor mínimo se ajustará automáticamente según los datos
                    max: 'auto', // El valor máximo se ajustará automáticamente según los datos
                },
            },
        };
        return <div id='reporteMeses'>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Mora</th>
                        <th>Capital</th>
                        <th>Porcentaje Mora</th>
                    </tr>
                </thead>
                <tbody>
                    {data_table.slice(1).map((row, index) => (
                        <tr key={index}>
                            <td>{row[0]}</td>
                            <td>{row[2]}</td>
                            <td>{row[3]}</td>
                            <td>{row[1]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>


            <Chart
                chartType="AreaChart"
                width="100%"
                height="400px"
                data={data_return}
                options={options}
            />
        </div>
    }

    return (
        <>
            <div className='container'>
                <InputNumber value={anio} onValueChange={(e) => setAnio(e.value)}
                    mode="decimal" showButtons min={0} useGrouping={false} style={{ 'margin': '5px' }} />
                <Dropdown
                    value={mes}
                    options={meses}
                    optionLabel="nombre"
                    placeholder="Seleccionar mes"
                    onChange={(e) => {
                        setMes(e.value)
                    }}
                    style={{ 'margin': '5px' }}
                />
                <Button label='Generar Graficos' severity='info' onClick={() => generarGraficos()} style={{ 'margin': '5px' }} />
                <Button label='Generar Reporte' severity='info' onClick={() => generarReporte()} style={{ 'margin': '5px' }} />
                <div id="all_graphs">
                    <br />
                    <br />
                    <div id='first_graph'>
                        {graphs && getPieGraphs(obtenerInfoMes(graphs.actual), mes.nombre)}
                    </div>
                    <br />
                    <div id='second_graph'>
                        {graphs && getPieGraphs(obtenerInfoMes(graphs.anterior), obtenerMesAnterior(mes).nombre)}
                    </div>
                    <br />
                    <div id='third_graph'>
                        {graphs && getPieGraphs(obtenerInfoMes(graphs.anterior2), obtenerMesAnterior(obtenerMesAnterior(mes)).nombre)}
                    </div>
                    <br />
                    <div id='forth_graph'>
                        {graphs && getPieGraphs(obtenerInfoMes(graphs.anterior3), obtenerMesAnterior(obtenerMesAnterior(obtenerMesAnterior(mes))).nombre)}
                    </div>
                    <br />
                    <div id='area_graph'>
                        {graphs && getAreaChart(graphs)}
                    </div>
                </div>

            </div>

        </>
    )
}

export default Graficas