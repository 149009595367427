import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import CargaArchivoDescuentos from './file_upload';
import { useRef } from 'react';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';

function CargaDescuentos() {

    const toast = useRef(null);
    const navigate = useNavigate();

    // Para BreadCrumb
    const items = [
        { label: 'NÓMINA', command: () => { navigate("/nominas") } },
        { label: 'DESCUENTOS', command: () => { navigate("/nominas/descuentos") } },
        { label: 'CARGA MASIVA', command: () => { navigate("/nominas/carga-descuentos") } }
    ];
    const home = { icon: 'pi pi-home', command: () => { navigate("/") } };

    return (
        <div>
            <Toast ref={toast}></Toast>
            <BreadCrumb model={items} home={home} />
            <div className='mt-6'>
                <CargaArchivoDescuentos toast={toast} />
            </div>
            <div className='btn__nextSetp'>
                <Button onClick={() => navigate("/nominas")} className='p-button-rounded p-button-raised p-button-secondary' icon='pi pi-check-circle' label='Regresar a Menú Nóminas' />
            </div>
        </div >
    );

}

export default CargaDescuentos;