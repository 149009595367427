import './busqueda_usuarios.css';
import UserCard from './user_card';
import SearchBar from './buscador';
import { Calendar } from 'primereact/calendar';
import { useState, useEffect } from 'react';
import { get_persona_route, get_comments_report } from '../../constants/backend_routes';
import { Button } from 'primereact/button';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function BusquedaUsuarioMoras() {
    const [responseData, setResponseData] = useState([])
    const [users, setUsers] = useState([])
    const [fecha, setFecha] = useState();


    function convertirFormato(fechaISO) {
        const fecha = new Date(fechaISO);

        // Obtener los componentes de la fecha
        const dia = fecha.getDate().toString().padStart(2, '0');
        const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        const año = fecha.getFullYear().toString().slice(-2);

        // Formatear la fecha como 'dd/mm/yy'
        const fechaFormateada = `${dia}/${mes}/${año}`;

        return fechaFormateada;
    }

    const updateUsers = (users) => {
        setUsers(users)
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(get_persona_route)
            const responseJson = await response.json()
            setResponseData(responseJson.data)
            var aux_users = []
            responseJson.data.forEach(raw_user => {
                const user = {
                    name: `${raw_user.nombres} ${raw_user.apellidos}`,
                    comment: raw_user.comentario,
                    address: raw_user.direccion,
                    email: raw_user.correo,
                    state: raw_user.estado,
                    gender: raw_user.genero,
                    start_date: raw_user.fecha_alza,
                    id_number: raw_user.numero_dpi,
                    ref_number: raw_user.registro,
                    tel: raw_user.telefono,
                    id_usuario: raw_user.id_persona
                }
                aux_users.push(user)
                setUsers(aux_users)
            })
        };
        fetchData()
    }, [])

    const button_style = {
        'color': "#fff",
        'backgroundColor': '#337ab7',
        'borderColor': '#2e6da4',
    }

    const generarReporte = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ fecha: fecha })
        };
        const responseComments = await fetch(get_comments_report, requestOptions)
        const commentsJson = await responseComments.json()
        console.log(commentsJson.comentarios)
        convertJsonToExcel(commentsJson.comentarios, 'comentarios')
    }

    const convertJsonToExcel = (jsonData, fileName) => {
        const worksheet = XLSX.utils.json_to_sheet(jsonData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

        // Crear el archivo Excel y guardarlo en el sistema de archivos
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    };



    return (
        <div className='busqueda__usuarios__moras container text-center'>
            <h1>Busqueda de usuario en modulo de moras</h1>
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                        <i className="pi pi-key"></i>
                    </span>
                    <span className="p-float-label">
                        <Calendar dateFormat="dd/mm/yy" value={fecha} onChange={(e) => setFecha(convertirFormato(e.value))}></Calendar>
                        <label>Fecha de reporte de comentarios</label>
                    </span>
                </div>
            <br />
            <div className='row text-center'>
                <div className='col-md-9'>
                    <SearchBar updateUsers={updateUsers}></SearchBar>
                </div>
                <div className='col-md-3'>
                    <Button label='Reporte de comentarios' style={button_style} icon="pi pi-check" onClick={() => generarReporte()} className="p-button-text" />
                </div>
            </div>
            <br />
            {
                <div className='row text-center'>
                    {users.map(user => (
                        <UserCard
                            name={user.name}
                            comment={user.comment}
                            address={user.address}
                            email={user.email}
                            state={user.state}
                            gender={user.gender}
                            start_date={user.start_date}
                            id_number={user.id_number}
                            ref_number={user.ref_number}
                            tel={user.tel}
                            id_usuario={user.id_usuario}
                        />
                    ))}
                </div>
            }
        </div>
    );
}

export default BusquedaUsuarioMoras;