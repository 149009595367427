import { requestOptions } from "./requestOptions";
const api = process.env.REACT_APP_API_URL_CUOTAS || "http://localhost:8091/cuotas/"

export async function crearCertificacion(data = {}) {
    const url = api + "certificaciones/crear_certificacion"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function getCertificaciones() {
    const url = api + "certificaciones/get_certificaciones"
    const response = await fetch(url, requestOptions("GET", null));
    return response.json();
}

export async function getCertificacion(data = {}) {
    const url = api + "certificaciones/get_certificacion"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function getVariables(data = {}) {
    const url = api + "certificaciones/get_variables"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function actualizarCertificacion(data = {}) {
    const url = api + "certificaciones/actualizar_certificacion"
    const response = await fetch(url, requestOptions("PUT", JSON.stringify(data)));
    return response.json();
}

export async function eliminarCertificacion(data = {}) {
    const url = api + "certificaciones/eliminar_certificacion/" + data.idCertificacion
    const response = await fetch(url, requestOptions("DELETE", null));
    return response.json();
}

export async function exportarCertificacion(data = {}) {
    const url = api + "certificaciones/exportar_certificacion"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response;
}

