import '../../calculo_cuotas.css';
import { Button } from 'primereact/button';
import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { Card } from 'primereact/card';

import { CalcularIntereses, fechaMesBody } from '../constants';
import { headerForm } from '../../../constants/bodiesYFiltos';


export default function CrearMesCobro(
    { periodosIniciales, interesesHasta, setInteresesHasta, AgregarMesCobro, setEditarPeriodosVisible }
) {
    // --------------------------------------------------  MESES DE COBRO
    // Datos editables
    const [fechaDescripcion, setFechaDescripcion] = useState(new Date(2020, 4 - 1, 1));
    const [cuota, setCuota] = useState(10.58);
    const [SNominal, setSNominal] = useState(10700);
    const [SRecibido, setSRecibido] = useState(7518.52);

    const [periodos, setPeriodos] = useState(periodosIniciales);

    // Datos de calculo
    const [TMes, setTMes] = useState(null);
    const [S, setS] = useState(null);
    const [interesesCompleto, setInteresesCompleto] = useState(null);
    const [cuotaAPagar, setCuotaAPagar] = useState(null);


    useEffect(() => {
        setPeriodos(periodosIniciales);
        HacerCalculos();
    }, [fechaDescripcion, cuota, SNominal, SRecibido, interesesHasta, periodosIniciales]);

    function HacerCalculos() {
        var resultado = CalcularIntereses(fechaDescripcion, cuota, SNominal,
            SRecibido, interesesHasta, periodosIniciales);
        if (resultado) {
            setTMes(resultado.TMes);
            setPeriodos(resultado.Periodos);
            setS(resultado.S);
            setCuotaAPagar(resultado.CuotaAPagar);
            setInteresesCompleto(resultado.InteresesCompleto);
        }
    }

    function EnviarMesCobro() {
        var nuevoMesCobro = {
            FechaDescripcion: fechaDescripcion,
            Cuota: cuota,
            SNominal: SNominal,
            SRecibido: SRecibido,
            TMes: TMes,
            S: S,
            Intereses: parseFloat(interesesCompleto),
            CuotaAPagar: parseFloat(cuotaAPagar),
            IPer1: periodos[0].Intereses,
            IPer2: periodos[1].Intereses,
            IPer3: periodos[2].Intereses,
            IPer4: periodos[3].Intereses,
            IPer5: periodos[4].Intereses,
            IPer6: periodos[5].Intereses,
            IPer7: periodos[6].Intereses,
            IPer8: periodos[7].Intereses,
            IPer9: periodos[8].Intereses,
            IPer10: periodos[9].Intereses,
            IPer11: periodos[10].Intereses,
            IPer12: periodos[11].Intereses,
        }

        AgregarMesCobro(nuevoMesCobro);
    }

    return (
        <>
            {/* MESES DE COBRO */}
            < Card header={headerForm("Agregar mes de cobro")} className="card" >
                <form noValidate={false}>
                    <div className="grid p-fluid mt-1">
                        {/* INTERESES HASTA*/}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Intereses Hasta</label>
                            <Calendar required={true} value={interesesHasta}
                                onChange={(e) => setInteresesHasta(e.target.value)} showButtonBar showIcon />
                        </div>
                        <div className='field col-12 md:col-3 col_toggle'>
                            <Button type="button" icon="pi pi-calendar" label="Editar periodos"
                                style={{ marginTop: '30px' }}
                                onClick={() => setEditarPeriodosVisible(true)}
                            />
                        </div>
                    </div>
                    <div className="grid p-fluid mt-1">
                        {/* FECHA DESCRIPCION */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Fecha Descripción</label>
                            <Calendar required={true} value={fechaDescripcion}
                                onChange={(e) => setFechaDescripcion(e.target.value)} showButtonBar showIcon
                                view="month" dateFormat="mm/yy" />
                        </div>
                        {/* CUOTA */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Cuota(%)</label>
                            <InputNumber required={true}
                                value={cuota} onChange={(e) => setCuota(e.value)}
                                minFractionDigits={2} maxFractionDigits={2} showButtons min={0} max={100} />
                        </div>
                        {/* S. NOMINAL */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>S. Nominal (Q)</label>
                            <InputNumber required={true}
                                value={SNominal} onChange={(e) => setSNominal(e.value)}
                                minFractionDigits={2} maxFractionDigits={2} showButtons min={0} />
                        </div>
                        {/* S. RECIBIDO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>S. Recibido (Q)</label>
                            <InputNumber required={true}
                                value={SRecibido} onChange={(e) => setSRecibido(e.value)}
                                minFractionDigits={2} maxFractionDigits={2} showButtons min={0} />
                        </div>
                    </div>

                    {/* DIVIDER */}
                    <div style={{ width: '100%', textAlign: 'center', margin: '20px 0' }}>
                        <div style={{ borderTop: '1px solid #ccc' }}></div>
                    </div>

                    <div className="grid p-fluid mt-1">
                        {/* DIFERENCIA */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Diferencia (Q)</label>
                            <InputNumber readOnly
                                value={SNominal - SRecibido}
                                minFractionDigits={2} maxFractionDigits={2} min={0} />
                        </div>
                        {/* CUOTA A PAGAR */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Cuota a pagar (Q)</label>
                            <InputNumber readOnly
                                value={cuotaAPagar}
                                minFractionDigits={2} maxFractionDigits={2} min={0} />
                        </div>
                        {/* T. MES */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>T. Mes</label>
                            <InputNumber readOnly
                                value={TMes} onChange={(e) => setTMes(e.value)}
                                mode="decimal" min={0} useGrouping={false} />
                        </div>
                    </div>
                    <br />
                    <h3 style={{ textAlign: 'center' }}>Periodos</h3>
                    <DataTable value={periodos}
                        tableStyle={{ minWidth: '50rem' }} size='small'>
                        <Column header="#" body={(rowData, rowIndex) => rowIndex.rowIndex + 1} />
                        <Column field="Anio" header="Año" ></Column>
                        <Column field="FechaInicio" header="Fecha Inicio"
                            body={(rowData) => fechaMesBody(rowData, 'FechaInicio')}></Column>
                        <Column field="FechaFinal" header="Fecha Final"
                            body={(rowData) => fechaMesBody(rowData, 'FechaFinal')}></Column>
                        <Column field="TasaInteres" header="Tasa Interes"></Column>
                        <Column field="Intereses" header="Intereses"></Column>
                    </DataTable>
                    <br />
                    <div className="grid p-fluid mt-1">
                        {/* S */}
                        <div className="field col-12 md:col-3 col_toggle" >
                            <label>S (Q)</label>
                            <InputNumber readOnly
                                value={S} onChange={(e) => setS(e.value)}
                                minFractionDigits={2} maxFractionDigits={2} min={0} />
                        </div>
                        {/* INTERESES COMPLETO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Intereses (Q)</label>
                            <InputNumber readOnly
                                value={interesesCompleto} onChange={(e) => setInteresesCompleto(e.value)}
                                minFractionDigits={2} maxFractionDigits={2} min={0} />
                        </div>
                        {/* BOTONES */}
                        <div className='col-12 md:col-3' style={{ marginLeft: 'auto', marginTop: '2.5%' }}>
                            <Button type='button' icon="pi pi-arrow-down"
                                label="Agregar mes de cobro" className='p-button-success'
                                onClick={() => EnviarMesCobro()} />
                        </div>
                    </div>
                </form>
            </Card >
        </>
    );
}
