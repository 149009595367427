import '../calculo_cuotas.css';
import { useState, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';

import { CalculosIntegracion, TablaCuotasIntegracion } from './constants';
import { headerForm } from '../../constants/bodiesYFiltos';
import { BotonExportarReporte } from '../../constants/exportacion';
import { exportarIntegracion, getCuotasIntegracion } from '../../endpoints/calculo_cuotas/integraciones';



export default function VerIntegracion() {
    // Datos trabajador
    const [selectedTrabajador, setSelectedTrabajador] = useState({ CUI: '', Registro: '', Nombre: '' });

    // Datos de la integración
    const [nominaInicial, setNominaInicial] = useState("");
    const [nominaFinal, setNominaFinal] = useState("");

    // Calculos
    // código 24
    const [cuotasNoDescontadasCalculo, setCuotasNoDescontadasCalculo] = useState([]);
    const [totalSalariosNoDescontados, setTotalSalariosNoDescontados] = useState(0);
    // código 14
    const [cuotas14, setCuotas14] = useState([]); // según rango de nóminas
    const [totalDescuentoCod14, setTotalDescuentoCod14] = useState(0);

    const toast = useRef(null);

    useEffect(() => {
        // obtener datos de la integración
        var seleccionado = JSON.parse(sessionStorage.getItem('integracion_seleccionada'));

        // colocar datos del trabajador
        setSelectedTrabajador({
            Registro: seleccionado.RegistroTrabajador,
            CUI: seleccionado.CUI, Nombre: seleccionado.Nombre
        });

        // consultar las cuotas del cálculo
        getCuotasIntegracion({ idIntegracion: seleccionado.idIntegracion }).then((resp) => {
            // datos cuotas 24
            var Cuotas24 = resp.data.Cuotas24;
            setCuotasNoDescontadasCalculo(Cuotas24);
            setNominaInicial(Cuotas24[0] ? Cuotas24[0].Nomina : null);
            setNominaFinal(Cuotas24[Cuotas24.length - 1] ? Cuotas24[Cuotas24.length - 1].Nomina : null);

            // datos cuotas 14
            var Cuotas14 = resp.data.Cuotas14;
            setCuotas14(Cuotas14);

            RealizarIntegracion(Cuotas24, Cuotas14);
        }).catch(err => {
            console.log(err);
            alert("Ocurrió un error :(");
        });

    }, []);

    function RealizarIntegracion(
        Cuotas24 = cuotasNoDescontadasCalculo,
        Cuotas14 = cuotas14) {
        // --------------------------------- código 24
        // sumar total de sueldos
        var totalSueldos = Cuotas24.reduce(function (acumulado, cuota) {
            return acumulado + cuota.Sueldo;
        }, 0);

        setTotalSalariosNoDescontados(totalSueldos);

        // --------------------------------- código 14
        // sumar total de descuentos en cuota 14
        var totalDescuentos14 = Cuotas14.reduce(function (acumulado, cuota) {
            return acumulado + cuota.Cuota;
        }, 0);

        setTotalDescuentoCod14(totalDescuentos14);
    }

    return (
        <div className='primario-crear-registro'>
            <Toast ref={toast} />
            <Card
                header={headerForm("Datos de Integración", true)} className="card">
                {/* DATOS DEL TRABAJADOR */}
                <h4 style={{ textAlign: 'center' }}>Datos del trabajador</h4>
                <div className="grid p-fluid mt-1">
                    {/* CUI */}
                    <div className="field col-12 md:col-3 col_toggle mx-auto">
                        <label>CUI</label>
                        <InputText type="text" value={selectedTrabajador.CUI} readOnly />
                    </div>
                    {/* REGISTRO */}
                    <div className="field col-12 md:col-3 col_toggle mx-auto">
                        <label>Registro</label>
                        <InputText type="text" value={selectedTrabajador.Registro} readOnly />
                    </div>
                    {/* NOMBRE */}
                    <div className="field col-12 md:col-5 col_toggle mx-auto">
                        <label>Nombre</label>
                        <InputText type="text" value={selectedTrabajador.Nombre} readOnly />
                    </div>
                </div>
                {/* NOMINAS */}
                <h4 style={{ textAlign: 'center' }}>Nóminas</h4>
                <div className="grid p-fluid mt-1">
                    {/* NOMINA INICIAL */}
                    <div className="field col-12 md:col-4 col_toggle mx-auto">
                        <label>Nomina Inicial</label>
                        <InputNumber
                            value={nominaInicial} readOnly
                            mode="decimal" min={0} useGrouping={false} />
                    </div>
                    {/* NOMINA FINAL */}
                    <div className="field col-12 md:col-4 col_toggle mx-auto">
                        <label>Nomina Final</label>
                        <InputNumber
                            value={nominaFinal} readOnly
                            mode="decimal" min={0} useGrouping={false} />
                    </div>
                </div>

            </Card>
            <br />

            <Card
                header={headerForm("Cuotas de integración")} className="card">
                <BotonExportarReporte
                    Endpoint={exportarIntegracion}
                    datos={{
                        CuotasNoDescontadas: cuotasNoDescontadasCalculo,
                        CuotasDescontadas: cuotas14,
                        TotalSueldoNoDescontado: totalSalariosNoDescontados,
                        TotalDescontado: totalDescuentoCod14,
                        RegistroTrabajador: selectedTrabajador.Registro,
                        NombreTrabajador: selectedTrabajador.Nombre
                    }}
                    nombreArchivo={'integracion'}
                />
                <TablaCuotasIntegracion
                    cuotasNoDescontadasCalculo={cuotasNoDescontadasCalculo}
                    cuotas14={cuotas14} />
                <br />
                <CalculosIntegracion
                    totalSalariosNoDescontados={totalSalariosNoDescontados}
                    totalDescuentoCod14={totalDescuentoCod14}
                />
            </Card>
        </div>
    );
}
