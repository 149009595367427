import './acuerdos.css';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { useRef, useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Fieldset } from 'primereact/fieldset';
import { requestOptions } from '../../modulo_nominas/misc/requestOptions';

function RegistroAcuerdos() {
    const [id_acuerdo, setAcuerdo] = useState('');
    const [idpersona, setIdPersona] = useState(null);
    const [partida, setPartida] = useState('');
    const [plaza, setPlaza] = useState('');
    const [registro, setRegistro] = useState('');
    // const [numero_dpi, setDPI] = useState('');
    const [pension_actual, setPensionActual] = useState('');
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [fecha_dictamen, setFD] = useState('');
    const [fecha_traslado, setFT] = useState('');
    const [fecha_nivelacion, setFN] = useState('');
    const [pension_nivelada, setPensionNivelada] = useState('');
    const [observacion, setObservacion] = useState('');

    const reset_form = () => {
        setIdPersona(null);
        setAcuerdo('');
        setPartida('');
        setPlaza('');
        setRegistro('');
        setFD('');
        setFT('');
        setFN('');
        setPensionNivelada('');
        setObservacion('');
        // setDPI('');
        setNombres('');
        setApellidos('');
        setPensionActual('');
    }

    const toast = useRef(null);
    const [loading, setLoading] = useState(false);

    const request_data = () => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL_CONSULTAR_PERSONA,
            requestOptions('POST', {
                partida: partida,
                plaza: plaza,
                registro: registro,
                busqueda: 'ppr'
            }))
            .then((response) => response.json())
            .then((data) => {
                if (data.error) toast.current.show({ severity: data.severity, summary: data.error });
                const x = data.data;
                if (x) {
                    if (!x.idacuerdo) {
                        setIdPersona(x.idpersona);
                        setNombres(x.nombres);
                        setApellidos(x.apellidos);
                        // setDPI(x.numero_dpi);
                        setPensionActual(x.valor_pension);
                    }
                    else {
                        toast.current.show({ severity: 'error', summary: 'Error: La persona ya posee un acuerdo registrado.' })
                    }
                }
                setLoading(false);
            });
    }

    const post__request = () => {
        fetch(process.env.REACT_APP_API_URL_ACUERDOS,
            requestOptions('POST', {
                idpersona: idpersona,
                id_acuerdo: id_acuerdo,
                fecha_dictamen: fecha_dictamen.toJSON().slice(0, 10),
                fecha_traslado: fecha_traslado.toJSON().slice(0, 10),
                fecha_nivelacion: fecha_nivelacion.toJSON().slice(0, 10),
                pension_nivelada: pension_nivelada,
                observacion: observacion
            }))
            .then((response) => response.json())
            .then((data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                reset_form();
            });
    }


    const confirm = (event) => {
        confirmPopup({
            target: document.getElementById('acuerdos_confirm'),
            message: '¿Seguro que desea continuar?',
            icon: 'pi pi-question-circle',
            acceptLabel: 'Sí',
            accept: () => {
                post__request()
            }
        });
        event.preventDefault();
    }

    return (
        <form style={{ maxWidth: '1150px', margin: 'auto' }} id='acuerdos_form' onSubmit={confirm} noValidate={false}>
            <Toast ref={toast}></Toast>
            <Fieldset className='fieldset__cuentas' legend="Búsqueda por partida, plaza y registro" toggleable >
                <div className="grid p-fluid mt-2">
                    <div className="field col-12 md:col-10 col_toggle">
                        {/* PARTIDA */}
                        <div className="grid p-fluid">
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText disabled={idpersona} type="text" keyfilter="pint" value={partida} onChange={(e) => setPartida(e.target.value)} />
                                        <label>Partida</label>
                                    </span>
                                </div>
                            </div>
                            {/* PLAZA */}
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText disabled={idpersona} type="text" keyfilter="pint" value={plaza} onChange={(e) => setPlaza(e.target.value)} />
                                        <label >Plaza</label>
                                    </span>
                                </div>
                            </div>
                            {/* REGISTRO */}
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText disabled={idpersona} type="text" keyfilter="pint" value={registro} onChange={(e) => setRegistro(e.target.value)} />
                                        <label >Registro</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field col-12 md:col-2 consulta_botones pt-0">
                        <Button type='button' disabled={!partida || !plaza || !registro} className='p-button-rounded p-button-warning' icon="pi pi-search" loading={loading} onClick={request_data} tooltip='Consultar' />
                        <Button type='button' className='p-button-rounded p-button-secondary' icon="pi pi-replay" onClick={reset_form} tooltip='Limpiar búsqueda' />
                    </div>
                    {
                        idpersona &&
                        <>
                            {/* NOMBRES */}
                            <div className="field col-12 md:col-4">
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" disabled={true} value={nombres} onChange={(e) => setNombres(e.target.value.toUpperCase())} />
                                        <label >Nombres</label>
                                    </span>
                                </div>
                            </div>
                            {/* APELLIDOS */}
                            <div className="field col-12 md:col-4">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" disabled={true} value={apellidos} onChange={(e) => setApellidos(e.target.value.toUpperCase())} />
                                        <label >Apellidos</label>
                                    </span>
                                </div>
                            </div>
                            {/* DPI */}
                            {/* <div className="field col-12 md:col-4">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-id-card"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" disabled={true} value={numero_dpi} onChange={(e) => setDPI(e.target.value)} />
                                        <label >Número de DPI</label>
                                    </span>
                                </div>
                            </div> */}
                            {/* PENSIÓN ACTUAL */}
                            <div className="field col-12 md:col-4">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-money-bill"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputNumber disabled={true} value={pension_actual} onValueChange={(e) => setPensionActual(e.value)} mode="currency" currency="GTQ" />
                                        <label >Pensión actual</label>
                                    </span>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Fieldset>
            {
                idpersona &&
                <>
                    <div className="grid p-fluid acuerdos">
                        {/* ACUERDO */}
                        <div className="field col-12 md:col-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-arrow-right"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText maxLength={25} type="text" required={true} value={id_acuerdo} onChange={(e) => setAcuerdo(e.target.value.toUpperCase())} />
                                    <label>Número de Acuerdo</label>
                                </span>
                            </div>
                        </div>
                        {/* FECHA DICTAMEN AUDITORÍA */}
                        <div className="field col-12 md:col-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-calendar"></i>
                                </span>
                                <span className="p-float-label">
                                    <Calendar dateFormat="dd/mm/yy" required={false} value={fecha_dictamen} onChange={(e) => setFD(e.value)}></Calendar>
                                    <label>Fecha dictamen auditoría</label>
                                </span>
                            </div>
                        </div>
                        {/* FECHA TRASLADO JAPP */}
                        <div className="field col-12 md:col-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-calendar"></i>
                                </span>
                                <span className="p-float-label">
                                    <Calendar dateFormat="dd/mm/yy" required={false} value={fecha_traslado} onChange={(e) => setFT(e.value)}></Calendar>
                                    <label>Fecha traslado JAPP</label>
                                </span>
                            </div>
                        </div>
                        {/* FECHA NIVELACIÓN */}
                        <div className="field col-12 md:col-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-calendar"></i>
                                </span>
                                <span className="p-float-label">
                                    <Calendar dateFormat="dd/mm/yy" required={true} value={fecha_nivelacion} onChange={(e) => setFN(e.value)}></Calendar>
                                    <label>Fecha de nivelación</label>
                                </span>
                            </div>
                        </div>
                        {/* NUEVA PENSIÓN */}
                        <div className="field col-12 md:col-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-money-bill"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputNumber min={0} required={true} value={pension_nivelada} onValueChange={(e) => setPensionNivelada(e.value)} mode="currency" currency="GTQ" />
                                    <label>Monto pensión 100%</label>
                                </span>
                            </div>
                        </div>
                        {/* OBSERVACIÓN */}
                        <div className="field col-12 md:col-6">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-book"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputTextarea maxLength={200} rows={1} required={false} value={observacion} autoResize={true} onChange={(e) => setObservacion(e.target.value.toUpperCase())} />
                                    <label>Anotación</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* CONFIRMAR */}
                    <div className="confirm" >
                        <Button disabled={!idpersona} id='acuerdos_confirm' type='submit' icon="pi pi-check-circle" label="Nivelar" className="mt-2 p-button-raised font-weight-bold" />
                        <ConfirmPopup />
                    </div>
                </>
            }
        </form>
    );
}

export default RegistroAcuerdos;
