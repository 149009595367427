import './constants.css'
import { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { headerForm } from '../constants/bodiesYFiltos';
import { getTrabajador } from '../endpoints/generales';

// ------------------------------------------------- 
export const SeleccionarTrabajador = ({ selectedTrabajador, setSelectedTrabajador, registroTrabajador }) => {
    // Datos trabajador
    const [CUI, setCUI] = useState(null);
    const [registro, setRegistro] = useState(null);

    const toast = useRef(null);

    useEffect(() => {
        setRegistro(registroTrabajador);
        if (registroTrabajador) {
            GetTrabajador(registroTrabajador);
        }
    }, [registroTrabajador])

    function GetTrabajador(reg = null) {
        var obj = {
            Registro: (!reg ? registro : reg),
            CUI: CUI
        }
        getTrabajador(obj).then((resp) => {
            if (resp.data) {
                // toast.current.show({ severity: "success", summary: 'Info', detail: "Trabajador encontrado" });
                setSelectedTrabajador(resp.data);
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: "No se encontró el trabajador" });
            }
        }).catch(err => {
            console.log(err);
            alert("Ocurrió un error :(");
        });
    }

    return <>
        <Toast ref={toast} />
        <Card header={headerForm("Trabajador", true)} className="card">
            <br />
            <Fieldset legend="Búsqueda por Registro Personal o CUI" toggleable collapsed={false}>
                <div className="grid p-fluid mt-1 inputs-datos-trabajador">
                    {/* REGISTRO */}
                    <div className="field col-12 md:col-5 col_toggle">
                        <span className="p-float-label">
                            <InputNumber value={registro}
                                onValueChange={
                                    (e) => {
                                        setRegistro(e.value);
                                        setCUI(null);
                                    }
                                }
                                mode="decimal" showButtons min={0} useGrouping={false} />
                            <label >Registro Personal</label>
                        </span>
                    </div>
                    {/* CUI */}
                    <div className="field col-12 md:col-6 col_toggle">
                        <span className="p-float-label">
                            <InputNumber type="text" value={CUI}
                                onValueChange={(e) => {
                                    setCUI(e.value);
                                    setRegistro(null);
                                }}
                                mode="decimal" showButtons min={0} useGrouping={false} />
                            <label>CUI</label>
                        </span>
                    </div>
                    <div className='field col-12 md:col-1 col_toggle'>
                        <Button type='button' className='p-button-rounded p-button-info'
                            icon="pi pi-search" onClick={() => GetTrabajador()}
                            tooltip='Buscar trabajador' />
                    </div>
                </div>
            </Fieldset>
            <br /><br />
            <h4 style={{ textAlign: 'center' }}>Trabajador Seleccionado *</h4>
            <br />
            <TrabajadorSeleccionado selectedTrabajador={selectedTrabajador} />
        </Card>
    </>
};

export const TrabajadorSeleccionado = ({ selectedTrabajador }) => {
    return <>
        <div className="grid p-fluid mt-1">
            {/* CUI */}
            <div className="field col-12 md:col-6 col_toggle">
                <label>CUI</label>
                <InputText type="text" value={selectedTrabajador.CUI} readOnly />
            </div>
            {/* REGISTRO */}
            <div className="field col-12 md:col-6 col_toggle">
                <label>Registro</label>
                <InputText type="text" value={selectedTrabajador.Registro} readOnly />
            </div>
        </div>
        {/* NOMBRE */}
        <label>Nombre</label>
        <InputText type="text" value={selectedTrabajador.Nombre} readOnly />
    </>
}