import { crearDate, moneyFormat } from '../../constants/bodiesYFiltos';
import {
    calcularCantidadMeses, calcularInteresesPorPeriodo,
    calcularS, calcularTotalMeses
} from './funciones';

// ------------------------------------------------- BODIES Y FILTROS
export const bodyDiferencia = (rowData) => {
    return <>{moneyFormat(rowData.SNominal - rowData.SRecibido)}</>
};

export const bodyCuotaAPagar = (rowData) => {
    return <>{moneyFormat((rowData.SNominal - rowData.SRecibido) * (rowData.Cuota / 100))}</>
};


const diccionarioMes = (numero, corto = true) => {
    const meses = {
        1: corto ? 'ene' : 'enero',
        2: corto ? 'feb' : 'febrero',
        3: corto ? 'mar' : 'marzo',
        4: corto ? 'abr' : 'abril',
        5: corto ? 'may' : 'mayo',
        6: corto ? 'jun' : 'junio',
        7: corto ? 'jul' : 'julio',
        8: corto ? 'ago' : 'agosto',
        9: corto ? 'sep' : 'septiembre',
        10: corto ? 'oct' : 'octubre',
        11: corto ? 'nov' : 'noviembre',
        12: corto ? 'dic' : 'diciembre',
    };

    return meses[numero] || '';
}

function formatDateMonth(fecha, corto) {
    if (!fecha)
        return null
    var now = crearDate(fecha);

    var month, year;

    year = now.getFullYear();
    month = now.getMonth() + 1;

    month = diccionarioMes(month, corto);

    return month + '-' + year;
}

export const fechaMesBody = (rowData, campo, corto = true) => {
    return <>{formatDateMonth(rowData[campo], corto)}</>
};

// --------------------------------------------------- FUNCIONES
export function CalcularIntereses(fechaDescripcion, cuota, SNominal, SRecibido,
    interesesHasta, periodos) {
    var obj = {};
    if (fechaDescripcion === null || fechaDescripcion === undefined ||
        cuota === null || cuota === undefined ||
        SNominal === null || SNominal === undefined ||
        SRecibido === null || SRecibido === undefined ||
        interesesHasta === null || interesesHasta === undefined ||
        (periodos === null || periodos === undefined ||
            (periodos && periodos.length === 0))) {
        return obj;
    }

    // Calcular cantidad de meses
    var periodosConCantidadMeses = calcularCantidadMeses(periodos, fechaDescripcion, interesesHasta);

    // Calcular total de meses
    var totalMeses = calcularTotalMeses(periodosConCantidadMeses);
    //setTMes(totalMeses);
    obj.TMes = totalMeses;

    // Calcular intereses por periodo
    var periodosConIntereses = calcularInteresesPorPeriodo(periodosConCantidadMeses, fechaDescripcion);
    // setPeriodos(periodosConIntereses);
    obj.Periodos = periodosConIntereses;

    // Calcular S
    var cuotaAPagar = (SNominal - SRecibido) * (cuota / 100);
    var ESE = calcularS(periodosConIntereses, cuotaAPagar);
    //setS(ESE.toFixed(2));
    obj.S = ESE.toFixed(2);

    // Cuota a Pagar
    //setCuotaAPagar(cuotaAPagar.toFixed(2));
    obj.CuotaAPagar = cuotaAPagar.toFixed(2);

    // Calcular Intereses Completo
    // setInteresesCompleto((ESE - cuotaAPagar).toFixed(2));
    obj.InteresesCompleto = (ESE - cuotaAPagar).toFixed(2);

    return obj;
}

