import { useParams } from 'react-router-dom';
import { TabView, TabPanel, } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { useRef, useState, useEffect } from 'react';
import { update_loan_route, get_prestamo_especifico_route } from '../../constants/backend_routes';
import './formulario_prestamos.css'


const UpdatePrestamo = () => {
    const regex_alfa = /[a-zA-zñÑáÁéÉíÍóÓúÚ0-9 ]/;
    const {clave} = useParams() 
    const [registro, setRegistro] = useState();
    const [interesFaltante, setInteresFaltante] = useState();
    const [capitalFaltante, setCapitalFaltante] = useState();
    const [cuota, setCuota] = useState();
    const [estadoReporteria, setEstadoReporteria]= useState();
    const [fechaCambio, setFechaCambio] = useState();
    const [cartera, setCartera] = useState();


    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${get_prestamo_especifico_route}/${clave}`)
            const responseJson = await response.json()
            setRegistro(responseJson.prestamo.partida)
            setInteresFaltante(responseJson.prestamo.interes_faltante)
            setCapitalFaltante(responseJson.prestamo.capital_faltante)
            setCuota(responseJson.prestamo.siguiente_cuota)
            setEstadoReporteria(responseJson.prestamo.estado_reporteria)
            setCartera(responseJson.prestamo.cartera)
        }
        fetchData()
    }, [])

    const reset_form = () => {
        setInteresFaltante('')
        setCapitalFaltante('')
        setCuota('')
        setEstadoReporteria('')
        setCartera('')
    }
    const toast = useRef(null);

    const submitLoan = async (loan) =>{
        const response = await fetch(update_loan_route, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(loan)
        })
        if (!response.ok){
            return false
        } else {
            return true
        }
    }


    const confirm = (event) => {
        confirmPopup({
            target: document.getElementById('alzas_confirm'),
            message: '¿Seguro que desea continuar?',
            icon: 'pi pi-question-circle',
            acceptLabel: 'Sí',
            accept: async () => {
                // consumir endpoint
                const success = await submitLoan({
                    partida: registro,
                    interes_faltante: interesFaltante,
                    capital_faltante: capitalFaltante,
                    siguiente_cuota: cuota,
                    estado_reporteria: estadoReporteria,
                    fecha_cambio: fechaCambio.toISOString().split('T')[0],
                    cartera: cartera
                })
                if (success){
                    toast.current.show({ severity: 'success', summary: 'Prestamo Actualizado.' });
                }else{
                    toast.current.show({ severity: 'error', summary: 'El prestamo no pudo ser actualizado'})
                }
            }
        });
        event.preventDefault();
    }
    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <div className='container'>
            <TabView>
                <TabPanel header="Formulario de modificacion prestamo" leftIcon='mr-2 pi pi-user-plus'>
                    <form id='alzas_form' onSubmit={confirm} noValidate={false}>
                        <Toast ref={toast}></Toast>
                        <div className="grid p-fluid formulario">
                            {/* INTERES FALTANTE */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="number" keyfilter="num" required={true} value={interesFaltante} onChange={(e) => setInteresFaltante(e.target.value)} />
                                        <label>Interes pendiente</label>
                                    </span>
                                </div>
                            </div>
                            {/* CAPITAL FALTANTE */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="number" keyfilter="num" required={true} value={capitalFaltante} onChange={(e) => setCapitalFaltante(e.target.value)} />
                                        <label>Capital</label>
                                    </span>
                                </div>
                            </div>
                            {/* CUOTA */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="number" keyfilter="num" required={true} value={cuota} onChange={(e) => setCuota(e.target.value)} />
                                        <label>Cuota</label>
                                    </span>
                                </div>
                            </div>
                            {/* ESTADO */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={true} value={estadoReporteria} onChange={(e) => setEstadoReporteria(e.target.value)} />
                                        <label>Estado</label>
                                    </span>
                                </div>
                            </div>
                            {/* FECHA CAMBIO ESTADO */}
                            <div className='field col-12 md:col-3'>
                                <div className='p-inputgroup'>
                                    <span className='p-inputgroup-addon'>
                                        <i className='pi pi-key'></i>
                                    </span>
                                    <span className='p-float-label'>
                                    <Calendar dateFormat="yy-mm-dd" value={fechaCambio} onChange={(e) => setFechaCambio(e.value)}></Calendar>
                                    <label>Fecha de cambio de estado</label>
                                    </span>
                                </div>
                            </div>
                            {/* CARTERA */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-calendar"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={true} value={cartera} onChange={(e) => setCartera(e.target.value)} />
                                        <label>Cartera (FOX - INMEDIATOS)</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="confirm" >
                            <Button id='alzas_confirm' type='submit' icon="pi pi-check-circle" label="Actualizar" className="mt-2 p-button-raised font-weight-bold" />
                            <ConfirmPopup />
                        </div>
                    </form>
                </TabPanel>
            </TabView>

        </div>

    );
}

export default UpdatePrestamo;