import { Button } from 'primereact/button';
import './menubar.css';
import { Menubar } from 'primereact/menubar';
import { Link, useNavigate } from 'react-router-dom';
import pdfFile from '../manuales/manual_usuario.pdf';
import { requestOptions } from '../misc/requestOptions';

function MenuBar() {

    const navigate = useNavigate();

    const getManual = async () => {
        try {
            const link = document.createElement('a');
            link.href = pdfFile;
            link.download = 'MANUAL NÓMINAS.pdf';
            link.click();
        } catch (error) {
            console.error('Error al descargar el PDF.');
        }
    }

    const request_report = async (idReport, fileType = 'xlsx') => {
        try {
            if (!window.confirm('¿Desea descargar la base de datos de la nómina?\nDeberá esperar un momento mientras se genera su archivo en Excel.'))
                return;
            const response = await fetch(process.env.REACT_APP_API_URL_REPORTERIA_JUBILADOS,
                requestOptions('POST', {
                    reportSelected: idReport,
                    fileType: fileType
                }))
            if (!response.ok) throw new Error('No fue posible generar el reporte.');
            const docBlob = await response.blob();
            // Crear un objeto URL a partir del Blob
            const docUrl = URL.createObjectURL(docBlob);
            // Crear un enlace temporal para descargar el doc
            const a = document.createElement('a');
            a.href = docUrl;
            a.download = 'BASE_NOMINA_PP';
            a.click();
            // Liberar la URL del objeto Blob
            URL.revokeObjectURL(docUrl);
        } catch (error) {
            console.error('Error al descargar el reporte.');
        }
    }

    const items = [
        {
            label: 'Nómina',
            icon: 'pi pi-fw pi-users',
            items: [
                {
                    label: 'Nueva',
                    icon: 'pi pi-fw pi-plus',
                    command: () => { navigate("/nominas/nueva"); }
                },
                {
                    separator: true
                },
                {
                    label: 'Seleccionar',
                    icon: 'pi pi-fw pi-search',
                    command: () => { navigate("/nominas/buscar"); }
                }
            ]
        },
        {
            label: 'Descuentos',
            icon: 'pi pi-fw pi-sort-amount-down-alt',
            items: [
                {
                    label: 'Catálogo',
                    icon: 'pi pi-fw pi-database',
                    command: () => { navigate("/nominas/descuentos"); }
                },
                {
                    separator: true
                },
                {
                    label: 'Gestionar',
                    icon: 'pi pi-fw pi-pencil',
                    command: () => { navigate("/nominas/gestion-descuentos"); }
                },
                {
                    separator: true
                },
                {
                    label: 'Carga masiva',
                    icon: 'pi pi-fw pi-file-excel',
                    command: () => { navigate("/nominas/carga-descuentos"); }
                }
            ]
        },
        {
            label: 'Reportería',
            icon: 'pi pi-fw pi-chart-line',
            command: () => { navigate("/nominas/reporteria"); }
        },
        {
            label: 'Gestión de documentos',
            icon: 'pi pi-fw pi-folder-open',
            command: () => { navigate("/nominas/gestion-documentos"); }
        },
        {
            label: 'Libro de salarios',
            icon: 'pi pi-fw pi-book',
            command: () => { navigate("/nominas/libro-salarios"); }
        },
        {
            label: 'Estadísticas',
            icon: 'pi pi-fw pi-chart-pie',
            command: () => { navigate("/nominas/estadisticas"); }
        },
        {
            label: 'Otros',
            icon: 'pi pi-fw pi-bars',
            items: [
                {
                    label: 'Base de datos',
                    icon: 'pi pi-fw pi-database',
                    command: () => { request_report(1) }
                },
                {
                    separator: true
                },
                {
                    label: 'Bancos',
                    icon: 'pi pi-fw pi-money-bill',
                    command: () => { navigate("/nominas/bancos"); }
                },
                {
                    separator: true
                },
                {
                    label: 'Partidas',
                    icon: 'pi pi-fw pi-hashtag',
                    command: () => { navigate("/nominas/partidas"); }
                },
                {
                    separator: true
                },
                {
                    label: 'Firmas reportes',
                    icon: 'pi pi-fw pi-pencil',
                    command: () => { navigate("/nominas/firmas"); }
                }
            ]
        }
    ];

    const end = (
        <>
            <Button type='button' icon='pi pi-question-circle' onClick={getManual}
                style={{ backgroundColor: 'transparent', border: 'none', color: 'black', width: 'fit-content' }} />
            <Link to="/">
                <Button type='button' icon='pi pi-home' style={{ backgroundColor: 'transparent', border: 'none', color: 'black' }} />
            </Link>
        </>
    );

    return (
        <div className="menu__bar">
            <Menubar model={items} end={end} />
        </div>
    );
}

export default MenuBar;
