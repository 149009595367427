import { useParams } from 'react-router-dom';
import { TabView, TabPanel, } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { useEffect, useRef, useState } from 'react';
import { update_persona_route, get_persona_especifica_route } from '../../constants/backend_routes';
import './formulario_usuario.css'; // Importa el archivo CSS para estilizar el formulario

const UpdateUsuarioMoras = () => {
    const {id_usuario} = useParams() 
    const regex_alfa = /[a-zA-zñÑáÁéÉíÍóÓúÚ 0-9/-]/;
    const [numero_dpi, setDPI] = useState('');
    const [registro, setRegistro] = useState('');
    const [plaza, setPlaza] = useState('');
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [apellido_casada, setApellidoCasada] = useState('');
    const [fecha_nacimiento, setFechaNacimiento] = useState('');
    const [direccion, setDireccion] = useState('');
    const [correo, setCorreo] = useState('');
    const [fecha_alza_nomina, setFechaAlzaNomina] = useState('');
    const [fecha_inicio_pension, setFechaInicioPension] = useState('');
    const [genero, setGenero] = useState('');
    const [telefono, setTelefono] = useState('');
    const [comentario, setComentario] = useState('');
    const [tipo_comentario, setTipoComentario] = useState('');
    const [pension, setPension] = useState('');
    const [estado, setEstado] = useState('');

    const toast = useRef(null);

    useEffect(()=>{
        const fetchData = async () => {
            const response = await fetch(`${get_persona_especifica_route}/${id_usuario}`)
            const responseJson = await response.json()
            setDPI(responseJson.persona.numero_dpi)
            setRegistro(responseJson.persona.registro)
            setPlaza(responseJson.persona.plaza)
            setNombres(responseJson.persona.nombres)
            setApellidos(responseJson.persona.apellidos)
            setFechaNacimiento(responseJson.persona.fecha_nacimiento)
            setDireccion(responseJson.persona.direccion)
            setCorreo(responseJson.persona.correo)
            setFechaAlzaNomina(responseJson.persona.fecha_alza)
            setFechaInicioPension(responseJson.persona.inicio_pension)
            setGenero(responseJson.persona.genero)
            setTelefono(responseJson.persona.telefono)
            setPension(responseJson.persona.valor_pension)
            setEstado(responseJson.persona.estado)
        }
        fetchData()
    },[])

    const submitPerson = async (person) => {
        const response = await fetch(update_persona_route, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(person)
        })
        console.log(person)
        if (!response.ok) {
            return false
        } else {
            return true
        }
    }

    function formatDateToYYYYMMDD(date) {
        const dateObject = new Date(date)
        const year = dateObject.getFullYear();
        // Se agrega 1 al mes porque los meses en JavaScript van de 0 a 11
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const day = String(dateObject.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }


    const confirm = (event) => {
        confirmPopup({
            target: document.getElementById('alzas_confirm'),
            message: '¿Seguro que desea continuar?',
            icon: 'pi pi-question-circle',
            acceptLabel: 'Sí',
            accept: async () => {
                // consumir endpoint
                const fechaNacimiento = formatDateToYYYYMMDD(fecha_nacimiento)
                const fechaAlza = formatDateToYYYYMMDD(fecha_alza_nomina)
                const fechaPension = formatDateToYYYYMMDD(fecha_inicio_pension)
                const success = await submitPerson({
                    numero_dpi: parseInt(numero_dpi),
                    registro: parseInt(registro),
                    plaza: parseInt(plaza),
                    nombres: nombres,
                    apellidos: apellido_casada ? apellidos + ' ' + apellido_casada : apellidos,
                    fecha_nacimiento: fechaNacimiento,
                    direccion: direccion,
                    correo: correo,
                    fecha_alza: fechaAlza,
                    inicio_pension: fechaPension,
                    genero: genero,
                    telefono: parseInt(telefono),
                    comentario: comentario,
                    tipo_comentario: tipo_comentario,
                    valor_pension: parseFloat(pension),
                    estado: estado
                })
                if (success) {
                    toast.current.show({ severity: 'success', summary: 'Usuario modificado correctamente' });
                } else {
                    toast.current.show({ severity: 'error', summary: 'El usuario no pudo ser modificado correctamente' })
                }
            }
        });
        event.preventDefault();
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        // Realiza acciones con los datos del formulario
        // console.log('Nombre:', nombre);
        // console.log('Código:', codigo);
        // console.log('Cantidad:', cantidad);
        // console.log('Fecha:', fecha);
        // console.log('Moroso:', moroso);
        // console.log('Descripción:', descripcion);
    };

    return (
        <div className="container">
            <TabView>
                <TabPanel header="Formulario de usuario" leftIcon='mr-2 pi pi-user-plus'>

                    <form id='alzas_form' onSubmit={confirm} noValidate={false}>
                        <Toast ref={toast}></Toast>
                        <div className="grid p-fluid formulario">
                            {/* DPI */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter="pint" required={false } value={numero_dpi} onChange={(e) => setDPI(e.target.value)} />
                                        <label>Numero de DPI</label>
                                    </span>
                                </div>
                            </div>
                            {/* REGISTRO */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter="pint" required={false } value={registro} onChange={(e) => setRegistro(e.target.value)} />
                                        <label>Registro</label>
                                    </span>
                                </div>
                            </div>
                            {/* PLAZA */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={false   } value={plaza} onChange={(e) => setPlaza(e.target.value)} />
                                        <label>Plaza</label>
                                    </span>
                                </div>
                            </div>
                            {/* NOMBRES */}
                            <div className="field col-12 md:col-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={false   } value={nombres} onChange={(e) => setNombres(e.target.value)} />
                                        <label>Nombres</label>
                                    </span>
                                </div>
                            </div>
                            {/* APELLIDOS */}
                            <div className="field col-12 md:col-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={false   } value={apellidos} onChange={(e) => setApellidos(e.target.value)} />
                                        <label>Apellidos</label>
                                    </span>
                                </div>
                            </div>
                            {/* APELLIDO DE CASADA */}
                            <div className="field col-12 md:col-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={false} value={apellido_casada} onChange={(e) => setApellidoCasada(e.target.value)} />
                                        <label>Apellido de casada</label>
                                    </span>
                                </div>
                            </div>
                            {/* DIRECCION */}
                            <div className="field col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={false   } value={direccion} onChange={(e) => setDireccion(e.target.value)} />
                                        <label>Direccion</label>
                                    </span>
                                </div>
                            </div>
                            {/* CORREO */}
                            <div className="field col-12 md:col-4">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" required={false  } value={correo} onChange={(e) => setCorreo(e.target.value)} />
                                        <label>Correo</label>
                                    </span>
                                </div>
                            </div>
                            {/* GENERO */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={false   } value={genero} onChange={(e) => setGenero(e.target.value)} />
                                        <label>Genero</label>
                                    </span>
                                </div>
                            </div>
                            {/* TELEFONO */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" required={false  } value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                                        <label>Telefono</label>
                                    </span>
                                </div>
                            </div>
                            {/* COMENTARIO */}
                            <div className="field col-12 md:col-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={false   } value={comentario} onChange={(e) => setComentario(e.target.value)} />
                                        <label>Comentario</label>
                                    </span>
                                </div>
                            </div>
                            {/* TIPO COMENTARIO*/}
                            <div className="field col-12 md:col-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={false   } value={tipo_comentario} onChange={(e) => setTipoComentario(e.target.value)} />
                                        <label>Tipo Comentario</label>
                                    </span>
                                </div>
                            </div>
                            {/* VALOR PENSION */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter="num" required={false  } value={pension} onChange={(e) => setPension(e.target.value)} />
                                        <label>Valor pension</label>
                                    </span>
                                </div>
                            </div>
                            {/* ESTADO */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={false   } value={estado} onChange={(e) => setEstado(e.target.value)} />
                                        <label>Estado</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="confirm" >
                            <Button id='alzas_confirm' type='submit' icon="pi pi-check-circle" label="Modificar" className="mt-2 p-button-raised font-weight-bold" />
                            <ConfirmPopup />
                        </div>
                    </form>
                </TabPanel>
            </TabView>
        </div>
    );
};

export default UpdateUsuarioMoras;
