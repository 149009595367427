import { useEffect, useState } from 'react';
import './resumen_moras.css';
import ReportModule from './tabla_resumen';
import { get_bitacoras_route } from '../../constants/backend_routes';

function ResumenMoras(){
    const [responseData, setResponseData] = useState()
    
    useEffect(()=>{
        const fetchData = async () => {
            const response = await fetch(get_bitacoras_route)
            const responseJson = await response.json()
            setResponseData(responseJson.data)
        }
        fetchData()
        console.log(responseData)
    })

    return(
        <div className='resumen__moras'>
            <ReportModule reportData={responseData} />
        </div>
    );
}

export default ResumenMoras;