import './nueva.css';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import AutorizacionDialog from '../autorizacion_dialog/autoriza_dialog';
import { requestOptions } from '../misc/requestOptions';
import get_bancos from '../misc/get_bancos';

function NuevaNomina() {
    const navigate = useNavigate();

    const items = [
        { label: 'NÓMINA', command: () => { navigate("/nominas") } },
        { label: 'CREAR NÓMINA' }
    ];

    const home = { icon: 'pi pi-home', command: () => { navigate("/") } }
    const toast = useRef(null);

    const [tipoNomina, setTipoNomina] = useState(null);
    const [formaPago, setFormaPago] = useState(null);
    const [fecha, setFecha] = useState(new Date());
    const [observacion, setObservacion] = useState('');
    const [loading, setLoading] = useState(false);
    const [autorizaDialog, setDialog] = useState(null);

    const tiposNominas = [
        { label: 'NORMAL', value: 1 },
        { label: 'COMPLEMENTO', value: 2 },
        { label: 'ÚLTIMAS PENSIONES', value: 7 },
        { label: 'DIFERIDO JUNIO', value: 3 },
        { label: 'DIFERIDO DICIEMBRE', value: 4 },
        { label: 'BONO 14', value: 5 },
        { label: 'AGUINALDO 75% (DICIEMBRE)', value: 6 },
        { label: 'AGUINALDO 25% (ENERO)', value: 8 }
    ];

    const [bancos_list, setBancos] = useState([]);
    useEffect(() => {
        if (tipoNomina === 2 || tipoNomina === 7) {
            async function fetchBancos() {
                const tmp = await get_bancos('dropdown');
                setBancos(tmp);
            }
            if (bancos_list.length === 0) fetchBancos();
            if (tipoNomina === 7) setFormaPago(3); // Últimas pensiones quemado con ChequesGYT (3)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoNomina]);

    const createNomina = () => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL_NUEVA_NOMINA,
            requestOptions('POST', {
                month: (fecha.getMonth() + 1),
                year: fecha.getFullYear(),
                observacion: observacion,
                tipo_nomina: tipoNomina,
                forma_pago: formaPago
            }))
            .then((response) => response.json())
            .then(async (data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                setLoading(false);
                setTipoNomina(null);
                setObservacion('');
                setFecha(null);
                if (!data.error) {
                    await new Promise(resolve => {
                        return setTimeout(resolve, 3000);
                    });
                    navigate('/nominas');
                }
            });
    }

    const validar_procesos_nomina = () => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL_NUEVA_NOMINA)
            .then((response) => response.json())
            .then(async (data) => {
                if (data.message || data.error)
                    toast.current.show({ severity: data.severity, summary: data.message || data.error });
                setLoading(false);
                if (data.data === true) {
                    createNomina();
                }
                else {
                    setDialog(true);
                }
            });
    }

    return (
        <div className="container__nueva">
            <Toast ref={toast}></Toast>
            <BreadCrumb model={items} home={home} />
            {
                autorizaDialog &&
                <AutorizacionDialog
                    continueOperation={() => createNomina()}
                    cancelOperation={() => setDialog(null)}
                    toast={toast}
                />
            }
            <div className='menu__nueva grid p-fluid'>
                <div className="field col-12">
                    <h5>Tipo de nómina</h5>
                    <Dropdown required value={tipoNomina} options={tiposNominas} onChange={(e) => setTipoNomina(e.value)} placeholder="Seleccione tipo de nómina" />
                </div>
                <div className="field col-12">
                    <h5>Mes</h5>
                    <Calendar required value={fecha} onChange={(e) => setFecha(e.value)} view="month" dateFormat="mm/yy" placeholder='Seleccione el mes' />
                </div>
                {
                    (tipoNomina === 2 || tipoNomina === 7) &&
                    <div className="field col-12">
                        <h5>Forma de pago</h5>
                        <Dropdown required value={formaPago} disabled={tipoNomina === 7} options={bancos_list} onChange={(e) => setFormaPago(e.value)} placeholder="Seleccione forma de pago" />
                    </div>
                }
                <div className="field col-12">
                    <h5>Observaciones</h5>
                    <InputTextarea maxLength={50} value={observacion} placeholder={tipoNomina === 2 ? 'NOMINA COMPLEMENTO X' : 'Puede ingresar observaciones'} autoResize onChange={(e) => setObservacion(e.target.value.toUpperCase())} />
                </div>
                <div className="field col-12 text-center mt-3">
                    <Button disabled={!tipoNomina || !fecha} style={{ width: 'fit-content', fontWeight: 'bold' }} className='p-button-raised' label="CREAR NÓMINA" icon={'pi pi-calendar-plus'} loading={loading} onClick={validar_procesos_nomina} />
                </div>
            </div>
        </div>
    );
}

export default NuevaNomina;
