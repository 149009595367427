import './busqueda.css';
import { useState } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import ListadoNominas from '../seleccionar_nomina/listado';

const BuscarNomina = (props) => {

    const now = new Date();
    const [year, setYear] = useState(now.getFullYear());
    const [month, setMonth] = useState(now.getMonth() + 1);
    const [tipoNomina, setTipoNomina] = useState('1,2,3,4,5,6,7');

    const [requestOptions, setRequestOptions] = useState(null);
    // const [loading, setLoading] = useState(false);

    const year_options = () => {
        let arr = [];
        for (let i = 2023; i <= now.getFullYear(); i++) {
            arr.push({ label: i, value: i });
        }
        return arr;
    }
    const month_options = [
        { label: 'ENERO', value: 1 },
        { label: 'FEBRERO', value: 2 },
        { label: 'MARZO', value: 3 },
        { label: 'ABRIL', value: 4 },
        { label: 'MAYO', value: 5 },
        { label: 'JUNIO', value: 6 },
        { label: 'JULIO', value: 7 },
        { label: 'AGOSTO', value: 8 },
        { label: 'SEPTIEMBRE', value: 9 },
        { label: 'OCTUBRE', value: 10 },
        { label: 'NOVIEMBRE', value: 11 },
        { label: 'DICIEMBRE', value: 12 }
    ];
    const tiposNominas = [
        { label: 'TODAS', value: '1,2,3,4,5,6,7' },
        { label: 'NORMAL', value: 1 },
        { label: 'COMPLEMENTO', value: 2 },
        { label: 'DIFERIDO JUNIO', value: 3 },
        { label: 'DIFERIDO DICIEMBRE', value: 4 },
        { label: 'BONO 14', value: 5 },
        { label: 'AGUINALDO', value: 6 },
        { label: 'ÚLTIMAS PENSIONES', value: 7 }
    ];

    const getRequestOptions = () => {
        const { estado } = props;
        return {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                year: year,
                month: month,
                tipo_nomina: tipoNomina,
                estado: estado
            })
        };
    }

    return (
        <div className="container__buscarNomina">
            <div className='menu__buscar grid p-fluid'>
                <div className="field col-12 md:col-3" />
                <div className="field col-12 md:col-3">
                    <h5>Año</h5>
                    <Dropdown required value={year} options={year_options()} onChange={(e) => setYear(e.value)} placeholder="Seleccione año" />
                </div>
                <div className="field col-12 md:col-3">
                    <h5>Mes</h5>
                    <Dropdown required value={month} options={month_options} onChange={(e) => setMonth(e.value)} placeholder="Seleccione mes" />
                </div>
                <div className="field col-12 md:col-3" />
                <div className="field col-12 md:col-3" />
                <div className="field col-12 md:col-4">
                    <h5>Tipo nómina</h5>
                    <Dropdown required value={tipoNomina} options={tiposNominas} onChange={(e) => setTipoNomina(e.value)} placeholder="Tipo de nómina" />
                </div>
                <div className="field col-12 md:col-2 mt-auto text-right">
                    <Button style={{ width: 'fit-content', fontWeight: 'bold', }} className='p-button-raised' label="BUSCAR NÓMINA" icon={'pi pi-search'} onClick={() => setRequestOptions(getRequestOptions())} />
                </div>
                <div className="field col-12 md:col-3" />
            </div>
            {
                requestOptions &&
                <div className='mt-5'>
                    <ListadoNominas requestOptions={requestOptions} />
                </div>
            }
        </div>
    );
}

export default BuscarNomina;
