import { crearDate } from "../constants/bodiesYFiltos";

export default function formatDate(fecha, conHoras = true) {
    if (!fecha)
        return null
    var now = crearDate(fecha);
    var day, month, year, horas, minutos, time;

    year = now.getFullYear();
    month = now.getMonth() + 1;
    day = now.getDate();

    if (month < 10) {
        month = '0' + month;
    }

    if (day < 10) {
        day = '0' + day;
    }

    if (conHoras) {
        horas = now.getHours();
        minutos = now.getMinutes();

        if (horas < 10) {
            horas = '0' + horas;
        }

        if (minutos < 10) {
            minutos = '0' + minutos;
        }

        time = horas + ":" + minutos;
    } else {
        time = ""
    }

    return day + '/' + month + '/' + year + ' ' + time;
}