import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons/faEllipsis'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons/faFileArrowDown'
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { Dialog } from 'primereact/dialog';
import { confirmPopup } from 'primereact/confirmpopup';
import { requestOptions } from '../misc/requestOptions';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Chip } from 'primereact/chip';

const TablaSalarios = (props) => {

    const [historial, setHistorial] = useState([]);
    const [loading, setLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [detalleDialog, setDetalleDialog] = useState(false);
    const [detallePago, setDetallePago] = useState([]);
    const [tipoNomina, setTipoNomina] = useState(0);

    /* Filters */
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    }

    useEffect(() => {
        const { historial } = props;
        setHistorial(historial);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.historial]);

    const nominaBody = (rowData) => {
        return <Chip className='chip_nomina rounded' label={rowData.name} />;
        // return <span style={{ letterSpacing: '0.5px' }} className='status-badge-nom status-liquido'>{rowData.name}</span>
    }

    const descripcionTemplate = (rowData) => {
        return loading === true ?
            <Skeleton></Skeleton> :
            rowData.descripcion
    }
    const nombreTemplate = (rowData) => {
        return loading === true ?
            <Skeleton></Skeleton> :
            rowData.nombre
    }
    const tipoTemplate = (rowData) => {
        return loading === true ?
            <Skeleton></Skeleton> :
            rowData.tipo
    }
    const montoTemplate = (rowData) => {
        return loading === true ?
            <Skeleton></Skeleton> :
            rowData.monto
    }
    const correlativoTemplate = (rowData) => {
        return loading === true ?
            <Skeleton></Skeleton> :
            rowData.correlativo
    }
    const destinoTemplate = (rowData) => {
        return loading === true ?
            <Skeleton></Skeleton> :
            rowData.destino
    }
    const benefTemplate = (rowData) => {
        return loading === true ?
            <Skeleton></Skeleton> :
            rowData.beneficiario
    }
    const acredBody = (rowData) => {
        return <span className={`status-badge-nom acred-${rowData.acreditamiento.toLowerCase()[0]}`}>{rowData.acreditamiento}</span>;
    }
    const acredCompleBody = (rowData) => {
        return loading === true ?
            <Skeleton></Skeleton> :
            acredBody(rowData)
    }
    const destinoBody = (rowData) => {
        if (rowData.tiponomina_idtiponomina != 7)
            return rowData.numero_cuenta;
        return <Button disabled className='text-dark border-0 bg-transparent'><FontAwesomeIcon icon={faEllipsis} /></Button>
    }
    const corrBody = (rowData) => {
        if (rowData.tiponomina_idtiponomina == 2 || rowData.tiponomina_idtiponomina == 7)
            return <Button disabled className='text-dark border-0 bg-transparent'><FontAwesomeIcon icon={faEllipsis} /></Button>
        return rowData.correlativo;
    }

    const obtenerDetalle = (idpersona, idnomina, tiponomina) => {
        setLoading(true);
        setDetalleDialog(true);
        setTipoNomina(tiponomina);
        fetch(process.env.REACT_APP_API_URL_CARGAR_LIBRO_SALARIOS + '/detalle',
            requestOptions('POST', {
                idpersona: idpersona,
                idnomina: idnomina,
                idtiponomina: tiponomina
            }))
            .then((response) => response.json())
            .then((data) => {
                if (data.error)
                    props.toast.current.show({ severity: data.severity, summary: data.message || data.error });
                const x = data.data;
                if (x && x.detalle_pago)
                    setDetallePago(x.detalle_pago);
                setLoading(false);
            });
    }

    const generarConstancia = async (idpersona, idnomina, tiponomina, endpointCert, fileType) => {
        setLoading(idnomina);
        try {
            const response = await fetch(process.env.REACT_APP_API_URL_CERTIFICACIONES + endpointCert,
                requestOptions('POST', {
                    idpersona: idpersona,
                    idnomina: idnomina,
                    idtiponomina: tiponomina,
                    fileType: fileType,
                    certificacion_prestaciones: props.certificacion_prestaciones
                }));
            if (!response.ok) throw new Error('No fue posible generar la constancia.');
            const pdfBlob = await response.blob();
            // Crear un objeto URL a partir del Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);
            // Crear un enlace temporal para descargar el PDF
            // const date = formatDate(new Date(), '-') || '';
            const a = document.createElement('a');
            a.href = pdfUrl;
            a.download = `Constancia_Detalle`;
            a.click();
            // Liberar la URL del objeto Blob
            URL.revokeObjectURL(pdfUrl);
            props.toast.current.show({ severity: 'success', summary: 'Constancia generada.' });
        } catch (error) {
            console.error('Error al descargar el PDF.');
            props.toast.current.show({ severity: 'error', summary: error.toString() });
        }
        setLoading(false);
    }

    const select_tipo_archivo = (id, idpersona, idnomina, tiponomina, endpointCert) => {
        confirmPopup({
            target: document.getElementById(id),
            message: 'Seleccione tipo de archivo',
            icon: 'pi pi-info-circle',
            acceptClassName: 'pdf_btn_report',
            acceptIcon: 'pi pi-file-pdf',
            rejectClassName: 'word_btn_report',
            rejectIcon: 'pi pi-pencil',
            acceptLabel: 'PDF',
            rejectLabel: 'Word',
            accept: () => { generarConstancia(idpersona, idnomina, tiponomina, endpointCert, 'pdf') },
            reject: () => { generarConstancia(idpersona, idnomina, tiponomina, endpointCert, 'docx') }
        });
    }

    const optionBody = (rowData) => {
        return (<div className='menu__buttons'>
            <Button id={'btn_cert_detalle' + rowData.idnomina} className="p-button-rounded p-button-raised p-button-warning" type="button" icon="pi pi-print" tooltip='Descargar'
                onClick={() => select_tipo_archivo('btn_cert_detalle' + rowData.idnomina, rowData.persona_idpersona, rowData.idnomina, rowData.tiponomina_idtiponomina, '/detalle')}
                loading={loading === rowData.idnomina} />
        </div >);
    }

    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }

    const pg_template = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];
            return (
                <React.Fragment>
                    <span className="mx-0" style={{ userSelect: 'none' }}>Elementos por página: </span>
                    <Dropdown className="mr-4 table__paginator" value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        'CurrentPageReport': (options) => {
            return (
                <span style={{ userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} de {options.totalRecords}
                </span>
            )
        }
    };

    const request_export = async (fileType) => {
        setLoading(props.idpersona);
        try {
            const response = await fetch(process.env.REACT_APP_API_URL_CARGAR_LIBRO_SALARIOS + '/exportar',
                requestOptions('POST', {
                    fileType: fileType,
                    idpersona: props.idpersona
                }))
            if (!response.ok) throw new Error('No fue posible exportar el libro de salarios.');
            const docBlob = await response.blob();
            // Crear un objeto URL a partir del Blob
            const docUrl = URL.createObjectURL(docBlob);
            // Crear un enlace temporal para descargar el doc
            const a = document.createElement('a');
            a.href = docUrl;
            a.download = `HISTORIAL_SALARIAL_${props.idpersona}`;
            a.click();
            // Liberar la URL del objeto Blob
            URL.revokeObjectURL(docUrl);
            props.toast.current.show({ severity: 'success', summary: 'Documento generado.' });
            // resetVars();
        } catch (error) {
            console.error('Error al descargar el reporte.');
            props.toast.current.show({ severity: 'error', summary: error.toString() });
        }
        setLoading(false);
    }

    const select_tipo_historial = (id) => {
        confirmPopup({
            target: document.getElementById(id),
            message: 'Seleccione tipo de archivo',
            icon: 'pi pi-info-circle',
            acceptClassName: 'pdf_btn_report',
            acceptIcon: 'pi pi-file-pdf',
            rejectClassName: 'excel_btn_report',
            rejectIcon: 'pi pi-file-excel',
            acceptLabel: 'PDF',
            rejectLabel: 'Excel',
            accept: () => { request_export('pdf') },
            reject: () => { request_export('xlsx') }
        });
    }


    const header = (
        <div className="table-header flex">
            <div className='my-auto'>Historial Salarial</div>
            <div className='ml-auto'>
                <Button id='bt-libsal-export' onClick={() => select_tipo_historial('bt-libsal-export')} className='mr-4 h-100 border-0 text-dark' style={{ backgroundColor: 'white' }} loading={loading === props.idpersona} >
                    {
                        loading !== props.idpersona
                        && <FontAwesomeIcon icon={faFileArrowDown} />
                    } <span style={{ letterSpacing: '1px', marginLeft: '10px' }}>GENERAR HISTORIAL</span>
                </Button >
                <span className="p-input-icon-right">
                    <i className="pi pi-search" />
                    <InputText style={{ width: '100%' }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Buscar" />
                </span>
            </div>
        </div>
    );

    return (
        <div className="datatable-nominas-list">
            <div className="card__listado">
                <DataTable value={historial} header={header} paginator paginatorTemplate={pg_template} first={first} rows={rows} onPage={onCustomPage}
                    selectionMode='single' emptyMessage="No se encontró ningún registro." paginatorClassName="justify-content-end" className="mt-5"
                    filters={filters} globalFilterFields={['name', 'year', 'month', 'acreditamiento']} stripedRows
                    onRowDoubleClick={e => { obtenerDetalle(e.data.persona_idpersona, e.data.idnomina, e.data.tiponomina_idtiponomina); }}
                    rowClassName={(e) => { return e.estado !== 'V' && 'row-anulado' }}>
                    <Column align={'center'} field="idnomina" header="ID"></Column>
                    <Column body={nominaBody} header="Nómina"></Column>
                    <Column field="month" header="Mes" ></Column>
                    <Column field="year" header="Año" ></Column>
                    <Column field="nominal" header="Nominal"></Column>
                    <Column field="liquido" header="Líquido"></Column>
                    <Column header="Banco" body={acredBody}></Column>
                    <Column body={destinoBody} header="Destino" ></Column>
                    <Column field='fecha_pago' header="Fecha pago" ></Column>
                    <Column align={'center'} bodyStyle={{ fontWeight: 'lighter' }} body={corrBody} header="No. Doc." ></Column>
                    {
                        (props.certificaciones || props.certificacion_prestaciones) &&
                        <Column align={'center'} style={{ maxWidth: '90px' }} header="Constancia" body={optionBody}></Column>
                    }
                </DataTable>
                <Dialog visible={detalleDialog} style={{ width: 'auto' }} header="Detalle de pago" modal className="p-fluid" onHide={() => { setDetalleDialog(false); setDetallePago([]); }} resizable={true}>
                    <div className="table__detalle">
                        <DataTable value={detallePago} emptyMessage='Sin detalle.' selectionMode='single'>
                            <Column header="Concepto" field='nombre' body={nombreTemplate}></Column>
                            <Column header="Tipo" field='tipo' body={tipoTemplate}></Column>
                            {(tipoNomina === 2 || tipoNomina === 7) && <Column header="Descripción" field='descripcion' body={descripcionTemplate}></Column>}
                            <Column header="Monto (Q.)" field='monto' body={montoTemplate}></Column>
                            {(tipoNomina === 2 || tipoNomina === 7) && <Column align={'center'} bodyStyle={{ fontWeight: 'lighter' }} body={correlativoTemplate} header="No. Doc." ></Column>}
                            {tipoNomina === 2 && <Column header="Banco" body={acredCompleBody}></Column>}
                            {tipoNomina === 7 && <Column header="Beneficiario" bodyStyle={{ fontSize: '10px' }} body={benefTemplate}></Column>}
                            {(tipoNomina === 2 || tipoNomina === 7) && <Column body={destinoTemplate} header="Destino" ></Column>}
                        </DataTable>
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

export default TablaSalarios;
