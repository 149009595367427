import '../convenios.css';
import { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from "primereact/checkbox";

import { crearPagoConvenio, editarPagoConvenio, eliminarPagoConvenio, getPagosConvenio } from '../../endpoints/convenios';

import { headerForm, moneyBody, mostrarError } from '../../constants/bodiesYFiltos';

import { fechaBody } from '../../constants/bodiesYFiltos';
import { DatosConvenio } from './constants';

export default function VerPagosConvenio() {
    // Datos convenio
    const [idConvenio, setIdConvenio] = useState(null);
    const [convenio, setConvenio] = useState([{}]);
    // Pagos
    const [pagos, setPagos] = useState([]);

    // Nuevo Pago y edición
    const [modoEdicion, setModoEdicion] = useState(false);
    const [fechaPago, setFechaPago] = useState(null);
    const [monto, setMonto] = useState(null);
    const [pagoCheque, setPagoCheque] = useState(null);
    const [numCheque, setNumCheque] = useState(null);

    // Calculos
    const [totalPagado, setTotalPagado] = useState(0);

    const [selected, setSelected] = useState(null);
    const toast = useRef(null);

    useEffect(() => {
        // obtener el convenio seleccionado
        var seleccionado = JSON.parse(sessionStorage.getItem('convenio_seleccionado'));
        setConvenio([seleccionado]);
        setIdConvenio(seleccionado.idConvenio);

        // obtener pagos del convenio
        GetPagosConvenio(seleccionado.idConvenio);
    }, []);

    function CrearEditarPago() {
        var obj = {
            FechaPago: fechaPago,
            Monto: monto,
            NumCheque: pagoCheque ? numCheque : null,
            idConvenio: idConvenio,
            idPagoConvenio: selected ? selected.idPagoConvenio : null
        }
        if (modoEdicion) {
            editarPagoConvenio(obj).then((resp) => {
                if (resp.severity === "success") {
                    toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                    ResetForm(true);
                } else {
                    toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
                }
            }).catch(err => {
                mostrarError(err);
            });
        } else {
            crearPagoConvenio(obj).then((resp) => {
                if (resp.severity === "success") {
                    toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                    ResetForm();
                } else {
                    toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
                }
            }).catch(err => {
                mostrarError(err);
            });
        }
    }

    function GetPagosConvenio(idConvenio) {
        getPagosConvenio({ idConvenio: idConvenio }).then((resp) => {
            setPagos(resp.data);

            var TotalPagado = resp.data.reduce((accumulator, currentValue) => accumulator + currentValue.Monto, 0);
            setTotalPagado(TotalPagado);
        }).catch(err => {
            mostrarError(err);
        });
    }

    const EliminarPago = () => {
        if (selected === null)
            return

        confirmDialog({
            message: '¿Está seguro de eliminar el pago seleccionado?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: acceptEliminar
        });
    };

    const acceptEliminar = () => {
        eliminarPagoConvenio({ idPagoConvenio: selected.idPagoConvenio }).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                // quitar el pago
                setMonto(null); setFechaPago(null); GetPagosConvenio(idConvenio); setModoEdicion(false);
                setSelected(null);
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err);
        });
    }

    function ResetForm() {
        setMonto(null);
        setFechaPago(null);
        GetPagosConvenio(idConvenio);
        setModoEdicion(false);
        setSelected(null);
        setPagoCheque(false);
        setNumCheque(null);
    }

    return (
        <div className='primario-crear-registro'>
            {/* PARA LA CONFIRMACIÓN DE ELIMINAR CONTRATO */}
            <Toast ref={toast} />
            <ConfirmDialog />
            {/* DATOS DEL CONVENIO */}
            <Card header={headerForm("Datos del convenio", true)} className="card">
                <DatosConvenio convenio={convenio} />
            </Card>
            <br />
            {/* GESTION DE PAGOS DE CONVENIO */}
            <Card className="card">
                {/* PARA AGREGAR O EDITAR PAGO */}
                <h3 style={{ textAlign: 'center' }}>{modoEdicion ? "Editar " : "Agregar Nuevo "}Pago</h3><br />
                <div className="grid p-fluid d-flex justify-content-around">
                    {/* FECHA DE PAGO */}
                    <div className="field col-12 md:col-3 col_toggle">
                        <label>Fecha del pago: </label>
                        <Calendar required={true} value={fechaPago}
                            onChange={(e) => setFechaPago(e.target.value)} showButtonBar showIcon />
                    </div>
                    {/* MONTO */}
                    <div className="field col-12 md:col-3 col_toggle">
                        <label>Monto del pago(Q): </label>
                        <InputNumber value={monto}
                            onValueChange={(e) => setMonto(e.value)}
                            minFractionDigits={2} maxFractionDigits={2} showButtons min={0} />
                    </div>
                    {/* NUM CHEQUE */}
                    <div className="field col-12 md:col-3 col_toggle">
                        <label>Número de cheque (opcional): </label>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <Checkbox onChange={e => setPagoCheque(e.checked)}
                                    checked={pagoCheque}></Checkbox>
                            </span>
                            <InputNumber value={numCheque} disabled={!pagoCheque}
                                onValueChange={(e) => setNumCheque(e.value)}
                                mode="decimal" showButtons min={0} useGrouping={false} />
                        </div>
                    </div>
                    <div className="botones-pagos">
                        {/* BOTONES */}
                        <Button type="button"
                            severity={modoEdicion ? "success" : "info"}
                            icon={modoEdicion ? "pi pi-check" : "pi pi-plus"}
                            tooltip={modoEdicion ? "Guardar Cambios" : "Agregar Pago"}
                            onClick={() => CrearEditarPago()} />
                        <Button type="button" icon="pi pi-pencil" tooltip="Editar Pago"
                            severity="warning" disabled={selected === null}
                            onClick={() => {
                                setModoEdicion(true);
                                setMonto(selected.Monto);
                                setFechaPago(new Date(selected.FechaPago));
                                setPagoCheque(!!selected.NumCheque);
                                setNumCheque(selected.NumCheque);
                            }} />
                        <Button type="button" icon="pi pi-trash" tooltip="Eliminar Pago"
                            severity="danger" disabled={selected === null}
                            onClick={EliminarPago} />
                    </div>
                </div><br />
                {/* DIVIDER */}
                <div className="w-100 text-align-center my-10" >
                    <div style={{ borderTop: '1px solid #ccc' }}></div><br />
                </div>
                <h3 style={{ textAlign: 'center' }}>Pagos realizados</h3><br />
                <div style={{ minHeight: '200px' }}>
                    <DataTable value={pagos} selectionMode='single' scrollable scrollHeight='200px'
                        selection={selected} onSelectionChange={(e) => { !modoEdicion && setSelected(e.value) }}
                        onRowClick={() => { !modoEdicion && setSelected(null) }}
                        tableStyle={{ minWidth: '50rem' }} size='small'>
                        <Column header="Fecha de Pago"
                            body={(rowData) => fechaBody(rowData, "FechaPago")} ></Column>
                        <Column field="Monto" header="Monto(Q)"
                            body={(rowData) => moneyBody(rowData, "Monto")}></Column>
                        <Column field="NumCheque" header="Número de Cheque"
                            body={(rowData) => rowData.NumCheque ? rowData.NumCheque : "Pago en Efectivo"}></Column>
                    </DataTable>
                </div>
                <br />
                <div className="grid p-fluid">
                    {/* TOTAL PAGADO */}
                    <div className="field col-12 md:col-3 col_toggle ml-auto md-0">
                        <label>Total pagado(Q): </label>
                        <InputNumber value={totalPagado}
                            minFractionDigits={2} maxFractionDigits={2} min={0} />
                    </div>
                    {/* MONTO PENDIENTE */}
                    <div className="field col-12 md:col-3 col_toggle">
                        <label>Monto pendiente de pago(Q): </label>
                        <InputNumber value={convenio[0].MontoTotal - totalPagado}
                            minFractionDigits={2} maxFractionDigits={2} min={0} />
                    </div>
                </div>
            </Card>
        </div>
    );
}
