import './reintegros.css';
import { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { periodoSeleccionadoItem } from './constants';
import { bodyMesInicio, bodyMesFinal, headerForm, mostrarError } from '../constants/bodiesYFiltos';
import { SeleccionarTrabajador } from '../constants/seleccionar_trabajador';
import { crearReintegro } from '../endpoints/reintegros';
import { getHistorialPeriodos } from '../endpoints/periodos';

export default function CrearReintegro() {
    // Datos trabajador
    const [selectedTrabajador, setSelectedTrabajador] = useState({ CUI: '', Registro: '', Nombre: '' });

    // Datos del reintegro
    const [cantidad, setCantidad] = useState(null);
    const [motivo, setMotivo] = useState('');
    const [fecha, setFecha] = useState(null);
    const [numCheque, setNumCheque] = useState(null);
    const [fechaCheque, setFechaCheque] = useState(null);
    const [periodos, setPeriodos] = useState([]);

    const [periodosSeleccionables, setPeriodosSeleccionables] = useState(null);
    const [periodosVisible, setPeriodosVisible] = useState(false);
    const [periodoSeleccionado, setPeriodoSeleccionado] = useState(null);

    const [selected, setSelected] = useState(null);

    const toast = useRef(null);

    useEffect(() => {
        // obtener todos los periodos
        getHistorialPeriodos().then((resp) => {
            setPeriodosSeleccionables(resp.data);
        }).catch(err => {
            mostrarError(err);
        });
    }, []);

    function CrearReintegro() {
        if (!selectedTrabajador.Registro) {
            alert("Seleccione un trabajador");
            return;
        }
        var obj = {
            Cantidad: cantidad,
            Motivo: motivo,
            Fecha: fecha,
            NumCheque: numCheque,
            FechaCheque: fechaCheque,
            Periodos: periodos,
            RegistroTrabajador: selectedTrabajador.Registro
        }
        crearReintegro(obj).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                ResetForm();
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err);
        });
    }

    function ResetForm() {
        setCantidad(null);
        setMotivo('');
        setFecha(null);
        setNumCheque(null);
        setFechaCheque(null);
        setPeriodos([]);
        setSelected(null);
        setSelectedTrabajador({ CUI: '', Registro: '', Nombre: '' });
    }

    const confirm = (event) => {
        event.preventDefault();
        CrearReintegro();
    }

    const FooterPeriodos = () => {
        return <Button label="Agregar" icon="pi pi-check"
            onClick={() => {
                setPeriodos([...periodos, periodoSeleccionado]);
                setPeriodoSeleccionado(null);
                setPeriodosVisible(false);
            }
            } disabled={!periodoSeleccionado} />;
    };

    function QuitarPeriodo() {
        var del = periodos.filter((periodo) => periodo.idPeriodo !== selected.idPeriodo);
        setPeriodos(del);
        setSelected(null);
    }

    return (
        <div className='primario-crear-registro'>
            <Toast ref={toast} />
            <div className="grid p-fluid">
                <div className='col-12 md:col-6'>
                    <SeleccionarTrabajador
                        selectedTrabajador={selectedTrabajador}
                        setSelectedTrabajador={setSelectedTrabajador}
                    />
                </div>
                <div className='col-12 md:col-6'>
                    <Card header={headerForm("Datos del reintegro")} className="card">
                        <form onSubmit={confirm} noValidate={false}>
                            <div className="grid p-fluid mt-1">
                                {/* CANTIDAD */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Cantidad (Q) *</label>
                                    <InputNumber required
                                        value={cantidad} onValueChange={(e) => setCantidad(e.value)}
                                        minFractionDigits={2} maxFractionDigits={2} showButtons min={0} />
                                </div>
                                {/* MOTIVO */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Motivo *</label>
                                    <InputText required value={motivo}
                                        onChange={(e) => setMotivo(e.target.value)} />
                                </div>
                            </div>
                            <div className="grid p-fluid mt-1">
                                {/* NUM. CHEQUE */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Número de Cheque *</label>
                                    <InputNumber required
                                        value={numCheque} onValueChange={(e) => setNumCheque(e.value)}
                                        mode="decimal" showButtons min={0} useGrouping={false} />
                                </div>
                                {/* FECHA CHEQUE */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Fecha del Cheque *</label>
                                    <Calendar required
                                        value={fechaCheque}
                                        onChange={(e) => setFechaCheque(e.target.value)}
                                        showButtonBar showIcon />
                                </div>
                            </div>
                            <div className="grid p-fluid mt-1">
                                {/* AGREGAR PERIODOS*/}
                                <div className='col-12 md:col-6'>
                                    <Button type="button" icon="pi pi-calendar" label="Agregar periodos"
                                        onClick={() => setPeriodosVisible(true)}
                                    />
                                    <Dialog header="Agregar periodos" visible={periodosVisible}
                                        style={{ width: '75vw' }} maximizable
                                        modal contentStyle={{ height: '300px' }}
                                        onHide={() => setPeriodosVisible(false)}
                                        footer={FooterPeriodos}>
                                        <DataTable value={periodosSeleccionables} scrollable scrollHeight="flex"
                                            tableStyle={{ minWidth: '50rem' }}
                                            selectionMode="single" selection={periodoSeleccionado}
                                            onSelectionChange={(e) => { setPeriodoSeleccionado(e.value); }}
                                            onRowClick={() => { setPeriodoSeleccionado(null); }}>
                                            <Column header="#" body={(rowData, rowIndex) => rowIndex.rowIndex + 1} />
                                            <Column field='Anio' header='Año' />
                                            <Column field='MesInicio' header='Mes Inicio'
                                                body={bodyMesInicio} />
                                            <Column field='MesFinal' header='Mes Final'
                                                body={bodyMesFinal} />
                                            <Column field='TasaInteres' header='Tasa Interés(%)' />
                                        </DataTable>
                                    </Dialog>
                                </div>
                                {/* QUITAR PERIODOS*/}
                                <div className='col-12 md:col-6'>
                                    <Button type="button" icon="pi pi-times" label="Quitar periodo"
                                        severity="danger" onClick={QuitarPeriodo}
                                        disabled={!selected}
                                    />
                                </div>
                            </div>
                            <br />
                            <div style={{ height: '175px' }}>
                                <DataTable value={periodos} scrollable scrollHeight="175px"
                                    selectionMode='single'
                                    selection={selected}
                                    onSelectionChange={(e) => { setSelected(e.value) }}
                                    onRowClick={() => { setSelected(null) }}
                                    emptyMessage="Agregar periodos">
                                    <Column field='Periodos agregados' header='Periodos agregados'
                                        body={(rowData) => periodoSeleccionadoItem(rowData)} />
                                </DataTable>
                            </div><br />
                            {/* BOTONES */}
                            <div className="grid p-fluid">
                                <div className='col-4'
                                    style={{ marginLeft: 'auto', marginBottom: '0' }}>
                                    <Button type='submit' icon="pi pi-check"
                                        label="Crear" className='p-button-success' />
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        </div>
    );
}
