import './periodos.css';
import { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';

import buscarObjetoPorId from '../tools/buscarObjetoPorId';
import { headerForm } from './constants';
import { meses } from '../constants/diccionarios';
import { bodyMesInicio, bodyMesFinal, mostrarError } from '../constants/bodiesYFiltos';
import {
    crearPeriodo, editarPeriodo, eliminarPeriodo, getHistorialPeriodos
} from '../endpoints/periodos';


export default function Periodos() {
    // periodos
    const [periodos, setPeriodos] = useState([]);
    const [historialPeriodos, setHistorialPeriodos] = useState([]);

    // para crear nuevo periodo
    const [anio, setAnio] = useState(null);
    const [mesInicio, setMesInicio] = useState(null);
    const [mesFinal, setMesFinal] = useState(null);
    const [tasaInteres, setTasaInteres] = useState(null);

    // para editar y eliminar
    const [selected, setSelected] = useState(null);
    const [modoEdicion, setModoEdicion] = useState(false);

    // historial periodos
    const [historialPeriodosVisible, setHistorialPeriodosVisible] = useState(false);

    const toast = useRef(null);

    useEffect(() => {
        GetPeriodos();
    }, []);

    function GetPeriodos() {
        // obtener historial de periodos
        getHistorialPeriodos().then((resp) => {
            setHistorialPeriodos(resp.data);
            // ultimos 12 periodos
            setPeriodos(resp.data.slice(-12));
        }).catch(err => {
            mostrarError(err);
        });
    };

    const CrearOEditarPeriodo = () => {
        if (!anio || !mesInicio || !mesFinal || !tasaInteres) {
            alert("Llenar todos los campos");
            return;
        }

        var obj = {
            Anio: anio,
            MesInicio: mesInicio.numero,
            MesFinal: mesFinal.numero,
            TasaInteres: tasaInteres
        }

        if (!selected) {
            // crear periodo
            crearPeriodo(obj).then((resp) => {
                if (resp.severity === "success") {
                    toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                    ResetForm();
                } else {
                    toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
                }
            }).catch(err => {
                mostrarError(err);
            });
        } else {
            // editar periodo
            confirmDialog({
                message: 'Los cambios también se reflejarán en los reintegros que hayan ' +
                    'utilizado este periodo. ¿Desea continuar?',
                header: 'Confirmación',
                icon: 'pi pi-exclamation-triangle',
                accept: () => acceptEditar(),
            });
        }
    };

    function ResetForm() {
        setAnio(null);
        setMesInicio(null);
        setMesFinal(null);
        setTasaInteres(null);
        setSelected(null);
        setModoEdicion(false);
        GetPeriodos();
    };

    const AEditarPeriodo = () => {
        if (!selected) {
            return;
        }

        setAnio(selected.Anio);
        setMesInicio(buscarObjetoPorId(meses, selected.MesInicio, "numero"));
        setMesFinal(buscarObjetoPorId(meses, selected.MesFinal, "numero"));
        setTasaInteres(selected.TasaInteres);

        setModoEdicion(true);
    };

    const acceptEliminar = () => {
        eliminarPeriodo({ idPeriodo: selected['idPeriodo'] }).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                ResetForm();
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err);
        });

    }

    const acceptEditar = () => {
        var obj = {
            idPeriodo: selected['idPeriodo'],
            Anio: anio,
            MesInicio: mesInicio.numero,
            MesFinal: mesFinal.numero,
            TasaInteres: tasaInteres
        }

        editarPeriodo(obj).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                ResetForm();
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err);
        });
    }

    const EliminarPeriodo = () => {
        if (!selected) {
            return;
        }

        confirmDialog({
            message: 'No se podrá eliminar el periodo si ya fue utilizado por reintegros, ' +
                'deberá editar o eliminar los reintegros antes. ¿Desea continuar?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptEliminar(),
        });
    };

    return (
        <div className='primario-crear-convenio'>
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="grid p-fluid">
                <div className='col-12 md:col-8'>
                    <Card
                        header={headerForm("Periodos Creados", true)} className="card" style={{ height: '100%' }}>
                        <div className="grid p-fluid">
                            <div className='col-12 md:col-4'>
                                <Button type="button" icon="pi pi-calendar" label="Historial Periodos"
                                    onClick={() => setHistorialPeriodosVisible(true)} />
                                <Dialog header="Historial de periodos" visible={historialPeriodosVisible}
                                    style={{ width: '75vw' }} maximizable
                                    modal contentStyle={{ height: '300px' }}
                                    onHide={() => setHistorialPeriodosVisible(false)}>
                                    <DataTable value={historialPeriodos} scrollable scrollHeight="flex"
                                        tableStyle={{ minWidth: '50rem' }}>
                                        <Column field='Anio' header='Año' />
                                        <Column field='MesInicio' header='Mes Inicio'
                                            body={bodyMesInicio} />
                                        <Column field='MesFinal' header='Mes Final'
                                            body={bodyMesFinal} />
                                        <Column field='TasaInteres' header='Tasa Interés(%)' />
                                    </DataTable>
                                </Dialog>
                            </div>
                            <div className='col-12 md:col-2' style={{ marginLeft: 'auto' }}>
                                <Button type="button" icon="pi pi-pencil" label="Editar"
                                    severity="warning" disabled={selected === null}
                                    onClick={() => AEditarPeriodo()} />
                            </div>
                            <div className='col-12 md:col-2'>
                                <Button type="button" icon="pi pi-trash" label="Eliminar"
                                    severity="danger" disabled={selected === null}
                                    onClick={() => EliminarPeriodo()} />
                            </div>
                        </div>
                        <br />
                        <DataTable value={periodos} editMode="cell" scrollable scrollHeight="400px"
                            size="small"
                            selectionMode='single'
                            selection={selected}
                            onSelectionChange={(e) => { !modoEdicion && setSelected(e.value); }}
                            onRowClick={() => { !modoEdicion && setSelected(null); }}
                        >
                            <Column header="#" body={(rowData, rowIndex) => rowIndex.rowIndex + 1} />
                            <Column field='Anio' header='Año' />
                            <Column field='MesInicio' header='Mes Inicio'
                                body={bodyMesInicio} />
                            <Column field='MesFinal' header='Mes Final'
                                body={bodyMesFinal} />
                            <Column field='TasaInteres' header='Tasa Interés(%)' />
                        </DataTable>
                    </Card>
                </div>
                <div className='col-12 md:col-4'>
                    <Card
                        header={headerForm(!modoEdicion ? "Nuevo Periodo" : "Editar Periodo")} className="card" style={{ height: '100%' }}>
                        {/* AÑO */}
                        <div style={{ marginBottom: '2%' }}>
                            <label>Año *</label>
                            <InputNumber value={anio} onValueChange={(e) => setAnio(e.value)}
                                mode="decimal" showButtons min={0} useGrouping={false} />
                        </div>
                        {/* MES INICIO */}
                        <div style={{ marginBottom: '2%' }}>
                            <label>Mes Inicio *</label>
                            <Dropdown
                                value={mesInicio}
                                options={meses}
                                optionLabel="nombre"
                                placeholder="Seleccionar mes"
                                onChange={(e) => setMesInicio(e.value)}
                            />
                        </div>
                        {/* MES FINAL */}
                        <div style={{ marginBottom: '2%' }}>
                            <label>Mes Final *</label>
                            <Dropdown
                                value={mesFinal}
                                options={meses}
                                optionLabel="nombre"
                                placeholder="Seleccionar mes"
                                onChange={(e) => setMesFinal(e.value)}
                            />
                        </div>
                        {/* TASA INTERES */}
                        <div style={{ marginBottom: '2%' }}>
                            <label>Tasa Interés (%) *</label>
                            <InputNumber value={tasaInteres} onValueChange={(e) => setTasaInteres(e.value)}
                                minFractionDigits={2} maxFractionDigits={2} showButtons min={0} />
                        </div>
                        {/* BOTONES */}
                        {!modoEdicion ?
                            <div style={{ marginTop: '30%', marginBottom: '0', height: '100%', paddingTop: '10%' }} >
                                <Button type="button" icon="pi pi-check" label="Crear"
                                    className='p-button-success'
                                    onClick={() => CrearOEditarPeriodo()}
                                />
                            </div>
                            :
                            <div className="grid p-fluid" style={{ marginTop: '30%', marginBottom: '0' }} >
                                <div className='col-12 md:col-6' style={{ marginLeft: 'auto' }}>
                                    <Button type="button" icon="pi pi-times" label="Cancelar"
                                        onClick={() => {
                                            ResetForm();
                                        }} />
                                </div>
                                <div className='col-12 md:col-6'>
                                    <Button type="button" icon="pi pi-check" label="Guardar Cambios"
                                        className='p-button-success'
                                        onClick={() => CrearOEditarPeriodo()} />
                                </div>
                            </div>
                        }
                    </Card>
                </div>
            </div>
        </div>
    );
}
