import './visualizador_logs.css';
import LogsModule from './tabla_logs';

function VisualizadorLogs(){
    const reportData = [
        { id: 'A-831', operation: 'Pago', file: 'NULL', amount: '200', date: '2014-10-24 21-04-17'},
        { id: 'B-128', operation: 'Pago de Mora', file: 'NULL', amount: '400', date: '2021-11-05 19-44-09'},
        { id: 'ERROR', operation: 'ERROR', file: '/var/lib/...', amount: 'NULL', date: '2019-01-09 03-55-19'},
        { id: 'B-909', operation: 'Pago', file: 'NULL', amount: '230', date: '2022-01-24 04-28-23'},
        { id: 'ERROR', operation: 'ERROR', file: '/var/lib/...', amount: 'NULL', date: '2022-01-24 04-28-23'},
        { id: 'ERROR', operation: 'ERROR', file: '/var/lib/...', amount: 'NULL', date: '2020-04-05 13-07-02'},
        // ... Agrega más filas de datos según tus necesidades
      ];

    return(
        <div className='visualizador__moras'>
            <LogsModule reportData={reportData}/>
        </div>
    );
}

export default VisualizadorLogs;