import '../certificaciones.css'
import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import { formats } from '../editor/formats';
import { modules } from '../editor/modules';
import listarVariables from '../../tools/listarVariables';
import { cellEditor, onCellEditCompletePlantilla, eliminarVariableBody } from '../constants';
import { mostrarError } from '../../constants/bodiesYFiltos';
import { crearPlantilla, getPlantilla, actualizarPlantilla } from '../../endpoints/plantillas';

const EditarPlantilla = () => {
  const { quill, quillRef, Quill } = useQuill({
    modules: modules,
    formats: { formats }
  });

  const [variables, setVariables] = useState([]);
  const [nuevaVariable, setNuevaVariable] = useState('');
  const [visibleNuevaPlantilla, setVisibleNuevaPlantilla] = useState(false);
  const [nombrePlantilla, setNombrePlantilla] = useState('');
  const toast = useRef(null);

  useEffect(() => {
    // obtener la plantilla seleccionada
    var seleccionada = sessionStorage.getItem('plantilla_seleccionada');

    getPlantilla({ idPlantilla: seleccionada }).then((resp) => {
      // variables
      var vars = listarVariables(resp.data.Contenido);
      setVariables(vars);

      // contenido
      quill.root.innerHTML = resp.data.Contenido;

    }).catch(err => {
      console.log(err);
    });
  }, [quill, Quill]);

  if (Quill && !quill) {
    Quill.register('modules/blotFormatter', BlotFormatter);
  }

  const AgregarVariable = () => {
    var formateada = nuevaVariable.toUpperCase().replaceAll(" ", "_");
    // buscar si no existe ya esa variable
    var buscar = variables.filter(registro => registro.NombreVariable === formateada);
    if (buscar.length > 0) {
      alert("Ya existe esa variable");
      return;
    }
    var nuevaVar = {
      'NombreVariable': formateada,
      'boton_eliminar': ``
    }

    setVariables([...variables, nuevaVar]);
    setNuevaVariable('');

    let textoVariable = '&&' + formateada + '&&';
    var selection = quill.getSelection(true);
    quill.insertText(selection.index, textoVariable);

  }

  const EliminarVariable = (NombreVariable) => {
    var del = variables.filter(registro => registro.NombreVariable !== NombreVariable);
    setVariables(del);

    // quitarla del editor
    var texto = quill.root.innerHTML;
    var nuevoTexto = texto.replace('&amp;&amp;' + NombreVariable + '&amp;&amp;', '');
    quill.root.innerHTML = nuevoTexto;
  }

  const GuardarPlantilla = () => {
    var data = {
      Nombre: nombrePlantilla,
      Contenido: quill.root.innerHTML
    }

    crearPlantilla(data).then((resp) => {
      if (resp.severity === "success") {
        toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
        setVisibleNuevaPlantilla(false);
        setNombrePlantilla('');
      } else {
        toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
      }
    }).catch(err => {
      mostrarError(err);
    });
  }

  const acceptActualizar = () => {
    var data = {
      idPlantilla: sessionStorage.getItem('plantilla_seleccionada'),
      Contenido: quill.root.innerHTML
    }

    actualizarPlantilla(data).then((resp) => {
      if (resp.severity === "success") {
        toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
      } else {
        toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
      }
    }).catch(err => {
      mostrarError(err);
    });
  }

  const ActualizarPlantilla = () => {
    confirmDialog({
      message: '¡Sobreescribir una plantilla también afectará a las certificaciones que la hayan utilizado! ' +
        'Si ya fue utilizada en certificaciones se recomienda guardar una nueva plantilla.\n ¿Desea actualizar la actual?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      className: 'w-50 text-justify',
      accept: acceptActualizar
    });
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className='primario_editar_cert'>
        <br />
        <Link to="/cuotas/certificaciones" style={{ position: 'absolute' }}>
          <Button type="button" icon="pi pi-arrow-left" label="Regresar" size="small"
            className='p-button-info' />
        </Link>
        <h3 style={{ color: 'black', textAlign: 'center' }}>Variables</h3>
        <div className="grid p-fluid" style={{ width: '85%', margin: 'auto' }}>
          <div className='col-12 md:col-6'>
            <DataTable value={variables} editMode="cell" scrollable scrollHeight="170px">
              <Column key='NombreVariable' field='NombreVariable' header='Nombre variable'
                style={{ width: '95%' }}
                editor={(options) => cellEditor(options)} onCellEditComplete={(e) =>
                  onCellEditCompletePlantilla(e, quill, variables)} />
              <Column key='boton_eliminar' field='boton_eliminar' header='Eliminar'
                style={{ width: '2%' }} body={(rowData) => eliminarVariableBody(rowData, EliminarVariable)} />
            </DataTable>
          </div>

          <div className='col-12 md:col-6'>
            <div className="grid p-fluid">
              <div className='col-12 md:col-8' >
                <label className="font-bold block mb-2 text-color">Nueva variable</label>
                <InputText size="large" value={nuevaVariable} onChange={(e) => setNuevaVariable(e.target.value)} />
              </div>
              <div className='col-12 md:col-4'>
                <br />
                <Button type="button" icon="pi pi-plus" label="Agregar variable"
                  onClick={() => AgregarVariable(quill)} style={{ marginTop: '8px' }} />
              </div>
            </div>
            <br /><br /><br />
          </div>
        </div>

        <div className='col-12 md:col-10 editor-wrapper'>
          <div className='editor-container'>
            <div className='encabezado-content'>
              <h3 style={{ color: 'black' }}>Editor de texto</h3>
              <span className="p-buttonset">
                <Button size="small" label="Guardar Nuevo" icon="pi pi-save"
                  onClick={() => setVisibleNuevaPlantilla(true)} />
                <Button size="small" label="Sobreescribir Cambios" icon="pi pi-check"
                  severity='secondary' onClick={ActualizarPlantilla} />
              </span>
              <Dialog header="Guardar nueva plantilla" visible={visibleNuevaPlantilla}
                style={{ width: '30%' }} onHide={() => setVisibleNuevaPlantilla(false)}>
                <label className="font-bold block mb-2 text-color">Nombre para la nueva plantilla</label>
                <InputText value={nombrePlantilla} onChange={(e) => setNombrePlantilla(e.target.value)} />
                <Button label="Guardar" icon="pi pi-save" style={{ float: 'right' }}
                  onClick={() => GuardarPlantilla(quill)} />
              </Dialog>
            </div>

            <div >
              <div className='editor'>
                <div style={{
                  paddingLeft: '8%', paddingRight: '8%', fontSize: 'medium',
                  color: 'black'
                }} ref={quillRef} />
              </div>
              <br /><br />
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default EditarPlantilla;