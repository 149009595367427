import './busqueda_prestamos.css';
import LoanCard from './loan_card';
import { Toast } from 'primereact/toast';
import SearchBar from './buscador';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useState, useEffect, useRef } from 'react';
import { get_prestamos_route, massive_loans_route, massive_loans_inmediatos_route, loans_massive_load_baja } from '../../constants/backend_routes';
import Papa from 'papaparse';

function BusquedaMoras() {
  const [responseData, setResponseData] = useState([])
  const [loans, setLoans] = useState([])
  const [files, setFiles] = useState([]);
  const op = useRef(null);
  const fileUploaded = useRef(null)
  const [fecha, setFecha] = useState();

  const op1 = useRef(null)
  const fileUploaded1 = useRef(null)
  
  const op2 = useRef(null)
  const fileUploaded2 = useRef(null)

  const updateLoans = (loans) => {
    setLoans(loans)
  }

  function convertirFormato(fechaISO) {
    const fecha = new Date(fechaISO);
    
    // Obtener los componentes de la fecha
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const año = fecha.getFullYear().toString().slice(-2);
  
    // Formatear la fecha como 'dd/mm/yy'
    const fechaFormateada = `${dia}/${mes}/${año}`;
  
    return fechaFormateada;
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(get_prestamos_route)
      const responseJson = await response.json()
      setResponseData(responseJson.data)
      setLoans(responseJson.data)
    }
    fetchData()
  }, [])

  const button_style = {
    'color': "#fff",
    'backgroundColor': '#337ab7',
    'borderColor': '#2e6da4',
  }

  const submitLoans = async (loans) => {
    const response = await fetch(massive_loans_route, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(loans)
    })
    if (!response.ok) {
      toast.current.show({ severity: 'error', summary: 'La carga de datos no pudo completarse' })
    } else {
      toast.current.show({ severity: 'success', summary: 'Carga de datos completada exitosamente' })
    }
  }

  const submitLoansInmediatos = async (loans) => {
    const response = await fetch(massive_loans_inmediatos_route, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(loans)
    })
    if (!response.ok) {
      toast.current.show({ severity: 'error', summary: 'La carga de datos no pudo completarse' })
    } else {
      toast.current.show({ severity: 'success', summary: 'Carga de datos completada exitosamente' })
    }
  }

  const submitLoansBaja = async (loans) => {
    const response = await fetch (loans_massive_load_baja, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(loans)
    });
    if (!response.ok) {
      toast.current.show({severity: 'error', summary: 'La carga de cierre de datos no pudo completarse'})
    }else {
      toast.current.show({severity: 'success', summary: "La carga de datos fue completada exitosamente"})
    }
  }


  const toast = useRef(null);
  const onUpload = () => {
    console.log('fecha', fecha)
    const uploadedFiles = fileUploaded.current.getFiles();
    setFiles(uploadedFiles);

    // Acceder al contenido del archivo si es un archivo de texto plano
    uploadedFiles.forEach(file => {
      Papa.parse(file, {
        complete: async (result) => {
          // El resultado es un objeto que contiene datos, errores, etc.
          console.log('Resultado del parsing CSV:', result);

          // Aquí puedes realizar cualquier manipulación de datos necesaria
          const jsonData = result.data;
          await submitLoans({ prestamos: jsonData, fecha: fecha })
        },
        header: true, // Si tu archivo CSV tiene encabezados
      });
    });
  };

  const onUpload1 = () => {
    const uploadedFiles = fileUploaded1.current.getFiles();
    setFiles(uploadedFiles);

    // Acceder al contenido del archivo si es un archivo de texto plano
    uploadedFiles.forEach(file => {
      Papa.parse(file, {
        complete: async (result) => {
          // El resultado es un objeto que contiene datos, errores, etc.
          console.log('Resultado del parsing CSV:', result);

          // Aquí puedes realizar cualquier manipulación de datos necesaria
          const jsonData = result.data;
          await submitLoansInmediatos({ prestamos: jsonData, fecha: fecha })
        },
        header: true, // Si tu archivo CSV tiene encabezados
      });
    });
  };

  const onUpload2 = () => {
    const uploadedFiles = fileUploaded2.current.getFiles()
    setFiles(uploadedFiles)
    uploadedFiles.forEach( file => {
      Papa.parse(
        file, {
          complete: async (result) => {
            console.log('Resultado del parsing CSV', result)
            const jsonData = result.data;

            await submitLoansBaja({prestamos: jsonData, fecha: fecha})
          },
          header: true
        }
      )
    }

    )
  }

  return (
    <div className='busqueda__usuarios__moras container text-center'>
      <Toast ref={toast}></Toast>
      <br />
      <div className='row'>
      <div className="field col-12 md:col-6">
        <div className="p-inputgroup">
          <span className="p-inputgroup-addon">
            <i className="pi pi-key"></i>
          </span>
          <span className="p-float-label">
            <Calendar dateFormat="dd/mm/yy" value={fecha} onChange={(e) => setFecha(convertirFormato(e.value))}></Calendar>
            <label>Fecha de corte de datos</label>
          </span>
        </div>
      </div>
      <div className='col-md-6'><h4> Esta sera la fecha en la cual se registraran los pagos de prestamos</h4></div>
      </div>
      <div className='row text-center'>
        <div className='col-md-9'>
          <SearchBar updateLoans={updateLoans}></SearchBar>
        </div>
        <div className='col-md-3'>
          <Button label="Carga de Bajas" style={button_style} icon="pi pi-check" onClick={(e) => op2.current.toggle(e)} className="p-button-text" />
          <OverlayPanel ref={op2} showCloseIcon>
            <FileUpload
              name="demo"
              multiple
              accept="text/csv"
              maxFileSize={1000000}
              ref={fileUploaded2}
              // onSelect={(e) => setFiles(e.files)}
              onClear={onUpload2}
              emptyTemplate={<p className="m-0">Carga de Bajas</p>} />
          </OverlayPanel>

          <Button label="Carga de Inmediatos" style={button_style} icon="pi pi-check" onClick={(e) => op1.current.toggle(e)} className="p-button-text" />
          <OverlayPanel ref={op1} showCloseIcon>
            <FileUpload
              name="demo"
              multiple
              accept="text/csv"
              maxFileSize={1000000}
              ref={fileUploaded1}
              // onSelect={(e) => setFiles(e.files)}
              onClear={onUpload1}
              emptyTemplate={<p className="m-0">Carga de Inmediatos</p>} />
          </OverlayPanel>

          <Button label="Carga de FOX" style={button_style} icon="pi pi-check" onClick={(e) => op.current.toggle(e)} className="p-button-text" />
          <OverlayPanel ref={op} showCloseIcon>
            <FileUpload
              name="demo"
              multiple
              accept="text/csv"
              maxFileSize={1000000}
              ref={fileUploaded}
              // onSelect={(e) => setFiles(e.files)}
              onClear={onUpload}
              emptyTemplate={<p className="m-0">Carga de saldos</p>} />
          </OverlayPanel>
        </div>
      </div>
      <br />
      <div className='row text-center'>
        {
          loans.map(prestamo => (
            <LoanCard
              loan={prestamo}
            />
          ))
        }
        {/* <LoanCard
          loan={mora1}
        />
        <LoanCard
          loan={mora2}
        />
        <LoanCard
          loan={mora3}
        />
        <LoanCard
          loan={mora4}
        />
        <LoanCard
          loan={mora5}
        /> */}
      </div>
    </div>
  );
}

export default BusquedaMoras;