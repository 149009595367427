import '../calculo_cuotas.css'
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { moneyBody } from '../../constants/bodiesYFiltos';

// --------------------------------------------------- COMPONENTES
const footerMensajeInicio = (setMensajeInicio) => {
    return (
        <>
            <div className='mensaje-inicio-botones'>
                <Link to="/cuotas/gestion_trabajadores">
                    <Button label="Ir a gestión de trabajadores" icon="pi pi-arrow-up-left"
                        onClick={() => setMensajeInicio(false)} />
                </Link>
                <Button severity="success" label="Entendido" icon="pi pi-check" onClick={() => setMensajeInicio(false)} autoFocus />
            </div>
        </>
    )
};

export const MensajeInicio = ({ mensajeInicio, setMensajeInicio }) => {
    return (
        <Dialog header="Antes de hacer una integración" visible={mensajeInicio}
            className='mensaje-inicio-container' onHide={() => setMensajeInicio(false)}
            footer={footerMensajeInicio(setMensajeInicio)}>
            <p className="m-0">
                Para que los cálculos de integración sean correctos el trabajador
                debe tener su historial de cuotas actualizado y todas
                sus plazas afectas a descuento agregadas en sus contratos.
            </p>
        </Dialog>
    )
}

export const TablaCuotasIntegracion = ({ cuotasNoDescontadasCalculo, cuotas14 }) => {
    return <>
        <TabView>
            <TabPanel header="Cuotas no descontadas en código 24" leftIcon='mr-2 pi pi-user-plus'>
                <DataTable value={cuotasNoDescontadasCalculo} paginator rows={10} paginatorPosition="top"
                    tableStyle={{ minWidth: '50rem' }} size='small'>
                    <Column field="Nomina" header="Nomina" ></Column>
                    <Column field="Partida" header="Partida" ></Column>
                    <Column field="Plaza" header="Plaza" ></Column>
                    <Column field="Sueldo" header="Sueldo Nominal(Q)"
                        body={(rowData) => moneyBody(rowData, "Sueldo")}></Column>
                    <Column field="Cuota" header="Descuento Plan de Prestaciones" body={() => '0'}></Column>
                </DataTable>
            </TabPanel>
            <TabPanel header="Cuotas descontadas en código 14" leftIcon='mr-2 pi pi-user-plus'>
                <DataTable value={cuotas14} paginator rows={10} paginatorPosition="top"
                    tableStyle={{ minWidth: '50rem' }} size='small'>
                    <Column field="Nomina" header="Nomina" ></Column>
                    <Column field="Partida" header="Partida" ></Column>
                    <Column field="Plaza" header="Plaza" ></Column>
                    <Column field="Sueldo" header="Sueldo Nominal(Q)"
                        body={(rowData) => moneyBody(rowData, "Sueldo")}></Column>
                    <Column field="Cuota" header="Descuento Plan de Prestaciones"
                        body={(rowData) => moneyBody(rowData, "Cuota")}></Column>
                </DataTable>
            </TabPanel>
        </TabView>
    </>
}

export const CalculosIntegracion = ({ totalSalariosNoDescontados,
    totalDescuentoCod14, GuardarIntegracion, uploadStatus }) => {
    return <>
        <div className="grid p-fluid mt-1">
            {/* TOTAL SALARIOS NO DESCONTADOS */}
            <div className="field col-12 md:col-7 col_toggle">
                <label>Total de salarios en los que no se aplicó
                    el descuento del Plan de Prestaciones (Q)</label>
                <InputNumber
                    value={totalSalariosNoDescontados} readOnly
                    minFractionDigits={2} maxFractionDigits={2} min={0} />
            </div>
            {/* TOTAL CUOTA NO DESCONTADA */}
            <div className="field col-12 md:col-5 col_toggle">
                <label>Total de cuota no descontada (Q)</label>
                <InputNumber
                    value={totalSalariosNoDescontados * 10.58 / 100} readOnly
                    minFractionDigits={2} maxFractionDigits={2} min={0} />
            </div>
        </div>
        <div className="grid p-fluid mt-1 d-flex justify-content-around">
            {/* TOTAL DESCUENTO EN CODIGO 14 */}
            <div className="field col-12 md:col-6 col_toggle">
                <label>Total de descuento aplicado en cuota código 14 (Q)</label>
                <InputNumber
                    value={totalDescuentoCod14} readOnly
                    minFractionDigits={2} maxFractionDigits={2} min={0} />
            </div>
            {/* MONTO PENDIENTE DE PAGO */}
            <div className="field col-12 md:col-3 col_toggle">
                <label>Monto pendiente de pago (Q)</label>
                <InputNumber
                    value={(totalSalariosNoDescontados * 10.58 / 100) - totalDescuentoCod14} readOnly
                    minFractionDigits={2} maxFractionDigits={2} min={0} />
            </div>

            {GuardarIntegracion && (
                <>
                    {/* BOTON */}
                    <div className="field col-12 md:col-3 col_toggle">
                        <br />
                        <Button style={{ marginTop: '8px' }} label="Guardar Integración" icon="pi pi-check"
                            severity='success' onClick={GuardarIntegracion}
                            disabled={!uploadStatus} />
                    </div>
                </>
            )}

        </div>
    </>
}


