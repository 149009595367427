import '../gestion_trabajadores';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';

import { FechasContratoBody, afectaDescuentoBody } from '../constants';
import { DatosTrabajador } from '../../constants/datos_trabajador';
import { columnaConFiltros, headerForm, moneyBody, mostrarError, renderRedirect } from '../../constants/bodiesYFiltos';
import { BotonExportarReporte } from '../../constants/exportacion';
import { eliminarContrato, exportarContratos, getContratos } from '../../endpoints/gestion_trabajadores';


export default function VerContratos() {
    // Datos trabajador
    const [trabajador, setTrabajador] = useState([{}]);
    // Contratos
    const [contratos, setContratos] = useState([]);

    const [selected, setSelected] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        // obtener el trabajador seleccionado
        var seleccionado = JSON.parse(sessionStorage.getItem('trabajador_seleccionado'));
        setTrabajador([seleccionado]);

        // obtener contratos del trabajador
        getContratos({ Registro: seleccionado.Registro }).then((resp) => {
            setContratos(resp.data);
        }).catch(err => {
            mostrarError(err);
        });
    }, []);

    const EliminarContrato = () => {
        if (selected === null)
            return

        confirmDialog({
            message: 'Está seguro de eliminar el contrato seleccionado?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept
        });
    };

    const accept = () => {
        eliminarContrato({ idContratoTrabajador: selected.idContratoTrabajador }).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                // quitar el contrato
                var del = contratos.filter(
                    contrato => contrato.idContratoTrabajador !== selected.idContratoTrabajador
                );
                setContratos(del);
                setSelected(null);
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err);
        });
    }

    const aEditarContrato = () => {
        if (!selected)
            return
        sessionStorage.setItem('contrato_seleccionado', JSON.stringify(selected));
        setSelected(null);
        setRedirect(true);
    };

    return (
        <div className='primario-crear-registro'>
            {/* PARA LA CONFIRMACIÓN DE ELIMINAR CONTRATO */}
            <Toast ref={toast} />
            <ConfirmDialog />
            {/* DATOS DEL TRABAJADOR */}
            <Card header={headerForm("Datos del trabajador", true)} className="card">
                <DatosTrabajador trabajador={trabajador} />
            </Card>
            <br />
            {/* CONTRATOS */}
            <Card header={headerForm("Contratos")} className="card">
                <div className="grid p-fluid">
                    <div className='col-12 md:col-3' style={{ marginLeft: 'auto' }}>
                        <Link to="/cuotas/asignar_contrato">
                            <Button type="button" icon="pi pi-plus" label="Asignar Contrato" />
                        </Link>
                    </div>
                    <div className='col-12 md:col-2'>
                        <Button type="button" icon="pi pi-pencil" label="Editar Contrato"
                            severity="warning" disabled={selected === null}
                            onClick={aEditarContrato} />
                    </div>
                    <div className='col-12 md:col-3'>
                        <Button type="button" icon="pi pi-trash" label="Eliminar Contrato"
                            severity="danger" disabled={selected === null}
                            onClick={EliminarContrato} />
                    </div>
                </div><br />
                <BotonExportarReporte
                    Endpoint={exportarContratos}
                    datos={{
                        Trabajador: trabajador,
                        Contratos: contratos
                    }}
                    nombreArchivo={'contratos'}
                    ocultar={['DOCX']}
                />
                <DataTable value={contratos} selectionMode='single'
                    selection={selected} onSelectionChange={(e) => { setSelected(e.value) }}
                    onRowClick={() => { setSelected(null) }}
                    tableStyle={{ minWidth: '50rem' }} size='small'
                    paginator rows={10} paginatorPosition="top"
                >
                    <Column field="NoContrato" header="No. Contrato" ></Column>
                    <Column field="Partida" header="Partida" ></Column>
                    {columnaConFiltros("NumPlaza", "No. Plaza", true, "Buscar por número de plaza")}
                    <Column field="Clasificacion" header="Clasificacion"></Column>
                    <Column field="Titulo" header="Titulo del Puesto"></Column>
                    <Column field="HorasContratacion" header="Horas de Contratacion"></Column>
                    <Column field="CodigoDependencia" header="Codigo de Dependencia"></Column>
                    <Column field="NombreDUE" header="Dependencia y Unidad Ejecutora"></Column>
                    <Column field="SueldoNominal" header="Sueldo Nominal(Q)"
                        body={(rowData) => moneyBody(rowData, "SueldoNominal")}></Column>
                    <Column field="AfectaDescuento" header="Afecta a Descuento"
                        body={afectaDescuentoBody}></Column>
                    <Column field="InicioContrato" header="Fecha de Inicio"
                        body={(rowData) => FechasContratoBody(rowData, "InicioContrato")}></Column>
                    <Column field="FinContrato" header="Fecha de Fin"
                        body={(rowData) => FechasContratoBody(rowData, "FinContrato")}></Column>

                </DataTable>
            </Card>
            {renderRedirect(redirect, 'editar_contrato')}
        </div>
    );
}
