import './dashboard.css';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import img_jubilados from '../img/jubilados_pensionados.png'
import img_nomina from '../img/nominas.png'
import img_cuotas from '../img/cuotas.png'
import img_moras from '../img/moras.png'
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';

function Dashboard(props) {

    const [aviso, setAviso] = useState(null);
    useEffect(() => {
        // Para mostrar avisos
        const ls_aviso = localStorage.getItem('aviso');
        ls_aviso && setAviso(ls_aviso);
    }, []);

    const img_style = { borderRadius: '2px 2px 0 0', height: '170px', maxWidth: '100%' };
    const hdr_jubilados = <img src={img_jubilados} alt='img_jubilados' style={img_style} />;
    const hdr_nominas = <img src={img_nomina} alt='img_nomina' style={img_style} />;
    const hdr_cuotas = <img src={img_cuotas} alt='img_nomina' style={img_style} />;
    const hdr_moras = <img src={img_moras} alt='img_nomina' style={img_style} />;

    const header_aviso = <h4 className='my-0 py-0 font-weight-light' style={{ letterSpacing: '5px' }}>
        AVISO DEL SISTEMA
    </h4>;

    return (
        <div className="container-fluid container__dashboard h-100">
            <Dialog header={header_aviso} style={{ width: '45vw', maxWidth: '800px' }} draggable={false} resizable maximizable
                visible={aviso != null} onHide={() => { /* localStorage.removeItem('aviso'); */ setAviso(null); }}>
                <h5 /* style={{ backgroundColor: 'lightgray', borderRadius: '10px' }} */ className='font-weight-bold'>{aviso}</h5>
            </Dialog>
            <div className="row text-center pt-3">
                <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                    <Link to="/jubilados">
                        <Card header={hdr_jubilados} >
                            <Button label="JUBILADOS Y PENSIONADOS" icon="pi pi-id-card" />
                        </Card>
                    </Link>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                    <Link to="/nominas">
                        <Card header={hdr_nominas} >
                            <div className='carta'>
                                <Button label="NÓMINAS" icon="pi pi-money-bill" className="" />
                            </div>
                        </Card>
                    </Link>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                    <Link to="/cuotas">
                        <Card header={hdr_cuotas} >
                            <Button label="CUOTAS" icon="pi pi-folder" className="" />
                        </Card>
                    </Link>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                    <Link to="/moras">
                        <Card header={hdr_moras} >
                            <Button label="MORAS" icon="pi pi-arrow-up-right" className="" />
                        </Card>
                    </Link>
                </div>
            </div>
            {/* HABILITAR EN PERIODO DE SOBREVIVENCIAS */}
            {/* <div className="row justify-content-center py-2">
                <div className="col-md-4 col-sm-12 mb-5 px-5" >
                    <Link to="https://planprestaciones.com/" target='_blank'>
                        <Card className='text-center' >
                            <Button label="RECEPCIÓN DE SOBREVIVENCIAS" icon="pi pi-external-link" />
                        </Card>
                    </Link>
                </div>
                {
                    props.admin_sobrevivencias &&
                    <div className="col-md-4 col-sm-12 mb-5 px-5" >
                        <Link to="https://planprestaciones.com/reportes" target='_blank'>
                            <Card className='text-center' >
                                <Button label="ADMINISTRADOR SOBREVIVENCIAS" icon="pi pi-lock" />
                            </Card>
                        </Link>
                    </div>
                }
            </div> */}
            <div className="footer">
                <p>&copy; {new Date().getFullYear()} &bull; Procesamiento de Datos | Plan de Prestaciones</p>
            </div>
        </div>
    );
}

export default Dashboard;
