import '../certificaciones'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { ultimaModificacionBody } from '../constants';
import { getPlantilla } from '../../endpoints/plantillas';

const VerPlantilla = () => {
  const { quill, quillRef, Quill } = useQuill({
    modules: { "toolbar": false },
    readOnly: true
  });
  const [plantilla, setPlantilla] = useState(false);

  useEffect(() => {
    // obtener la plantilla seleccionada
    var seleccionada = sessionStorage.getItem('plantilla_seleccionada');

    getPlantilla({ idPlantilla: seleccionada }).then((resp) => {
      setPlantilla(resp.data);
      // contenido
      quill.root.innerHTML = resp.data.Contenido;

    }).catch(err => {
      console.log(err);
    });
  }, [quill, Quill]);

  if (Quill && !quill) {
    Quill.register('modules/blotFormatter', BlotFormatter);
  }

  return (
    <>
      <div className='primario_editar_cert'>
        <br />
        <Link to="/cuotas/certificaciones" style={{ position: 'absolute' }}>
          <Button type="button" icon="pi pi-arrow-left" label="Regresar" size="small"
            className='p-button-info' />
        </Link>
        <br /><br />
        <h3 style={{ color: 'black', marginLeft: '9%' }}>Detalles</h3>
        <center>
          <DataTable value={[plantilla]}
            tableStyle={{ minWidth: '50rem', maxWidth: '60rem' }}
            style={{ margin: 'auto' }}
            size='small'>
            <Column field="Nombre" header="Nombre" ></Column>
            <Column field="UltimaModificacion" header="Ultima Modificacion"
              body={ultimaModificacionBody}></Column>
          </DataTable>
        </center>
        <br /><br />



        <div className='col-12 md:col-10 editor-wrapper'>
          <div className='editor-container'>
            <div className='encabezado-content'>
              <h3 style={{ color: 'black' }}>Contenido</h3>
              <Link to="/cuotas/editar_plantilla">
                <Button severity='warning' label="Editar esta plantilla" icon="pi pi-pencil" />
              </Link>
            </div>
            <div className='editor'>
              <div style={{
                paddingLeft: '8%', paddingRight: '8%', fontSize: 'medium',
                color: 'black'
              }} ref={quillRef} />
            </div>
            <br /><br />
          </div>
        </div>
      </div>
    </>
  );
};

export default VerPlantilla;