import './menu_nomina.css';
import MenuBar from './menubar/menubar';
import { requestOptions } from './misc/requestOptions';
import ListadoNominas from './seleccionar_nomina/listado';

function MenuNomina() {

    const fecha = new Date();
    const ro = requestOptions('POST', {
        year: fecha.getFullYear(),
        month: (fecha.getMonth() + 1),
        tipo_nomina: '1,2,3,4,5,6,7',
        estado: 0
    });

    return (
        <div className="menu__nomina">
            <MenuBar />
            <ListadoNominas requestOptions={ro} />
        </div>
    );
}

export default MenuNomina;
