import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { fechaBody, moneyBody, moneyFormat } from '../../constants/bodiesYFiltos';
import { calcularPago } from '../constants';

// --------------------------------------------------- COMPONENTES
export const DatosConvenio = ({ convenio }) => {
    return (
        <DataTable value={convenio}
            tableStyle={{ minWidth: '50rem' }} size='small'>
            <Column field="Registro" header="Registro Personal" ></Column>
            <Column field="CUI" header="CUI" ></Column>
            <Column field="Nombre" header="Nombre" ></Column>
            <Column field="MontoTotal" header="Monto Total(Q)"
                body={(rowData) => moneyBody(rowData, 'MontoTotal')}></Column>
            <Column field="Frecuencia" header="Frecuencia (Meses)"></Column>
            <Column field="ActaAutorizacion" header="Acta Autorización" ></Column>
            <Column field="FechaInicio" header="Fecha Inicio"
                body={(rowData) => fechaBody(rowData, 'FechaInicio')}></Column>
            <Column field="FechaFin" header="Fecha Fin"
                body={(rowData) => fechaBody(rowData, 'FechaFin')}></Column>
            <Column header="Monto de cada pago(Q)"
                body={(rD) =>
                    moneyFormat(calcularPago(rD.FechaFin, rD.FechaInicio, rD.Frecuencia, rD.MontoTotal))
                }></Column>
            <Column field="FechaAutorizacion" header="Fecha Autorizacion"
                body={(rowData) => fechaBody(rowData, 'FechaAutorizacion')}></Column>
        </DataTable>
    )
}