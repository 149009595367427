import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { FileUpload } from 'primereact/fileupload';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

import './resumen_moras.css'

const columnClass = (field, header, filter, filterPlaceholder) => {
  return <Column
    filter={filter}
    filterPlaceholder={filterPlaceholder}
    field={field}
    showFilterMatchModes={false}
    showAddButton={false}
    showFilterOperator={false}
    header={header}></Column>
}


const ReportModule = ({ reportData }) => {
  const [anio, setAnio] = useState(2023);
  const [mes, setMes] = useState('');
  const op = useRef(null);
  const [filters] = useState({
    nombre: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    registro: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    plaza: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    estado_trabajador: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  });

  const meses = [
    {
      nombre: 'Enero',
      numero: 1
    },
    {
      nombre: 'Febrero',
      numero: 2
    },
    {
      nombre: 'Marzo',
      numero: 3
    },
    {
      nombre: 'Abril',
      numero: 4
    },
    {
      nombre: 'Mayo',
      numero: 5
    },
    {
      nombre: 'Junio',
      numero: 6
    },
    {
      nombre: 'Julio',
      numero: 7
    },
    {
      nombre: 'Agosto',
      numero: 8
    },
    {
      nombre: 'Septiembre',
      numero: 9
    },
    {
      nombre: 'Octubre',
      numero: 10
    },
    {
      nombre: 'Noviembre',
      numero: 11
    },
    {
      nombre: 'Diciembre',
      numero: 12
    }
  ]
  const downloadReport = () => {
    // Lógica para descargar el reporte
    // Puedes implementar aquí la generación del reporte en el formato deseado (por ejemplo, CSV, XLSX, PDF, etc.) y descargarlo
  };

  return (
    <>
      <div className='primario'>
        <div className="grid p-fluid">
          <div className='col-12 md:col-2'>
            <label className="font-bold block mb-2 text-color">Año</label>
            <InputNumber value={anio} onValueChange={(e) => setAnio(e.value)}
              mode="decimal" showButtons min={0} useGrouping={false} />
          </div>
          <div className='col-12 md:col-2'>
            <label className="font-bold block mb-2 text-color">Mes</label>
            <Dropdown
              value={mes}
              options={meses}
              optionLabel="nombre"
              placeholder="Seleccionar mes"
              onChange={(e) => {
                console.log(e.value)
                setMes(e.value)
              }}
            />
          </div>
          <div className='col-12 md:col-1'>
            <br />
            <Button type='button' className='p-button-rounded p-button-success'
              icon="pi pi-search" onClick={() => console.log(mes)} tooltip='Buscar registros'
              style={{ marginTop: '5%' }} />
          </div>
          <div className='col-12 md:col-2' style={{ marginLeft: 'auto', marginRight: '0' }}>
            <br />
            <Button type="button" icon="pi pi-plus" label="Cargar Archivo"
              onClick={(e) => op.current.toggle(e)} style={{ marginTop: '1%' }} />
            <OverlayPanel ref={op} showCloseIcon>
              <FileUpload name="demo[]" url={'/api/upload'}
                multiple accept="image/*" maxFileSize={1000000}
                emptyTemplate={<p className="m-0">Subir archivo XML</p>} />
            </OverlayPanel>
          </div>
        </div>
        <br />
        <DataTable value={reportData} paginator rows={10} paginatorPosition="top"
          rowsPerPageOptions={[10, 25, 50]}
          // tableStyle={{ minWidth: '50rem' }}
          filters={filters}
          size='small'
        >
          {columnClass("id_bitacora", "Codigo Bitacora", true, "Buscar por Codigo")}
          {columnClass("detalle", "Detalle", true, "Buscar por Nombre")}
          <Column field="fecha" header="Fecha" ></Column>
          <Column field="tipo" header="Tipo"></Column>
          <Column field="clave_prestamo" header="Clave del Prestamo" ></Column>
        </DataTable>
        <Button label="Descargar Reporte" icon="pi pi-download" className="p-button-success custom-button" onClick={downloadReport}></Button>
        <br />
      </div>
    </>
  );
};

export default ReportModule;
