import './gestion_trabajadores';
import { Button } from 'primereact/button';
import { useState, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';

import {
    afiliacionItemTemplate, calcularEdadActual, calcularEdadIngreso,
} from './constants';
import { headerForm } from '../constants/bodiesYFiltos';
import formatDate from '../tools/formatDate';
import { estados_trabajador } from '../constants/estados_trabajador';
import mostrarFechaEstado from '../tools/mostrarFechaEstado';
import buscarObjetoPorId from '../tools/buscarObjetoPorId';
import { editarTrabajador } from '../endpoints/gestion_trabajadores';

export default function VerTrabajador() {
    // Posibles estados para el trabajador
    const [estadosSeleccionables, setEstadosSeleccionables] =
        useState(estados_trabajador.filter(estado => estado.Afiliacion === 1));

    // Datos trabajador
    const [registro, setRegistro] = useState(null);
    const [CUI, setCUI] = useState(null);
    const [nombre, setNombre] = useState('');
    const [genero, setGenero] = useState(null);
    const [fechaNacimiento, setFechaNacimiento] = useState(null);
    const [fechaIngreso, setFechaIngreso] = useState(null);
    const [afiliadoPlan, setAfiliadoPlan] = useState(1);
    const [estado, setEstado] = useState(estados_trabajador[0]);
    // si el estado aplica
    const [fechaInicioEstado, setFechaInicioEstado] = useState(null);
    const [fechaFinEstado, setFechaFinEstado] = useState(null);

    const toast = useRef(null);

    useEffect(() => {
        // obtener el trabajador seleccionado
        var seleccionado = JSON.parse(sessionStorage.getItem('trabajador_seleccionado'));

        // llenar los datos en el formulario
        setRegistro(seleccionado.Registro);
        setCUI(seleccionado.CUI);
        setNombre(seleccionado.Nombre);

        setGenero(seleccionado.Genero === 1 ? 'Masculino' : 'Femenino');
        setFechaNacimiento(seleccionado.FechaNacimiento ? new Date(seleccionado.FechaNacimiento) : null);
        setFechaIngreso(seleccionado.FechaIngreso ? new Date(seleccionado.FechaIngreso) : null);
        setAfiliadoPlan(seleccionado.Afiliacion);
        setEstado(
            buscarObjetoPorId(estados_trabajador,
                seleccionado.idEstadoTrabajador,
                'idEstadoTrabajador')
        );
        setFechaInicioEstado(
            seleccionado.FechaInicioEstado ? new Date(seleccionado.FechaInicioEstado) : null
        );
        setFechaFinEstado(
            seleccionado.FechaFinEstado ? new Date(seleccionado.FechaFinEstado) : null
        );

        // para mostrar los estados correspondientes a la afiliacion
        var del = estados_trabajador.filter(estado => estado.Afiliacion === seleccionado.Afiliacion);
        setEstadosSeleccionables(del);
    }, []);

    return (
        <div className='primario-crear-registro'>
            <Toast ref={toast} />
            <Card header={headerForm("Datos del trabajador", true)} className="card">
                <form>
                    <div className="grid p-fluid mt-1">
                        {/* REGISTRO */}
                        <div className="field col-12 md:col-6 col_toggle">
                            <label>Registro</label>
                            <InputText required={true}
                                value={registro} readOnly />
                        </div>
                        {/* CUI */}
                        <div className="field col-12 md:col-6 col_toggle">
                            <label>CUI</label>
                            <InputText required={true} readOnly
                                value={CUI} />
                        </div>
                    </div>
                    <div className="grid p-fluid mt-1">
                        {/* NOMBRE */}
                        <div className="field col-12 md:col-8 col_toggle">
                            <label>Nombre Completo</label>
                            <InputText required={true} readOnly value={nombre} />
                        </div>
                        {/* GENERO */}
                        <div className="field col-12 md:col-4 col_toggle">
                            <label>Género</label>
                            <InputText
                                // required={true}
                                readOnly
                                value={genero}
                                placeholder="Género"
                            />
                        </div>
                    </div>
                    <div className="grid p-fluid mt-1">
                        {/* FECHA DE NACIMIENTO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Fecha de Nacimiento</label>
                            <InputText value={formatDate(fechaNacimiento, false)} />
                        </div>
                        {/* FECHA DE INGRESO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Fecha de Ingreso</label>
                            <InputText value={formatDate(fechaIngreso, false)} />
                        </div>

                        {/* EDAD ACTUAL */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Edad Actual</label>
                            <InputText readOnly value={calcularEdadActual(fechaNacimiento)} />
                        </div>
                        {/* EDAD DE INGRESO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Edad de Ingreso</label>
                            <InputText readOnly value={calcularEdadIngreso(fechaNacimiento, fechaIngreso)} />
                        </div>
                    </div>
                    <div className="grid p-fluid mt-1">
                        {/* AFILIACION AL PLAN */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>Está afiliado al plan de prestaciones</label>
                            <Dropdown
                                readOnly
                                value={afiliadoPlan}
                                options={[1, 0]}
                                placeholder="Afiliado al plan de prestaciones"
                                itemTemplate={afiliacionItemTemplate}
                                valueTemplate={afiliacionItemTemplate}
                            />
                        </div>
                        {/* ESTADO O MOTIVO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            <label>{afiliadoPlan === 1 ?
                                'Estado del trabajador' :
                                'Motivo desafiliación'}
                            </label>
                            <Dropdown
                                readOnly
                                value={estado}
                                options={estadosSeleccionables}
                                optionLabel='Nombre'
                                placeholder="Estados del trabajador"
                            />
                        </div>
                        {/* FECHA DE INICIO DE ESTADO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            {mostrarFechaEstado(estado, 'inicio') ?
                                <>
                                    <label>Fecha en que inicia el estado</label>
                                    <InputText
                                        readOnly
                                        value={formatDate(fechaInicioEstado, false)}
                                        showButtonBar showIcon />
                                </>
                                : <></>}
                        </div>
                        {/* FECHA DE FIN DEL ESTADO */}
                        <div className="field col-12 md:col-3 col_toggle">
                            {mostrarFechaEstado(estado, 'fin') ?
                                <>
                                    <label>Fecha en que finaliza el estado</label>
                                    <InputText
                                        readOnly
                                        value={formatDate(fechaFinEstado, false)}
                                        showButtonBar showIcon />
                                </>
                                : <></>}
                        </div>
                    </div>

                    {/* BOTONES */}
                    <br />
                    <div className="grid p-fluid">
                        <div className='col-4' style={{ marginLeft: 'auto', marginRight: '0' }}>
                            <Link to="/cuotas/editar_trabajador">
                                <Button icon="pi pi-pencil"
                                    label="Modificar Datos" className='p-button-help' />
                            </Link>
                        </div>
                        <div className='col-4' >
                            <Link to="/cuotas/ver_contratos">
                                <Button icon="pi pi-file"
                                    label="Ver Contratos" className='p-button-success' />
                            </Link>
                        </div>
                    </div>
                </form>
            </Card>
        </div>
    );
}
