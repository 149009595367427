export default function listarVariables(contenido) {
    var variables = contenido.match(/&amp;&amp;.*?&amp;&amp;/g);
    if(!variables)
        return []
    var nuevo = []
    variables.forEach(variable => {
        var nuevaVar = {
            NombreVariable: variable.replaceAll("&amp;&amp;", ""),
            Valor: ''
        };
        nuevo.push(nuevaVar);
    });
    return nuevo;
}