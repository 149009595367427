import { useNavigate, useParams } from 'react-router-dom';
import BuscarNomina from '../buscar_nomina/busqueda';
import { BreadCrumb } from 'primereact/breadcrumb';
import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import './gestion_documentos.css';
import { requestOptions } from '../misc/requestOptions';

function GestionDocumentos(props) {
    const navigate = useNavigate();
    const { nominaSelected } = useParams();

    const items = [
        { label: 'NÓMINA', command: () => { navigate("/nominas") } },
        { label: 'GESTIÓN DE DOCUMENTOS', command: () => { navigate("/nominas/gestion-documentos") } },
        { label: 'SELECCIONAR NÓMINA', command: () => { navigate("/nominas/gestion-documentos") } }
    ].concat(nominaSelected ? { label: nominaSelected } : []);
    const home = { icon: 'pi pi-home', command: () => { navigate("/") } };

    const [documentos, setDocumentos] = useState([]);
    const [tipo_nomina, seTipoNomina] = useState(null);
    const [loading, setLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(20);

    const [idpagodocumento, setIdPd] = useState(null);
    const [motivo, setMotivo] = useState('');

    const toast = useRef(null);
    const [displayDialog, setDisplayDialog] = useState(null);

    /* Filters */
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    }

    const get_documentos = (showMessage = false) => {
        setLoading(true);
        fetch((process.env.REACT_APP_API_URL_DOCUMENTOS_NOMINA),
            requestOptions('POST', { idnomina: nominaSelected }))
            .then((response) => response.json())
            .then((data) => {
                if (showMessage)
                    toast.current.show({ severity: data.severity, summary: data.message || data.error });
                if (data.data) {
                    data.data.documentos && setDocumentos(data.data.documentos);
                    data.data.tipo_nomina && seTipoNomina(data.data.tipo_nomina);
                }
                setLoading(false);
            });
    }

    const modificar_documento = (request, idpagodocumento) => {
        setLoading(idpagodocumento);
        fetch((process.env.REACT_APP_API_URL_DOCUMENTOS_NOMINA),
            requestOptions(request, { idpagodocumento: idpagodocumento, motivo: motivo }))
            .then((response) => response.json())
            .then((data) => {
                toast.current.show({ severity: data.severity, summary: data.error || `Documento modificado correctamente.` });
                setLoading(false);
                setIdPd(null);
                setMotivo('');
                get_documentos();
            });
    }

    const get_detalle = (idpd) => {
        fetch((process.env.REACT_APP_API_URL_LISTADO_NOMINA + '/detalle'),
            requestOptions('POST', { idpd: idpd, idtiponomina: tipo_nomina }))
            .then((response) => response.json())
            .then((data) => {
                if (data.error)
                    props.toast.current.show({ severity: data.severity, summary: data.error });
                const x = data.data;
                if (x && x.detalle_pago) {
                    const content_detalle = (<div className="table__detalle">
                        <DataTable value={x.detalle_pago} emptyMessage='Sin detalle.' selectionMode='single'>
                            <Column header="Concepto" field='nombre'></Column>
                            <Column header="Tipo" field='tipo'></Column>
                            {tipo_nomina === 2 && <Column header="Descripción" field='descripcion'></Column>}
                            <Column header="Monto (Q.)" field='monto'></Column>
                        </DataTable>
                    </div>);
                    setDisplayDialog(content_detalle);
                }
            });
    }

    useEffect(() => {
        if (nominaSelected)
            get_documentos(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nominaSelected]);

    const liquidoBadge = (rowData) => {
        return <span className={`status-badge-nom status-liquido`}>{rowData.liquido}</span>;
    }
    const acredBody = (rowData) => {
        return <span className={`status-badge-nom acred-${rowData.acreditamiento.toLowerCase()[0]}`}>{rowData.acreditamiento}</span>;
    }
    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }

    const pg_template = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 }
            ];
            return (
                <React.Fragment>
                    <span className="mx-0">Elementos por página: </span>
                    <Dropdown className="mr-4 table__paginator" value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        'CurrentPageReport': (options) => {
            return (
                <span style={{ width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} de {options.totalRecords}
                </span>
            )
        }
    };

    const header = (
        <div className="table-header flex">
            <div className='my-auto py-0'>Documentos de Pago</div>
            <div className='ml-auto'>
                <span className="p-input-icon-right">
                    <i className="pi pi-search" />
                    <InputText style={{ width: '100%' }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Buscar" />
                </span>
            </div>
        </div>
    );

    const anulacion_footer = () => {
        return <div>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={() => { setIdPd(null); setMotivo(''); }} />
            <Button disabled={!motivo} label="Anular" icon="pi pi-check" className="p-button-text" onClick={() => modificar_documento('DELETE', idpagodocumento)} />
        </div>
    }

    const anularBtn = (rowData) => {
        return <div>
            {
                rowData.estado === 'VIGENTE' ?
                    <>
                        {
                            props.anulable &&
                            <Tooltip target={`#tooltip-tag-${rowData.idpagodocumento}-activo`} autoHide={false}>
                                <Button id={rowData.idpagodocumento} icon='pi pi-times-circle' label='Anular'
                                    className='p-button-rounded p-button-danger p-button-sm'
                                    onClick={() => setIdPd(rowData.idpagodocumento)} loading={loading === rowData.idpagodocumento} />
                            </Tooltip>
                        }
                        <Tag id={`tooltip-tag-${rowData.idpagodocumento}-activo`} severity="success" value={rowData.estado}></Tag>
                    </>
                    :
                    <>
                        {
                            props.anulable &&
                            <Tooltip target={`#tooltip-tag-${rowData.idpagodocumento}-anulado`} autoHide={false}>
                                <div className='text-center'>
                                    <p className='mb-1'>{`MOTIVO:\n${rowData.anotacion}`}</p>
                                    <Button id={rowData.idpagodocumento} icon='pi pi-sync' label='Revertir'
                                        className='p-button-rounded p-button-warning p-button-sm'
                                        onClick={() => modificar_documento('PUT', rowData.idpagodocumento)} loading={loading === rowData.idpagodocumento} />
                                </div>
                            </Tooltip>
                        }
                        <Tag id={`tooltip-tag-${rowData.idpagodocumento}-anulado`} severity="danger" value={rowData.estado}></Tag>
                    </>
            }
        </div>
    }

    return (
        <div className="container__gestion_doc">
            <Toast ref={toast}></Toast>
            <BreadCrumb model={items} home={home} />
            <BuscarNomina estado={3} />
            {
            /* loading ?
                    <ProgressBar mode="indeterminate" style={{ height: '8px', marginTop: '200px' }} />
                    : */ nominaSelected &&
                <div className="container__documentos">
                    <DataTable value={documentos} header={header} paginator paginatorTemplate={pg_template} first={first} rows={rows} onPage={onCustomPage}
                        selectionMode='single' emptyMessage="Sin resultados." paginatorClassName="justify-content-end" className="mt-6"
                        filters={filters} globalFilterFields={['registro', 'nombre', 'acreditamiento', 'cuenta', 'correlativo', 'estado']}
                        onRowDoubleClick={(e) => get_detalle(e.data.idpagodocumento)}>
                        <Column field="partida" header="Partida"></Column>
                        <Column field="plaza" header="Plaza" ></Column>
                        <Column field="registro" header="Registro" ></Column>
                        <Column field="nombre" header="Nombre" ></Column>
                        <Column body={acredBody} style={{ minWidth: 'fit-content' }} header="Banco" ></Column>
                        <Column field="cuenta" header="Destino" ></Column>
                        <Column align={'left'} style={{ minWidth: 'fit-content' }} header="Líquido" body={liquidoBadge}></Column>
                        <Column bodyStyle={{ fontWeight: 'lighter', textAlign: 'center' }} field="correlativo" header="No. Doc." ></Column>
                        <Column align={'center'} header="Estado" body={anularBtn}></Column>
                    </DataTable>
                    <Dialog header={`Anular documento`} footer={anulacion_footer} headerStyle={{ paddingTop: '8px', paddingBottom: '8px' }}
                        visible={idpagodocumento !== null} position={'center'} modal
                        onHide={() => { setIdPd(null); setMotivo(''); }} draggable={true} resizable={false}>
                        <div className="field mb-1">
                            <label htmlFor='motivoa' className='mr-2'>Motivo</label>
                            <InputText autoFocus required className='mr-2' style={{ width: '250px' }} id='motivoa' type='text' placeholder='Ingrese motivo de anulación' maxLength={50} value={motivo} onChange={(e) => setMotivo(e.target.value.toUpperCase())} />
                        </div>
                    </Dialog>
                    <Dialog header={'Detalle del documento'} headerStyle={{ paddingTop: '8px', paddingBottom: '8px' }} visible={displayDialog !== null}
                        position={'center'} modal style={{ minWidth: '20%' }} onHide={() => setDisplayDialog(null)}
                        draggable={true} resizable={false}>
                        <p className="m-0">{displayDialog}</p>
                    </Dialog>
                </div>
            }
        </div>
    );
}

export default GestionDocumentos;
