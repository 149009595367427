
// --------------------------------------------------- COMPONENTES
export const emptyTemplate = () => {
    return (
        <div className="flex align-items-center flex-column">
            <i className="pi pi-image mt-3" style={{ fontSize: '3em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
            <span style={{ fontSize: '1.2em' }} className="my-2">
                Arrastrar o seleccionar imagen de firma
            </span>
        </div>
    );
};

// --------------------------------------------------- FUNCIONES
export function calcularPago(fechaFin, fechaInicio, frecuencia, montoTotal) {
    if (!fechaFin || !fechaInicio || !frecuencia || !montoTotal)
        return 0
    fechaFin = new Date(fechaFin);
    fechaInicio = new Date(fechaInicio);
    // calcular cantidad de meses
    const diferenciaAnios = fechaFin.getFullYear() - fechaInicio.getFullYear();
    const diferenciaMeses = fechaFin.getMonth() - fechaInicio.getMonth();

    var mesesTotales = diferenciaAnios * 12 + diferenciaMeses;
    if (fechaFin.getDate() < fechaInicio.getDate()) {
        mesesTotales -= 1;
    }

    // calcular cantidad de pagos
    var cantidadDePagos = mesesTotales / frecuencia;

    // calcular cantidad de cada pago
    var cantidadPorPago = montoTotal / cantidadDePagos;

    return cantidadPorPago.toFixed(2);
}
