import { requestOptions } from "../requestOptions";
const api = process.env.REACT_APP_API_URL_CUOTAS || "http://localhost:8091/cuotas/"

// --------------------------------------------------- INTEGRACIONES
export async function getIntegraciones() {
    const url = api + "integraciones/get_integraciones"
    const response = await fetch(url, requestOptions("GET", null));
    return response.json();
}

// -- sirve para crear integraciones
export async function getCuotasParaIntegracion(data = {}) {
    const url = api + "integraciones/get_cuotas_para_integracion"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function guardarIntegracion(data = {}) {
    const url = api + "integraciones/guardar_integracion"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function eliminarIntegracion(data = {}) {
    const url = api + "integraciones/eliminar_integracion/" + data.idIntegracion
    const response = await fetch(url, requestOptions("DELETE", null));
    return response.json();
}

// -- sirve para ver integraciones
export async function getCuotasIntegracion(data = {}) {
    const url = api + "integraciones/get_cuotas_integracion"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function exportarIntegracion(data = {}) {
    const url = api + "integraciones/exportar_integracion"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response;
}










