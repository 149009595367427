import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import SamplePDF from '../../reports/default.pdf';
import { get_comments_report_person } from '../../constants/backend_routes';
import * as XLSX from 'xlsx';

function PrefilUsuario ({ user, userCards, userId }) {
  const comments = JSON.parse(user.description)
  
  const descargarPDF = () => {
    const link = document.createElement('a');
    link.href = SamplePDF; // La ruta hacia tu archivo PDF importado
    link.target = '_blank';
    link.download = 'reporte.pdf'; // Nombre del archivo descargado
    link.click();
  };
  
  const generarReporte = async () => {
    const responseComments = await fetch(`${get_comments_report_person}/${userId}`)
    const commentsJson = await responseComments.json()
    console.log(commentsJson.comentarios)
    convertJsonToExcel(commentsJson.comentarios, 'comentarios')
}
const convertJsonToExcel = (jsonData, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(jsonData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

  // Crear el archivo Excel y guardarlo en el sistema de archivos
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};
  // setComments(JSON.parse(user.description))

  return (
    <div>
      <h3>Informacion Basica</h3>
      <Card title="Descripcion Individual" style={{ width: '100%' }}>
        <div className="p-card-content">
          <h3>{user.name}</h3>
          <p>Numero de DPI: {user.identification}</p>
          <p>Direccion: {user.location}</p>
          {/* <p>Descripcion: {user.description}</p> */}
          <p>Fecha de Nacimiento: {user.date}</p>
          <p>Fecha de Alza: {user.start_date}</p>
          <p>Correo: {user.email}</p>
          <p>Numero de Telefono: {user.phone_number}</p>
        </div>
      </Card>
      <br />
      <h3>Prestamos</h3>
      <div className='row text-center' >
        {userCards.map((card) => (
          <div key={card.id} className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <Card key={card.id} title={card.title} style={{ width: '100%', marginTop: '20px' }}>
              <div>
                <p>Clave: {card.clave}</p>
                <p>Dia de Pago: {card.dia_pago}</p>
                <p>Duracion: {card.duracion}</p>
                <p>Fecha de Inicio: {card.fecha_inicio}</p>
                <p>Interes faltante: {card.interes_faltante}</p>
                <p>Mora faltante: {card.mora_faltante}</p>
                <p>Capital faltante: {card.capital_faltante}</p>
                <p>Tasa de interes: {card.tasa}</p>
                <Button label="Detalle" icon="pi pi-check" onClick={() => {window.location.href = `/moras/detalle_prestamo/${card.clave}`}} />
              </div>
            </Card>
          </div>
        ))}
      </div>
      <h3>Comentarios</h3>
      {comments &&
        <div className='row text-center'>
        {comments.map((card) => (
          <div key={card.fecha} className="col-lg-4 col-md-6 col-sm-12 mb-5">
          <Card key={card.fecha} title={card.fecha} style={{ width: '100%', marginTop: '20px' }}>
            <div>
              <p>Fecha: {card.fecha}</p>
              <p>Comentario: {card.comentario}</p>
            </div>
          </Card>
        </div>
        ))
        }
      </div>
      }
      <div>
        <Button label="Modificar usuario/Agregar comentario" icon="pi pi-check" onClick={() => {window.location.href = `/moras/modificacion_usuario/${userId}`}} />
        <br/>
        <br/>
        <Button label="Reporte Comentarios" icon='pi pi-check' onClick={() => {generarReporte()}} />
      </div>
    </div>
  );
};

export default PrefilUsuario;
