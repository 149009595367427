import { requestOptions } from "./requestOptions";
const api = process.env.REACT_APP_API_URL_CUOTAS || "http://localhost:8091/cuotas/"

export async function getConvenios() {
    const url = api + "convenios/get_convenios"
    const response = await fetch(url, requestOptions("GET", null));
    return response.json();
}

export async function crearConvenio(data = {}) {
    const url = api + "convenios/crear_convenio"
    const response = await fetch(url, { method: "POST", body: data });
    return response.json();
}

export async function getFirmaConvenio(data = {}) {
    const url = api + "convenios/get_firma_convenio"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response;
}

export async function editarConvenio(data = {}) {
    const url = api + "convenios/editar_convenio"
    const response = await fetch(url, { method: "PUT", body: data });
    return response.json();
}

export async function eliminarConvenio(data = {}) {
    const url = api + "convenios/eliminar_convenio/" + data.idConvenio
    const response = await fetch(url, requestOptions("DELETE", null));
    return response.json();
}

export async function exportarConvenio(data = {}) {
    const url = api + "convenios/exportar_convenio"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response;
}

// ---------------------------------------------------- PAGOS
export async function getPagosConvenio(data = {}) {
    const url = api + "convenios/get_pagos_convenio"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function crearPagoConvenio(data = {}) {
    const url = api + "convenios/crear_pago_convenio"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function editarPagoConvenio(data = {}) {
    const url = api + "convenios/editar_pago_convenio"
    const response = await fetch(url, requestOptions("PUT", JSON.stringify(data)));
    return response.json();
}

export async function eliminarPagoConvenio(data = {}) {
    const url = api + "convenios/eliminar_pago_convenio/" + data.idPagoConvenio
    const response = await fetch(url, requestOptions("DELETE", null));
    return response.json();
}






