import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import './busqueda_prestamos.css';
import { get_prestamo_clave_persona_route } from '../../constants/backend_routes';

const SearchBar = ( {updateLoans} ) => {
  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [
    { name: 'Nombre', code: 'NY' },
    { name: 'Codigo', code: 'RM' },
    { name: 'Monto', code: 'LDN' },
    { name: 'Mensualidad', code: 'IST' },
    { name: 'Plazo', code: 'PRS' }
  ];

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const get_loans = async (params) => {
    const request = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(params)
    }
    const response = await fetch(get_prestamo_clave_persona_route, request)
    const responseJson = await response.json()
    return responseJson.data
  }

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    // Aquí puedes realizar la lógica de búsqueda
    const body = {
      "registro":searchQuery,
      "partida" : searchQuery,
      "clave": searchQuery
    }
    updateLoans(await get_loans(body))
  };


  return (
    <form className="search-bar" onSubmit={handleSearchSubmit}>
      {/* <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name"
      editable placeholder="Filtrar por" className="w-full md:w-14rem custom-filter" /> */}
      <input
        type="text"
        placeholder="Buscar..."
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <button type="submit">Buscar</button>
    </form>
  );
};

export default SearchBar;