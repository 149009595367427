import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';


function UserCard({ img = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
  name,
  comment,
  address,
  email,
  state,
  gender,
  start_date,
  id_number,
  ref_number,
  tel,
  id_usuario }) {
  const img_style = { borderRadius: '2px 2px 0 0', height: '150px', maxWidth: '100%' };
  const img_perfil = <img src={img} alt='img_busqueda_prestamos' style={img_style} />;

  name = (name === '' || name === null) ? "Nombre no definido" : name
  comment = (comment === '' || comment === null) ? "Usario no cuenta con descripción" : comment
  address = (address === '' || address === null) ? "Direccion no registrada para este usuario" : address
  email = (email === '' || email === null) ? "Correo no registrado para este usuario" : email
  state = state === 'S' ? 'Activo' : 'Inactivo'
  gender = (gender === '' || gender === null) ? "Genero no especificado en creación de usuario" : gender
  start_date = (start_date === '' || start_date === null) ? "Fecha de inicio no especificada" : start_date
  id_number = (id_number === '' || id_number === null) ? "Numero de identificacion (DPI) no registrado" : id_number
  ref_number = (ref_number === '' || ref_number === null) ? "Numero de referencia no coincide con base de datos" : ref_number
  tel = (tel === '' || tel === null) ? "Numero de telefono no registrado" : tel

  id_usuario = (id_usuario === '' || id_usuario === null) ? "Id de usuario no definido" : id_usuario

  const date_format = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  const date = new Date(Date.parse(start_date)).toLocaleDateString('es-ES', date_format)

  return (
    <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
      <Card header={img_perfil} >
        <h2>{name}</h2>
        {/* <p>Descripción/Comentario: {comment}</p> */}
        <p>Dirección: {address}</p>
        <p>Correo electronico: {email}</p>
        <p>Estado: {state}</p>
        <p>Genero: {gender}</p>
        <p>Fecha de alza: {date}</p>
        <p>Identificación (DPI): {id_number}</p>
        <p>Registro: {ref_number}</p>
        <p>Telefono: {tel}</p>
        <Button label="Detalle" severity="info" onClick={() => { window.location.href = `perfil_usuario/${id_usuario}` }} />
      </Card>
    </div>
  );
};

export default UserCard;


// Nombres
// comentario
// direccion
// correo
// estado
// genero
// fecha alza
// numero dpi
// registro
// telefono