import './historial.css';
import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { useSessionStorage } from 'primereact/hooks';
import { InputNumber } from 'primereact/inputnumber';
import { locale, addLocale } from 'primereact/api';
import data from '../diccionario.json';
import {
    estadoChequeBody, estadoCuotaBody,
    estadoChequeFilter, estadoCuotaFilter,
    codCuotaFilter, obtenerAnioMes,
} from './constants';
import {
    fechaBody, columnaConFiltros, containsFilter,
    renderRedirect, mostrarError, moneyBody
} from '../constants/bodiesYFiltos';
import { BotonExportarTabla } from '../constants/exportacion';
import { meses } from '../constants/diccionarios';
import Header from '../../header/header';
import buscarObjetoPorId from '../tools/buscarObjetoPorId';
import {
    getUltimaFecha, getRegistrosHistorialFecha, eliminarRegistro,
    getRegistrosHistorialTrabajador, exportarTabla
} from '../endpoints/historial';

locale('es');
addLocale('es', data.es);

export default function HistorialFecha() {
    const [mode, setMode] = useState(0); // 0 = por fecha; 1 = por trabajador

    // historial por fecha
    const [anio, setAnio] = useState(2023);
    const [mes, setMes] = useState('');

    // historial por trabajador
    const [registro, setRegistro] = useSessionStorage(null, 'registro');
    const [CUI, setCUI] = useSessionStorage(null, 'CUI');

    const [filters] = useState({
        Registro: containsFilter,
        Nombre: containsFilter,
        Nomina: containsFilter,
        Partida: containsFilter,
        NumCheque: containsFilter,
        Plaza: containsFilter,
        Nulo: containsFilter,
        idEstadoCuota: containsFilter,
        CodCuota: containsFilter
    });
    const [registros, setRegistros] = useSessionStorage([], 'registros');
    const [registrosTemp, setRegistrosTemp] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [agregarRegistrosModeVisible, setAgregarRegistrosModeVisible] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        // obtener el tipo de historial seleccionado
        var seleccionado = sessionStorage.getItem('modo_historial');
        if (seleccionado) {
            seleccionado = parseInt(seleccionado);
            setMode(seleccionado);
        }

        // verificar si habían aplicado filtros trabajador o fecha
        var filtros = JSON.parse(sessionStorage.getItem('filtros_historial'));

        if (filtros) {
            if (seleccionado === 0) {
                setAnio(filtros.Anio); setMes(filtros.Mes);
            } else {
                setRegistro(filtros.Registro); setCUI(filtros.CUI);
            }
        }

        // verificar si hay que recargar los registros por edicion o limite de storage
        var recargar = sessionStorage.getItem('recargar_historial');

        // historial por fecha
        if (seleccionado === 0) {
            // si ya había filtrado
            if (filtros) {
                if (filtros.Anio && filtros.Mes && !recargar) {
                    setLoading(false);
                    return;
                }
                // si necesita recargar
                if (filtros.Anio && filtros.Mes && recargar) {
                    obtenerRegistrosFecha({ Anio: filtros.Anio, Mes: filtros.Mes.numero });
                    return;
                }
            }

            setLoading(true);
            // obtener ultima fecha
            getUltimaFecha().then((resp) => {
                var fecha = obtenerAnioMes(resp.data.fechaReciente);
                setAnio(fecha.Anio);
                for (var i = 0; i < meses.length; i++) {
                    var month = meses[i];
                    if (month.numero === parseInt(fecha.Mes)) {
                        setMes(month);
                        break;
                    }
                }

                // obtener los registros
                obtenerRegistrosFecha(fecha);
            }).catch(err => {
                mostrarError(err, setLoading(false), () => { setRegistros([]); setRegistrosTemp([]) });
            });
        } else {
            // historial por trabajador
            if (recargar) {
                // si no hay filtros
                if (!filtros) {
                    setLoading(false);
                    setRegistros([]);
                    return;
                } else if (!filtros.Registro && !filtros.CUI) {
                    setLoading(false);
                    setRegistros([]);
                    return;
                }
                // de lo contrario obtener registros con filtros
                obtenerRegistrosTrabajador(filtros.Registro, filtros.CUI);
            } else {
                setLoading(false);
            }
        }

    }, []);

    const obtenerRegistrosFecha = (AnioMes) => {
        setLoading(true);
        // obtener los registros
        getRegistrosHistorialFecha(AnioMes).then((resp) => {
            // si excede el limite de storage
            if (resp.data.length > 19000) {
                setRegistros(resp.data.slice(0, 19000));
                setRegistrosTemp(resp.data.slice(19000));
                sessionStorage.setItem('recargar_historial', true);
            } else {
                setRegistros(resp.data);
                setRegistrosTemp([]);
                sessionStorage.removeItem("recargar_historial");
            }
            setLoading(false);
        }).catch(err => {
            mostrarError(err, setLoading(false), () => { setRegistros([]); setRegistrosTemp([]) });
        });

        sessionStorage.setItem('filtros_historial',
            JSON.stringify({ Anio: AnioMes.Anio, Mes: buscarObjetoPorId(meses, AnioMes.Mes, "numero") })
        );
        // sessionStorage.removeItem("recargar_historial");
    };

    const obtenerRegistrosTrabajador = (Registro, CUI) => {
        if (!Registro && !CUI) {
            return;
        }
        setLoading(true);
        // obtener los registros
        getRegistrosHistorialTrabajador({ Registro: Registro, CUI: CUI }).then((resp) => {
            // si excede el limite de storage
            if (resp.data.length > 19000) {
                setRegistros(resp.data.slice(0, 19000));
                setRegistrosTemp(resp.data.slice(19000));
                sessionStorage.setItem('recargar_historial', true);
            } else {
                setRegistros(resp.data);
                setRegistrosTemp([]);
                sessionStorage.removeItem("recargar_historial");
            }
            setLoading(false);
        }).catch(err => {
            mostrarError(err, setLoading(false), () => { setRegistros([]); setRegistrosTemp([]) });
        });

        sessionStorage.setItem('filtros_historial',
            JSON.stringify({ Registro: Registro, CUI: CUI })
        );
        sessionStorage.removeItem("recargar_historial");
    };

    const aEditarRegistro = () => {
        if (selected === null)
            return
        sessionStorage.setItem('registro_seleccionado', JSON.stringify(selected));
        setSelected(null);
        setRedirect(true);
    }

    const accept = () => {
        eliminarRegistro({ idCuota: selected.idCuota }).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                // quitar el registro
                var delRegistros = registros.filter(registro => registro.idCuota !== selected.idCuota);
                var delRegistrosTemp = registrosTemp.filter(registroTemp => registroTemp.idCuota !== selected.idCuota);
                setRegistros(delRegistros);
                setRegistrosTemp(delRegistrosTemp);
                setSelected(null);
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err, setLoading(false), () => { setRegistros([]); setRegistrosTemp([]) });
        });
    }

    const EliminarRegistro = () => {
        if (!selected)
            return

        confirmDialog({
            message: 'Está seguro de eliminar la cuota seleccionada?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept
        });
    }

    return (
        <>
            {/* PARA LA CONFIRMACIÓN DE ELIMINAR REGISTRO */}
            <Toast ref={toast} />
            <ConfirmDialog />
            <Header title={<h2>{mode === 0 ? 'Historial por Fecha' : 'Historial por Trabajador'}</h2>} />
            <div className='primario'>
                <br />
                <div className='botones'>
                    <div className="grid p-fluid botones-filtros" >
                        <div >
                            <Link to="/cuotas/">
                                <Button type='button' className='p-button-rounded'
                                    icon="pi pi-arrow-left"
                                    tooltip='Regresar'
                                    style={{ marginTop: '90%' }} />
                            </Link>
                        </div>
                        {mode === 0 ?
                            <>
                                <div className='campo-filtro'>
                                    <label className="font-bold block mb-2 text-color">Año</label>
                                    <InputNumber value={anio} onValueChange={(e) => setAnio(e.value)}
                                        mode="decimal" showButtons min={0} useGrouping={false} />
                                </div>
                                <div className='campo-filtro'>
                                    <label className="font-bold block mb-2 text-color">Mes</label>
                                    <Dropdown
                                        value={mes}
                                        options={meses}
                                        optionLabel="nombre"
                                        placeholder="Seleccionar mes"
                                        onChange={(e) => setMes(e.value)}
                                    />
                                </div>
                                <div>
                                    <Button type='button' className='p-button-rounded p-button-success'
                                        icon="pi pi-search"
                                        onClick={() => obtenerRegistrosFecha({ Anio: anio, Mes: mes.numero })}
                                        tooltip='Buscar registros'
                                        style={{ marginTop: '90%' }} />
                                </div>
                            </>
                            :
                            <>
                                <div className='campo-filtro'>
                                    <label className="font-bold block mb-2 text-color">Registro</label>
                                    <InputNumber value={registro} onValueChange={
                                        (e) => {
                                            setRegistro(e.value)
                                            setCUI(null)
                                        }
                                    }
                                        mode="decimal" showButtons min={0} useGrouping={false} />
                                </div>
                                <div className='campo-filtro'>
                                    <label className="font-bold block mb-2 text-color">CUI</label>
                                    <InputNumber value={CUI} onValueChange={(e) => {
                                        setRegistro(null)
                                        setCUI(e.value)
                                    }}
                                        mode="decimal" showButtons min={0} useGrouping={false} />
                                </div>
                                <div>
                                    <Button type='button' className='p-button-rounded p-button-success'
                                        icon="pi pi-search"
                                        onClick={() => obtenerRegistrosTrabajador(registro, CUI)}
                                        tooltip='Buscar registros'
                                        style={{ marginTop: '90%' }} />
                                </div>
                            </>
                        }
                    </div>
                    <div className="grid p-fluid botones-acciones" >
                        <div className='col-12 md:col-4'>
                            <Button type="button" icon="pi pi-plus" label="Agregar Registros"
                                onClick={(e) => setAgregarRegistrosModeVisible(true)} />
                        </div>
                        <Dialog header="Agregar Registros" visible={agregarRegistrosModeVisible}
                            style={{ width: '55vw' }}
                            onHide={() => setAgregarRegistrosModeVisible(false)}
                        >
                            <h3 style={{ textAlign: 'center' }}>
                                ¿Cómo desea agregar nuevos registros?
                            </h3>
                            <div className="grid p-fluid" style={{ marginTop: '5%' }}>
                                <div className='col-6'>
                                    <Link to="/cuotas/crear_registro">
                                        <Button type="button" icon="pi pi-pencil" label="Forma Manual" />
                                    </Link>
                                </div>
                                <div className='col-6'>
                                    <Link to="/cuotas/cargar_registros">
                                        <Button type="button" icon="pi pi-file" label="Cargar Archivo XLS" />
                                    </Link>
                                </div>
                            </div>
                        </Dialog>
                        <div className='col-12 md:col-4'>
                            <Button type="button" icon="pi pi-pencil" label="Editar Registro"
                                severity="warning" disabled={selected === null}
                                onClick={(e) => aEditarRegistro()} />
                        </div>
                        <div className='col-12 md:col-4'>
                            <Button type="button" icon="pi pi-trash" label="Eliminar Registro"
                                severity="danger" disabled={selected === null}
                                onClick={EliminarRegistro} />
                        </div>
                    </div>
                </div>
                <br />
                <DataTable value={registros &&
                    [...registros, ...registrosTemp]
                } paginator rows={10} paginatorPosition="top"
                    rowsPerPageOptions={[10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                    filters={filters}
                    stateStorage="session" stateKey="dt-historial"
                    size='small'
                    paginatorRight={
                        BotonExportarTabla(
                            exportarTabla,
                            registros && [...registros, ...registrosTemp],
                            'dt-historial',
                            'historial')
                    }
                    loading={loading}
                    selectionMode='single'
                    selection={selected}
                    onSelectionChange={(e) => { setSelected(e.value) }}
                    onRowClick={() => { setSelected(null) }}
                    emptyMessage="Realizar una búsqueda"
                >
                    {columnaConFiltros("Registro", "Registro Personal", true, "Buscar por registro")}
                    {columnaConFiltros("Nombre", "Nombre", true, "Buscar por nombre")}
                    {columnaConFiltros("Nomina", "Nomina", true, "Buscar por nomina")}
                    {columnaConFiltros("Partida", "Partida", true, "Buscar por partida")}
                    <Column field="Sueldo" header="Sueldo(Q)"
                        body={(rowData) => moneyBody(rowData, "Sueldo")}></Column>
                    {columnaConFiltros("NumCheque", "Número de Cheque", true, "Buscar por número de cheque")}
                    <Column field="FechaCheque" header="Fecha del cheque"
                        body={(rowData) => fechaBody(rowData, "FechaCheque")}></Column>
                    <Column field="Nulo"
                        header="Cheque Nulo?"
                        filter filterField="Nulo"
                        body={estadoChequeBody}
                        filterElement={estadoChequeFilter}
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showFilterOperator={false}
                        showApplyButton={false} showClearButton={false}>
                    </Column>
                    {columnaConFiltros("Plaza", "Plaza", true, "Buscar por plaza")}
                    <Column field="AfiliaPlan" header="Cuota(Q)"
                        body={(rowData) => moneyBody(rowData, "AfiliaPlan")}></Column>
                    <Column field="idEstadoCuota"
                        header="Estado de Cuota"
                        filter filterField="idEstadoCuota"
                        body={estadoCuotaBody}
                        filterElement={estadoCuotaFilter}
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showFilterOperator={false}
                        showApplyButton={false} showClearButton={false}>
                    </Column>
                    <Column field="CodCuota"
                        header="Código de Cuota"
                        filter filterField="CodCuota"
                        filterElement={codCuotaFilter}
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showFilterOperator={false}
                        showApplyButton={false} showClearButton={false}>
                    </Column>
                </DataTable>
                <br />
                {renderRedirect(redirect, 'editar_registro')}
            </div>
        </>
    );
}
