import './bancos.css';
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import get_bancos from '../misc/get_bancos';
import { requestOptions } from '../misc/requestOptions';
import { Chip } from 'primereact/chip';

function CatalogoBancos(props) {
    const navigate = useNavigate();

    const [bancoDialog, setBancoDialog] = useState(false);
    const [isLoaded, setLoaded] = useState(false);

    const [cuenta_contable, setCuentaContable] = useState('');
    const [nombreBanco, setNombre] = useState('');
    const [anotacion, setAnotacion] = useState('');

    const toast = useRef(null);

    const [bancos_list, setBancos] = useState([]);
    async function fetchBancos() {
        const tmp = await get_bancos();
        setBancos(tmp);
        setLoaded(true);
    }

    useEffect(() => {
        fetchBancos();
    }, []);

    const add_banco = () => {
        fetch((process.env.REACT_APP_API_URL_PARTIDAS_CATALOGO + '/nuevo_banco'),
            requestOptions('POST', {
                idcc: cuenta_contable,
                nombre: nombreBanco,
                anotacion: anotacion
            }))
            .then((response) => response.json())
            .then((data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                setCuentaContable('');
                setNombre('');
                setAnotacion('');
                setBancoDialog(false);
                fetchBancos();
            });
    }

    const header = () => {
        return (
            <div className="flex">
                <p className='my-auto'>Listado de formas de pago</p>
                {
                    props.agregar &&
                    <Button type='button' label="Crear banco o forma de pago" icon="pi pi-plus" iconPos='right' severity='warning' raised outlined className="ml-auto font-weight-bold" onClick={() => setBancoDialog(true)} />
                }
            </div>
        )
    }

    const dialog_footer = () => {
        return <div>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={() => setBancoDialog(false)} />
            <Button disabled={!cuenta_contable || !nombreBanco || !anotacion} label="Guardar" icon="pi pi-check" className="p-button-text" onClick={add_banco} />
        </div>
    }

    // Para BreadCrumb
    const items = [
        { label: 'NÓMINAS', command: () => { navigate("/nominas") } },
        { label: 'BANCOS', command: () => { navigate("/nominas/bancos") } }
    ];
    const home = { icon: 'pi pi-home', command: () => { navigate("/") } };

    const bancosBody = (rowData) => {
        let image_path = '';
        switch (rowData.idbanco) {
            case 1:
                image_path = `../gytc.png`;
                break;
            case 2:
                image_path = `../banrural_c.jpg`;
                break;
            case 3:
                image_path = `../cheque.png`;
                break;
            default:
                break;
        }
        return <Chip className='chip_banco align-middle' image={image_path} label={rowData.nombre} />;
    }

    return (
        <div className='container__bancos'>
            <Toast ref={toast}></Toast>
            <BreadCrumb model={items} home={home} />
            {
                isLoaded &&
                <div className="card__bancos">
                    <DataTable value={bancos_list} header={header} selectionMode='single' emptyMessage="No se encontró ningún registro." >
                        <Column align={'center'} bodyStyle={{ fontWeight: 'bold' }} field="idbanco" header="ID"></Column>
                        <Column align={'center'} field="cuenta_contable" header="Partida"></Column>
                        <Column alignHeader={'center'} body={bancosBody} header="Nombre"></Column>
                        <Column field="anotacion" bodyStyle={{ fontFamily: 'monospace' }} header="DM." ></Column>
                    </DataTable>
                    <Dialog visible={bancoDialog} style={{ width: '400px' }} header="Nuevo banco" modal className="p-fluid" footer={dialog_footer} onHide={() => setBancoDialog(false)} resizable={false}>
                        <div className="field">
                            <label htmlFor="idccb">Cuenta contable</label>
                            <InputText id="idccb" type="text" keyfilter={/[\d]/} value={cuenta_contable} onChange={(e) => setCuentaContable(e.target.value)} required autoFocus />
                        </div>
                        <div className="field">
                            <label htmlFor="nombreb">Nombre</label>
                            <InputText id='nombreb' type='text' value={nombreBanco} onChange={(e) => setNombre(e.target.value.toUpperCase())} required />
                        </div>
                        <div className="field">
                            <label htmlFor="anotab">DM.</label>
                            <InputText id='anotab' placeholder='DM. #' type='text' value={anotacion} onChange={(e) => setAnotacion(e.target.value)} required />
                        </div>
                    </Dialog>
                </div>
            }

        </div>
    );
}

export default CatalogoBancos;
