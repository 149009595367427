import { requestOptions } from "./requestOptions";
const api = process.env.REACT_APP_API_URL_CUOTAS || "http://localhost:8091/cuotas/"

export async function crearPlantilla(data = {}) {
    const url = api + "plantillas/crear_plantilla"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function getPlantillas() {
    const url = api + "plantillas/get_plantillas"
    const response = await fetch(url, requestOptions("GET", null));
    return response.json();
}

export async function getPlantilla(data = {}) {
    const url = api + "plantillas/get_plantilla"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function actualizarPlantilla(data = {}) {
    const url = api + "plantillas/actualizar_plantilla"
    const response = await fetch(url, requestOptions("PUT", JSON.stringify(data)));
    return response.json();
}

export async function eliminarPlantilla(data = {}) {
    const url = api + "plantillas/eliminar_plantilla/" + data.idPlantilla
    const response = await fetch(url, requestOptions("DELETE", null));
    return response.json();
}


