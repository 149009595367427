import usac_logo from '../../img/usac_logo.png'
import React, { useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Link, useLocation } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import BusquedaAvanzada from '../../modulo_jubilados/busqueda_avanzada/busqueda_avanzada';

const NominaSidebar = (props) => {

    const [visibleLeft, setVisibleLeft] = useState(false);

    const [displayBusqueda, setDisplayBusqueda] = useState(false);

    const location = useLocation();

    const hideSidebar = () => {
        setVisibleLeft(false);
    }

    useEffect(() => {
        // console.log('La ruta ha cambiado a:', location.pathname);
        setDisplayBusqueda(false);
    }, [location.pathname]);

    return (
        <>
            <Sidebar className='menu__sidebar' visible={visibleLeft} position='left' onHide={() => setVisibleLeft(false)}>
                <div className='content__sidebar' >
                    <img src={usac_logo} alt='logo' />
                    <h3 style={{ width: 'min-content', paddingLeft: '25px' }}>{props.title}</h3>
                    <br />
                    <ul className='text-left'>
                        <li className='mb-2'><Link to="#" onClick={() => { hideSidebar(); setDisplayBusqueda(true); }}>Búsqueda avanzada</Link></li>
                        <li className='mb-2'><Link onClick={hideSidebar} to="/jubilados">Jubilados y pensionados</Link></li>
                        <li className='mb-2'><Link onClick={hideSidebar} to="/nominas">Nóminas</Link></li>
                        <li className='mb-2'><Link onClick={hideSidebar} to="/">Ir a Dashboard</Link></li>
                    </ul>
                </div>
            </Sidebar>
            <Button id='btn__menu' icon="pi pi-bars" onClick={() => setVisibleLeft(true)} className="p-0" />
            <Dialog header={"Búsqueda avanzada"} visible={displayBusqueda} style={{ width: '1130px' }} modal={false}
                headerStyle={{ paddingTop: '8px', paddingBottom: '8px' }} keepInViewport={false}
                resizable={true} draggable={true} onHide={() => setDisplayBusqueda(false)} >
                <BusquedaAvanzada />
            </Dialog>
        </>
    );
}

export default NominaSidebar;