import { requestOptions } from "./requestOptions";

/* Obtener autorización con clave */
const request_authorization = async (key = '') => {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_URL_NOMINAS_AUTORIZACION,
            requestOptions('POST', { clave: key }))
            .then((response) => response.json())
            .then((data) => {
                return resolve(data);
            });
    });
}

export default request_authorization;
