import './signup.css';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import usac_logo from '../../img/usac_logo.png'
import { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';

import { sign_up_route } from '../../constants/backend_routes';

function Signup() {
    const img_style = { borderRadius: '2px 2px 0 0', height: '125px', width: '125px' };
    const logo = (<div className='' >
        <img src={usac_logo} alt='logo' style={img_style} />
    </div>);

    const navigate = useNavigate();
    const [user, setUser] = useState('');
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShow] = useState(false);

    const [loading, setLoading] = useState(false);

    const toast = useRef(null);

    const go_back = () => navigate('/login', { replace: true });
    const request_signin = async (event) => {
        event.preventDefault();
        setLoading(true);
        const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'username': user, 'password': password, 'email': mail, 'user_type': 2 })
        }
        await fetch(sign_up_route, request).then(
            async response => {
                if (response.ok) {
                    // toast.current.show({severity: 'success', sumary: 'Usuario registrado correctamente', detail: `Usuario '${user}' agregado, debe iniciar sesión con este usuario.`});
                    // await new Promise(resolve => {
                    //     return setTimeout(resolve, 2 * 1000);
                    // });
                    navigate("/login", { replace: true });
                } else {
                    toast.current.show({ severity: 'error', summary: 'El usuario no pudo ser agregado', detail: `Usuario '${user}', no pudo ser ingresado.` });
                }
                setLoading(false);
            }
        )
    }
    return (
        <>
            <Toast ref={toast}></Toast>
            <div className='container container__signup'>
                <div className="row text-center">
                    <div className="col-md-7 col-sm-12">
                        <Card style={{ paddingTop: '43px', paddingBottom: '42px', margin: '100px 20px 150px 0px' }}
                            header={logo}
                            title="Plan de Prestaciones"
                            subTitle="Universidad de San Carlos de Guatemala">
                            <hr />
                        </Card>
                    </div>
                    <div className="col-md-5 col-sm-12">
                        <Card style={{ marginTop: '100px', marginBottom: '150px' }}>
                            <h4 className="font-weight-bold mb-5">Registrar nuevo usuario</h4>
                            <form onSubmit={request_signin} className="p-fluid">
                                <div className="field mb-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-at"></i>
                                        </span>
                                        <span className="p-float-label">
                                            <InputText type="email" required={true} value={mail} onChange={(e) => setMail(e.target.value)} />
                                            <label>Email</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="field mb-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-user"></i>
                                        </span>
                                        <span className="p-float-label">
                                            <InputText type="text" required={true} value={user} onChange={(e) => setUser(e.target.value)} />
                                            <label>Usuario</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="field mb-">
                                    <div className="p-inputgroup">
                                        <span onClick={() => { setShow(!showPassword) }} className="p-inputgroup-addon">
                                            <i className="pi pi-eye"></i>
                                        </span>
                                        <span className="p-float-label">
                                            <InputText type={showPassword ? 'text' : 'password'} required={true} value={password} onChange={(e) => setPassword(e.target.value)} />
                                            <label>Contraseña</label>
                                        </span>
                                    </div>
                                </div>
                                {/* <Button type="submit" label="Ingresar" className="mt-2 mb-1" /> */}
                                <div className='container mx-auto'>
                                    <Button type="submit" label="Registrar" style={{ margin: '5px 5px 5px 0px' }} className='col-md-5' loading={loading} />
                                    <Button label='Regresar' style={{ margin: '5px 5px 5px 0px' }} className='col-md-5 p-button-danger' onClick={go_back} />
                                </div>
                            </form>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Signup;