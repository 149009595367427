import React from 'react';
import { Link } from 'react-router-dom';
import './404.css'

class NotFoundPage extends React.Component {
    render() {
        return (<div className="notfound">
            <h1 id="NFtitle">401</h1>
            <h1 id="NFtext">Acceso no autorizado</h1>
            <Link id='rdc_p' to="/" replace={true}>Ir al Dashboard</Link>
        </div>);
    }
} export default NotFoundPage;