import './detalle_prestamo.css'
import Prestamo from './prestamo'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get_prestamo_especifico_route } from '../../constants/backend_routes'
import { Button } from 'primereact/button'

function PrestamoEspecifico(){
    const {clave_prestamo} = useParams()
    const [loanData, setLoanData] = useState()
    const [loan, setLoan] = useState()
    const [payments, setPayments] = useState()
    
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${get_prestamo_especifico_route}/${clave_prestamo}`)
            const responseJson = await response.json()
            setLoanData(responseJson.prestamo)
            setLoan({
                clave: clave_prestamo,
                partida: responseJson.prestamo.partida,
                cantidad: responseJson.prestamo.cantidad,
                capital_faltante: responseJson.prestamo.capital_faltante,
                dia_pago: responseJson.prestamo.dia_pago,
                duracion: responseJson.prestamo.duracion,
                estado: responseJson.prestamo.estado,
                fecha_inicio: responseJson.prestamo.fecha_inicio,
                interes_faltante: responseJson.prestamo.interes_faltante,
                mora_faltante: responseJson.prestamo.mora_faltante,
                persona: responseJson.prestamo.persona
            })
            setPayments(responseJson.prestamo.pagos)
        }
        fetchData()
    }, [])

    return(
        <div className='loan__detail container'>
            { loan && loanData && <Prestamo loan={loan} paymentCards={payments}/>}
        </div>
    )
}

export default PrestamoEspecifico