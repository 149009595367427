import './lbsalarios.css';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import TablaSalarios from './tabla_salarios';
import { requestOptions } from '../misc/requestOptions';

function LibroSalarios(props) {
    const navigate = useNavigate();

    const { ptd, plz, reg } = useParams();

    const [lbl_breadcrumb, setlLbl_breadcrumb] = useState(null);
    let items = [
        { label: 'NÓMINA', command: () => { navigate("/nominas") } },
        { label: 'LIBRO DE SALARIOS', command: () => { navigate("/nominas/libro-salarios") } },
        { label: 'SELECCIONAR PERSONA', command: () => { navigate("/nominas/libro-salarios") } }
    ].concat(lbl_breadcrumb ? { label: lbl_breadcrumb } : []);
    const home = { icon: 'pi pi-home', command: () => { navigate("/") } };

    const toast = useRef(null);

    const [idpersona, setIdPersona] = useState(null);
    const [registro, setRegistro] = useState('');
    const [partida, setPartida] = useState('');
    const [plaza, setPlaza] = useState('');
    const [numero_dpi, setDPI] = useState('');
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (ptd && plz && reg) {
            setPartida(ptd);
            setPlaza(plz);
            setRegistro(reg);
            request_data(ptd, plz, reg);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ptd, plz, reg]);

    const request_data = async (_ptd = null, _plz = null, _reg = null) => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL_CARGAR_LIBRO_SALARIOS,
            requestOptions('POST', {
                partida: _ptd || partida,
                plaza: _plz || plaza,
                registro: _reg || registro,
                busqueda: 'ppr'
            }))
            .then((response) => response.json())
            .then((data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                const x = data.data;
                if (x && x.datos_persona) {
                    setIdPersona(x.datos_persona.idpersona);
                    setNombres(x.datos_persona.nombres);
                    setApellidos(x.datos_persona.apellidos);
                    setDPI(x.datos_persona.numero_dpi);
                    setlLbl_breadcrumb(registro);
                }
                if (x && x.historial) {
                    setData(x.historial);
                }
                setLoading(false);
            });
    }

    const reset_form = () => {
        setIdPersona(null);
        setPartida('');
        setPlaza('');
        setRegistro('');
        setNombres('');
        setApellidos('');
        setDPI('');
        setLoading(false);
        setData(null);
        setlLbl_breadcrumb(null);
        navigate('/nominas/libro-salarios');
    }

    const generarCertificacion = async (fileType) => {
        setLoading(idpersona);
        try {
            const response = await fetch(process.env.REACT_APP_API_URL_CERTIFICACIONES + '/nominal',
                requestOptions('POST', { idpersona: idpersona, fileType: fileType }));
            if (!response.ok) throw new Error('No fue posible generar la certificación.');
            const pdfBlob = await response.blob();
            // Crear un objeto URL a partir del Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);
            // Crear un enlace temporal para descargar el PDF
            // const date = formatDate(new Date(), '-') || '';
            const a = document.createElement('a');
            a.href = pdfUrl;
            a.download = `Cert_Nominal`;
            a.click();
            // Liberar la URL del objeto Blob
            URL.revokeObjectURL(pdfUrl);
            toast.current.show({ severity: 'success', summary: 'Constancia generada.' });
        } catch (error) {
            console.error('Error al descargar el PDF.');
            toast.current.show({ severity: 'error', summary: error.toString() });
        }
        setLoading(false);
    }

    const select_tipo_archivo = (id) => {
        confirmPopup({
            target: document.getElementById(id),
            message: 'Seleccione tipo de archivo',
            icon: 'pi pi-info-circle',
            acceptClassName: 'pdf_btn_report',
            acceptIcon: 'pi pi-file-pdf',
            rejectClassName: 'word_btn_report',
            rejectIcon: 'pi pi-pencil',
            acceptLabel: 'PDF',
            rejectLabel: 'Word',
            accept: () => { generarCertificacion('pdf') },
            reject: () => { generarCertificacion('docx') }
        });
    }

    return (
        <div className="menu__lbsalarios">
            <Toast ref={toast}></Toast>
            <BreadCrumb model={items} home={home} />
            <Fieldset className='fieldset__lbsalarios' legend="Búsqueda por partida, plaza y registro" toggleable={false} >
                <div className="grid p-fluid mt-1">
                    <div className="field col-12 md:col-10 col_toggle">
                        {/* PARTIDA */}
                        <div className="grid p-fluid">
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText disabled={data} type="text" keyfilter="pint" value={partida} onChange={(e) => setPartida(e.target.value)} />
                                        <label>Partida</label>
                                    </span>
                                </div>
                            </div>
                            {/* PLAZA */}
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText disabled={data} type="text" keyfilter="pint" value={plaza} onChange={(e) => setPlaza(e.target.value)} />
                                        <label >Plaza</label>
                                    </span>
                                </div>
                            </div>
                            {/* REGISTRO */}
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText disabled={data} type="text" keyfilter="pint" value={registro} onChange={(e) => setRegistro(e.target.value)} />
                                        <label >Registro</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field col-12 md:col-2 consulta_botones pt-0">
                        <Button type='button' disabled={!partida || !plaza || !registro} className='p-button-rounded p-button-raised' icon="pi pi-search" loading={loading === true} onClick={() => request_data()} tooltip='Consultar' />
                        <Button type='button' className='p-button-rounded p-button-secondary p-button-raised' icon="pi pi-replay" onClick={reset_form} tooltip='Nueva búsqueda' />
                    </div>

                    {
                        data &&
                        <>
                            <div className="field col-12 md:col-10 mb-0">
                                <div className="grid p-fluid">
                                    {/* NOMBRES */}
                                    <div className="field col-12 md:col-4 mb-0">
                                        <div className="p-inputgroup" >
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-user"></i>
                                            </span>
                                            <span className="p-float-label">
                                                <InputText type="text" disabled={true} value={nombres} onChange={(e) => setNombres(e.target.value.toUpperCase())} />
                                                <label >Nombres</label>
                                            </span>
                                        </div>
                                    </div>
                                    {/* APELLIDOS */}
                                    <div className="field col-12 md:col-4 mb-0">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-user"></i>
                                            </span>
                                            <span className="p-float-label">
                                                <InputText type="text" disabled={true} value={apellidos} onChange={(e) => setApellidos(e.target.value.toUpperCase())} />
                                                <label >Apellidos</label>
                                            </span>
                                        </div>
                                    </div>
                                    {/* DPI */}
                                    <div className="field col-12 md:col-4 mb-0">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-id-card"></i>
                                            </span>
                                            <span className="p-float-label">
                                                <InputText type="text" disabled={true} value={numero_dpi} onChange={(e) => setDPI(e.target.value)} />
                                                <label >Número de DPI</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="field col-12 md:col-2 certificacion">
                                <Button id='btn_cert_nominal' type='button' className='p-button-rounded' icon="pi pi-download" tooltip='Descargar' loading={loading === idpersona}
                                    style={{ backgroundColor: '#930001', border: 'none' }} label='Certificación' disabled={!props.certificaciones} onClick={() => select_tipo_archivo('btn_cert_nominal')} />
                                <ConfirmPopup />
                            </div>
                        </>
                    }
                </div>
            </Fieldset>
            {
                data &&
                <TablaSalarios toast={toast} historial={data} certificaciones={props.certificaciones} certificacion_prestaciones={props.certificacion_prestaciones} idpersona={idpersona} />
            }
        </div>
    );
}

export default LibroSalarios;
