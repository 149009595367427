import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'jspdf-autotable';
import { requestOptions } from '../misc/requestOptions';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

function Variaciones(props) {

    const { id } = useParams();
    const navigate = useNavigate();
    const { estadoNomina, setActiveIndex, toast } = props;
    const [displayDialog, setDisplay] = useState(false);
    const [loading, setLoading] = useState(false);
    const [variaciones, setVariaciones] = useState([]);

    /* Filters */
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(20);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'registro': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'tipo': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    }
    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }
    const pg_template = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 20, value: 20 },
                { label: 100, value: 100 },
                { label: 'Todos', value: variaciones ? variaciones.length : 0 }
            ];
            return (
                <React.Fragment>
                    <span className="mx-0">Elementos por página: </span>
                    <Dropdown className="mr-4 table__paginator" value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        'CurrentPageReport': (options) => {
            return (
                <span style={{ width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} de {options.totalRecords}
                </span>
            )
        }
    };

    const hide = () => {
        setDisplay(false);
        get_variaciones();
        // props.setActiveIndex();
    };

    const get_variaciones = () => {
        setLoading(true);
        fetch((process.env.REACT_APP_API_URL_VARIACIONES_NOMINA),
            requestOptions('POST', { idnomina: id }))
            .then((response) => response.json())
            .then((data) => {
                if (data.message || data.error)
                    toast.current.show({ severity: data.severity, summary: data.message || data.error });
                data.data && setVariaciones(data.data);
                setLoading(false);
            });
    }

    useEffect(() => {
        if (estadoNomina === 1)
            setDisplay(true);
        else
            get_variaciones();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dialog_footer = (
        <div>
            <Button label="Sí" icon="pi pi-check" onClick={() => navigate('/jubilados')} />
            <Button label="No" icon="pi pi-times" onClick={hide} />
        </div>
    );
    const header_var = (
        <div className="table-header flex">
            <div className='my-auto'>Registro de variaciones</div>
            <div className='ml-auto'>
                <span className="p-input-icon-right">
                    <i className="pi pi-search" />
                    <InputText style={{ width: '100%' }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Buscar" />
                </span>
            </div>
        </div>
    );

    const nextStep = () => {
        const className = `p-button-rounded p-button-raised font-weight-bold`;
        if (estadoNomina === 1)
            return <Button onClick={setActiveIndex} className={className} icon='pi pi-arrow-right' iconPos='right' label='Realizar carga' />
        return <Button onClick={() => navigate("/nominas")} className={className} icon='pi pi-check-circle' label='Regresar a Menú Nóminas' />
    };

    const tableVariaciones = (
        <div style={{ maxWidth: '1000px', margin: 'auto' }}>
            {
                loading ?
                    <ProgressBar mode="indeterminate" style={{ height: '8px', marginTop: '200px' }} />
                    :
                    <>
                        <DataTable value={variaciones} header={header_var}
                            paginator paginatorTemplate={pg_template} first={first} rows={rows} onPage={onCustomPage} paginatorClassName="justify-content-end"
                            filters={filters} globalFilterFields={['registro', 'nombre', 'tipo']}
                            selectionMode='single' emptyMessage="Sin resultados." className="mt-6">
                            <Column alignHeader={'center'} field="tipo" header="Concepto" ></Column>
                            <Column align={'center'} bodyStyle={{ minWidth: '100px', padding: '0' }} field="fecha" header="Fecha" ></Column>
                            <Column align={'center'} bodyStyle={{ minWidth: '50px', padding: '0' }} field="registro" header="Registro" ></Column>
                            <Column alignHeader={'center'} field="nombre" header="Nombre" ></Column>
                            <Column alignHeader={'center'} bodyStyle={{ whiteSpace: 'nowrap' }} field="variacion" header="Variación" ></Column>
                        </DataTable>
                        <div className='btn__nextSetp'>
                            {nextStep()}
                        </div>
                    </>
            }
        </div>
    );

    return (
        <div className="container_variaciones">
            <Dialog header="Variaciones de nómina" visible={displayDialog} style={{ width: 'fit-content' }}
                footer={dialog_footer} resizable={false} draggable={false} onHide={hide} >
                ¿Desea registrar alzas, bajas o modificaciones de cuenta para la presente nómina?
            </Dialog>
            {!displayDialog && tableVariaciones}
        </div>
    );
}

export default Variaciones;
