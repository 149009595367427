import './historial.css';
import { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';

import { estadoChequeItem, estadoCuotaItem } from './constants';
import { headerForm, mostrarError } from '../constants/bodiesYFiltos';
import { SeleccionarTrabajador } from '../constants/seleccionar_trabajador';
import { editarRegistro } from '../endpoints/historial';

export default function EditarRegistro() {
    // Datos trabajador
    const [registro, setRegistro] = useState(null);
    const [selectedTrabajador, setSelectedTrabajador] = useState({ CUI: '', Registro: '', Nombre: '' });

    // Datos de la cuota
    const [idCuota, setIdCuota] = useState(null);
    const [nomina, setNomina] = useState(null);
    const [partida, setPartida] = useState(null);
    const [sueldo, setSueldo] = useState(null);
    const [numCheque, setNumCheque] = useState(null);
    const [fechaCheque, setFechaCheque] = useState(null);
    const [chequeNulo, setChequeNulo] = useState(0);
    const [plaza, setPlaza] = useState(null);
    const [cuota, setCuota] = useState(null);
    const [codCuota, setCodCuota] = useState(24);
    const [idEstadoCuota, setIdEstadoCuota] = useState(1);

    const toast = useRef(null);

    useEffect(() => {
        // obtener el registro seleccionado
        var seleccionado = JSON.parse(sessionStorage.getItem('registro_seleccionado'));
        setIdCuota(seleccionado.idCuota);
        // obtener datos del trabajador
        setRegistro(seleccionado.Registro);
        // llenar los datos en el formulario
        setNomina(seleccionado.Nomina);
        setPartida(seleccionado.Partida);
        setSueldo(seleccionado.Sueldo);
        setNumCheque(seleccionado.NumCheque);
        setFechaCheque(new Date(seleccionado.FechaCheque));
        setChequeNulo(seleccionado.Nulo);
        setPlaza(seleccionado.Plaza);
        setCuota(seleccionado.AfiliaPlan);
        setCodCuota(seleccionado.CodCuota);
        setIdEstadoCuota(seleccionado.idEstadoCuota);
    }, []);

    function EditarCuota() {
        if (!selectedTrabajador.Registro) {
            alert("Seleccione un trabajador");
            return;
        }
        var obj = {
            idCuota: idCuota,
            Registro: selectedTrabajador.Registro,
            Nomina: nomina,
            Partida: partida,
            Sueldo: sueldo,
            NumCheque: numCheque,
            FechaCheque: fechaCheque,
            ChequeNulo: chequeNulo,
            Plaza: plaza,
            Cuota: cuota,
            CodCuota: codCuota,
            idEstadoCuota: idEstadoCuota
        }

        editarRegistro(obj).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                sessionStorage.setItem('recargar_historial', true);
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err);
        });
    }

    const confirm = (event) => {
        event.preventDefault();
        EditarCuota();
    }

    return (
        <div className='primario-crear-registro'>
            <Toast ref={toast} />
            <div className="grid p-fluid">
                <div className='col-12 md:col-6'>
                    <SeleccionarTrabajador
                        selectedTrabajador={selectedTrabajador}
                        setSelectedTrabajador={setSelectedTrabajador}
                        registroTrabajador={registro}
                    />
                </div>
                <div className='col-12 md:col-6'>
                    <Card header={headerForm("Datos de la cuota")} className="card">
                        <form onSubmit={confirm} noValidate={false}>
                            <div className="grid p-fluid mt-1">
                                {/* NOMINA */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Nomina *</label>
                                    <InputNumber required
                                        value={nomina} onValueChange={(e) => setNomina(e.value)}
                                        mode="decimal" showButtons min={0} useGrouping={false} />
                                </div>
                                {/* PARTIDA */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Partida *</label>
                                    <InputNumber required
                                        value={partida} onValueChange={(e) => setPartida(e.value)}
                                        mode="decimal" showButtons min={0} useGrouping={false} />
                                </div>
                            </div>
                            <div className="grid p-fluid mt-1">
                                {/* SUELDO */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Sueldo (Q) *</label>
                                    <InputNumber required
                                        value={sueldo} onValueChange={(e) => setSueldo(e.value)}
                                        minFractionDigits={2} maxFractionDigits={2} showButtons min={0} />
                                </div>
                                {/* NUM. CHEQUE */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Número de Cheque *</label>
                                    <InputNumber required
                                        value={numCheque} onValueChange={(e) => setNumCheque(e.value)}
                                        mode="decimal" showButtons min={0} useGrouping={false} />
                                </div>
                            </div>
                            <div className="grid p-fluid mt-1">
                                {/* FECHA CHEQUE */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Fecha del Cheque *</label>
                                    <Calendar required value={fechaCheque}
                                        onChange={(e) => setFechaCheque(e.target.value)} showButtonBar showIcon />
                                </div>
                                {/* CHEQUE NULO? */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Cheque Nulo? *</label>
                                    <Dropdown
                                        value={chequeNulo}
                                        options={[0, 1]}
                                        itemTemplate={estadoChequeItem}
                                        valueTemplate={estadoChequeItem}
                                        showClear
                                        placeholder="Es un cheque nulo?"
                                        onChange={(e) => setChequeNulo(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="grid p-fluid mt-1">
                                {/* PLAZA */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Plaza *</label>
                                    <InputNumber required
                                        value={plaza}
                                        onValueChange={(e) => setPlaza(e.value)}
                                        mode="decimal" showButtons min={0} useGrouping={false} />
                                </div>
                                {/* CUOTA */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Cuota (Q) *</label>
                                    <InputNumber required
                                        value={cuota}
                                        onValueChange={(e) => setCuota(e.value)}
                                        minFractionDigits={2} maxFractionDigits={2} showButtons min={0} />
                                </div>
                            </div>
                            <div className="grid p-fluid mt-1">
                                {/* CODIGO DE CUOTA */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Código de cuota *</label>
                                    <Dropdown
                                        required
                                        value={codCuota}
                                        options={[14, 24]}
                                        placeholder="Código de cuota"
                                        showClear
                                        onChange={(e) => {
                                            setCodCuota(e.target.value);
                                            setIdEstadoCuota(e.target.value === 14 ? 4 : 1);
                                        }}
                                    />
                                </div>
                                {/* ESTADO DE LA CUOTA */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Estado de la cuota *</label>
                                    <Dropdown
                                        required={true}
                                        value={idEstadoCuota}
                                        options={[1, 2, 3, 4]}
                                        placeholder="Estado de la cuota"
                                        itemTemplate={estadoCuotaItem}
                                        valueTemplate={estadoCuotaItem}
                                        showClear
                                        onChange={(e) => setIdEstadoCuota(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* BOTONES */}
                            <br />
                            <div className="grid p-fluid">

                                <div className='col-5' style={{ marginLeft: 'auto', marginRight: '0' }}>
                                    <Button type='submit' icon="pi pi-check"
                                        label="Guardar Cambios" className='p-button-success' />
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        </div>
    );
}
