import './header.css';
// import usac_logo from '../img/usac_logo.png'
import gif_logo from '../img/gif_logo.gif'
import gif_nominas from '../img/gif_nominas.gif'
import gif_gestion_datos from '../img/gif_gestion_datos.gif'
import gif_moras from '../img/gif_moras.gif'
import gif_cuotas from '../img/gif_cuotas.gif'

function Header(props) {
    return (
        <div className='container__header' style={{ marginTop: '' }}>
            {props.logo &&
                <div className='logo__header' >
                    <img src={gif_logo} alt='logo' style={{ height: '350px', marginBottom: '5px', marginTop: '15px' }} />
                </div>
            }
            {
                props.nominas &&
                <div className='nominas__header' >
                    <img src={gif_nominas} alt='nominas' style={{ height: '140px', marginBottom: '0px', marginTop: '5px' }} />
                </div>
            }
            {
                props.gestion_datos &&
                <div className='gestion__header' >
                    <img src={gif_gestion_datos} alt='gestion_datos' style={{ height: '145px', marginBottom: '0px', marginTop: '0px' }} />
                </div>
            }
            {
                props.cuotas &&
                <div className='cuotas__header' >
                    <img src={gif_cuotas} alt='cuotas' style={{ height: '136px', marginBottom: '0px', marginTop: '5px' }} />
                </div>
            }
            {
                props.moras &&
                <div className='moras__header' >
                    <img src={gif_moras} alt='moras' style={{ height: '136px', marginBottom: '0px', marginTop: '5px' }} />
                </div>
            }
            <div className='text__header' >
                {props.title}
                {props.subtitle}
            </div>
            {props.hr ?
                <hr id='hr_header' />
                : <></>
            }
        </div >
    );
}

export default Header;
