const host = process.env.REACT_APP_API_URL_MORAS;

export const login_route = `${host}/login`
export const get_user_type = `${host}/get_user_type`
export const sign_up_route = `${host}/signup`
export const modify_permission_route = `${host}/modify_permissions`
export const new_loan_route = `${host}/new_loan`
export const update_loan_route = `${host}/modify_loan`
export const new_payment_route = `${host}/new_payment`
export const new_person_route = `${host}/new_person`
export const get_persona_route = `${host}/get_persona`
export const get_prestamos_route = `${host}/get_prestamos`
export const get_pagos_route = `${host}/get_pagos`
export const get_bitacoras_route = `${host}/get_bitacoras`
export const get_persona_especifica_route = `${host}/get_persona_especificia`
export const update_persona_route = `${host}/modify_person`
export const massive_loans_route = `${host}/loans_massive_load`
export const massive_loans_inmediatos_route = `${host}/loans_massive_load_inmediatos`
export const get_moras_route = `${host}/get_reporte_moras`
export const get_prestamo_especifico_route = `${host}/get_prestamo_especifico`
export const get_persona_nombre_registro_route = `${host}/get_persona_nombre_registro`
export const get_prestamo_clave_persona_route = `${host}/get_prestamo_clave_persona`
export const get_delay_tables_route = `${host}/get_delay_tables`
export const get_counts_loans_route = `${host}/get_counts_loans`
export const get_balance_route = `${host}/get_balance`
export const get_comments_report = `${host}/monthly_comments_report`
export const get_comments_report_person = `${host}/person_comments_report`
export const get_payment_requirements_route = `${host}/payment_requirements`
export const attend_payment_requirement_route = `${host}/attend_payment_requirement`
export const next_payment_requirement_route = `${host}/next_payment_requirement`
export const obtain_requirements_route = `${host}/obtain_requirements`
export const update_payment_requirements_massive_route = `${host}/update_payment_requirements_massive`
export const get_graphs_route = `${host}/get_graphs`
export const loans_massive_load_baja = `${host}/loans_massive_load_baja`