import './cuentas.css';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import get_bancos from '../../modulo_nominas/misc/get_bancos';
import { requestOptions } from '../../modulo_nominas/misc/requestOptions';

function CuentasPersona() {
    const [idpersona, setIdPersona] = useState(null);
    const [numero_cuenta, setNumeroCuenta] = useState('');
    const [idbanco, setIdBanco] = useState(null);
    const [partida, setPartida] = useState('');
    const [plaza, setPlaza] = useState('');
    const [registro, setRegistro] = useState('');
    const [numero_dpi, setDPI] = useState('');
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [observacion, setObservacion] = useState('');
    const [data, setData] = useState(null);
    const [cuentaDialog, setCuentaDialog] = useState(false);

    const [bancos_list, setBancos] = useState([]);
    async function fetchBancos() {
        const tmp = await get_bancos('dropdown');
        setBancos(tmp);
    }

    const reset_form = () => {
        setIdPersona('');
        setNumeroCuenta('');
        setIdBanco(null);
        setPartida('');
        setPlaza('');
        setRegistro('');
        setDPI('');
        setNombres('');
        setApellidos('');
        setObservacion('');
        setData(null);
    }

    const reset_dialog = () => {
        setCuentaDialog(false);
        setNumeroCuenta('');
        setIdBanco(null);
        setObservacion('');
    }

    const toast = useRef(null);
    const [loading, setLoading] = useState(false);

    const request_data = (showMessage = true) => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL_CUENTAS,
            requestOptions('POST', {
                partida: partida,
                plaza: plaza,
                registro: registro,
                busqueda: 'ppr'
            }))
            .then((response) => response.json())
            .then((data) => {
                if (showMessage)
                    toast.current.show({ severity: data.severity, summary: data.message || data.error });
                const x = data.data;
                if (x) {
                    setIdPersona(x.idpersona);
                    setNombres(x.nombres);
                    setApellidos(x.apellidos);
                    setDPI(x.numero_dpi);
                    setData(x.cuentas || []);
                }
                setLoading(false);
            });
    }


    const add_cuenta = () => {
        fetch(process.env.REACT_APP_API_URL_CUENTAS + '/insert_cuenta',
            requestOptions('POST', {
                idpersona: idpersona,
                numero_cuenta: numero_cuenta,
                idbanco: idbanco,
                observacion: observacion
            }))
            .then((response) => response.json())
            .then((data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                reset_dialog();
                request_data(false);
            });
    }

    const header = () => {
        return (
            <div className="flex">
                <p className='my-auto'>Registro de cuentas</p>
                <Button type='button' label="Agregar cuenta" icon="pi pi-plus" iconPos='right' severity='warning' raised outlined className="ml-auto font-weight-bold" onClick={async () => { await fetchBancos(); setCuentaDialog(true) }} />
            </div>
        )
    }

    const dialog_footer = () => {
        return <div>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={reset_dialog} />
            <Button disabled={!idbanco || (idbanco !== 3 && !numero_cuenta)} label="Guardar" icon="pi pi-check" className="p-button-text" onClick={add_cuenta} />
        </div>
    }

    const statusCuenta = (rowData) => {
        return <span className={`status-badge-nom status-${rowData.status.toLowerCase()}`}>{rowData.status}</span>;
    }

    return (
        <div className='container__cuentas'>
            <Toast ref={toast}></Toast>
            <Fieldset className='fieldset__cuentas' legend="Búsqueda por partida, plaza y registro" toggleable >
                <div className="grid p-fluid mt-2">
                    <div className="field col-12 md:col-10 col_toggle">
                        {/* PARTIDA */}
                        <div className="grid p-fluid">
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText disabled={data} type="text" keyfilter="pint" value={partida} onChange={(e) => setPartida(e.target.value)} />
                                        <label>Partida</label>
                                    </span>
                                </div>
                            </div>
                            {/* PLAZA */}
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText disabled={data} type="text" keyfilter="pint" value={plaza} onChange={(e) => setPlaza(e.target.value)} />
                                        <label >Plaza</label>
                                    </span>
                                </div>
                            </div>
                            {/* REGISTRO */}
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText disabled={data} type="text" keyfilter="pint" value={registro} onChange={(e) => setRegistro(e.target.value)} />
                                        <label >Registro</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field col-12 md:col-2 consulta_botones pt-0">
                        <Button type='button' disabled={!partida || !plaza || !registro} className='p-button-rounded p-button-warning' icon="pi pi-search" loading={loading} onClick={request_data} tooltip='Consultar' />
                        <Button type='button' className='p-button-rounded p-button-secondary' icon="pi pi-replay" onClick={reset_form} tooltip='Limpiar búsqueda' />
                    </div>

                    {
                        data &&
                        <>
                            {/* NOMBRES */}
                            <div className="field col-12 md:col-4">
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" disabled={true} value={nombres} onChange={(e) => setNombres(e.target.value.toUpperCase())} />
                                        <label >Nombres</label>
                                    </span>
                                </div>
                            </div>
                            {/* APELLIDOS */}
                            <div className="field col-12 md:col-4">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" disabled={true} value={apellidos} onChange={(e) => setApellidos(e.target.value.toUpperCase())} />
                                        <label >Apellidos</label>
                                    </span>
                                </div>
                            </div>
                            {/* DPI */}
                            <div className="field col-12 md:col-4">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-id-card"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" disabled={true} value={numero_dpi} onChange={(e) => setDPI(e.target.value)} />
                                        <label >Número de DPI</label>
                                    </span>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Fieldset>
            {
                data &&
                <div className="card__cuentas">
                    <DataTable value={data} header={header} style={{ borderRadius: '1em' }}
                        selectionMode='single' emptyMessage="No se encontró ningún registro."  >
                        <Column field="idcuenta" header="ID"></Column>
                        <Column field="numero_cuenta" header="Número de cuenta" ></Column>
                        <Column field="banco" header="Banco" ></Column>
                        <Column field="fecha_agregada" header="Fecha agregada" ></Column>
                        <Column field="observacion" header="Observación" ></Column>
                        <Column header="Estado" body={statusCuenta}></Column>
                    </DataTable>
                    <Dialog visible={cuentaDialog} style={{ width: '400px' }} header="Nueva cuenta" modal className="p-fluid" footer={dialog_footer} onHide={reset_dialog}>
                        <div className="field">
                            <label htmlFor="numberc">Número de cuenta</label>
                            <InputText disabled={idbanco === 3} id="numberc" type="text" keyfilter={/[\d-]/} value={numero_cuenta} onChange={(e) => setNumeroCuenta(e.target.value)} required autoFocus />
                        </div>
                        <div className="field">
                            <label htmlFor="bancoi">Forma de pago</label>
                            <Dropdown id='bancoi' required value={idbanco} options={bancos_list} onChange={(e) => { setIdBanco(e.value); if (e.value === 3) setNumeroCuenta(''); }} placeholder="Seleccione banco" />
                        </div>
                        <div className="field">
                            <label htmlFor="obserc">Observación</label>
                            <InputText maxLength={75} id="obserc" type="text" value={observacion} onChange={(e) => setObservacion(e.target.value.toUpperCase())} />
                        </div>
                    </Dialog>
                </div>
            }

        </div>
    );
}

export default CuentasPersona;
