import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';

// ------------------------------------------------- BODIES
export const estadoChequeBody = (rowData) => {
    if (rowData.Nulo === 1) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='SÍ' severity='danger' />
        </center>;
    } else if (rowData.Nulo === 0) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='NO' severity='success' />
        </center>;
    } else {
        return 'Seleccionar'
    }
};

export const estadoCuotaBody = (rowData) => {
    if (rowData.idEstadoCuota === 1) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='Completa' severity='success' />
        </center>;
    } else if (rowData.idEstadoCuota === 2) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='Incompleta' severity='warning' />
        </center>;
    } else if (rowData.idEstadoCuota === 3) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='Ausente' severity='danger' />
        </center>;
    } else if (rowData.idEstadoCuota === 4) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='No Aplica' severity='secondary' />
        </center>;
    } else {
        return 'Seleccionar'
    }
};

export const estadoChequeFilter = (options) => {
    return <Dropdown value={options.value} options={[0, 1]}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={estadoChequeItem} placeholder="Cualquiera" className="p-column-filter"
        showClear valueTemplate={estadoChequeItem} />;
};

export const estadoCuotaFilter = (options) => {
    return <Dropdown value={options.value} options={[1, 2, 3, 4]}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={estadoCuotaItem} placeholder="Cualquiera" className="p-column-filter"
        showClear valueTemplate={estadoCuotaItem} />;
};

export const estadoChequeItem = (option) => {
    if (option === 1) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='Sí' severity='danger' />
        </center>;
    } else if (option === 0) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='No' severity='success' />
        </center>;
    } else {
        return 'Seleccionar'
    }
};

export const estadoCuotaItem = (option) => {
    if (option === 1) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='Completa' severity='success' />
        </center>;
    } else if (option === 2) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='Incompleta' severity='warning' />
        </center>;
    } else if (option === 3) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='Ausente' severity='danger' />
        </center>;
    } else if (option === 4) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='No Aplica' severity='secondary' />
        </center>;
    } else {
        return 'Seleccionar'
    }
};

export const codCuotaFilter = (options) => {
    return <Dropdown value={options.value} options={[14, 24]}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Cualquiera" className="p-column-filter"
        showClear />;
};
// ------------------------------------------------- FUNCIONES
export const obtenerAnioMes = (fecha) => {
    var date = new Date(fecha);
    var mes, anio;

    anio = date.getFullYear();
    mes = date.getMonth() + 1;

    if (mes < 10) {
        mes = '0' + mes;
    }

    return { Anio: anio, Mes: mes }
};

// -------------------------------------------------- CARGAR ARCHIVOS
export const emptyTemplate = () => {
    return (
        <div className="flex align-items-center flex-column">
            <i className="pi pi-file mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
            <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                Arrastrar o seleccionar un archivo .xls
            </span>
        </div>
    );
};




