export const estadosCuota = [
    {
        'idEstadoCuota': 1,
        'Nombre': 'Completa'
    },
    {
        'idEstadoCuota': 2,
        'Nombre': 'Incompleta'
    },
    {
        'idEstadoCuota': 3,
        'Nombre': 'Ausente'
    },
    {
        'idEstadoCuota': 4,
        'Nombre': 'No Aplica'
    },
]

export const meses = [
    {
        nombre: 'Enero',
        numero: 1
    },
    {
        nombre: 'Febrero',
        numero: 2
    },
    {
        nombre: 'Marzo',
        numero: 3
    },
    {
        nombre: 'Abril',
        numero: 4
    },
    {
        nombre: 'Mayo',
        numero: 5
    },
    {
        nombre: 'Junio',
        numero: 6
    },
    {
        nombre: 'Julio',
        numero: 7
    },
    {
        nombre: 'Agosto',
        numero: 8
    },
    {
        nombre: 'Septiembre',
        numero: 9
    },
    {
        nombre: 'Octubre',
        numero: 10
    },
    {
        nombre: 'Noviembre',
        numero: 11
    },
    {
        nombre: 'Diciembre',
        numero: 12
    }
];