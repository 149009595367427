import './busqueda.css';
import { useNavigate } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import BuscarNomina from './busqueda';

function MenuBuscarNomina() {
    const navigate = useNavigate();

    const items = [
        { label: 'NÓMINA', command: () => { navigate("/nominas") } },
        { label: 'SELECCIONAR NÓMINA' }
    ];
    const home = { icon: 'pi pi-home', command: () => { navigate("/") } }

    return (
        <div className='buscar__route'>
            <BreadCrumb model={items} home={home} />
            <BuscarNomina estado={-1} />
        </div>
    );
}

export default MenuBuscarNomina;
