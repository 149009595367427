import ReactDOM from 'react-dom';
import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const MiModal = ({ informacion }) => {
    const [infoModal, setInfoModal] = useState(false);
    const [position, setPosition] = useState('center');

    const dialogFuncMap = {
        'infoModal': setInfoModal,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cerrar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Generar reporte" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
            </div>
        );
    }   

    return (
        <div>
            <Button label="Resumen" severity="info" onClick={() => onClick('infoModal')} />
            <Dialog header="Informacion" visible={infoModal} style={{ width: '50vw' }} footer={renderFooter('infoModal')} onHide={() => onHide('infoModal')}>
                <h4>Prestamo con codigo: {informacion.clave}</h4>
                <br/>
                <p>La persona <strong>{informacion.usuario}</strong> tiene a su cargo el prestamo <strong>{informacion.codigo}</strong>, el
                cual debe pagar en un plazo de <strong>{informacion.duracion}</strong>, siendo su siguiente mensualidad de <strong>{informacion.siguiente_cuota}</strong>,
                haciendo de este un prestamo de un total de <strong>{informacion.cantidad}</strong></p>
                <p>Actualmente la persona encargada de este prestamo, <strong>{informacion.mora_faltante > 0 ? 'Si' : 'No'}</strong> tiene saldos caidos en mora</p>
                <p>A esta persona le faltan pagar las siguientes cantidades:</p>
                <p>Capital: <strong>{informacion.capital_faltante}</strong></p>
                <p>Intereses: <strong>{informacion.interes_faltante}</strong></p>
                <p>Mora: <strong>{informacion.mora_faltante}</strong></p>
            </Dialog>
        </div>
    );
}

export default MiModal