import { useEffect, useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import buscarObjetoPorId from '../tools/buscarObjetoPorId';
import { estadosCuota } from './diccionarios';

export const BotonExportarTabla = (Endpoint, datos, tabla, nombreArchivo) => {
    const op = useRef(null);
    const [espacioFirmas, setEspacioFirmas] = useState(0);
    return (
        <>
            <Button type="button" className='p-button-help'
                icon="pi pi-print"
                label="Exportar"
                onClick={(e) => op.current.toggle(e)}
                tooltip='Exportar'
                tooltipOptions={{ position: 'bottom' }}
                size="small"
                style={{ marginRight: '2px' }} />
            <OverlayPanel ref={op} style={{ width: '25%' }}>
                <h6 style={{
                    width: 'fit-content', margin: 'auto',
                    marginBottom: '2%'
                }}>Seleccionar formato</h6>
                <div className="grid p-fluid" >
                    <div className='col-12 md:col-4' >
                        <Button type="button" label="PDF" severity='danger'
                            onClick={() =>
                                // ExportarTabla('pdf', Endpoint, datos, tabla, nombreArchivo, espacioFirmas)
                                ExportarTablaGrandePDF(datos, tabla, nombreArchivo)
                            } size='small' />
                    </div>
                    <div className='col-12 md:col-4' >
                        <Button type="button" label="XLSX" severity='success'
                            onClick={() =>
                                ExportarTabla('xlsx', Endpoint, datos, tabla, nombreArchivo, espacioFirmas)
                            } size='small' />
                    </div>
                    <div className='col-12 md:col-4'>
                        <Button type="button" label="CSV"
                            onClick={() =>
                                ExportarTabla('csv', Endpoint, datos, tabla, nombreArchivo, espacioFirmas)
                            } size='small' />
                    </div>
                </div>
                {/* <div className="grid p-fluid d-flex justify-content-center align-items-center my-2" >
                    <label>Bajar firmas</label>
                    <InputNumber value={espacioFirmas} style={{ width: '100px', margin: '0 5px 0 5px' }}
                        onValueChange={(e) => setEspacioFirmas(e.value)}
                        mode="decimal" showButtons min={0} useGrouping={false} />
                    <label>espacios </label>
                </div> */}
            </OverlayPanel>
        </>
    )
}

export const filtrarReporte = (datos, filtros) => {
    for (const key in filtros) {
        var valorFiltro = filtros[key]['constraints'][0]['value'];
        if (valorFiltro) {
            var del = datos.filter(
                dato => dato[key] && dato[key].toString().includes(valorFiltro)
            );
            datos = del;
        }
    }

    return datos;
};

const ExportarTabla = async (tipo, Endpoint, datos, tabla, nombreArchivo, espacioFirmas) => {
    const filtrosTabla = sessionStorage.getItem(tabla);
    if (filtrosTabla) {
        var datoos = filtrarReporte(datos, JSON.parse(filtrosTabla).filters);
        // 
        Endpoint({ Registros: datoos, Tipo: tipo, espacioFirmas: espacioFirmas }).then(async (resp) => {
            const pdfBlob = await resp.blob();
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(pdfBlob);
            link.target = '_blank';
            if (tipo === 'xlsx' || tipo === 'csv') {
                link.download = nombreArchivo + "." + tipo
            }
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            console.log(err);
            alert("Ocurrió un error :(");
        });
    }
}

export const BotonExportarReporte = ({ Endpoint, datos, nombreArchivo, size = "small",
    style = { position: 'absolute', zIndex: '2', right: '18px', marginTop: '2px' },
    ocultar = [], bajarFirmas = true
}) => {
    const op = useRef(null);
    const [espacioFirmas, setEspacioFirmas] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (windowWidth < 890 && style) {
        style.position = 'relative';
        style.right = '0';
    }

    return (
        <>
            <div style={windowWidth < 890 ? {
                width: '100%', position: 'relative', display: 'flex',
                justifyContent: 'right', marginBottom: '10px'
            } : null}>
                <Button type="button" className='p-button-help'
                    icon="pi pi-print"
                    label="Exportar"
                    onClick={(e) => op.current.toggle(e)}
                    tooltip='Exportar'
                    tooltipOptions={{ position: 'bottom' }}
                    size={size}
                    style={style} />
            </div>
            <OverlayPanel ref={op} style={{ width: '25%' }}>
                <h6 style={{
                    position: '',
                    width: 'fit-content', margin: 'auto',
                    marginBottom: '2%'
                }}>Seleccionar formato</h6>
                <div className="grid p-fluid d-flex justify-content-around" >
                    {!ocultar.includes('PDF') &&
                        <div className='col-12 md:col-4' >
                            <Button type="button" label="PDF" severity='danger'
                                onClick={() =>
                                    ExportarReporte('pdf', Endpoint, datos, nombreArchivo, espacioFirmas)
                                } size='small' />
                        </div>
                    }

                    {!ocultar.includes('XLSX') &&
                        <div className='col-12 md:col-4' >
                            <Button type="button" label="XLSX" severity='success'
                                onClick={() =>
                                    ExportarReporte('xlsx', Endpoint, datos, nombreArchivo, espacioFirmas)
                                } size='small' />
                        </div>
                    }

                    {!ocultar.includes('DOCX') &&
                        <div className='col-12 md:col-4'>
                            <Button type="button" label="DOCX"
                                onClick={() =>
                                    ExportarReporte('docx', Endpoint, datos, nombreArchivo, espacioFirmas)
                                } size='small' />
                        </div>
                    }
                </div>
                {bajarFirmas && <div className="grid p-fluid d-flex justify-content-center align-items-center my-2" >
                    <label>Bajar firmas</label>
                    <InputNumber value={espacioFirmas} style={{ width: '100px', margin: '0 5px 0 5px' }}
                        onValueChange={(e) => setEspacioFirmas(e.value)}
                        mode="decimal" showButtons min={0} useGrouping={false} />
                    <label>espacios </label>
                </div>}

            </OverlayPanel>
        </>
    )
}

const ExportarReporte = async (tipo, Endpoint, datos, nombreArchivo, espacioFirmas) => {
    datos['espacioFirmas'] = espacioFirmas;
    datos['Tipo'] = tipo;
    // 
    Endpoint(datos).then(async (resp) => {
        const pdfBlob = await resp.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.target = '_blank';
        if (tipo === 'xlsx' || tipo === 'docx') {
            link.download = nombreArchivo + "." + tipo
        }
        document.body.appendChild(link);
        link.click();
    }).catch(err => {
        console.log(err);
        alert("Ocurrió un error :(");
    });
}

const columnasHistorialPDF = [
    { header: 'Registro\nPersonal', dataKey: 'Registro' },
    { header: 'Nomina', dataKey: 'Nomina' },
    { header: 'Partida', dataKey: 'Partida' },
    { header: 'Sueldo', dataKey: 'Sueldo' },
    { header: 'Número\nde\ncheque', dataKey: 'NumCheque' },
    { header: 'Fecha\ndel\ncheque', dataKey: 'FechaCheque' },
    { header: 'Cheque\nNulo?', dataKey: 'Nulo' },
    { header: 'Plaza', dataKey: 'Plaza' },
    { header: 'Cuota(Q)', dataKey: 'AfiliaPlan' },
    { header: 'Estado\nde\nCuota', dataKey: 'idEstadoCuota' },
    { header: 'Código\nde\nCuota', dataKey: 'CodCuota' },
]

const columnasTrabajadoresPDF = [
    { header: 'Registro', dataKey: 'Registro' },
    { header: 'CUI', dataKey: 'CUI' },
    { header: 'Nombre', dataKey: 'Nombre' },
    { header: 'Genero', dataKey: 'Genero' },
    { header: 'FechaNacimiento', dataKey: 'FechaNacimiento' },
    { header: 'FechaIngreso', dataKey: 'FechaIngreso' },
    { header: 'EdadActual', dataKey: 'EdadActual' },
    { header: 'EdadIngreso', dataKey: 'EdadIngreso' },
    { header: 'Afiliacion', dataKey: 'Afiliacion' },
    { header: 'NombreEstado', dataKey: 'NombreEstado' },
]

const ExportarTablaGrandePDF = (datos, tabla, nombreArchivo) => {
    var filtrosTabla = sessionStorage.getItem(tabla);
    if (filtrosTabla) {
        filtrosTabla = JSON.parse(filtrosTabla).filters;
        const datosFiltrados = filtrarReporte(datos, filtrosTabla);
        // formatear datos
        const datosFormateados = formatDatosTablaPDF(datosFiltrados, tabla);
        // 
        const data = datosFormateados;
        const fname = nombreArchivo;
        const title = tabla === 'dt-historial' ? 'Historial de cuotas' : 'Registro de trabajadores';
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const encabezado = `Fecha: ${new Date().toLocaleString()}\n` +
                    `UNIVERSIDAD DE SAN CARLOS DE GUATEMALA\n` +
                    `PLAN DE PRESTACIONES\n`;
                const doc = new jsPDF.default({ orientation: 'landscape' });
                let textX = doc.internal.pageSize.getWidth() / 2; // Centrar horizontalmente
                let textY = 20; // Posición vertical
                doc.setFont('helvetica', 'bold'); // Establecer la fuente en negrita
                doc.text(title, textX, textY, { align: 'center' });
                doc.setFont('helvetica', 'normal'); // Restaurar el estilo de fuente normal
                textX = 15;
                textY = 35;
                doc.setFontSize(9);
                doc.text(encabezado, textX, textY, { align: 'left' });
                doc.autoTable({
                    columns: tabla === 'dt-historial' ? columnasHistorialPDF : columnasTrabajadoresPDF,
                    body: data,
                    startY: 50,
                    theme: 'striped',
                    margin: { left: 10 },
                });
                doc.setFontSize(12);
                doc.text(new Date().toLocaleString(), doc.internal.pageSize.getWidth() - 50, doc.internal.pageSize.height - 10);
                doc.save(fname + '.pdf');
            })
        })
    }
};

const formatDatosTablaPDF = (data, tabla) => {
    const newData = JSON.parse(JSON.stringify(data));
    if (tabla === 'dt-historial') {
        // formatear datos historial
        newData.forEach(item => {
            item.FechaCheque = item.FechaCheque ? new Date(item.FechaCheque).toLocaleDateString() : item.FechaCheque;
            item.Nulo = item.Nulo === 1 ? 'Sí' : 'No';
            item.idEstadoCuota = buscarObjetoPorId(estadosCuota, item.idEstadoCuota, 'idEstadoCuota').Nombre;
        });
    } else {
        // formatear datos trabajadores
        newData.forEach(item => {
            item.Genero = generoTrabajador(item.Genero);
            item.FechaNacimiento = item.FechaNacimiento ? new Date(item.FechaNacimiento).toLocaleDateString() : item.FechaNacimiento;
            item.FechaIngreso = item.FechaIngreso ? new Date(item.FechaIngreso).toLocaleDateString() : item.FechaIngreso;
            item.Afiliacion = item.Afiliacion === 1 ? 'Afiliado' : 'No Afiliado';
        });
    }
    return newData;
}

// funciones para formatear datos en listado trabajadores
function generoTrabajador(genero) {
    if (genero === 1) {
        return 'Masculino'
    } else if (genero === 0) {
        return 'Femenino'
    } else {
        return ''
    }
}
