import './busqueda_avanzada.css';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode } from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { requestOptions } from '../../modulo_nominas/misc/requestOptions';
import { useNavigate } from 'react-router-dom';
import { ContextMenu } from 'primereact/contextmenu';

function BusquedaAvanzada() {
    const [partida, setPartida] = useState('');
    const [plaza, setPlaza] = useState('');
    const [registro, setRegistro] = useState('');
    const [numero_dpi, setCUI] = useState('');
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [resultados, setResultados] = useState([]);

    // Para click derecho
    const navigate = useNavigate();
    const [ptd, setPtd] = useState(null);
    const [plz, setPlz] = useState(null);
    const [reg, setReg] = useState(null);
    const cm = useRef(null);
    const menuModel = [
        { label: 'Ir a libro de salarios', icon: 'pi pi-fw pi-book', command: () => navigate(`/nominas/libro-salarios/${ptd}/${plz}/${reg}`) },
        { label: 'Ir a descuentos', icon: 'pi pi-fw pi-sort-numeric-down', command: () => navigate(`/nominas/gestion-descuentos/${ptd}/${plz}/${reg}`) },
        { label: 'Consultar datos', icon: 'pi pi-fw pi-eye', command: () => navigate(`/jubilados/${ptd}/${plz}/${reg}`) }
    ];

    const reset_form = () => {
        setPartida('');
        setPlaza('');
        setRegistro('');
        setCUI('');
        setNombres('');
        setApellidos('');
        setResultados([]);
    }

    const toast = useRef(null);
    const [loading, setLoading] = useState(false);

    const search_request = () => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL_BUSQUEDA_AVANZADA,
            requestOptions('POST', {
                partida,
                plaza,
                registro,
                numero_dpi,
                nombres: nombres.replace(/ /g, "%"),
                apellidos: apellidos.replace(/ /g, "%")
            }))
            .then((response) => response.json())
            .then((data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                data.data && setResultados(data.data);
                setLoading(false);
            });
    }

    const disabled_search = () => {
        if (partida || plaza || registro || numero_dpi || nombres || apellidos)
            return false;
        return true;
    }

    /* Paginator */
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    /* Filters */
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    }
    const pg_template = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 }
            ];
            return (
                <React.Fragment>
                    <span style={{ userSelect: 'none', textAlign: 'right' }} className="mx-0">Resultados por página: </span>
                    <Dropdown className="mr-4 table__paginator" value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        'CurrentPageReport': (options) => {
            return (
                <span style={{ userSelect: 'none', width: '120px', textAlign: 'right' }}>
                    {options.first} - {options.last} de {options.totalRecords}
                </span>
            )
        }
    };
    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }

    const header = (
        <div className="table-header flex">
            <div className='my-auto'>Resultados de la búsqueda</div>
            <div className='ml-auto'>
                <span className="p-input-icon-right">
                    <i className="pi pi-search" />
                    <InputText style={{ width: '100%' }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Buscar" />
                </span>
            </div>
        </div>
    );

    const tooltipPension = (rowData) => {
        return <div>
            <Tooltip target={`#tooltip-${rowData.idpersona}`} autoHide={false}>
                {rowData.cuenta && <p className='m-0'>Cuenta: <span className='font-weight-bold'>{rowData.cuenta}</span></p>}
                <p className='m-0'>Pensión: <span className='font-weight-bold'>{rowData.pension}</span></p>
            </Tooltip>
            <span id={`tooltip-${rowData.idpersona}`} className={`status-badge-nom acred-${rowData.forma_pago.toLowerCase()[0]}`}>{rowData.forma_pago}</span>
        </div>
    }

    return (
        <div className='row busqueda_container'>
            <Toast ref={toast}></Toast>
            <ContextMenu model={menuModel} ref={cm} onHide={() => { setPtd(null); setPlz(null); setReg(null); }} />
            <div className="pr-0 col-3" style={{ width: 'fit-content' }} >
                {/* PARTIDA */}
                <div className="field col-12">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-search"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" keyfilter="pint" value={partida} onChange={(e) => setPartida(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter" && !disabled_search()) { search_request(); } }} />
                            <label>Partida</label>
                        </span>
                    </div>
                </div>
                {/* PLAZA */}
                <div className="field col-12">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-search"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" keyfilter="pint" value={plaza} onChange={(e) => setPlaza(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter" && !disabled_search()) { search_request(); } }} />
                            <label>Plaza</label>
                        </span>
                    </div>
                </div>
                {/* REGISTRO */}
                <div className="field col-12">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-search"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" keyfilter="pint" value={registro} onChange={(e) => setRegistro(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter" && !disabled_search()) { search_request(); } }} />
                            <label>Registro</label>
                        </span>
                    </div>
                </div>
                {/* CUI */}
                <div className="field col-12">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-search"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" keyfilter="pint" value={numero_dpi} onChange={(e) => setCUI(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter" && !disabled_search()) { search_request(); } }} />
                            <label>Número de DPI</label>
                        </span>
                    </div>
                </div>
                {/* NOMBRES */}
                <div className="field col-12">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-search"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" value={nombres} onChange={(e) => setNombres(e.target.value.toUpperCase())} onKeyDown={(e) => { if (e.key === "Enter" && !disabled_search()) { search_request(); } }} />
                            <label>Nombres</label>
                        </span>
                    </div>
                </div>
                {/* APELLIDOS */}
                <div className="field col-12">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-search"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" value={apellidos} onChange={(e) => setApellidos(e.target.value.toLocaleUpperCase())} onKeyDown={(e) => { if (e.key === "Enter" && !disabled_search()) { search_request(); } }} />
                            <label>Apellidos</label>
                        </span>
                    </div>
                </div>
                {/* CONFIRMAR */}
                <div className="btn_buscar_avanzado" >
                    <Button disabled={disabled_search()} loading={loading} onClick={search_request} type='button' icon="pi pi-search-plus" label="Buscar" className="font-weight-bold p-button-info" />
                    <Button disabled={loading} onClick={reset_form} type='button' icon="pi pi-times" label="Limpiar" className="font-weight-bold p-button-secondary" />
                </div>
            </div>
            <div className="col-9 tabla_resultados pr-0" >
                <div className="card w-100 ml-3" >
                    <DataTable value={resultados} header={header}
                        /* contextMenuSelection={selectedProduct} */
                        onContextMenuSelectionChange={(e) => { setPtd(e.value.partida); setPlz(e.value.plaza); setReg(e.value.registro); }}
                        onContextMenu={e => cm.current.show(e.originalEvent)}
                        stripedRows emptyMessage="Sin resultados."
                        paginator paginatorTemplate={pg_template} first={first} rows={rows} onPage={onCustomPage}
                        filters={filters} globalFilterFields={['partida', 'plaza', 'registro', 'nombre', 'numero_dpi']}
                        rowClassName={(e) => { return !e.estado && 'row-anulado' }}>
                        {/* <Column field="idpersona" header="ID"></Column> */}
                        <Column field="partida" header="Partida"></Column>
                        <Column field="plaza" header="Plaza" ></Column>
                        <Column field="registro" header="Registro" ></Column>
                        <Column field="nombre" header="Nombre" ></Column>
                        <Column field="numero_dpi" header="No. DPI" ></Column>
                        <Column body={tooltipPension} header="Pago" ></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
}

export default BusquedaAvanzada;
