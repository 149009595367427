import './constants.css'
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

import formatDate from '../tools/formatDate';
import buscarObjetoPorId from '../tools/buscarObjetoPorId';
import { meses } from './diccionarios';

// ------------------------------------------------- BODIES Y FILTROS TABLA
export const moneyFormat = (valor) => {
    if (valor) {
        try {
            valor = parseFloat(valor);
            var formattedNumber = valor;
            if (Number.isInteger(valor)) {
                formattedNumber = valor.toLocaleString('en-US');
            } else {
                formattedNumber = valor.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            }
            return formattedNumber;
        } catch (_) {
            return valor;
        }
    }
    return valor
}

export const moneyBody = (rowData, campo) => {
    if (rowData[campo]) {
        return moneyFormat(rowData[campo])
    }
    return rowData[campo]
};

export const fechaBody = (rowData, campo, conHora = false) => {
    return <>{formatDate(rowData[campo], conHora)}</>
};

// sirven para periodos
export const bodyMesInicio = (rowData) => {
    return <>{buscarObjetoPorId(meses, rowData.MesInicio, "numero")['nombre']}</>
};

export const bodyMesFinal = (rowData) => {
    return <>{buscarObjetoPorId(meses, rowData.MesFinal, "numero")['nombre']}</>
};

export const columnaConFiltros = (field, header, filter, filterPlaceholder) => {
    return <Column
        filter={filter}
        filterPlaceholder={filterPlaceholder}
        field={field}
        showFilterMatchModes={false}
        showAddButton={false}
        showFilterOperator={false}
        header={header}></Column>
}

export const containsFilter = { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }

// ------------------------------------------------- COMPONENTES
export const headerForm = (Titulo, regresar = false) => {
    return <>
        {regresar ?
            <div className='header-form-header'>
                <BotonRegresar />
                <div className='header-form-contenedor-titulo-regresar'>
                    <h2 className='header-form_titulo-regresar'
                    >{Titulo}</h2>
                </div>
                <div className='container-boton-regresar'></div>
            </div > :
            <h2 className='header-form_titulo'>{Titulo}</h2>
        }
    </>
}

export const BotonRegresar = ({ absolute = true }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return <>
        <div className={absolute ? 'col-3 container-boton-regresar' : 'col-2'}>
            {
                windowWidth < 1200 ?
                    <Button type='button' icon="pi pi-arrow-left" tooltip='Regresar'
                        className='p-button-rounded p-button-info boton-regresar'
                        onClick={() => window.history.back()} />
                    :
                    <Button type="button" icon="pi pi-arrow-left" label="Regresar"
                        className='p-button-info boton-regresar'
                        onClick={() => window.history.back()} />
            }
        </div>
    </>
}

// ------------------------------------------------- FUNCIONES
export const renderRedirect = (redirect, destino) => {
    if (redirect) {
        return <Navigate to={'/cuotas/' + destino} />
    }
}

export const filtrarReporte = (datos, filtros) => {
    for (const key in filtros) {
        var valorFiltro = filtros[key]['constraints'][0]['value'];
        if (valorFiltro) {
            var del = datos.filter(
                dato => dato[key] && dato[key].toString().includes(valorFiltro)
            );
            datos = del;
        }
    }

    return datos;
};

export const mostrarError = (error, accion = null, accion2 = null) => {
    console.error(error);
    // if (!localStorage.getItem('token')) {
    //     alert('La sesión ha expirado, por favor inicie sesión');
    //     sessionStorage.clear();
    //     localStorage.clear();
    //     window.location.replace('/login');
    //     return;
    // }
    alert("Ha ocurrido un error inesperado");
    if (accion) {
        accion();
    }
    if (accion2) {
        accion2();
    }
}

export const crearDate = (fecha) => {
    const fechaOriginal = new Date(fecha);
    fechaOriginal.setMinutes(fechaOriginal.getMinutes() + fechaOriginal.getTimezoneOffset());
    return fechaOriginal;
}

