import { Tag } from 'primereact/tag';
import formatDate from '../tools/formatDate';

// ------------------------------------------------- BODIES Y FILTROS TABLA
// crear_trabajador, editar_trabajador
const getSeverity = (status) => {
    switch (status) {
        case 1:
            return 'success';
        case 0:
            return 'danger';
    }
};

export const afiliacionItemTemplate = (option) => {
    return <Tag value={option === 1 ? 'Sí' : 'No'} severity={getSeverity(option)} />;
};

// contratos
export const afectaDescuentoBody = (rowData) => {
    return <center style={{ textTransform: 'uppercase' }}>
        {rowData.AfectaDescuento === 1 ?
            <Tag value='Sí' severity='success' />
            :
            <Tag value='No' severity='danger' />
        }
    </center>;
};

export const DepUniItemTemplate = (option) => {
    return !option ? "Seleccionar" :
        option.CodigoDependencia + " - " + option.Nombre;
};

export const FechasContratoBody = (rowData, campo) => {
    return rowData[campo] ?
        <>{formatDate(rowData[campo], false)}</>
        : <>No definida</>
};

// ------------------------------------------------- FUNCIONES
export function calcularEdadActual(fechaNacimiento) {
    if (!fechaNacimiento) {
        return null
    }
    const fechaActual = new Date();
    const edadActual = fechaActual.getFullYear() -
        fechaNacimiento.getFullYear() - (fechaActual.getMonth() < fechaNacimiento.getMonth() ||
            (fechaActual.getMonth() === fechaNacimiento.getMonth() && fechaActual.getDate() < fechaNacimiento.getDate()));
    return edadActual;
}

export function calcularEdadIngreso(fechaNacimiento, fechaIngreso) {
    if (!fechaNacimiento || !fechaIngreso) {
        return null
    }
    const edadActual = fechaIngreso.getFullYear() - fechaNacimiento.getFullYear() -
        (fechaIngreso.getMonth() < fechaNacimiento.getMonth() ||
            (fechaIngreso.getMonth() === fechaNacimiento.getMonth() && fechaIngreso.getDate() < fechaNacimiento.getDate()));
    return edadActual;
}
