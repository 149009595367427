import './bajas.css';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { requestOptions } from '../../modulo_nominas/misc/requestOptions';


function Bajas() {

    const [idpersona, setIdPersona] = useState(null);
    const [partida, setPartida] = useState('');
    const [plaza, setPlaza] = useState('');
    const [registro, setRegistro] = useState('');
    const [numero_dpi, setDPI] = useState('');
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [estado, setEstado] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isLoaded, setLoaded] = useState(false);
    const [motivo, setMotivo] = useState('');
    const [fecha_defuncion, setFdef] = useState('');

    const reset_form = () => {
        setIdPersona(null);
        setPartida('');
        setPlaza('');
        setRegistro('');
        setNombres('');
        setApellidos('');
        setDPI('');
        setMotivo('');
        setFdef('')
        setLoading(false);
        setLoaded(false);
    }

    const toast = useRef(null);

    const request_data = async () => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL_CONSULTAR_PERSONA,
            requestOptions('POST', {
                partida: partida,
                plaza: plaza,
                registro: registro,
                busqueda: 'ppr'
            }))
            .then((response) => response.json())
            .then((data) => {
                const x = data.data;
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                if (x) {
                    setIdPersona(x.idpersona);
                    setEstado(x.estado);
                    setNombres(x.nombres);
                    setApellidos(x.apellidos);
                    setDPI(x.numero_dpi);
                    setLoaded(true);
                }
                setLoading(false);
            });
    }

    const post_request = (nuevo_estado) => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL_BAJAS,
            requestOptions('POST', {
                idpersona: idpersona,
                nuevo_estado: nuevo_estado,
                motivo: motivo,
                fecha_defuncion: fecha_defuncion ? fecha_defuncion.toJSON().slice(0, 10) : null
            }))
            .then((response) => response.json())
            .then((data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                setLoading(false);
                reset_form();
            });
    }

    const confirm_baja = () => {
        confirmPopup({
            target: document.getElementById('baja_confirm'),
            message: '¿Seguro desea dar de baja?',
            icon: 'pi pi-question-circle',
            acceptLabel: 'Sí',
            accept: () => post_request(0)
        });
    }

    const confirm_realza = () => {
        confirmPopup({
            target: document.getElementById('realza_confirm'),
            message: '¿Seguro desea dar de alza de nuevo?',
            icon: 'pi pi-question-circle',
            acceptLabel: 'Sí',
            accept: () => post_request(1)
        });
    }

    return (
        <>
            <div className="grid p-fluid bajas">
                <Toast ref={toast}></Toast>
                <div className='col-12 md:col-7'>
                    <Fieldset legend="Búsqueda por partida, plaza y registro" toggleable  >
                        <div className="grid p-fluid mt-1">
                            {/* PARTIDA */}
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter="pint" value={partida} required={true} disabled={isLoaded} onChange={(e) => setPartida(e.target.value)} />
                                        <label>Partida</label>
                                    </span>
                                </div>
                            </div>
                            {/* PLAZA */}
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter="pint" value={plaza} required={true} disabled={isLoaded} onChange={(e) => setPlaza(e.target.value)} />
                                        <label >Plaza</label>
                                    </span>
                                </div>
                            </div>
                            {/* REGISTRO */}
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter="pint" value={registro} required={true} disabled={isLoaded} onChange={(e) => setRegistro(e.target.value)} />
                                        <label >Registro</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div className='col-12 md:col-2 bajas_consulta_botones'>
                    <Button type='button' disabled={!partida || !plaza || !registro} className='p-button-rounded p-button-warning' icon="pi pi-search" loading={loading} onClick={request_data} tooltip='Buscar persona' />
                    <Button type='button' className='p-button-rounded p-button-secondary' icon="pi pi-replay" onClick={reset_form} tooltip='Limpiar búsqueda' />
                </div>
            </div>
            {
                isLoaded &&
                <>
                    <div className="grid p-fluid mt-0" >
                        {/* NOMBRES */}
                        <div className="field col-12 md:col-4">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-user"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText type="text" disabled={true} value={nombres} onChange={(e) => setNombres(e.target.value.toUpperCase())} />
                                    <label >Nombres</label>
                                </span>
                            </div>
                        </div>
                        {/* APELLIDOS */}
                        <div className="field col-12 md:col-4">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-user"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText type="text" disabled={true} value={apellidos} onChange={(e) => setApellidos(e.target.value.toUpperCase())} />
                                    <label >Apellidos</label>
                                </span>
                            </div>
                        </div>
                        {/* DPI */}
                        <div className="field col-12 md:col-4">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-id-card"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText type="text" disabled={true} value={numero_dpi} onChange={(e) => setDPI(e.target.value)} />
                                    <label >Número de DPI</label>
                                </span>
                            </div>
                        </div>
                        {/* MOTIVO */}
                        <div className="field col-12 md:col-4 my-3 ml-auto">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-info-circle"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputTextarea maxLength={200} rows={1} required value={motivo} autoResize={true} onChange={(e) => setMotivo(e.target.value.toUpperCase())} />
                                    <label >Motivo de baja</label>
                                </span>
                            </div>
                        </div>
                        {/* FECHA DEFUNCIÓN  */}
                        <div className="field col-12 md:col-4 my-3 mr-auto">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-calendar"></i>
                                </span>
                                <span className="p-float-label">
                                    <Calendar disabled={!estado} dateFormat="dd/mm/yy" value={fecha_defuncion} onChange={(e) => setFdef(e.value)}></Calendar>
                                    <label >Fecha de defunción (opcional)</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="confirm" >
                        {
                            estado ?
                                <Button disabled={!isLoaded} id='baja_confirm' icon="pi pi-times" label="Dar de baja"
                                    className="p-button-raised mt-2 font-weight-bold p-button-danger" onClick={confirm_baja} />
                                :
                                <Button disabled={!isLoaded} id='realza_confirm' icon="pi pi-replay" label="Volver a dar de alza"
                                    className="p-button-raised mt-2 font-weight-bold p-button-success" onClick={confirm_realza} />
                        }
                        <ConfirmPopup />
                    </div>
                </>
            }
        </>
    );
}

export default Bajas;