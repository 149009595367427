function fechaPeriodo(Periodo, Tipo) {
    if (Tipo == "inicio") {
        return new Date(Periodo['FechaInicio'])
    } else {
        return new Date(Periodo['FechaFinal'])
    }
}

// ---------------------------------------------------------------------------------- Cantidad de Meses
function cantidadPorPeriodo(fechaInicioPeriodo, fechaFinalPeriodo) {

    return (
        fechaFinalPeriodo ?
            (fechaFinalPeriodo.getMonth() + 1) - (fechaInicioPeriodo.getMonth() + 1) +
            ((fechaFinalPeriodo.getFullYear() - fechaInicioPeriodo.getFullYear()) * 12) + 1
            : 0
    )

};

function cantidadMesesPer1(interesesHasta, fechaFinalPeriodo, fechaDescripcion) {
    var fechaCalculos = new Date(fechaDescripcion);
    fechaCalculos.setMonth(fechaDescripcion.getMonth() + 1);

    return (
        fechaCalculos ?
            (
                fechaCalculos > interesesHasta ?
                    0 :
                    (
                        fechaCalculos <= fechaFinalPeriodo ?
                            (
                                1 + (-fechaDescripcion.getMonth() + 1) + (fechaFinalPeriodo.getMonth() + 1) +
                                ((fechaCalculos.getFullYear() + fechaFinalPeriodo.getFullYear()) * 12)
                            ) :
                            0
                    )
            ) :
            0
    )
};

function cantidadMesesPerAll(CantidadMesesPeriodoAnterior, CantidadPorPeriodo,
    fechaDescripcion, interesesHasta, fechaInicioPeriodo, fechaFinalPeriodo) {

    var fechaCalculos = new Date(fechaDescripcion);
    fechaCalculos.setMonth(fechaDescripcion.getMonth() + 1);

    return (
        CantidadMesesPeriodoAnterior !== 0 ?
            CantidadPorPeriodo :
            (
                fechaCalculos > interesesHasta ?
                    0 :
                    (
                        fechaCalculos >= fechaInicioPeriodo ?
                            (
                                fechaCalculos <= fechaFinalPeriodo ?
                                    (
                                        1 + -(fechaCalculos.getMonth() + 1) + (fechaFinalPeriodo.getMonth() + 1) +
                                        ((-fechaCalculos.getFullYear() + fechaFinalPeriodo.getFullYear()) * 12)
                                    ) :
                                    0
                            ) :
                            0
                    )
            )
    )
};

export function calcularCantidadMeses(periodos, fechaDescripcion, interesesHasta) {
    var periodosConCantidadMeses = []

    // se calcula para el periodo 1
    var periodo1 = periodos[0];
    periodo1['CantidadMeses'] = cantidadMesesPer1(
        interesesHasta,
        fechaPeriodo(periodo1, "final"),
        fechaDescripcion
    );
    periodosConCantidadMeses.push(periodo1);

    // se calcula para el resto de periodos
    for (var i = 1; i < periodos.length; i++) {
        var periodo = periodos[i];
        var fechaInicioPeriodo = fechaPeriodo(periodo, "inicio");
        var fechaFinalPeriodo = fechaPeriodo(periodo, "final");

        var CantidadPorPeriodo = cantidadPorPeriodo(fechaInicioPeriodo, fechaFinalPeriodo);

        periodo['CantidadMeses'] = cantidadMesesPerAll(
            periodosConCantidadMeses[i - 1]['CantidadMeses'], CantidadPorPeriodo,
            fechaDescripcion, interesesHasta, fechaInicioPeriodo, fechaFinalPeriodo
        )

        periodosConCantidadMeses.push(periodo);
    }

    return periodosConCantidadMeses;
}

export function calcularTotalMeses(periodos) {
    var totalMeses = 0;
    for (var contador in periodos) {
        var periodo = periodos[contador];
        totalMeses += periodo['CantidadMeses'];
    }

    return totalMeses;
}

// ---------------------------------------------------------------------------------- Intereses por periodo

function calculoRaro(TasaInteres) {
    TasaInteres = TasaInteres / 100;
    var calculo = (TasaInteres / 12) + 1;
    return calculo;
}

function interesesPer1(fechaDescripcion, fechaFinalPeriodo, calculoRaro) {
    var fechaCalculos = new Date(fechaDescripcion);
    fechaCalculos.setMonth(fechaDescripcion.getMonth() + 1);

    return (
        fechaCalculos ?
            (
                fechaCalculos <= fechaFinalPeriodo ?
                    ((calculoRaro) ** (fechaFinalPeriodo)) :
                    1
            ) :
            1
    )
}

function interesesPerAll(fechaDescripcion, fechaInicioPeriodo, InteresesPeriodoAnterior,
    calculoRaro, cantidadDeMesesDelPeriodo) {

    var fechaCalculos = new Date(fechaDescripcion);
    fechaCalculos.setMonth(fechaDescripcion.getMonth() + 1);

    return (
        fechaCalculos ?
            (
                ( // OR
                    (fechaInicioPeriodo <= fechaCalculos) ||
                    (InteresesPeriodoAnterior !== 1)
                ) ?
                    ((calculoRaro) ** (cantidadDeMesesDelPeriodo)) :
                    1
            ) :
            1
    )
}

export function calcularInteresesPorPeriodo(periodos, fechaDescripcion) {
    var periodosConIntereses = []

    // Calcular intereses del primer periodo
    var periodo1 = periodos[0];
    periodo1['Intereses'] = interesesPer1(
        fechaDescripcion,
        fechaPeriodo(periodo1, "final"),
        calculoRaro(periodo1['TasaInteres'])
    );

    periodosConIntereses.push(periodo1);

    // Calcular intereses para el resto de periodos
    for (var i = 1; i < periodos.length; i++) {
        var periodo = periodos[i];

        periodo['Intereses'] = interesesPerAll(
            fechaDescripcion,
            fechaPeriodo(periodo, "inicio"),
            periodosConIntereses[i - 1]['Intereses'],
            calculoRaro(periodo['TasaInteres']),
            periodo['CantidadMeses']
        )

        periodosConIntereses.push(periodo);
    }

    return periodosConIntereses
}

export function calcularS(periodos, cuotaAPagar) {
    // multiplicar los intereses de cada periodo
    var productoIntereses = periodos[0]['Intereses'];
    for (var contador = 1; contador < periodos.length; contador++) {
        var periodo = periodos[contador];
        productoIntereses *= periodo['Intereses'];
    }

    return productoIntereses * cuotaAPagar;
}
