import '../calculo_cuotas.css';
import { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { Fieldset } from 'primereact/fieldset';

import { CalculosIntegracion, MensajeInicio, TablaCuotasIntegracion } from './constants';
import { headerForm, mostrarError } from '../../constants/bodiesYFiltos';
import { BotonExportarReporte } from '../../constants/exportacion';
import { guardarIntegracion, exportarIntegracion, getCuotasParaIntegracion } from '../../endpoints/calculo_cuotas/integraciones';


export default function CrearIntegracionRegistros() {
    // Datos trabajador
    const [CUI, setCUI] = useState(null);
    const [registro, setRegistro] = useState(null);
    const [selectedTrabajador, setSelectedTrabajador] = useState({ CUI: '', Registro: '', Nombre: '' });

    // Datos de la integración
    const [nominaInicial, setNominaInicial] = useState("");
    const [nominaFinal, setNominaFinal] = useState("");

    // Calculos
    const [mostrarCalculos, setMostrarCalculos] = useState(false);
    // código 24
    const [cuotasNoDescontadas, setCuotasNoDescontadas] = useState([]); // historial completo
    const [cuotasNoDescontadasCalculo, setCuotasNoDescontadasCalculo] = useState([]); // según rango de nóminas
    const [totalSalariosNoDescontados, setTotalSalariosNoDescontados] = useState(0);
    // código 14
    const [cuotas14, setCuotas14] = useState([]); // según rango de nóminas
    const [totalDescuentoCod14, setTotalDescuentoCod14] = useState(0);

    // mensaje contratos trabajador
    const [mensajeInicio, setMensajeInicio] = useState(true);

    const toast = useRef(null);
    const calculosRef = useRef(null);

    useEffect(() => {
        if (mostrarCalculos) {
            calculosRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [mostrarCalculos]);

    const GetCuotasIntegracionRegistros = async () => {
        ResetForm();
        var obj = {
            Registro: registro,
            CUI: CUI
        }
        getCuotasParaIntegracion(obj).then((resp) => {
            if (resp.data) {
                setSelectedTrabajador(
                    { CUI: resp.data.CUI, Registro: resp.data.RegistroTrabajador, Nombre: resp.data.Nombre }
                );

                if (!resp.data.Cuotas24.length > 0) {
                    toast.current.show({
                        severity: "warn", summary: 'Advertencia', detail: "No se encontraron cuotas código 24 sin descontar, " +
                            "considere actualizar las plazas afectas a descuento"
                    });
                    return;
                }
                setNominaInicial(resp.data.Cuotas24[0].Nomina);
                setNominaFinal(resp.data.Cuotas24[resp.data.Cuotas24.length - 1].Nomina);
                setCuotasNoDescontadas(resp.data.Cuotas24);
                setCuotas14(resp.data.Cuotas14);
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: "No se encontró el trabajador" });
            }
        }).catch(err => {
            mostrarError(err);
        });
    };

    function RealizarIntegracion() {
        // --------------------------------- código 24
        // filtrar por rango de nóminas
        var filtroNominas = cuotasNoDescontadas.filter(
            (cuota) => cuota.Nomina >= nominaInicial && cuota.Nomina <= nominaFinal);
        setCuotasNoDescontadasCalculo(filtroNominas);

        // sumar total de sueldos
        var totalSueldos = filtroNominas.reduce(function (acumulado, cuota) {
            return acumulado + cuota.Sueldo;
        }, 0);

        setTotalSalariosNoDescontados(totalSueldos);

        // --------------------------------- código 14
        // sumar total de descuentos en cuota 14
        var totalDescuentos14 = cuotas14.reduce(function (acumulado, cuota) {
            return acumulado + cuota.Cuota;
        }, 0);

        setTotalDescuentoCod14(totalDescuentos14);

        setMostrarCalculos(true);
        calculosRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    function ResetForm() {
        setSelectedTrabajador({
            CUI: '',
            Registro: '',
            Nombre: ''
        });
        setNominaFinal(null);
        setNominaInicial(null);
        setMostrarCalculos(false);
        setCuotasNoDescontadas([]);
        setCuotasNoDescontadasCalculo([]);
        setTotalSalariosNoDescontados(0);
    }

    const GuardarIntegracion = async () => {
        var obj = {
            CuotasNoDescontadas: cuotasNoDescontadasCalculo,
            CuotasDescontadas: cuotas14,
            RegistroTrabajador: selectedTrabajador.Registro
        }

        try {
            guardarIntegracion(obj).then((resp) => {
                if (resp.severity === "success") {
                    toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                } else {
                    toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
                }
            }).catch(err => {
                mostrarError(err);
            });
        } catch (error) {
            console.error('Error al enviar el archivo:', error);
        }
    };

    return (
        <div className='primario-crear-registro'>
            <Toast ref={toast} />
            <MensajeInicio mensajeInicio={mensajeInicio} setMensajeInicio={setMensajeInicio} />
            <Card
                header={headerForm("Datos de Integración", true)} className="card">
                <div className="grid p-fluid">
                    <div className='col-12 md:col-6'>
                        <h4 style={{ textAlign: 'center' }}>Seleccionar Trabajador</h4><br />
                        <Fieldset legend="Búsqueda por Registro Personal o CUI" toggleable collapsed={false}
                            style={{ minHeight: '150px' }}>
                            <div className="grid p-fluid mt-1">
                                {/* REGISTRO */}
                                <div className="field col-12 md:col-5 col_toggle">
                                    <span className="p-float-label">
                                        <InputNumber value={registro}
                                            onValueChange={
                                                (e) => {
                                                    setRegistro(e.value);
                                                    setCUI(null);
                                                }
                                            }
                                            mode="decimal" showButtons min={0} useGrouping={false} />
                                        <label >Registro Personal</label>
                                    </span>
                                </div>
                                {/* CUI */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <span className="p-float-label">
                                        <InputNumber type="text" value={CUI}
                                            onValueChange={(e) => {
                                                setCUI(e.value);
                                                setRegistro(null);
                                            }}
                                            mode="decimal" showButtons min={0} useGrouping={false} />
                                        <label>CUI</label>
                                    </span>
                                </div>
                                <div className='col-12 md:col-1'>
                                    <Button type='button' className='p-button-rounded p-button-info'
                                        icon="pi pi-search" onClick={() => GetCuotasIntegracionRegistros()}
                                        tooltip='Buscar trabajador' />
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                    {/* DATOS DEL TRABAJADOR */}
                    <div className='col-12 md:col-6'>
                        <h4 style={{ textAlign: 'center' }}>Trabajador Seleccionado</h4>
                        <div className="grid p-fluid mt-1">
                            {/* CUI */}
                            <div className="field col-12 md:col-6 col_toggle">
                                <label>CUI</label>
                                <InputText type="text" value={selectedTrabajador.CUI} readOnly />
                            </div>
                            {/* REGISTRO */}
                            <div className="field col-12 md:col-6 col_toggle">
                                <label>Registro</label>
                                <InputText type="text" value={selectedTrabajador.Registro} readOnly />
                            </div>
                        </div>
                        {/* NOMBRE */}
                        <label>Nombre</label>
                        <InputText type="text" value={selectedTrabajador.Nombre} readOnly />
                    </div>
                </div>
                <br />
                <div className="grid p-fluid mt-1">
                    {/* NOMINA INICIAL */}
                    <div className="field col-12 md:col-4 col_toggle">
                        <label>Nomina Inicial</label>
                        <InputNumber
                            value={nominaInicial} onValueChange={(e) => setNominaInicial(e.value)}
                            mode="decimal" showButtons min={0} useGrouping={false} />
                    </div>
                    {/* NOMINA FINAL */}
                    <div className="field col-12 md:col-4 col_toggle">
                        <label>Nomina Final</label>
                        <InputNumber
                            value={nominaFinal} onValueChange={(e) => setNominaFinal(e.value)}
                            mode="decimal" showButtons min={0} useGrouping={false} />
                    </div>
                    {/* BOTON */}
                    <div className="field col-12 md:col-4 col_toggle">
                        <br />
                        <Button style={{ marginTop: '8px' }} label="Realizar Integración" icon="pi pi-arrow-down"
                            severity='success' onClick={() => RealizarIntegracion()}
                            disabled={(selectedTrabajador.Registro === '')} />
                    </div>
                </div>
            </Card>
            <br />
            {mostrarCalculos && (
                <Card ref={calculosRef}
                    header={headerForm("Cuotas de integración")} className="card">
                    <BotonExportarReporte
                        Endpoint={exportarIntegracion}
                        datos={{
                            CuotasNoDescontadas: cuotasNoDescontadasCalculo,
                            CuotasDescontadas: cuotas14,
                            TotalSueldoNoDescontado: totalSalariosNoDescontados,
                            TotalDescontado: totalDescuentoCod14,
                            RegistroTrabajador: selectedTrabajador.Registro,
                            NombreTrabajador: selectedTrabajador.Nombre
                        }}
                        nombreArchivo={'integracion'}
                    />
                    <TablaCuotasIntegracion
                        cuotasNoDescontadasCalculo={cuotasNoDescontadasCalculo}
                        cuotas14={cuotas14} />
                    <br />
                    <CalculosIntegracion
                        totalSalariosNoDescontados={totalSalariosNoDescontados}
                        totalDescuentoCod14={totalDescuentoCod14}
                        GuardarIntegracion={GuardarIntegracion}
                        uploadStatus={!(selectedTrabajador.Registro === '')}
                    />
                </Card>
            )
            }
        </div>
    );
}
