const Plugin_title = (_title, _size = 20, _subtitle = null) => {
    return {
        plugins:
        {
            title: { display: true, text: _title, font: { size: _size } },
            subtitle: _subtitle && { display: true, text: _subtitle, font: { size: 14, weight: 'bold' }, align: 'end' }
        }
    }
}

const DoughnutFormasPago = (data) => {
    const chartData = {
        labels: data.map((row) => row.forma_pago),
        datasets: [
            {
                data: data.map((row) => row.cantidad),
                backgroundColor: [
                    "#0067b1",
                    "#128a38",
                    "#FFA726",
                ],
            }]
    };
    return chartData;
}

const BarPartidas = (data, label) => {
    const chartData = {
        labels: data.map((row) => row.partida),
        datasets: [
            {
                label: label,
                backgroundColor: '#0087AC',
                data: data.map((row) => row.cantidad)
            }]
    };
    return chartData;
}

const BarDescuentos = (data) => {
    const chartData = {
        labels: data.map((row) => row.nombre),
        datasets: [
            {
                data: data.map((row) => row.totales)
            }]
    };
    return chartData;
}

const Plugin_horizontal = (_title, _size = 20) => {
    return {
        indexAxis: 'y',
        plugins: {
            title: {
                display: true,
                text: _title,
                font: {
                    size: _size
                }
            },
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                ticks: { precision: 0 }
            }
        }
    }
};


const BarAlzasVsBajas = (data) => {
    const chartData = {
        labels: data.map((row) => row.nombre),
        datasets: [
            {
                data: data.map((row) => row.cantidad),
                backgroundColor: [
                    "#007000",
                    "#d2222d",
                ],
            }]
    };
    return chartData;
}

const RangosPensiones = (pension, pension_bonif) => {
    const chartData = {
        labels: pension.map((row) => row.rango),
        datasets: [
            {
                label: 'Líquido (Q.)',
                fill: true,
                tension: .4,
                // borderColor: '#FFA726',
                // backgroundColor: 'rgba(255,167,38,0.2)',
                data: pension_bonif.map((row) => row.cantidad_personas)
            },
            {
                label: 'Nominal (Q.)',
                // fill: true,
                tension: .4,
                borderDash: [5, 5],
                // borderColor: '#FFA726',
                // backgroundColor: 'rgba(255,167,38,0.2)',
                data: pension.map((row) => row.cantidad_personas)
            }
        ]
    };
    return chartData;
}

module.exports = { Plugin_title, DoughnutFormasPago, BarPartidas, BarDescuentos, BarAlzasVsBajas, RangosPensiones, Plugin_horizontal };