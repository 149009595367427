import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { fechaBody } from './bodiesYFiltos';
import { estados_trabajador } from './estados_trabajador';
import buscarObjetoPorId from '../tools/buscarObjetoPorId';

export const generoBody = (rowData) => {
    if (rowData.Genero === 1) {
        return 'Masculino'
    } else if (rowData.Genero === 0) {
        return 'Femenino'
    } else {
        return ''
    }
};

export const afiliacionTrabajadorBody = (rowData) => {
    return <center style={{ textTransform: 'uppercase' }}>
        {rowData.Afiliacion === 1 ?
            <Tag value='Afiliado' severity='success' />
            :
            <Tag value='No Afiliado' severity='danger' />
        }
    </center>;
};

export const afiliacionTrabajadorFilter = (options) => {
    return <Dropdown value={options.value} options={[0, 1]}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={afiliacionTrabajadorItem} placeholder="Cualquiera" className="p-column-filter"
        showClear valueTemplate={afiliacionTrabajadorItem} />;
};

export const afiliacionTrabajadorItem = (option) => {
    if (option === 1) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='Afiliado' severity='success' />
        </center>;
    } else if (option === 0) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='No Afiliado' severity='danger' />
        </center>;
    } else {
        return 'Cualquiera'
    }
};

export const estadoTrabajadorItem = (option) => {
    if (option === undefined || option === null) return 'Cualquiera'
    return buscarObjetoPorId(estados_trabajador, option, "idEstadoTrabajador").Nombre;
};


export const estadoTrabajadorFilter = (options) => {
    return <Dropdown value={options.value} options={[1, 2, 3, 4, 5, 6, 7, 8]}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={estadoTrabajadorItem} placeholder="Cualquiera" className="p-column-filter"
        showClear valueTemplate={estadoTrabajadorItem}
    />;
};

export const DatosTrabajador = ({ trabajador }) => {
    return (
        <DataTable value={trabajador}
            tableStyle={{ minWidth: '50rem' }} size='small' >
            <Column field="Registro" header="Registro Personal" ></Column>
            <Column field="CUI" header="CUI" ></Column>
            <Column field="Nombre" header="Nombre" ></Column>
            <Column field="Genero" header="Genero" body={generoBody}></Column>
            <Column field="FechaNacimiento" header="Fecha de Nacimiento"
                body={(rowData) => fechaBody(rowData, 'FechaNacimiento')}></Column>
            <Column field="FechaIngreso" header="Fecha de Ingreso"
                body={(rowData) => fechaBody(rowData, 'FechaIngreso')}></Column>
            <Column field="Afiliacion" header="Afiliado al Plan de prestaciones?"
                body={afiliacionTrabajadorBody}></Column>
            <Column field="NombreEstado" header="Estado del Trabajador"></Column>
        </DataTable >
    );
};