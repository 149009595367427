import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { TabPanel, TabView } from 'primereact/tabview';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';

import './formulario_moras.css'; // Importa el archivo CSS para estilizar el formulario

const FormularioMoras = () => {
    const regex_alfa = /[a-zA-zñÑáÁéÉíÍóÓúÚ ]/;
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [apellido_casada, setApellidoCasada] = useState('');
    const [numero_dpi, setDPI] = useState('');
    const [telefono, setTelefono] = useState('');
    const [correo, setCorreo] = useState('');
    const [direccion, setDireccion] = useState('');
    const [registro, setRegistro] = useState('');
    const [mora, setMora] = useState('');
    const [fecha, setFecha] = useState('');
    const [fechaInicio, setFechaInicio] = useState('');
    const [razon, setRazon] = useState('');

    const reset_form = () => {
        setNombres('');
        setApellidos('');
        setApellidoCasada('');
        setDPI('');
        setTelefono('');
        setCorreo('');
        setDireccion('');
        setRegistro('');
        setMora('');
        setFecha('');
        setFechaInicio('');
        setRazon('');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Realiza acciones con los datos del formulario
    };
    const confirm = (event) => {
        confirmPopup({
            target: document.getElementById('alzas_confirm'),
            message: '¿Seguro que desea continuar?',
            icon: 'pi pi-question-circle',
            acceptLabel: 'Sí',
            accept: () => {
                // consumir endpoint
                reset_form();
                toast.current.show({ severity: 'success', summary: 'Mora registrada.' });
            }
        });
        event.preventDefault();
    }

    const toast = useRef(null);
    return (
        <div className='container'>
            <TabView>
                <TabPanel header="Formulario de mora" leftIcon='mr-2 pi pi-money-bill'>
                    <form id='pagos_form' onSubmit={confirm} onValidate={false}>
                        <Toast ref={toast}></Toast>
                        <div className='grid p-fluid formulario'>
                            {/* CODIGO Mora */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-id-card"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter="pint" required={true} value={mora} onChange={(e) => setMora(e.target.value)} />
                                        <label >Codigo de Prestamo</label>
                                    </span>
                                </div>
                            </div>
                            {/* FECHA */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-id-card"></i>
                                    </span>
                                    <span className="p-float-label">
                                    <Calendar dateFormat="dd/mm/yy" value={fecha} onChange={(e) => setFecha(e.value)}></Calendar> 
                                        <label >Fecha de pago</label>
                                    </span>
                                </div>
                            </div>
                            {/* FECHA INICIO */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-id-card"></i>
                                    </span>
                                    <span className="p-float-label">
                                    <Calendar dateFormat="dd/mm/yy" value={fechaInicio} onChange={(e) => setFechaInicio(e.value)}></Calendar> 
                                        <label >Fecha inicial</label>
                                    </span>
                                </div>
                            </div>
                            {/* CANTIDAD */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-id-card"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="number" keyfilter="pint" required={true} value={razon} onChange={(e) => setRazon(e.target.value)} />
                                        <label >Razon de mora</label>
                                    </span>
                                </div>
                            </div>
                            {/* DPI */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-id-card"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter="pint" required={true} value={numero_dpi} onChange={(e) => setDPI(e.target.value)} />
                                        <label >Número de DPI</label>
                                    </span>
                                </div>
                            </div>
                            {/* NOMBRES */}
                            <div className="field col-12 md:col-4">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={true} value={nombres} onChange={(e) => setNombres(e.target.value.toUpperCase())} />
                                        <label >Nombres</label>
                                    </span>
                                </div>
                            </div>
                            {/* APELLIDOS */}
                            <div className="field col-12 md:col-4">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={true} value={apellidos} onChange={(e) => setApellidos(e.target.value.toUpperCase())} />
                                        <label >Apellidos</label>
                                    </span>
                                </div>
                            </div>
                            {/* APELLIDO CASADA */}
                            <div className="field col-12 md:col-4">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        de
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={false} value={apellido_casada} onChange={(e) => setApellidoCasada(e.target.value.toUpperCase())} />
                                        <label >Apellido de casada</label>
                                    </span>
                                </div>
                            </div>
                            {/* TELÉFONO */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-phone"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter="pint" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                                        <label>Teléfono</label>
                                    </span>
                                </div>
                            </div>
                            {/* CORREO */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-envelope"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter="email" value={correo} onChange={(e) => setCorreo(e.target.value.toLowerCase())} />
                                        <label >Correo</label>
                                    </span>
                                </div>
                            </div>
                            {/* DIRECCIÓN */}
                            <div className="field col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-home"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" value={direccion} onChange={(e) => setDireccion(e.target.value)} />
                                        <label >Dirección</label>
                                    </span>
                                </div>
                            </div>
                            {/* REGISTRO */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter="pint" required={true} value={registro} onChange={(e) => setRegistro(e.target.value)} />
                                        <label >Registro</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='confirm'>
                            <Button id='alzas_confirm' type='submit' icon="pi pi-check-circle" label="Registrar" className="mt-2 p-button-raised font-weight-bold" />
                            <ConfirmPopup />
                        </div>
                    </form>
                </TabPanel>
            </TabView>
        </div>
    );
};

export default FormularioMoras;
