import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import formatDate from '../tools/formatDate'
import { exportacionCertificacion } from './constants';
import { getCertificacion, exportarCertificacion } from '../endpoints/certificaciones';

const VerCertificacion = () => {
  const { quill, quillRef, Quill } = useQuill({
    modules: { "toolbar": false },
    readOnly: true
  });

  const [certificacion, setCertificacion] = useState({});
  const toast = useRef(null);
  const op = useRef(null);

  useEffect(() => {
    // obtener certificacion seleccionada
    var seleccionada = sessionStorage.getItem('certificacion_seleccionada');

    getCertificacion({ idCertificacion: seleccionada }).then((resp) => {
      setCertificacion(resp.data);
      // contenido
      quill.root.innerHTML = resp.data.Contenido;

    }).catch(err => {
      console.log(err);
    });

  }, [quill, Quill])

  if (Quill && !quill) {
    Quill.register('modules/blotFormatter', BlotFormatter);
  }

  const ExportarCertificacion = async (tipo) => {
    var data = {
      Contenido: quill.root.innerHTML,
      Tipo: tipo
    }

    exportacionCertificacion(exportarCertificacion, data);
  }

  const ultimaModificacionBody = (rowData) => {
    return <>{formatDate(rowData.UltimaModificacion)}</>
  };

  return (
    <>
      <Toast ref={toast} />
      <div className='primario_editar_cert'>
        <br />
        <Link to="/cuotas/certificaciones" style={{ position: 'absolute' }}>
          <Button type="button" icon="pi pi-arrow-left" label="Regresar" size="small"
            className='p-button-info' />
        </Link>
        <br /><br />
        <h3 style={{ color: 'black', marginLeft: '9%' }}>Detalles</h3>
        <center>
          <DataTable value={[certificacion]}
            tableStyle={{ minWidth: '50rem', maxWidth: '60rem' }}
            style={{ margin: 'auto' }}
            size='small'>
            <Column field="Nombre" header="Nombre" ></Column>
            <Column field="NombrePlantilla" header="Plantilla"
            ></Column>
            <Column field="UltimaModificacion" header="Ultima Modificacion"
              body={ultimaModificacionBody}></Column>
          </DataTable>
        </center>
        <br />

        <br />

        <div className='col-12 md:col-10 editor-wrapper'>
          <div className='editor-container'>
            <div className='encabezado-content'>
              <h3 style={{ color: 'black' }}>Contenido</h3>

              <span className="p-buttonset">
                <Link to="/cuotas/editar_certificacion">
                  <Button label="Editar" icon="pi pi-pencil" severity="warning" size="small" />
                </Link>
                <Button size="small" label="Exportar" icon="pi pi-print" severity="help"
                  onClick={(e) => op.current.toggle(e)} />
                <OverlayPanel ref={op} style={{ width: '25%' }}>
                  <h6 style={{
                    width: 'fit-content', margin: 'auto',
                    marginBottom: '2%'
                  }}>Seleccionar formato</h6>
                  <div className="grid p-fluid" >
                    <div className='col-12 md:col-6' >
                      <Button type="button" label="PDF" severity='danger'
                        onClick={() => ExportarCertificacion('pdf')} size='small' />
                    </div>
                    <div className='col-12 md:col-6'>
                      <Button type="button" label="DOCX"
                        onClick={() => ExportarCertificacion('docx')} size='small' />
                    </div>
                  </div>
                </OverlayPanel>
              </span>
            </div>

            <div className='editor'>
              <div style={{
                paddingLeft: '8%', paddingRight: '8%', fontSize: 'medium',
                color: 'black'
              }} ref={quillRef} />
            </div>
            <br /><br />
          </div>
        </div>
      </div>
    </>
  );
};

export default VerCertificacion;