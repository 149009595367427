import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useState } from 'react';
import request_authorization from '../misc/autorizacion';
import { InputText } from 'primereact/inputtext';

function AutorizacionDialog(props) {

    const { header, continueOperation, cancelOperation, toast } = props;

    const [display, setDisplay] = useState(true);
    const [key, setKey] = useState('');
    const [dots, setDots] = useState('');

    const cancelDialog = () => {
        setDisplay(false);
        cancelOperation();
    };

    const send_autorizacion = async () => {
        const res = await request_authorization(key);
        setKey('');
        if (toast && (res.message || res.error))
            toast.current.show({ severity: res.severity, summary: res.message || res.error });
        if (res.data) { setDisplay(false); continueOperation(); }
        else cancelDialog();
    };

    const footer = (
        <div>
            <Button disabled={!key} label="Ingresar" icon="pi pi-check" onClick={send_autorizacion} />
            <Button className='mr-0' label="Cancelar" icon="pi pi-times" onClick={cancelDialog} />
        </div>
    );

    return (
        <>
            <Dialog header={header || "Autorización requerida"} visible={display} style={{ maxWidth: 'fit-content' }}
                footer={footer} resizable={false} draggable={false} onHide={cancelDialog} >
                <div className="field my-1">
                    <label htmlFor="keya">Ingrese clave de autorización</label>
                    <InputText style={{ width: '178px', marginLeft: '12px' }} id='keya' type='text' value={dots}
                        onChange={(e) => { setDots(e.target.value.replace(/./g, '\u25CF')); setKey(key + e.nativeEvent.data); }}
                        required autoFocus />
                </div>
            </Dialog>
        </>
    );
}

export default AutorizacionDialog;
