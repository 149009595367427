import React, { useRef, useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { FileUpload } from 'primereact/fileupload';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { jsPDF } from 'jspdf';
import { useDownloadExcel } from 'react-export-table-to-excel'
import * as XLSX from 'xlsx';
import 'jspdf-autotable';
import Papa from 'papaparse';
import './visualizador_logs.css'
import { get_moras_route, get_delay_tables_route, get_counts_loans_route, get_balance_route } from '../../constants/backend_routes';



const columnClass = (field, header, filter, filterPlaceholder) => {
  return <Column
    filter={filter}
    filterPlaceholder={filterPlaceholder}
    field={field}
    showFilterMatchModes={false}
    showAddButton={false}
    showFilterOperator={false}
    header={header}></Column>
}

const LogsModule = ({ reportData }) => {
  const [delays, setDelays] = useState([])
  const [reportDelays, setReportDelays] = useState()
  const [reportCount, setReportCount] = useState()
  const [reportBalance, setReportBalance] = useState()
  const tableref = useRef()
  const tableref1 = useRef()
  const tableref2 = useRef()
  const tableref3 = useRef()
  const tableref4 = useRef()
  const tableref5 = useRef()

  const [reportDelaysInmediatos, setReportDelaysInmediatos] = useState()
  const [reportCountInmediatos, setReportCountInmediatos] = useState()
  const [reportBalanceInmediatos, setReportBalanceInmediatos] = useState()

  const [anio, setAnio] = useState(2024);
  const [mes, setMes] = useState('');
  const op = useRef(null);
  const [filters] = useState({
    nombre: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    registro: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    plaza: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    estado_trabajador: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  });

  const meses = [
    {
      nombre: 'Enero',
      numero: 1
    },
    {
      nombre: 'Febrero',
      numero: 2
    },
    {
      nombre: 'Marzo',
      numero: 3
    },
    {
      nombre: 'Abril',
      numero: 4
    },
    {
      nombre: 'Mayo',
      numero: 5
    },
    {
      nombre: 'Junio',
      numero: 6
    },
    {
      nombre: 'Julio',
      numero: 7
    },
    {
      nombre: 'Agosto',
      numero: 8
    },
    {
      nombre: 'Septiembre',
      numero: 9
    },
    {
      nombre: 'Octubre',
      numero: 10
    },
    {
      nombre: 'Noviembre',
      numero: 11
    },
    {
      nombre: 'Diciembre',
      numero: 12
    }
  ]

  const obtenerFecha = () => {
    let anio_util = mes.numero >= 12 ? anio + 1 : anio
    let mes_util = mes.numero >= 12 ? 1 : mes.numero + 1
    let dia_util = '04'

    const fecha = `${dia_util}/${mes_util}/${anio_util}`
    return fecha
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(get_moras_route)
      const responseJson = await response.json()
      setDelays(responseJson.data)
    }
    fetchData()
  }, [])

  const fetchReportDelays = async (fechaInput) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fecha: fechaInput, cartera: 'FOX' })
    }
    const response = await fetch(get_delay_tables_route, requestOptions)
    const responseJson = await response.json()
    console.log('report delays', responseJson)
    setReportDelays(responseJson.data)
  }
  const fetchReportCounts = async (fechaInput) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fecha: fechaInput, cartera: 'FOX' })
    }
    const response = await fetch(get_counts_loans_route, requestOptions)
    const responseJson = await response.json()
    console.log('report counts', responseJson)
    setReportCount(responseJson.data)
  }
  const fetchBalance = async (fechaInput) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fecha: fechaInput, cartera: 'FOX' })
    }
    const reponse = await fetch(get_balance_route, requestOptions)
    const responseJson = await reponse.json()
    console.log('report balance', responseJson)
    setReportBalance(responseJson.data)
  }


  const fetchReportDelaysInmediatos = async (fechaInput) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fecha: fechaInput, cartera: 'INMEDIATOS' })
    }
    const response = await fetch(get_delay_tables_route, requestOptions)
    const responseJson = await response.json()
    console.log('report delays', responseJson)
    setReportDelaysInmediatos(responseJson.data)
  }
  const fetchReportCountsInmediatos = async (fechaInput) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fecha: fechaInput, cartera: 'INMEDIATOS' })
    }
    const response = await fetch(get_counts_loans_route, requestOptions)
    const responseJson = await response.json()
    console.log('report counts', responseJson)
    setReportCountInmediatos(responseJson.data)
  }
  const fetchBalanceInmediatos = async (fechaInput) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fecha: fechaInput, cartera: 'INMEDIATOS' })
    }
    const reponse = await fetch(get_balance_route, requestOptions)
    const responseJson = await reponse.json()
    console.log('report balance', responseJson)
    setReportBalanceInmediatos(responseJson.data)
  }


  const obtenerMesAnterior = (mess) => {
    const mesActualIndex = meses.findIndex((mes_op) => mes_op.numero === mess.numero);

    // Si el mes actual no se encuentra en el arreglo o es enero, devolvemos diciembre
    if (mesActualIndex === -1 || mesActualIndex === 0) {
      return meses[11]
    }

    // Devolvemos el número del mes anterior
    return meses[mesActualIndex - 1];
  };

  const generarPDF = () => {
    const pdf = new jsPDF();

    // Configuración adicional para el PDF si es necesario

    // Definir el margen y el espaciado entre elementos
    const margen = 15;
    const espaciado = 10;

    // Configurar posición inicial
    let posY = margen;

    // Título
    pdf.text('REPORTE DE CARTERA DE PRESTAMOS (FOX):', margen, posY);
    posY += espaciado;

    // Generar la tabla de moras
    const tablaBalancePrestamos = document.getElementById('tabla-reporte-balance');
    const optionsBalance = { startY: posY + espaciado };
    pdf.autoTable({ html: tablaBalancePrestamos, ...optionsBalance });

    // Ajustar la posición después de la tabla de moras
    posY = pdf.autoTable.previous.finalY + espaciado;

    // Título
    pdf.text('INTEGRACION DE CONTRATOS DE PRESTAMOS A (FOX):', margen, posY);
    posY += espaciado;

    // Generar la tabla de moras
    const tableContentMoras = document.getElementById('tabla-reporte-moras');
    const optionsMoras = { startY: posY + espaciado };
    pdf.autoTable({ html: tableContentMoras, ...optionsMoras });

    // Ajustar la posición después de la tabla de moras
    posY = pdf.autoTable.previous.finalY + espaciado;

    pdf.addPage();
    posY = margen;

    // Título
    pdf.text('REPORTE DE MORAS (FOX):', margen, posY);
    posY += espaciado;

    // Generar la tabla de conteos
    const tableContentCounts = document.getElementById('tabla-reporte-counts');
    const optionsCounts = { startY: posY + espaciado };
    pdf.autoTable({ html: tableContentCounts, ...optionsCounts });

    // Ajustar la posición después de la tabla de moras
    posY = pdf.autoTable.previous.finalY + espaciado;



    // Título
    pdf.text('REPORTE DE CARTERA DE PRESTAMOS (INMEDIATOS):', margen, posY);
    posY += espaciado;

    // Generar la tabla de moras
    const tablaBalancePrestamosInmediatos = document.getElementById('tabla-reporte-balance-inmediatos');
    const optionsBalanceInmediatos = { startY: posY + espaciado };
    pdf.autoTable({ html: tablaBalancePrestamosInmediatos, ...optionsBalanceInmediatos });

    // Ajustar la posición después de la tabla de moras
    posY = pdf.autoTable.previous.finalY + espaciado;

    pdf.addPage();
    posY = margen;

    // Título
    pdf.text('INTEGRACION DE CONTRATOS DE PRESTAMOS A (INMEDIATOS):', margen, posY);
    posY += espaciado;

    // Generar la tabla de moras
    const tableContentMorasInmediatos = document.getElementById('tabla-reporte-moras-inmediatos');
    const optionsMorasInmediatos = { startY: posY + espaciado };
    pdf.autoTable({ html: tableContentMorasInmediatos, ...optionsMorasInmediatos });

    // Ajustar la posición después de la tabla de moras
    posY = pdf.autoTable.previous.finalY + espaciado;

    // Título
    pdf.text('REPORTE DE MORAS (INMEDIATOS):', margen, posY);
    posY += espaciado;

    // Generar la tabla de conteos
    const tableContentCountsInmediatos = document.getElementById('tabla-reporte-counts-inmediatos');
    const optionsCountsInmediatos = { startY: posY + espaciado };
    pdf.autoTable({ html: tableContentCountsInmediatos, ...optionsCountsInmediatos });

    // Ajustar la posición después de la tabla de moras
    posY = pdf.autoTable.previous.finalY + espaciado;
    // Guardar el PDF con el nombre 'reporte.pdf'
    pdf.save('reporte.pdf');
  };



  const generarYDescargarCSV = (datos, nombreArchivo) => {
    const csv = Papa.unparse(datos);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const enlace = document.createElement('a');
    enlace.href = url;
    enlace.setAttribute('download', nombreArchivo || 'datos.csv');
    document.body.appendChild(enlace);
    enlace.click();
    document.body.removeChild(enlace);
  }

  const convertJsonToExcel = (jsonData, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

    // Crear el archivo Excel y guardarlo en el sistema de archivos
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };


  const FormatDate = (date) => {
    const fechaOriginal = new Date(date);

    const dia = fechaOriginal.getDate().toString().padStart(2, '0');
    const mes = (fechaOriginal.getMonth() + 1).toString().padStart(2, '0');
    const anio = fechaOriginal.getFullYear();

    const fechaFormateada = `${dia}/${mes}/${anio}`;
    return fechaFormateada;
  }

  const FormatAndOperateDate = (date, months) => {
    const fechaOriginal = new Date(date)
    const nuevaFecha = fechaOriginal.setMonth(fechaOriginal.getMonth() + months)

    return FormatDate(nuevaFecha)
  }

  const downloadReport = () => {
    let for_report = []
    delays.forEach((element) => {
      for_report.push({
        Registro: element.registro,
        Nombre: element.persona,
        Contrato: element.partida,
        Fecha_inicio: FormatDate(element.fecha_inicio),
        Fecha_final: FormatAndOperateDate(element.fecha_inicio, element.duracion),
        Vigencia: element.estado,
        Tasa: element.tasa,
        Aplica_seguro: element.aplica_seguro,
        Seguro: element.seguro,
        Monto: element.cantidad,
        Plazo: element.duracion,
        Cuota: element.siguiente_cuota,
        Capital: element.capital_faltante,
        Interes_Pendiente: element.interes_faltante,
        Cuotas_Pendientes: element.faltantes,
        Morosidad: element.faltantes * element.siguiente_cuota,
        Rango: element.cuotas_mora,
        Cuotas: element.cuotas_mora,
        Fox_Nuevos: element.cartera,
        Saldos: element.saldos
      })
    })
    generarYDescargarCSV(for_report, 'ReporteCSV')
    convertJsonToExcel(for_report, 'ReporteExcel')
  };

  const renderButtonColumn = (rowData) => {
    return (
      <Button label="Detalle" icon="pi pi-check" onClick={() => {
        window.location.href = `detalle_prestamo/${rowData.clave}`
      }} />
    );
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableref.current,
    filename: 'Reporte',
    sheet: "Reporte"
  })

  const ExportToExcel = (tableId) => {
    const table = document.getElementById(tableId)
    const ws = XLSX.utils.table_to_sheet(table);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    XLSX.writeFile(wb, `${tableId}.xlsx`);
  }

  const retornoTablaMoras = () => {
    return <div className="container" >
      <h3 className="mt-4 mb-4">INTEGRACION DE CONTRATOS DE PRESTAMOS A (FOX):</h3>
      <table id="tabla-reporte-moras" className="table table-bordered table-primary" ref={tableref}>
        <thead>
          <tr>
            <th className='bg-primary' rowSpan='2'>Descripcion</th>
            <th className='bg-primary' colSpan="2">{obtenerMesAnterior(obtenerMesAnterior(mes)).nombre}</th>
            <th className='bg-primary' colSpan="2">{obtenerMesAnterior(mes).nombre}</th>
            <th className='bg-primary' colSpan="2">{mes.nombre}</th>
          </tr>
          <tr>
            <th className='bg-primary'>Prestat</th>
            <th className='bg-primary'>Total</th>
            <th className='bg-primary'>Prestat</th>
            <th className='bg-primary'>Total</th>
            <th className='bg-primary'>Prestat</th>
            <th className='bg-primary'>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr><th colSpan="7"></th></tr>
          {/* <tr>
            <th>Contratos al dia</th>
            <th>{reportDelays.anterior2.dia.cantidad}</th>
            <th>{parseFloat(reportDelays.anterior2.dia.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelays.anterior.dia.cantidad}</th>
            <th>{parseFloat(reportDelays.anterior.dia.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelays.actual.dia.cantidad}</th>
            <th>{parseFloat(reportDelays.actual.dia.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr> */}
          <tr>
            <th>Contratos con 1 cuota de atraso</th>
            <th>{reportDelays.anterior2.una.cantidad}</th>
            <th>{parseFloat(reportDelays.anterior2.una.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelays.anterior.una.cantidad}</th>
            <th>{parseFloat(reportDelays.anterior.una.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelays.actual.una.cantidad}</th>
            <th>{parseFloat(reportDelays.actual.una.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Contratos con 2 a 6 cuotas de atraso</th>
            <th>{reportDelays.anterior2.dos_seis.cantidad}</th>
            <th>{parseFloat(reportDelays.anterior2.dos_seis.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelays.anterior.dos_seis.cantidad}</th>
            <th>{parseFloat(reportDelays.anterior.dos_seis.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelays.actual.dos_seis.cantidad}</th>
            <th>{parseFloat(reportDelays.actual.dos_seis.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Contratos con 7 a 12 cuotas de atraso</th>
            <th>{reportDelays.anterior2.siete_doce.cantidad}</th>
            <th>{parseFloat(reportDelays.anterior2.siete_doce.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelays.anterior.siete_doce.cantidad}</th>
            <th>{parseFloat(reportDelays.anterior.siete_doce.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelays.actual.siete_doce.cantidad}</th>
            <th>{parseFloat(reportDelays.actual.siete_doce.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Contratos con 13 a 24 cuotas de atraso</th>
            <th>{reportDelays.anterior2.trece_veinticinco.cantidad}</th>
            <th>{parseFloat(reportDelays.anterior2.trece_veinticinco.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelays.anterior.trece_veinticinco.cantidad}</th>
            <th>{parseFloat(reportDelays.anterior.trece_veinticinco.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelays.actual.trece_veinticinco.cantidad}</th>
            <th>{parseFloat(reportDelays.actual.trece_veinticinco.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Contratos con mas de 25 cuotas de atraso</th>
            <th>{reportDelays.anterior2.mas_veinticinco.cantidad}</th>
            <th>{parseFloat(reportDelays.anterior2.mas_veinticinco.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelays.anterior.mas_veinticinco.cantidad}</th>
            <th>{parseFloat(reportDelays.anterior.mas_veinticinco.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelays.actual.mas_veinticinco.cantidad}</th>
            <th>{parseFloat(reportDelays.actual.mas_veinticinco.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr><th colSpan="7"></th></tr>
          <tr>
            <th className='bg-primary'>Total</th>
            <th className='bg-success'>{reportDelays.anterior2.cantidad_total-reportDelays.anterior2.dia.cantidad}</th>
            <th className='bg-success'>{(parseFloat(reportDelays.anterior2.total)-parseFloat(reportDelays.anterior2.dia.total)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th className='bg-success'>{reportDelays.anterior.cantidad_total-reportDelays.anterior.dia.cantidad}</th>
            <th className='bg-success'>{(parseFloat(reportDelays.anterior.total)-parseFloat(reportDelays.anterior.dia.total)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th className='bg-success'>{reportDelays.actual.cantidad_total-reportDelays.actual.dia.cantidad}</th>
            <th className='bg-success'>{(parseFloat(reportDelays.actual.total)-parseFloat(reportDelays.actual.dia.total)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
        </tbody>
      </table>
      <Button label="Descargar Reporte" icon="pi pi-download" className="p-button-success custom-button" onClick={() => ExportToExcel("tabla-reporte-moras")} />
    </div>
  }

  const retornoTablasConteos = () => {
    return <div className="container">
      <h3 className="mt-4 mb-4">REPORTES DE MOROSIDAD (FOX)</h3>
      <table id="tabla-reporte-counts" className="table table-bordered table-primary" ref={tableref1}>
        <thead>
          <tr>
            <th className='bg-primary' rowSpan="2">Estado</th>
            <th className='bg-primary' colSpan="2">{obtenerMesAnterior(obtenerMesAnterior(mes)).nombre}</th>
            <th className='bg-primary' colSpan="2">{obtenerMesAnterior(mes).nombre}</th>
            <th className='bg-primary' colSpan="2">{mes.nombre}</th>
          </tr>
          <tr>
            <th className='bg-primary'>No de Prest</th>
            <th className='bg-primary'>Total</th>
            <th className='bg-primary'>No de Prest</th>
            <th className='bg-primary'>Total</th>
            <th className='bg-primary'>No de Prest</th>
            <th className='bg-primary'>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr><th colSpan="7"></th></tr>
          <tr>
            <th>Vigentes al dia</th>
            <th>{reportCount.anterior2.vigentes_al_dia.cantidad}</th>
            <th>{parseFloat(reportCount.anterior2.vigentes_al_dia.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCount.anterior.vigentes_al_dia.cantidad}</th>
            <th>{parseFloat(reportCount.anterior.vigentes_al_dia.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCount.actual.vigentes_al_dia.cantidad}</th>
            <th>{parseFloat(reportCount.actual.vigentes_al_dia.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Vigentes en mora</th>
            <th>{reportCount.anterior2.vigentes_en_mora.cantidad}</th>
            <th>{parseFloat(reportCount.anterior2.vigentes_en_mora.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCount.anterior.vigentes_en_mora.cantidad}</th>
            <th>{parseFloat(reportCount.anterior.vigentes_en_mora.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCount.actual.vigentes_en_mora.cantidad}</th>
            <th>{parseFloat(reportCount.actual.vigentes_en_mora.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Vencidos</th>
            <th>{reportCount.anterior2.vencidos.cantidad}</th>
            <th>{parseFloat(reportCount.anterior2.vencidos.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCount.anterior.vencidos.cantidad}</th>
            <th>{parseFloat(reportCount.anterior.vencidos.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCount.actual.vencidos.cantidad}</th>
            <th>{parseFloat(reportCount.actual.vencidos.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Cobro judicial</th>
            <th>{reportCount.anterior2.cobro_judicial.cantidad}</th>
            <th>{parseFloat(reportCount.anterior2.cobro_judicial.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCount.anterior.cobro_judicial.cantidad}</th>
            <th>{parseFloat(reportCount.anterior.cobro_judicial.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCount.actual.cobro_judicial.cantidad}</th>
            <th>{parseFloat(reportCount.actual.cobro_judicial.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Regularizacion</th>
            <th>{reportCount.anterior2.regularizacion.cantidad}</th>
            <th>{parseFloat(reportCount.anterior2.regularizacion.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCount.anterior.regularizacion.cantidad}</th>
            <th>{parseFloat(reportCount.anterior.regularizacion.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCount.actual.regularizacion.cantidad}</th>
            <th>{parseFloat(reportCount.actual.regularizacion.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr><th colSpan="7"></th></tr>
          <tr>
            <th className='bg-primary'>Total</th>
            <th className='bg-success'>{reportCount.anterior2.cantidad_total}</th>
            <th className='bg-success'>{parseFloat(reportCount.anterior2.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th className='bg-success'>{reportCount.anterior.cantidad_total}</th>
            <th className='bg-success'>{parseFloat(reportCount.anterior.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th className='bg-success'>{reportCount.actual.cantidad_total}</th>
            <th className='bg-success'>{parseFloat(reportCount.actual.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
        </tbody>
      </table>
      <Button label="Descargar Reporte" icon="pi pi-download" className="p-button-success custom-button" onClick={() => ExportToExcel("tabla-reporte-counts")} />
    </div>
  }

  const retornoTablasBalance = () => {
    var cuenta_corriente1 = parseFloat(reportBalance.anterior2.cuenta_corriente.total)
    var cuenta_corriente2 = parseFloat(reportBalance.anterior.cuenta_corriente.total)
    var cuenta_corriente3 = parseFloat(reportBalance.actual.cuenta_corriente.total)

    var cantidad_morosos1 = reportDelays.anterior2.cantidad_total-reportDelays.anterior2.dia.cantidad
    var cantidad_morosos2 = reportDelays.anterior.cantidad_total-reportDelays.anterior.dia.cantidad
    var cantidad_morosos3 = reportDelays.actual.cantidad_total-reportDelays.actual.dia.cantidad

    var total_morosos1 = (parseFloat(reportDelays.anterior2.total)-parseFloat(reportDelays.anterior2.dia.total))
    var total_morosos2 = (parseFloat(reportDelays.anterior.total)-parseFloat(reportDelays.anterior.dia.total))
    var total_morosos3 = (parseFloat(reportDelays.actual.total)-parseFloat(reportDelays.actual.dia.total))

    var total_total1 = parseFloat(reportBalance.anterior2.cuenta_corriente.total) + parseFloat(reportBalance.anterior2.saldos_negativos.total) - parseFloat(reportBalance.anterior2.saldos_menores_5.total)
    var total_total2 = parseFloat(reportBalance.anterior.cuenta_corriente.total) + parseFloat(reportBalance.anterior.saldos_negativos.total) - parseFloat(reportBalance.anterior.saldos_menores_5.total)
    var total_total3 = parseFloat(reportBalance.actual.cuenta_corriente.total) + parseFloat(reportBalance.actual.saldos_negativos.total) - parseFloat(reportBalance.actual.saldos_menores_5.total)

    return <div className="container">
      <h3 className="mt-4 mb-4">REPORTES DE CARTERA DE PRESTAMOS (FOX)</h3>
      <table id="tabla-reporte-balance" className="table table-bordered table-primary" ref={tableref2}>
        <thead>
          <tr>
            <th className='bg-primary' rowSpan="3">Total Cartera de Prestamos</th>
            <th className='bg-primary' colSpan="3">{obtenerMesAnterior(obtenerMesAnterior(mes)).nombre}</th>
            <th className='bg-primary' colSpan="3">{obtenerMesAnterior(mes).nombre}</th>
            <th className='bg-primary' colSpan="3">{mes.nombre}</th>
          </tr>
          <tr>
            <th className='bg-primary'>No</th>
            <th className='bg-primary'>Monto</th>
            <th className='bg-primary'>%</th>
            <th className='bg-primary'>No</th>
            <th className='bg-primary'>Monto</th>
            <th className='bg-primary'>%</th>
            <th className='bg-primary'>No</th>
            <th className='bg-primary'>Monto</th>
            <th className='bg-primary'>%</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan="10"></th>
          </tr>
          <tr>
            <th>Cuenta Corriente</th>
            <th>{reportBalance.anterior2.cuenta_corriente.cantidad}</th>
            <th>{parseFloat(reportBalance.anterior2.cuenta_corriente.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalance.anterior2.cuenta_corriente.total)*100 / cuenta_corriente1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportBalance.anterior.cuenta_corriente.cantidad}</th>
            <th>{parseFloat(reportBalance.anterior.cuenta_corriente.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalance.anterior.cuenta_corriente.total)*100 / cuenta_corriente2).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportBalance.actual.cuenta_corriente.cantidad}</th>
            <th>{parseFloat(reportBalance.actual.cuenta_corriente.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalance.actual.cuenta_corriente.total)*100 / cuenta_corriente3).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Saldos Negativos</th>
            <th>{reportBalance.anterior2.saldos_negativos.cantidad}</th>
            <th>{parseFloat(reportBalance.anterior2.saldos_negativos.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalance.anterior2.saldos_negativos.total)*100 / cuenta_corriente1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportBalance.anterior.saldos_negativos.cantidad}</th>
            <th>{parseFloat(reportBalance.anterior.saldos_negativos.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalance.anterior.saldos_negativos.total)*100 / cuenta_corriente2).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportBalance.actual.saldos_negativos.cantidad}</th>
            <th>{parseFloat(reportBalance.actual.saldos_negativos.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalance.actual.saldos_negativos.total)*100 / cuenta_corriente3).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>{"Saldos <= 5"}</th>
            <th>{reportBalance.anterior2.saldos_menores_5.cantidad}</th>
            <th>{parseFloat(reportBalance.anterior2.saldos_menores_5.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalance.anterior2.saldos_menores_5.total)*100 / cuenta_corriente1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportBalance.anterior.saldos_menores_5.cantidad}</th>
            <th>{parseFloat(reportBalance.anterior.saldos_menores_5.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalance.anterior.saldos_menores_5.total)*100 / cuenta_corriente2).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportBalance.actual.saldos_menores_5.cantidad}</th>
            <th>{parseFloat(reportBalance.actual.saldos_menores_5.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalance.actual.saldos_menores_5.total)*100 / cuenta_corriente3).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Total</th>
            <th>{reportBalance.anterior2.total.cantidad}</th>
            <th>{parseFloat(total_total1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(total_total1)*100 / cuenta_corriente1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportBalance.anterior.total.cantidad}</th>
            <th>{parseFloat(total_total2).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(total_total2)*100 / cuenta_corriente2).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportBalance.actual.total.cantidad}</th>
            <th>{parseFloat(total_total3).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(total_total3)*100 / cuenta_corriente3).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Total Mora</th>
            <th>{cantidad_morosos1}</th>
            <th>{(total_morosos1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(total_morosos1*100 / cuenta_corriente1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{cantidad_morosos2}</th>
            <th>{(total_morosos2).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(total_morosos2*100 / cuenta_corriente2).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{cantidad_morosos3}</th>
            <th>{(total_morosos3).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(total_morosos3*100 / cuenta_corriente3).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr><th colSpan="10"></th></tr>
        </tbody>
      </table>
      <Button label="Descargar Reporte" icon="pi pi-download" className="p-button-success custom-button" onClick={() => ExportToExcel("tabla-reporte-balance")} />
    </div>
  }

  const retornoTablaMorasInmediatos = () => {
    return <div className="container" >
      <h3 className="mt-4 mb-4">INTEGRACION DE CONTRATOS DE PRESTAMOS A (INMEDIATOS):</h3>
      <table id="tabla-reporte-moras-inmediatos" className="table table-bordered table-primary" ref={tableref3}>
        <thead>
          <tr>
            <th className='bg-primary' rowSpan='2'>Descripcion</th>
            <th className='bg-primary' colSpan="2">{obtenerMesAnterior(obtenerMesAnterior(mes)).nombre}</th>
            <th className='bg-primary' colSpan="2">{obtenerMesAnterior(mes).nombre}</th>
            <th className='bg-primary' colSpan="2">{mes.nombre}</th>
          </tr>
          <tr>
            <th className='bg-primary'>Prestat</th>
            <th className='bg-primary'>Total</th>
            <th className='bg-primary'>Prestat</th>
            <th className='bg-primary'>Total</th>
            <th className='bg-primary'>Prestat</th>
            <th className='bg-primary'>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr><th colSpan="7"></th></tr>
          {/* <tr>
            <th>Contratos al dia</th>
            <th>{reportDelaysInmediatos.anterior2.dia.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.anterior2.dia.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelaysInmediatos.anterior.dia.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.anterior.dia.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelaysInmediatos.actual.dia.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.actual.dia.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr> */}
          <tr>
            <th>Contratos con 1 cuota de atraso</th>
            <th>{reportDelaysInmediatos.anterior2.una.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.anterior2.una.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelaysInmediatos.anterior.una.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.anterior.una.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelaysInmediatos.actual.una.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.actual.una.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Contratos con 2 a 6 cuotas de atraso</th>
            <th>{reportDelaysInmediatos.anterior2.dos_seis.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.anterior2.dos_seis.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelaysInmediatos.anterior.dos_seis.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.anterior.dos_seis.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelaysInmediatos.actual.dos_seis.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.actual.dos_seis.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Contratos con 7 a 12 cuotas de atraso</th>
            <th>{reportDelaysInmediatos.anterior2.siete_doce.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.anterior2.siete_doce.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelaysInmediatos.anterior.siete_doce.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.anterior.siete_doce.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelaysInmediatos.actual.siete_doce.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.actual.siete_doce.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Contratos con 13 a 24 cuotas de atraso</th>
            <th>{reportDelaysInmediatos.anterior2.trece_veinticinco.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.anterior2.trece_veinticinco.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelaysInmediatos.anterior.trece_veinticinco.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.anterior.trece_veinticinco.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelaysInmediatos.actual.trece_veinticinco.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.actual.trece_veinticinco.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Contratos con mas de 25 cuotas de atraso</th>
            <th>{reportDelaysInmediatos.anterior2.mas_veinticinco.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.anterior2.mas_veinticinco.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelaysInmediatos.anterior.mas_veinticinco.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.anterior.mas_veinticinco.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportDelaysInmediatos.actual.mas_veinticinco.cantidad}</th>
            <th>{parseFloat(reportDelaysInmediatos.actual.mas_veinticinco.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr><th colSpan="7"></th></tr>
          <tr>
            <th className='bg-primary'>Total</th>
            <th className='bg-success'>{reportDelaysInmediatos.anterior2.cantidad_total-reportDelaysInmediatos.anterior2.dia.cantidad}</th>
            <th className='bg-success'>{(parseFloat(reportDelaysInmediatos.anterior2.total)-parseFloat(reportDelaysInmediatos.anterior2.dia.total)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th className='bg-success'>{reportDelaysInmediatos.anterior.cantidad_total-reportDelaysInmediatos.anterior.dia.cantidad}</th>
            <th className='bg-success'>{(parseFloat(reportDelaysInmediatos.anterior.total)-parseFloat(reportDelaysInmediatos.anterior.dia.total)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th className='bg-success'>{reportDelaysInmediatos.actual.cantidad_total-reportDelaysInmediatos.actual.dia.cantidad}</th>
            <th className='bg-success'>{(parseFloat(reportDelaysInmediatos.actual.total)-parseFloat(reportDelaysInmediatos.actual.dia.total)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
        </tbody>
      </table>
      <Button label="Descargar Reporte" icon="pi pi-download" className="p-button-success custom-button" onClick={() => ExportToExcel("tabla-reporte-moras-inmediatos")} />
    </div>
  }

  const retornoTablasConteosInmediatos = () => {
    return <div className="container">
      <h3 className="mt-4 mb-4">REPORTES DE MOROSIDAD (INMEDIATOS)</h3>
      <table id="tabla-reporte-counts-inmediatos" className="table table-bordered table-primary" ref={tableref4}>
        <thead>
          <tr>
            <th className='bg-primary' rowSpan="2">Estado</th>
            <th className='bg-primary' colSpan="2">{obtenerMesAnterior(obtenerMesAnterior(mes)).nombre}</th>
            <th className='bg-primary' colSpan="2">{obtenerMesAnterior(mes).nombre}</th>
            <th className='bg-primary' colSpan="2">{mes.nombre}</th>
          </tr>
          <tr>
            <th className='bg-primary'>No de Prest</th>
            <th className='bg-primary'>Total</th>
            <th className='bg-primary'>No de Prest</th>
            <th className='bg-primary'>Total</th>
            <th className='bg-primary'>No de Prest</th>
            <th className='bg-primary'>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr><th colSpan="7"></th></tr>
          <tr>
            <th>Vigentes al dia</th>
            <th>{reportCountInmediatos.anterior2.vigentes_al_dia.cantidad}</th>
            <th>{parseFloat(reportCountInmediatos.anterior2.vigentes_al_dia.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCountInmediatos.anterior.vigentes_al_dia.cantidad}</th>
            <th>{parseFloat(reportCountInmediatos.anterior.vigentes_al_dia.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCountInmediatos.actual.vigentes_al_dia.cantidad}</th>
            <th>{parseFloat(reportCountInmediatos.actual.vigentes_al_dia.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Vigentes en mora</th>
            <th>{reportCountInmediatos.anterior2.vigentes_en_mora.cantidad}</th>
            <th>{parseFloat(reportCountInmediatos.anterior2.vigentes_en_mora.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCountInmediatos.anterior.vigentes_en_mora.cantidad}</th>
            <th>{parseFloat(reportCountInmediatos.anterior.vigentes_en_mora.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCountInmediatos.actual.vigentes_en_mora.cantidad}</th>
            <th>{parseFloat(reportCountInmediatos.actual.vigentes_en_mora.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Vencidos</th>
            <th>{reportCountInmediatos.anterior2.vencidos.cantidad}</th>
            <th>{parseFloat(reportCountInmediatos.anterior2.vencidos.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCountInmediatos.anterior.vencidos.cantidad}</th>
            <th>{parseFloat(reportCountInmediatos.anterior.vencidos.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCountInmediatos.actual.vencidos.cantidad}</th>
            <th>{parseFloat(reportCountInmediatos.actual.vencidos.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Cobro judicial</th>
            <th>{reportCountInmediatos.anterior2.cobro_judicial.cantidad}</th>
            <th>{parseFloat(reportCountInmediatos.anterior2.cobro_judicial.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCountInmediatos.anterior.cobro_judicial.cantidad}</th>
            <th>{parseFloat(reportCountInmediatos.anterior.cobro_judicial.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCountInmediatos.actual.cobro_judicial.cantidad}</th>
            <th>{parseFloat(reportCountInmediatos.actual.cobro_judicial.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Regularizacion</th>
            <th>{reportCountInmediatos.anterior2.regularizacion.cantidad}</th>
            <th>{parseFloat(reportCountInmediatos.anterior2.regularizacion.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCountInmediatos.anterior.regularizacion.cantidad}</th>
            <th>{parseFloat(reportCountInmediatos.anterior.regularizacion.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportCountInmediatos.actual.regularizacion.cantidad}</th>
            <th>{parseFloat(reportCountInmediatos.actual.regularizacion.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr><th colSpan="7"></th></tr>
          <tr>
            <th className='bg-primary'>Total</th>
            <th className='bg-success'>{reportCountInmediatos.anterior2.cantidad_total}</th>
            <th className='bg-success'>{parseFloat(reportCountInmediatos.anterior2.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th className='bg-success'>{reportCountInmediatos.anterior.cantidad_total}</th>
            <th className='bg-success'>{parseFloat(reportCountInmediatos.anterior.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th className='bg-success'>{reportCountInmediatos.actual.cantidad_total}</th>
            <th className='bg-success'>{parseFloat(reportCountInmediatos.actual.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
        </tbody>
      </table>
      <Button label="Descargar Reporte" icon="pi pi-download" className="p-button-success custom-button" onClick={() => ExportToExcel("tabla-reporte-counts-inmediatos")} />
    </div>
  }

  const retornoTablasBalanceInmediatos = () => {
    var cuenta_corriente1 = parseFloat(reportBalanceInmediatos.anterior2.cuenta_corriente.total)
    var cuenta_corriente2 = parseFloat(reportBalanceInmediatos.anterior.cuenta_corriente.total)
    var cuenta_corriente3 = parseFloat(reportBalanceInmediatos.actual.cuenta_corriente.total)

    var cantidad_morosos1 = reportDelaysInmediatos.anterior2.cantidad_total-reportDelaysInmediatos.anterior2.dia.cantidad
    var cantidad_morosos2 = reportDelaysInmediatos.anterior.cantidad_total-reportDelaysInmediatos.anterior2.dia.cantidad
    var cantidad_morosos3 = reportDelaysInmediatos.actual.cantidad_total-reportDelaysInmediatos.anterior2.dia.cantidad

    var total_morosos1 = (parseFloat(reportDelaysInmediatos.anterior2.total)-parseFloat(reportDelaysInmediatos.anterior2.dia.total))
    var total_morosos2 = (parseFloat(reportDelaysInmediatos.anterior.total)-parseFloat(reportDelaysInmediatos.anterior.dia.total))
    var total_morosos3 = (parseFloat(reportDelaysInmediatos.actual.total)-parseFloat(reportDelaysInmediatos.actual.dia.total))

    var total_total1 = parseFloat(reportBalanceInmediatos.anterior2.cuenta_corriente.total) + parseFloat(reportBalanceInmediatos.anterior2.saldos_negativos.total) - parseFloat(reportBalanceInmediatos.anterior2.saldos_menores_5.total)
    var total_total2 = parseFloat(reportBalanceInmediatos.anterior.cuenta_corriente.total) + parseFloat(reportBalanceInmediatos.anterior.saldos_negativos.total) - parseFloat(reportBalanceInmediatos.anterior.saldos_menores_5.total)
    var total_total3 = parseFloat(reportBalanceInmediatos.actual.cuenta_corriente.total) + parseFloat(reportBalanceInmediatos.actual.saldos_negativos.total) - parseFloat(reportBalanceInmediatos.actual.saldos_menores_5.total)


    return <div className="container">
      <h3 className="mt-4 mb-4">REPORTES DE CARTERA DE PRESTAMOS (INMEDIATOS)</h3>
      <table id="tabla-reporte-balance-inmediatos" className="table table-bordered table-primary" ref={tableref5}>
        <thead>
          <tr>
            <th className='bg-primary' rowSpan="3">Total Cartera de Prestamos</th>
            <th className='bg-primary' colSpan="3">{obtenerMesAnterior(obtenerMesAnterior(mes)).nombre}</th>
            <th className='bg-primary' colSpan="3">{obtenerMesAnterior(mes).nombre}</th>
            <th className='bg-primary' colSpan="3">{mes.nombre}</th>
          </tr>
          <tr>
            <th className='bg-primary'>No</th>
            <th className='bg-primary'>Monto</th>
            <th className='bg-primary'>%</th>
            <th className='bg-primary'>No</th>
            <th className='bg-primary'>Monto</th>
            <th className='bg-primary'>%</th>
            <th className='bg-primary'>No</th>
            <th className='bg-primary'>Monto</th>
            <th className='bg-primary'>%</th>
          </tr>
        </thead>
        <tbody>
          <tr><th colSpan="10"></th></tr>
          <tr>
            <th>Cuenta Corriente</th>
            <th>{reportBalanceInmediatos.anterior2.cuenta_corriente.cantidad}</th>
            <th>{parseFloat(reportBalanceInmediatos.anterior2.cuenta_corriente.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalanceInmediatos.anterior2.cuenta_corriente.total)*100 / cuenta_corriente1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportBalanceInmediatos.anterior.cuenta_corriente.cantidad}</th>
            <th>{parseFloat(reportBalanceInmediatos.anterior.cuenta_corriente.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalanceInmediatos.anterior.cuenta_corriente.total)*100 / cuenta_corriente2).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportBalanceInmediatos.actual.cuenta_corriente.cantidad}</th>
            <th>{parseFloat(reportBalanceInmediatos.actual.cuenta_corriente.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalanceInmediatos.actual.cuenta_corriente.total)*100 / cuenta_corriente3).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Saldos Negativos</th>
            <th>{reportBalanceInmediatos.anterior2.saldos_negativos.cantidad}</th>
            <th>{parseFloat(reportBalanceInmediatos.anterior2.saldos_negativos.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalanceInmediatos.anterior2.saldos_negativos.total)*100 / cuenta_corriente1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportBalanceInmediatos.anterior.saldos_negativos.cantidad}</th>
            <th>{parseFloat(reportBalanceInmediatos.anterior.saldos_negativos.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalanceInmediatos.anterior.saldos_negativos.total)*100 / cuenta_corriente2).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportBalanceInmediatos.actual.saldos_negativos.cantidad}</th>
            <th>{parseFloat(reportBalanceInmediatos.actual.saldos_negativos.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalanceInmediatos.actual.saldos_negativos.total)*100 / cuenta_corriente3).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>{"Saldos <= 5"}</th>
            <th>{reportBalanceInmediatos.anterior2.saldos_menores_5.cantidad}</th>
            <th>{parseFloat(reportBalanceInmediatos.anterior2.saldos_menores_5.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalanceInmediatos.anterior2.saldos_menores_5.total)*100 / cuenta_corriente1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportBalanceInmediatos.anterior.saldos_menores_5.cantidad}</th>
            <th>{parseFloat(reportBalanceInmediatos.anterior.saldos_menores_5.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalanceInmediatos.anterior.saldos_menores_5.total)*100 / cuenta_corriente2).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportBalanceInmediatos.actual.saldos_menores_5.cantidad}</th>
            <th>{parseFloat(reportBalanceInmediatos.actual.saldos_menores_5.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(reportBalanceInmediatos.actual.saldos_menores_5.total)*100 / cuenta_corriente3).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Total</th>
            <th>{reportBalanceInmediatos.anterior2.total.cantidad}</th>
            <th>{parseFloat(total_total1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(total_total1)*100 / cuenta_corriente1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportBalanceInmediatos.anterior.total.cantidad}</th>
            <th>{parseFloat(total_total2).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(total_total2)*100 / cuenta_corriente2).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{reportBalanceInmediatos.actual.total.cantidad}</th>
            <th>{parseFloat(total_total3).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{(parseFloat(total_total3)*100 / cuenta_corriente3).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr>
            <th>Total Morosos</th>
            <th>{cantidad_morosos1}</th>
            <th>{(total_morosos1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{((total_morosos1)*100 / cuenta_corriente1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{cantidad_morosos2}</th>
            <th>{(total_morosos2).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{((total_morosos2)*100 / cuenta_corriente2).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{cantidad_morosos3}</th>
            <th>{(total_morosos3).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
            <th>{((total_morosos3)*100 / cuenta_corriente3).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</th>
          </tr>
          <tr><th colSpan="10"></th></tr>
        </tbody>
      </table>
      <Button label="Descargar Reporte" icon="pi pi-download" className="p-button-success custom-button" onClick={() => ExportToExcel("tabla-reporte-balance-inmediatos")} />
    </div>
  }

  return (
    <>
      <div className='text-center'>
        <h3>Prestamos caidos en mora</h3>
        <br />
      </div>
      <div className='primario'>
        <div className="grid p-fluid">
          <div className='col-12 md:col-2'>
            <label className="font-bold block mb-2 text-color">Año</label>
            <InputNumber value={anio} onValueChange={(e) => setAnio(e.value)}
              mode="decimal" showButtons min={0} useGrouping={false} />
          </div>
          <div className='col-12 md:col-2'>
            <label className="font-bold block mb-2 text-color">Mes</label>
            <Dropdown
              value={mes}
              options={meses}
              optionLabel="nombre"
              placeholder="Seleccionar mes"
              onChange={(e) => {
                console.log(e.value)
                setMes(e.value)
              }}
            />
          </div>
          <div className='col-12 md:col-1'>
            <br />
            <Button type='button' className='p-button-rounded p-button-success'
              icon="pi pi-search" onClick={() => console.log(mes)} tooltip='Buscar registros'
              style={{ marginTop: '5%' }} />
          </div>
          <div className='col-12 md:col-2' style={{ marginLeft: 'auto', marginRight: '0' }}>
            <br />
            <Button type="button" icon="pi pi-plus" label="Generar tablas"
              onClick={() => {
                let fecha = obtenerFecha()
                fetchReportDelays(fecha)
                fetchReportCounts(fecha)
                fetchBalance(fecha)
                fetchReportDelaysInmediatos(fecha)
                fetchReportCountsInmediatos(fecha)
                fetchBalanceInmediatos(fecha)
              }} style={{ marginTop: '1%' }} />
          </div>
        </div>
        <br />
        <DataTable value={delays} paginator rows={10} paginatorPosition="top"
          rowsPerPageOptions={[10, 25, 50, 100, 1000]}
          // tableStyle={{ minWidth: '50rem' }}
          filters={filters}
          size='small'
        >
          {columnClass("registro", "Registro", true, "Buscar por nombre")}
          {columnClass("partida", "Partida", true, "Buscar por registro")}
          <Column field="cantidad" header="Cantidad" ></Column>
          <Column field="capital_faltante" header="Capital Faltante"></Column>
          <Column field="estado" header="Estado" ></Column>
          <Column field="interes_faltante" header="Interes Faltante" ></Column>
          <Column field="persona" header="Nombre" ></Column>
          <Column field="faltantes" header='Cuotas Pendientes'></Column>
          <Column field="cuotas_mora" header='Rango en mora'></Column>
          <Column body={renderButtonColumn} header='Detalle'></Column>
        </DataTable>
        <Button label="Descargar Reporte" icon="pi pi-download" className="p-button-success custom-button" onClick={downloadReport}></Button>
        <Button label="Presentacion Reportes PDF" icon="pi pi-download" className="p-button-success custom-button" onClick={() => { generarPDF() }}></Button>
        {/* <Button label="Presentacion Reportes Excel" icon="pi pi-download" className="p-button-success custom-button" onClick={() => { generarPDF() }}></Button> */}
        {/* GenerarPDF(retornoTablaMoras(),retornoTablasConteos()) */}
        <br />
      </div>
      <div className='text-center'>
        <br />
        <br />
      </div>

      {reportBalance && reportCount && reportDelays && retornoTablasBalance()}
      {reportDelays && reportCount && reportBalance && retornoTablaMoras()}
      {reportCount && reportDelays && reportBalance && retornoTablasConteos()}
      {reportBalanceInmediatos && reportCountInmediatos && reportDelaysInmediatos && retornoTablasBalanceInmediatos()}
      {reportDelaysInmediatos && reportCountInmediatos && reportBalanceInmediatos && retornoTablaMorasInmediatos()}
      {reportCountInmediatos && reportDelaysInmediatos && reportBalanceInmediatos  && retornoTablasConteosInmediatos()}

    </>
  );
};

export default LogsModule;
