import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { FilterMatchMode } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { requestOptions } from '../misc/requestOptions';

function GestionDescuentos(props) {
    const navigate = useNavigate();

    const { ptd, plz, reg } = useParams();

    const items = [
        { label: 'NÓMINA', command: () => { navigate("/nominas") } },
        { label: 'DESCUENTOS', command: () => { navigate("/nominas/descuentos") } },
        { label: 'GESTIÓN', command: () => { navigate("/nominas/gestion-descuentos") } }
    ];
    const home = { icon: 'pi pi-home', command: () => { navigate("/") } };

    const toast = useRef(null);

    const [registro, setRegistro] = useState('');
    const [partida, setPartida] = useState('');
    const [plaza, setPlaza] = useState('');
    const [numero_dpi, setDPI] = useState('');
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [idPersona, setIdPersona] = useState(null);

    const [listado_descuentos, setListadoDescuentos] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (ptd && plz && reg) {
            setPartida(ptd);
            setPlaza(plz);
            setRegistro(reg);
            request_data(true, ptd, plz, reg);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ptd, plz, reg]);

    const request_data = (showMessage = true, _ptd = null, _plz = null, _reg = null) => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL_DESCUENTOS_GESTION,
            requestOptions('POST', {
                partida: _ptd || partida,
                plaza: _plz || plaza,
                registro: _reg || registro,
                busqueda: 'ppr'
            }))
            .then((response) => response.json())
            .then((data) => {
                if (showMessage)
                    toast.current.show({ severity: data.severity, summary: data.message || data.error });
                const x = data.data;
                if (x && x.datos_persona) {
                    setNombres(x.datos_persona.nombres);
                    setApellidos(x.datos_persona.apellidos);
                    setDPI(x.datos_persona.numero_dpi);
                    setIdPersona(x.datos_persona.idpersona);
                }
                if (x && x.listado_descuentos) {
                    setListadoDescuentos(x.listado_descuentos);
                }
                setLoading(false);
            });
    }

    const reset_form = () => {
        setPartida('');
        setPlaza('');
        setRegistro('');
        setNombres('');
        setApellidos('');
        setDPI('');
        setLoading(false);
        setListadoDescuentos(null);
        setIdPersona(null);
        navigate('/nominas/gestion-descuentos');
    }

    const update_descuento = (newData) => {
        fetch((process.env.REACT_APP_API_URL_DESCUENTOS_GESTION + '/update'),
            requestOptions('POST', {
                idPersona: idPersona,
                idcc: newData.idcc,
                nuevo_valor: newData.field_2
            }))
            .then((response) => response.json())
            .then((data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                request_data(false);
            });
    }

    const descargarHistorico = async (idpersona, iddb) => {
        setLoading(iddb);
        try {
            const response = await fetch(process.env.REACT_APP_API_URL_HISTORICO_DESCUENTOS,
                requestOptions('POST', {
                    idpersona: idpersona,
                    iddb: iddb
                }));
            if (!response.ok) throw new Error('Error: No fue posible generar la constancia.');
            const pdfBlob = await response.blob();
            // Crear un objeto URL a partir del Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);
            // Crear un enlace temporal para descargar el PDF
            // const date = formatDate(new Date(), '-') || '';
            const a = document.createElement('a');
            a.href = pdfUrl;
            a.download = `Reg${registro}_Histórico${iddb}.pdf`;
            a.click();
            // Liberar la URL del objeto Blob
            URL.revokeObjectURL(pdfUrl);
            toast.current.show({ severity: 'success', summary: 'Histórico generado.' });
        } catch (error) {
            console.error('Error al descargar el PDF.');
            toast.current.show({ severity: 'error', summary: error.toString() });
        }
        setLoading(false);
    }

    const textEditor = (options) => { return <InputNumber min={0} onValueChange={(e) => options.editorCallback(e.target.value)} mode="currency" currency="GTQ" /> }

    /* Filters */
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(20);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };
    const pg_template = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];
            return (
                <React.Fragment>
                    <span className="mx-0">Elementos por página: </span>
                    <Dropdown className="mr-4 table__paginator" value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        'CurrentPageReport': (options) => {
            return (
                <span style={{ width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} de {options.totalRecords}
                </span>
            )
        }
    };
    const header = (
        <div className="table-header flex">
            <div className='my-auto'>Gestión de bonificación y descuentos</div>
            <div className='ml-auto'>
                <span className="p-input-icon-right">
                    <i className="pi pi-search" />
                    <InputText style={{ width: '100%' }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Buscar" />
                </span>
            </div>
        </div>
    );
    const valueColumn = (rowData) => {
        return <span className={`status-badge-nom status-liquido`} style={{ fontSize: '14px' }}>Q. {rowData.monto}</span>;
    }
    const optionBody = (rowData) => {
        return <Button className="p-button-rounded p-button-raised p-button-warning" type="button" icon="pi pi-print" tooltip='Descargar'
            onClick={() => descargarHistorico(idPersona, rowData.id)} loading={loading === rowData.id} />
    }

    return (
        <div className="menu__lbsalarios">
            <Toast ref={toast}></Toast>
            <BreadCrumb model={items} home={home} />
            <Fieldset className='fieldset__lbsalarios' legend="Búsqueda por partida, plaza y registro" toggleable={false} >
                <div className="grid p-fluid mt-1">
                    <div className="field col-12 md:col-10 col_toggle">
                        {/* PARTIDA */}
                        <div className="grid p-fluid">
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText disabled={listado_descuentos} type="text" keyfilter="pint" value={partida} onChange={(e) => setPartida(e.target.value)} />
                                        <label>Partida</label>
                                    </span>
                                </div>
                            </div>
                            {/* PLAZA */}
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText disabled={listado_descuentos} type="text" keyfilter="pint" value={plaza} onChange={(e) => setPlaza(e.target.value)} />
                                        <label >Plaza</label>
                                    </span>
                                </div>
                            </div>
                            {/* REGISTRO */}
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText disabled={listado_descuentos} type="text" keyfilter="pint" value={registro} onChange={(e) => setRegistro(e.target.value)} />
                                        <label >Registro</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field col-12 md:col-2 consulta_botones pt-0">
                        <Button type='button' disabled={!partida || !plaza || !registro} className='p-button-rounded p-button-raised' icon="pi pi-search" loading={loading} onClick={() => request_data()} tooltip='Consultar' />
                        <Button type='button' className='p-button-rounded p-button-secondary p-button-raised' icon="pi pi-replay" onClick={reset_form} tooltip='Nueva búsqueda' />
                    </div>

                    {
                        idPersona &&
                        <>
                            {/* NOMBRES */}
                            <div className="field col-12 md:col-4">
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" disabled={true} value={nombres} onChange={(e) => setNombres(e.target.value.toUpperCase())} />
                                        <label >Nombres</label>
                                    </span>
                                </div>
                            </div>
                            {/* APELLIDOS */}
                            <div className="field col-12 md:col-4">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" disabled={true} value={apellidos} onChange={(e) => setApellidos(e.target.value.toUpperCase())} />
                                        <label >Apellidos</label>
                                    </span>
                                </div>
                            </div>
                            {/* DPI */}
                            <div className="field col-12 md:col-4">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-id-card"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" disabled={true} value={numero_dpi} onChange={(e) => setDPI(e.target.value)} />
                                        <label >Número de DPI</label>
                                    </span>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Fieldset>
            {
                (listado_descuentos && idPersona) &&
                <div className="datatable-descuentos-list">
                    <div className="card__descuentos_listado">
                        <DataTable value={listado_descuentos} header={header} stripedRows
                            paginator paginatorTemplate={pg_template} first={first} rows={rows} onPage={onCustomPage} paginatorClassName="justify-content-end"
                            filters={filters} globalFilterFields={['idcc', 'nombre']}
                            editMode="row" onRowEditComplete={(e) => {
                                if ((e.newData.field_2 || e.newData.field_2 === 0) && e.newData.idcc)
                                    update_descuento(e.newData)
                                else
                                    toast.current.show({ severity: 'error', summary: `Error: No se puedo actualizar el valor del descuento de persona ${idPersona}.` });
                            }}
                            className="mt-6" selectionMode='single' emptyMessage="No se encontró ningún registro.">
                            <Column field="idcc" header="Cuenta contable"></Column>
                            <Column field="nombre" header="Descuento"></Column>
                            <Column body={valueColumn} header="Valor" align={'left'} editor={(options) => textEditor(options)}></Column>
                            <Column align={'center'} header="Histórico" body={optionBody}></Column>
                            {props.modificable && <Column rowEditor className='editor-column' align={'center'} header="Editar" ></Column>}
                        </DataTable>
                    </div>
                </div>
            }
        </div>
    );
}

export default GestionDescuentos;
