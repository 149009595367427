import './certificaciones.css'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { confirmDialog } from 'primereact/confirmdialog';
import formatDate from '../tools/formatDate';
import { mostrarError } from '../constants/bodiesYFiltos';

// ------------------------------------------------- BODIES
export const ultimaModificacionBody = (rowData) => {
    return <>{formatDate(rowData.UltimaModificacion)}</>
};

export const eliminarVariableBody = (rowData, EliminarVariable) => {
    return <center><Button type="button" className='p-button-danger'
        icon="pi pi-times"
        onClick={(e) => EliminarVariable(rowData.NombreVariable)}
        tooltip='Eliminar variable' size='small' /></center>;
};

// ------------------------------------------------- COMPONENTES
const footerMensajeInicio = (setMensajeInicio, CargarEjemplo) => {
    return (
        <>
            <div className='dialog-botones'>
                <Button label="No, empezar desde cero"
                    onClick={() => { setMensajeInicio(false) }} />
                <Button severity="success" label="Sí, usar plantilla de ejemplo"
                    onClick={() => { setMensajeInicio(false); CargarEjemplo() }} />
            </div>
        </>
    )
};

export const MensajeInicio = ({ mensajeInicio, setMensajeInicio, CargarEjemplo }) => {
    return (
        <Dialog header="¿Usar plantilla ejemplo?" visible={mensajeInicio}
            style={{ width: '50vw' }} onHide={() => setMensajeInicio(false)}
            footer={footerMensajeInicio(setMensajeInicio, CargarEjemplo)}>
            <p className="m-0">
                ¿Cargar plantilla de ejemplo?
            </p>
        </Dialog>
    )
}

export const cellEditor = (options) => {
    return <InputText type="text" value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)} />;
};

// ------------------------------------------------- DATOS
export const plantillaEjemplo = `<p class="ql-align-center"></p>
<h1 class="ql-align-center"><strong>001-2023</strong></h1>
<p class="ql-align-center"><br></p>
<h1 class="ql-align-center"><strong>CERTIFICACION</strong></h1>
<p class="ql-align-justify" style="text-align: justify;">La Encargada de Cuotas del Plan de Prestaciones de la Universidad de San Carlos de Guatemala, CERTIFICA que se examinó el expediente de pago de cuotas de: <strong>&amp;&amp;NOMBRE_DE_TRABAJADOR&amp;&amp;</strong> registro personal No. <strong>&amp;&amp;REGISTRO_PERSONAL&amp;&amp;</strong> <strong>CUI No. </strong><strong>&amp;&amp;NUMERO_CUI&amp;&amp;</strong> determinando según relación laboral extendida por La División de Administración de Recursos Humanos que, <strong>&amp;&amp;CUOTAS_PENDIENTES?&amp;&amp;</strong> tiene cuotas pendientes de pago al Plan de Prestaciones.</p>
<p class="ql-align-justify">Así mismo, se informa que en los meses de julio a diciembre 2006 aplicaron descuento en el salario de Q.166.00, de enero y febrero 2007 en el salario de Q.166.00, de marzo a diciembre 2007 en el salario de Q.180.00, de enero a marzo 2008 en el salario de Q.180.00, abril 2008 en el salario de Q.196.00, junio 2009 en el salario de Q.234.00 y de julio a diciembre 2009 en el salario de Q.490.00.</p>
<p class="ql-align-justify">Se extiende la presente en la ciudad de Guatemala, el 13 de enero de 2023.</p>
<p class="ql-align-justify"><br></p>
<p class="ql-align-center"><strong class="ql-size-large"><em>“ID Y ENSEÑAD A TODOS”</em></strong></p>
</p><p class="ql-align-center"><br></p><p>
</p><p><br></p><p>
</p><p>		Vo.Bo.&nbsp;Licda. Sindy Marissa Palma Cerón&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;										Leslie Sucel Curtidor López</p><p>			 Asistente Administrativa Financiera&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                         <em><span class="ql-cursor">﻿﻿</span></em>Encargada de Cuotas</p><p>
</p><p><br></p><p>
</p><p><br></p><p>
</p><p class="ql-align-center"><br></p><p>
</p><p><br></p><p>
</p><p>
</p>
`

export const varTemp = [
    {
        'NombreVariable': 'NOMBRE_DE_TRABAJADOR',
        'Valor': ''
    }, {
        'NombreVariable': 'REGISTRO_PERSONAL',
        'Valor': ''
    }, {
        'NombreVariable': 'NUMERO_CUI',
        'Valor': ''
    }, {
        'NombreVariable': 'CUOTAS_PENDIENTES?',
        'Valor': ''
    },
]

// ------------------------------------------------ FUNCIONES
export const confirmarEdicionLibre = (acceptEdicionLibre) => {
    confirmDialog({
        message: 'Al activar el modo de edición libre se tendrán que editar manualmente también ' +
            'los valores de las variables para esta certificación. ¿Desea continuar?',
        header: 'Aviso',
        icon: 'pi pi-exclamation-triangle',
        accept: acceptEdicionLibre
    });
};

export const onCellEditCompleteCertificacion = (e, contenidoPlantilla, variables, quill) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    if (newValue.trim().length > 0) {
        rowData[field] = newValue;
    }
    else event.preventDefault();

    var nuevoTexto = contenidoPlantilla;
    for (var i = 0; i < variables.length; i++) {
        // reemplazar el nuevo valor en el editor
        var variable = variables[i];
        if (variable.Valor !== "") {
            var aReemplazar = '&amp;&amp;' + variable.NombreVariable + '&amp;&amp;';
            nuevoTexto = nuevoTexto.replace(aReemplazar, variable.Valor);
        }
    }
    quill.root.innerHTML = nuevoTexto;
};

export const onCellEditCompletePlantilla = (e, quill, variables) => {
    var original = "";
    let { rowData, newValue, field, originalEvent: event } = e;

    var formateada = newValue.toUpperCase().replaceAll(" ", "_");
    // buscar si no existe ya esa variable
    var buscar = variables.filter(registro => registro.NombreVariable === formateada);
    if (buscar.length > 0 && rowData[field] !== newValue) {
        alert("Ya existe esa variable");
        return;
    }

    if (formateada.trim().length > 0) {
        original = rowData[field];
        rowData[field] = formateada;
    }
    else event.preventDefault();

    // reemplazar el nuevo valor en el editor
    var texto = quill.root.innerHTML;
    var nuevoTexto = texto.replace('&amp;' + original + '&amp;', '&amp;' + formateada + '&amp;');
    quill.root.innerHTML = nuevoTexto;
};

export const exportacionCertificacion = (Endpoint, data) => {
    Endpoint(data).then(async (response) => {
        if (!response.ok) {
            mostrarError("Fallo al obtener PDF del servidor");
            throw new Error('Fallo al obtener PDF del servidor');
        }

        const pdfBlob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.target = '_blank';
        if (data.Tipo === 'docx') {
            link.download = "certificacion." + data.Tipo;
        }
        document.body.appendChild(link);
        link.click();
    }).catch(err => {
        mostrarError(err);
    });
};
