import './menu_moras.css';

import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';

import busqueda_presamos from '../img/busqueda_prestamos.png';
import busqueda_usuarios from '../img/busqueda_usuarios.png';
import formulario_moras from '../img/formulario_moras.png';
import formulario_pagos from '../img/formulario_pagos.png';
import formulario_usuarios from '../img/formulario_usuario.png';
import perfil_usuario from '../img/perfil_usuario.png';
import resumen_moras from '../img/resumen_moras.png';
import logs from '../img/logs.jpg';
import migracion from '../img/migracion.jpg'
import prestamos from '../img/prestamo-bancario.webp';
import graph from '../img/PieChart.jpg'

function MenuMoras() {
   
    const img_style = { borderRadius: '2px 2px 0 0', height: '150px', maxWidth: '100%' };
    const img_busqueda_prestamos = <img src={busqueda_presamos} alt='img_busqueda_prestamos' style={img_style} />;
    const img_busqueda_usuarios = <img src={busqueda_usuarios} alt='img_busqueda_usuarios' style={img_style} />;
    const img_fomrulario_moras = <img src={formulario_moras} alt='img_formulario_moras' style={img_style} />;
    const img_fomrulario_pagos = <img src={formulario_pagos} alt='img_formulario_pagos' style={img_style} />;
    const img_formulario_usuarios = <img src={formulario_usuarios} alt='img_formulario_usuarios' style={img_style} />;
    const img_perfil_usuario = <img src={perfil_usuario} alt='img_perfil_usuario' style={img_style} />;
    const img_resumen_moras = <img src={resumen_moras} alt='img_resumen_moras' style={img_style} />;
    const img_logs = <img src={logs} alt='img_logs' style={img_style} />;
    const img_migracion = <img src={migracion} alt='img_migracion' style={img_style}/>
    const img_prestamos = <img src={prestamos} alt='img_logs' style={img_style}/>
    const img_graph = <img src={graph} alt='img_logs' style={img_style}/>

    return (
        <div className="container container__menu__moras">
            <h1>Menú de Moras</h1>
            <br />
            <div className="row text-center">
                <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                    <Card header={img_busqueda_prestamos} >
                        <Link to="/moras/busqueda_prestamos">
                            <Button label="Busqueda de prestamo" icon="pi pi-users" className="" />
                        </Link>
                    </Card>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                    <Card header={img_busqueda_usuarios} >
                        <Link to="/moras/busqueda_usuarios">
                            <Button label="Busqueda de usuarios" icon="pi pi-users" className="" />
                        </Link>
                    </Card>
                </div>
                {/* <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                    <Card header={img_fomrulario_moras} >
                        <Link to="/moras/formulario_moras">
                            <Button label="Formuario de moras" icon="pi pi-users" className="" />
                        </Link>
                    </Card>
                </div> */}
                <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                    <Card header={img_fomrulario_pagos} >
                        <Link to="/moras/formulario_pagos">
                            <Button label="Formulario de pagos" icon="pi pi-users" className="" />
                        </Link>
                    </Card>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                    <Card header={img_formulario_usuarios} >
                        <Link to="/moras/formulario_usuario">
                            <Button label="Formulario de usuarios" icon="pi pi-users" className="" />
                        </Link>
                    </Card>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                    <Card header={img_prestamos} >
                        <Link to="/moras/formulario_prestamo">
                            <Button label="Formulario Prestamo" icon="pi pi-users" className="" />
                        </Link>
                    </Card>
                </div>
                {/* <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                    <Card header={img_perfil_usuario} >
                        <Link to="/moras/perfil_usuario">
                            <Button label="Perfil de usuarios" icon="pi pi-users" className="" />
                        </Link>
                    </Card>
                </div> */}
                <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                    <Card header={img_resumen_moras} >
                        <Link to="/moras/resumen_moras">
                            <Button label="Resumen de prestamos" icon="pi pi-users" className="" />
                        </Link>
                    </Card>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                    <Card header={img_logs} >
                        <Link to="/moras/logs">
                            <Button label="Logs" icon="pi pi-users" className="" />
                        </Link>
                    </Card>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                    <Card header={img_migracion} >
                        <Link to="/moras/requirements">
                            <Button label="Requerimientos de pago" icon="pi pi-users" className="" />
                        </Link>
                    </Card>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
                    <Card header={img_graph} >
                        <Link to="/moras/graphs">
                            <Button label="Graficas" icon="pi pi-users" className="" />
                        </Link>
                    </Card>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <p>Manual de usuario <a href='https://drive.google.com/file/d/1HkLFq54KKC_LJ2sczGGZVwlq-rFvyRav/view?usp=sharing' download="ManualUsuario.pdf">
          Descargar
        </a>
      .</p>
        </div>
    );
}


export default MenuMoras;