import '../calculo_cuotas.css';
import { Button } from 'primereact/button';
import { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { Checkbox } from "primereact/checkbox";

import { DatosTrabajador } from '../../constants/datos_trabajador';
import { CalcularIntereses, bodyDiferencia, fechaMesBody } from './constants';
import { moneyBody, mostrarError } from '../../constants/bodiesYFiltos';
import { headerForm } from '../../constants/bodiesYFiltos';
import { BotonExportarReporte } from '../../constants/exportacion';
import CrearMesCobro from './components/crear_mes_cobro';
import { TablaEditarPeriodos } from './components/tabla_editar_periodos';
import { TablaHistorialPeriodos } from './components/tabla_historial_periodos';
import { CalculosTotales } from './components/calculos_totales';
import { exportarOrdenpago, guardarOrdenPago } from '../../endpoints/calculo_cuotas/orden_pago';
import { getHistorialPeriodos } from '../../endpoints/periodos';
import formatDate from '../../tools/formatDate';

export default function OrdenPago() {
    // --------------------------------------------------  MESES DE COBRO
    // Todos los periodos
    const [historialPeriodosVisible, setHistorialPeriodosVisible] = useState(true);
    const [editarPeriodosVisible, setEditarPeriodosVisible] = useState(false);
    const [periodoInicial, setPeriodoInicial] = useState(null);

    const [historialPeriodos, setHistorialPeriodos] = useState([]);
    const [periodos, setPeriodos] = useState([]);

    // Datos trabajador
    const [trabajador, setTrabajador] = useState([{}]);

    // Datos editables
    const [interesesHasta, setInteresesHasta] = useState(new Date(2023, 7 - 1, 1));

    // --------------------------------------------------  ORDEN DE PAGO
    const [mesesCobro, setMesesCobro] = useState([]);
    const [selected, setSelected] = useState(null);

    const [totalCuotaAPagar, setTotalCuotaAPagar] = useState(0);
    const [totalIntereses, setTotalIntereses] = useState(0);

    // para actualizar periodos en mes cobro
    const [key, setKey] = useState(0);
    const toast = useRef(null);

    const [mostrarDetalleIntereses, setMostrarDetalleIntereses] = useState(false);

    useEffect(() => {
        // obtener el trabajador seleccionado
        var seleccionado = JSON.parse(sessionStorage.getItem('trabajador_seleccionado'));
        setTrabajador([seleccionado]);

        // obtener todos los periodos
        getHistorialPeriodos().then((resp) => {
            var nuevosPeriodos = [];
            for (var i = 0; i < resp.data.length; i++) {
                var periodo = resp.data[i];
                periodo.FechaInicio = new Date(periodo.Anio, periodo.MesInicio - 1, 1);
                periodo.FechaFinal = new Date(periodo.Anio, periodo.MesFinal - 1, 1);
                nuevosPeriodos.push(periodo);
            }
            setHistorialPeriodos(nuevosPeriodos);
            // ultimos 12 periodos
            setPeriodos(nuevosPeriodos.slice(-12));
        }).catch(err => {
            mostrarError(err);
        });
    }, []);

    function CambiarMesesCobro() {
        var nuevosMesesCobro = [];
        var TotalIntereses = 0;
        for (var i = 0; i < mesesCobro.length; i++) {
            var mesCobro = mesesCobro[i];
            var calculos = CalcularIntereses(
                mesCobro.FechaDescripcion,
                mesCobro.Cuota, mesCobro.SNominal,
                mesCobro.SRecibido,
                interesesHasta, periodos
            )
            mesCobro = { ...mesCobro, ...calculos };
            mesCobro.Intereses = mesCobro.InteresesCompleto;
            nuevosMesesCobro.push(mesCobro);
            TotalIntereses += parseFloat(mesCobro.InteresesCompleto);
        }
        setMesesCobro(nuevosMesesCobro);
        setPeriodos(periodos);
        setTotalIntereses(TotalIntereses);
        setKey(key + 1);
    }

    const FooterEditarPeriodos = () => {
        return <>
            <div className='mensaje-inicio-botones'>
                <Button label="Seleccionar periodo inicial" icon="pi pi-calendar"
                    onClick={() => {
                        setEditarPeriodosVisible(false);
                        setHistorialPeriodosVisible(true);
                    }} />
                <Button label="Ok" icon="pi pi-check" severity="success"
                    onClick={() => setEditarPeriodosVisible(false)} />
            </div>
        </>;
    };

    function CambiarPeriodoInicial() {
        var index = historialPeriodos.findIndex((periodo) => periodo.idPeriodo === periodoInicial.idPeriodo);
        var arreglo = historialPeriodos.slice(index, index + 12);
        setPeriodos(arreglo);
        setHistorialPeriodosVisible(false);
        setEditarPeriodosVisible(true);
        setPeriodoInicial(null);
    }

    const FooterHistorialPeriodos = () => {
        return <Button label="Ok" icon="pi pi-check" disabled={!periodoInicial}
            onClick={CambiarPeriodoInicial} />;
    };

    const GuardarOrdenPago = () => {
        var obj = {
            InteresesHasta: interesesHasta,
            RegistroTrabajador: trabajador[0]['Registro'],
            MesesCobro: mesesCobro,
            Periodos: periodos,
        }

        guardarOrdenPago(obj).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err);
        });
    };

    const AgregarMesCobro = (nuevoMesCobro) => {
        var nuevosMesesCobro = [...mesesCobro, nuevoMesCobro];
        setMesesCobro(nuevosMesesCobro);
        setTotalCuotaAPagar(totalCuotaAPagar + nuevoMesCobro.CuotaAPagar);
        setTotalIntereses(totalIntereses + nuevoMesCobro.Intereses);
        ScrollDown();
        toast.current.show({ severity: "success", summary: 'Mes de cobro agregado' });
    };

    function ScrollDown() {
        // hacer scroll hacia la seleccion de periodos e intereses hasta
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });
    }

    const QuitarMesCobro = () => {
        var del = mesesCobro.filter(mesCobro => mesCobro !== selected);
        setMesesCobro(del);
        setTotalCuotaAPagar(totalCuotaAPagar - selected.CuotaAPagar);
        setTotalIntereses(totalIntereses - selected.Intereses);
        setSelected(null);
    };

    return (
        <div className='primario-crear-registro'>
            <Toast ref={toast} />
            {/* DATOS DEL TRABAJADOR */}
            <Card header={headerForm("Datos del trabajador", true)} className="card">
                <DatosTrabajador trabajador={trabajador} />
            </Card>
            <br />
            {/* MESES DE COBRO */}
            <CrearMesCobro
                periodosIniciales={periodos}
                interesesHasta={interesesHasta}
                setInteresesHasta={setInteresesHasta}
                AgregarMesCobro={AgregarMesCobro}
                setEditarPeriodosVisible={setEditarPeriodosVisible}
                key={key}
            /><br />
            {/* ORDEN DE PAGO */}
            <Card header={headerForm("Anexo cálculo de cuotas")} className="card">
                <form noValidate={false}>
                    <div className="grid p-fluid mt-1">
                        {/* EDITAR PERIODOS */}
                        {/* BOTÓN EN CREAR MES COBRO */}
                        <Dialog header="Editar periodos" visible={editarPeriodosVisible}
                            style={{ width: '75vw' }} maximizable
                            modal contentStyle={{ height: '300px' }}
                            onHide={() => setEditarPeriodosVisible(false)}
                            footer={FooterEditarPeriodos}>
                            <TablaEditarPeriodos periodos={periodos} CambiarMesesCobro={CambiarMesesCobro} />
                        </Dialog>
                        <Dialog header="Seleccionar periodo inicial" visible={historialPeriodosVisible}
                            style={{ width: '75vw' }} maximizable
                            modal contentStyle={{ height: '300px' }}
                            onHide={() => {
                                if (periodoInicial) {
                                    setHistorialPeriodosVisible(false);
                                    setEditarPeriodosVisible(true);
                                }
                            }}
                            footer={FooterHistorialPeriodos}>
                            <TablaHistorialPeriodos
                                periodos={historialPeriodos.slice(0, historialPeriodos.length - 11)}
                                periodoInicial={periodoInicial}
                                setPeriodoInicial={setPeriodoInicial} />
                        </Dialog>
                        {/* BOTON ELIMINAR */}
                        <div className='col-12 md:col-3' style={{ marginTop: '29px' }}>
                            <Button type='button' icon="pi pi-trash" disabled={!selected}
                                label="Eliminar Mes de cobro" className='p-button-danger'
                                onClick={QuitarMesCobro} />
                        </div>
                        {/* BOTON EXPORTAR */}
                        <div className='col-12 md:col-2'
                            style={{
                                marginTop: '29px', width: 'fit-content',
                                marginLeft: 'auto', marginRight: '0'
                            }}>
                            <BotonExportarReporte
                                Endpoint={exportarOrdenpago}
                                datos={{
                                    Trabajador: trabajador,
                                    Periodos: periodos,
                                    InteresesHasta: interesesHasta,
                                    MesesCobro: mesesCobro,
                                    TotalCuotaAPagar: totalCuotaAPagar,
                                    TotalIntereses: totalIntereses,
                                    FechaElaboracion: formatDate(new Date()),
                                    DetalleIntereses: mostrarDetalleIntereses
                                }}
                                nombreArchivo={'orden_pago'}
                                size="medium"
                                style={null}
                                ocultar={['DOCX']}
                                bajarFirmas={false}
                            />
                        </div>
                    </div>
                    {/* MOSTRAR DETALLE INTERESES */}
                    <div className="flex align-items-center" style={{ marginTop: '5px' }}>
                        <Checkbox onChange={() => setMostrarDetalleIntereses(!mostrarDetalleIntereses)} checked={mostrarDetalleIntereses} />
                        <label className="ml-2" style={{ marginTop: 'auto', marginBottom: '2px' }}>Mostrar Detalle de Intereses</label>
                    </div>
                    <br />
                    <DataTable value={mesesCobro} paginator rows={10} paginatorPosition="top"
                        rowsPerPageOptions={[10, 25, 50]}
                        tableStyle={{ minWidth: '50rem' }}
                        size='small'
                        selectionMode='single'
                        selection={selected}
                        onSelectionChange={(e) => { setSelected(e.value) }}
                        onRowClick={() => { setSelected(null) }}
                        emptyMessage="Agregar meses de cobro"
                    >
                        {/* MESES DE COBRO */}
                        <Column field="FechaDescripcion" header="FechaDescripcion"
                            body={(rowData) => fechaMesBody(rowData, 'FechaDescripcion', false)}></Column>
                        <Column field="Cuota" header="Cuota(%)"></Column>
                        <Column field="SNominal" header="S.Nominal(Q)"
                            body={(rowData) => moneyBody(rowData, 'SNominal')} ></Column>
                        <Column field="SRecibido" header="S.Recibido(Q)"
                            body={(rowData) => moneyBody(rowData, 'SRecibido')} ></Column>
                        <Column header="Diferencia(Q)" body={bodyDiferencia}></Column>
                        <Column field="CuotaAPagar" header="Cuota a Pagar(Q)"
                            body={(rowData) => moneyBody(rowData, 'CuotaAPagar')}></Column>
                        <Column field="TMes" header="TMes" ></Column>
                        <Column field="S" header="S(Q)"
                            body={(rowData) => moneyBody(rowData, 'S')}></Column>
                        <Column field="Intereses" header="Intereses(Q)"
                            body={(rowData) => moneyBody(rowData, 'Intereses')} ></Column>
                        {/* DETALLE INTERESES */}
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((col, i) => (
                            mostrarDetalleIntereses && <Column key={col.field} field={`IPer${col}`} header={`I.Per${col}.`} />
                        ))}
                    </DataTable>
                    <br />
                    <div className="grid p-fluid mt-1">
                        <CalculosTotales
                            totalCuotaAPagar={totalCuotaAPagar}
                            totalIntereses={totalIntereses}
                        />
                        {/* BOTONES */}
                        <div className='col-12 md:col-3' style={{ marginLeft: 'auto', marginTop: '29px' }}>
                            <Button type='button' icon="pi pi-check"
                                label="Guardar Orden De Pago" className='p-button-primary'
                                onClick={GuardarOrdenPago} />
                        </div>
                    </div>
                </form>
            </Card>
            <br />
        </div>
    );
}
