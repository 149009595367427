import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { fechaMesBody } from '../constants';

const anioEditor = (options, CambiarMesesCobro) => {
    return <InputNumber value={options.value}
        onChange={(e) => { options.editorCallback(e.value); CambiarMesesCobro(); }}
        mode="decimal" min={0} useGrouping={false} />;
};

const fechaEditor = (options, CambiarMesesCobro) => {
    return <Calendar value={options.value} onChange={(e) => { options.editorCallback(e.value); CambiarMesesCobro(); }} view="month" dateFormat="mm-yy" />
};

const tasaInteresEditor = (options, CambiarMesesCobro) => {
    return <InputNumber value={options.value}
        onChange={(e) => { options.editorCallback(e.value); CambiarMesesCobro(); }}
        minFractionDigits={2} maxFractionDigits={2} min={0} />;
};

const cellEditor = (options, CambiarMesesCobro) => {
    if (options.field === 'TasaInteres') {
        return tasaInteresEditor(options, CambiarMesesCobro);
    } else if (options.field === 'FechaInicio' || options.field === 'FechaFinal') {
        return fechaEditor(options, CambiarMesesCobro);
    } else {
        return anioEditor(options, CambiarMesesCobro);
    }
};

const onCellEditComplete = (e, CambiarMesesCobro) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    rowData[field] = newValue;
    CambiarMesesCobro();
};

const columna = (field, header, CambiarMesesCobro) => {
    return (
        <Column field={field} header={header}
            editor={(options) => cellEditor(options, CambiarMesesCobro)}
            onCellEditComplete={(e) => onCellEditComplete(e, CambiarMesesCobro)}
            body={(field === "FechaInicio" || field === "FechaFinal") && ((rowData) => fechaMesBody(rowData, field))} />
    )
}

export const TablaEditarPeriodos = ({ periodos = [], CambiarMesesCobro }) => {
    return (<>
        <DataTable value={periodos ? periodos : []} scrollable scrollHeight="flex"
            tableStyle={{ minWidth: '50rem' }}>
            <Column header="#" body={(rowData, rowIndex) => rowIndex.rowIndex + 1} />
            {columna("Anio", "Año", CambiarMesesCobro)}
            {columna("FechaInicio", "Fecha Inicio", CambiarMesesCobro)}
            {columna("FechaFinal", "Fecha Final", CambiarMesesCobro)}
            {columna("TasaInteres", "Tasa Interes(%)", CambiarMesesCobro)}
        </DataTable>
    </>)
}
