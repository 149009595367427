import './convenios.css';
import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { useSessionStorage } from 'primereact/hooks';
import { locale, addLocale } from 'primereact/api';

import data from '../diccionario.json';
import { renderRedirect, containsFilter, columnaConFiltros, mostrarError, moneyBody } from '../constants/bodiesYFiltos';
import { fechaBody } from '../constants/bodiesYFiltos';
import { eliminarConvenio, getConvenios } from '../endpoints/convenios';

locale('es');
addLocale('es', data.es);

export default function Convenios() {
    const [filters] = useState({
        RegistroTrabajador: containsFilter,
        CUI: containsFilter,
        Nombre: containsFilter,
        MontoTotal: containsFilter,
        Frecuencia: containsFilter,
        ActaAutorizacion: containsFilter,
    });
    const [convenios, setConvenios] = useSessionStorage([], 'convenios');
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [destino, setDestino] = useState('');
    const [redirect, setRedirect] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        setLoading(true);

        getConvenios().then((resp) => {
            setConvenios(resp.data);
            setLoading(false);
        }).catch(err => {
            mostrarError(err, setLoading(false), setConvenios([]));
        });

    }, []);

    const redireccionar = (destino) => {
        if (selected === null)
            return
        sessionStorage.setItem('convenio_seleccionado', JSON.stringify(selected));
        setSelected(null);
        setDestino(destino);
        setRedirect(true);
    }

    const accept = () => {
        eliminarConvenio({ idConvenio: selected.idConvenio }).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                // quitar el registro
                var del = convenios.filter(registro => registro.idConvenio !== selected.idConvenio);
                setConvenios(del);
                setSelected(null);
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err, setLoading(false), setConvenios([]));
        });
    }

    const EliminarConvenio = () => {
        if (!selected)
            return

        confirmDialog({
            message: '¿Está seguro de eliminar el convenio seleccionado?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept
        });
    }


    return (
        <>
            {/* PARA LA CONFIRMACIÓN DE ELIMINAR REGISTRO */}
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className='primario'>
                <br />
                <div className="grid p-fluid">
                    <div className='col-12 md:col-3' style={{ marginLeft: 'auto' }}>
                        <Button type="button" icon="pi pi-money-bill" label="Pagos del Convenio"
                            severity="success" disabled={selected === null}
                            onClick={() => redireccionar('ver_pagos_convenio')} />
                    </div>

                    <div className='col-12 md:col-2' >
                        <Button type="button" icon="pi pi-eye" label="Ver Detalle"
                            severity="help" disabled={selected === null}
                            onClick={() => redireccionar('ver_convenio')} />
                    </div>

                    <div className='col-12 md:col-2'>
                        <Button type="button" icon="pi pi-pencil" label="Editar Convenio"
                            severity="warning" disabled={selected === null}
                            onClick={() => redireccionar('editar_convenio')} />
                    </div>

                    <div className='col-12 md:col-2'>
                        <Button type="button" icon="pi pi-trash" label="Eliminar Convenio"
                            severity="danger" disabled={selected === null}
                            onClick={EliminarConvenio} />
                    </div>
                    <div className='col-12 md:col-2'>
                        <Link to="/cuotas/crear_convenio">
                            <Button type="button" icon="pi pi-plus" label="Agregar Convenio" />
                        </Link>
                    </div>
                </div>
                <br />
                <DataTable value={convenios} paginator rows={10} paginatorPosition="top"
                    rowsPerPageOptions={[10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                    filters={filters}
                    size='small'
                    loading={loading}
                    selectionMode='single'
                    selection={selected}
                    onSelectionChange={(e) => { setSelected(e.value) }}
                    onRowClick={() => { setSelected(null) }}
                    emptyMessage="No hay convenios registrados"
                >
                    {columnaConFiltros("RegistroTrabajador", "Registro Trabajador", true, "Buscar por registro")}
                    {columnaConFiltros("CUI", "CUI", true, "Buscar por CUI")}
                    {columnaConFiltros("Nombre", "Nombre", true, "Buscar por nombre")}
                    <Column
                        filter={true}
                        filterPlaceholder='Buscar por monto total'
                        field='MontoTotal'
                        header="Monto Total(Q)"
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showFilterOperator={false}
                        body={(rowData) => moneyBody(rowData, 'MontoTotal')}
                    ></Column>
                    {columnaConFiltros("Frecuencia", "Frecuencia(Meses)", true, "Buscar por frecuencia")}
                    {columnaConFiltros("ActaAutorizacion", "Acta Autorización", true, "Buscar por acta")}
                    <Column field="FechaInicio" header="Fecha Inicio"
                        body={(rowData) => fechaBody(rowData, 'FechaInicio')}></Column>
                    <Column field="FechaFin" header="Fecha Fin"
                        body={(rowData) => fechaBody(rowData, 'FechaFin')}></Column>
                    <Column field="FechaAutorizacion" header="Fecha Autorizacion"
                        body={(rowData) => fechaBody(rowData, 'FechaAutorizacion')}></Column>
                </DataTable>
                <br />
                {renderRedirect(redirect, destino)}
            </div>
        </>
    );
}
