import React from 'react';
import Dashboard from '../dashboard/dashboard';
import Header from '../header/header';
import MenuNomina from '../modulo_nominas/menu_nomina';
import MenuJubilados from '../modulo_jubilados/menu_jubilados';
import MenuMoras from '../modulo_moras/menu_moras';
import NotFoundPage from '../notFound/404';
import { Route, Routes } from "react-router-dom";
// imports para nominas
import NuevaNomina from '../modulo_nominas/nueva_nomina/nueva';
import ProcesarNomina from '../modulo_nominas/procesar_nomina/proceso';
import Reporteria from '../modulo_nominas/reporteria/reporteria';
import MenuBuscarNomina from '../modulo_nominas/buscar_nomina/buscar';
import LibroSalarios from '../modulo_nominas/libro_salarios/lbsalarios';
import CatalogoDescuentos from '../modulo_nominas/descuentos/catalogo';
import GestionDescuentos from '../modulo_nominas/descuentos/gestionar';
// imports para cuotas
import CuotasRoutes from '../modulo_cuotas/CuotasRoutes';
// imports para moras
import BusquedaMoras from '../modulo_moras/busqueda_prestamos/busqueda_prestamos';
import BusquedaUsuarioMoras from '../modulo_moras/busqueda_usuarios/busqueda_usuarios';
import FormularioMoras from '../modulo_moras/formulario_moras/formulario_moras';
import FormularioPagosMoras from '../modulo_moras/formulario_pagos/formulario_pagos';
import FromularioPrestamos from '../modulo_moras/formulario_prestamos/formulario_prestamos';
import UpdatePrestamo from '../modulo_moras/formulario_prestamos/update_prestamo';
import FormularioUsuarioMoras from '../modulo_moras/formulario_usuario/formulario_usuario';
import UpdateUsuarioMoras from '../modulo_moras/formulario_usuario/update_usuario';
import PerfilUsuarioMoras from '../modulo_moras/perfil_usuario/perfil_usuario';
import PrestamoEspecifico from '../modulo_moras/detalle_prestamo/detalle_prestamo';
import ResumenMoras from '../modulo_moras/resumen_moras/resumen_moras';
import VisualizadorLogs from '../modulo_moras/visualizador_logs/visualizador_logs';
import Login from '../auth/login/login';
import CatalogoPartidas from '../modulo_nominas/partidas/catalogo';
import FirmasReportes from '../modulo_nominas/reporteria/firmas';
import GestionDocumentos from '../modulo_nominas/gestion_documentos/gestion_documentos';
import CatalogoBancos from '../modulo_nominas/bancos/catalogo';
import Signup from '../auth/signup/signup';
import PaymentsRequirementModule from '../modulo_moras/migracion/migracion';
import Graficas from '../modulo_moras/graficas/graficas';
import CargaDescuentos from '../modulo_nominas/descuentos/carga_masiva';
import Estadisticas from '../modulo_nominas/estadisticas/estadisticas';

function Rutas() {

    const rol = +localStorage.getItem('rol');

    return (
        <Routes>
            {/* LOGIN */}
            <Route path="/login" element={<>
                <Login />
            </>} />
            {/* SIGNUP */}
            {
                rol == 1 &&
                <Route path="/signup" element={<>
                    <Signup />
                </>} />
            }
            {/* DASHBOARD */}
            <Route path="/" element={<>
                <Header logo={true} hr={true}
                />
                <Dashboard admin_sobrevivencias={rol == 1} />
            </>} />
            {/* JUBILADOS */}
            <Route path="/jubilados/:ptd?/:plz?/:reg?" element={<>
                <Header gestion_datos={true} hr={true} />
                <MenuJubilados />
            </>} />
            {/* NOMINAS */}
            <Route path="/nominas" element={<>
                <Header nominas={true} />
                <MenuNomina />
            </>} />
            {
                rol <= 4 &&
                <>
                    <Route path="/nominas/nueva" element={<>
                        <NuevaNomina />
                    </>} />
                    <Route path="/nominas/descuentos" element={
                        <CatalogoDescuentos />
                    } />
                </>
            }
            {
                (rol <= 4 || rol == 10) &&
                <Route path="/nominas/carga-descuentos" element={<>
                    <CargaDescuentos />
                </>} />
            }
            <Route path="/nominas/gestion-descuentos/:ptd?/:plz?/:reg?" element={<>
                <GestionDescuentos modificable={rol <= 4} />
            </>} />
            <Route path="/nominas/partidas" element={
                <CatalogoPartidas agregar={rol <= 4} />
            } />
            <Route path="/nominas/bancos" element={
                <CatalogoBancos agregar={rol <= 4} />
            } />
            <Route path="/nominas/:id" element={<>
                <ProcesarNomina />
            </>} />
            {
                (rol <= 5 || rol == 7 || rol == 10) &&
                <>
                    <Route path="/nominas/buscar" element={<>
                        <MenuBuscarNomina />
                    </>} />
                    <Route path="/nominas/reporteria/:nominaSelected?" element={<>
                        <Reporteria />
                    </>}
                    />
                </>
            }
            <Route path="/nominas/gestion-documentos/:nominaSelected?" element={<>
                <GestionDocumentos anulable={rol <= 4 || rol == 7} />
            </>} />
            <Route path="/nominas/libro-salarios/:ptd?/:plz?/:reg?" element={<>
                <LibroSalarios certificaciones={rol <= 3 || rol == 6} certificacion_prestaciones={rol == 10 || rol == 8} />
            </>} />
            <Route path="/nominas/estadisticas" element={<>
                <Estadisticas />
            </>} />
            <Route path="/nominas/firmas" element={
                <FirmasReportes modificable={rol == 1} />
            } />
            {/* CUOTAS */}
            {
                (rol == 8 || rol == 1) &&
                <Route path="/cuotas/*" element={<CuotasRoutes />} />
            }
            {/* MORAS */}
            {
                (rol == 9 || rol == 10 || rol == 1) &&
                <>
                    <Route path="/moras" element={<>
                        <Header moras={true} />
                        <MenuMoras />
                    </>} />
                    <Route path="/moras/busqueda_prestamos" element={<>
                        <Header title={<h3>Búsqueda de Prestamos</h3>} />
                        <BusquedaMoras />
                    </>} />
                    <Route path="/moras/busqueda_usuarios" element={<>
                        <Header title={<h3>Búsqueda de Usuarios</h3>} />
                        <BusquedaUsuarioMoras />
                    </>} />
                    <Route path="/moras/formulario_moras" element={<>
                        <Header title={<h3>Formulario de Moras</h3>} />
                        <FormularioMoras />
                    </>} />
                    <Route path="/moras/formulario_pagos" element={<>
                        <Header title={<h3>Formuario de Pagos</h3>} />
                        <FormularioPagosMoras />
                    </>} />
                    <Route path="/moras/formulario_usuario" element={<>
                        <Header title={<h3>Formulario de Usuario</h3>} />
                        <FormularioUsuarioMoras />
                    </>} />
                    <Route path="/moras/modificacion_usuario/:id_usuario" element={<>
                        <Header title={<h3>Modificación de Usuario</h3>} />
                        <UpdateUsuarioMoras />
                    </>} />
                    <Route path="/moras/perfil_usuario/:id_usuario" element={<>
                        <Header title={<h3>Perfil de Usuario</h3>} />
                        <PerfilUsuarioMoras />
                    </>} />
                    <Route path="/moras/detalle_prestamo/:clave_prestamo" element={<>
                        <Header title={<h3>Detalle de Prestamo</h3>} />
                        < PrestamoEspecifico />
                    </>} />
                    <Route path="/moras/resumen_moras" element={<>
                        <Header title={<h3>Resumen de Moras y Préstamos</h3>} />
                        <ResumenMoras />
                    </>} />
                    <Route path="/moras/formulario_prestamo" element={<>
                        <Header title={<h3>Formulario de Préstamos</h3>} />
                        <FromularioPrestamos />
                    </>} />
                    <Route path='/moras/modificacion_prestamo/:clave' element={<>
                        <Header title={<h3>Modificación de Usuario</h3>} />
                        <UpdatePrestamo />
                    </>} />
                    <Route path="/moras/logs" element={<>
                        <Header title={<h3>Visualizador de Logs</h3>} />
                        <VisualizadorLogs />
                    </>} />
                    <Route path="/moras/requirements" element={<>
                        <Header title={<h3>Errores en migración</h3>} />
                        <PaymentsRequirementModule />
                    </>} />
                    <Route path="/moras/graphs" element={<>
                        <Header title={<h3>Gráficas</h3>} />
                        <Graficas />
                    </>} />
                </>
            }
            {/* NOT FOUND */}
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
}

export default Rutas;
