import React from 'react';
import { Route, Routes } from "react-router-dom";
import MenuHistorial from './historial/menu_historial';
import Historial from './historial/historial';
import CrearRegistro from './historial/crear_registro';
import EditarRegistro from './historial/editar_registro';
import CargarRegistros from './historial/cargar_registros';

import GestionTrabajadores from './gestion_trabajadores/gestion_trabajadores';
import CrearTrabajador from './gestion_trabajadores/crear_trabajador';
import VerTrabajador from './gestion_trabajadores/ver_trabajador';
import EditarTrabajador from './gestion_trabajadores/editar_trabajador';
import AsignarContrato from './gestion_trabajadores/contratos/asignar_contrato';
import EditarContrato from './gestion_trabajadores/contratos/editar_contrato';
import VerContratos from './gestion_trabajadores/contratos/ver_contratos';

import MenuCalculos from './calculo_cuotas/menu_calculos';

import Integraciones from './calculo_cuotas/integraciones/integraciones';
import CrearIntegracion from './calculo_cuotas/integraciones/crear_integracion';
import VerIntegracion from './calculo_cuotas/integraciones/ver_integracion';

import SeleccionTrabajador from './calculo_cuotas/orden_pago/components/seleccion_trabajador';
import OrdenesDePago from './calculo_cuotas/orden_pago/ordenes_pago';
import CrearOrdenPago from './calculo_cuotas/orden_pago/crear_orden_pago';
import VerOrdenPago from './calculo_cuotas/orden_pago/ver_orden_pago';

import Reintegros from './reintegros/reintegros';
import CrearReintegro from './reintegros/crear_reintegro';
import EditarReintegro from './reintegros/editar_reintegro';
import VerReintegro from './reintegros/ver_reintegro';

import Convenios from './convenios/convenios';
import CrearConvenio from './convenios/crear_convenio';
import EditarConvenio from './convenios/editar_convenio';
import VerConvenio from './convenios/ver_convenio';
import VerPagosConvenio from './convenios/pagos_convenio/ver_pagos_convenio';

import MenuCertificaciones from './certificaciones/menu_certificaciones';
import CrearPlantilla from './certificaciones/plantillas/crear_plantilla';
import EditarPlantilla from './certificaciones/plantillas/editar_plantilla';
import VerPlantilla from './certificaciones/plantillas/ver_plantilla';
import CrearCertificacion from './certificaciones/crear_certificacion';
import EditarCertificacion from './certificaciones/editar_certificacion';
import VerCertificacion from './certificaciones/ver_certificacion';

import Periodos from './periodos/periodos';
import CuotasLogs from './logs/logs';

import Header from '../header/header';
import CuotasSidebar from './sidebar/sidebar';

const CuotasRoutes = () => {
    return (
        <>
            <Routes>
                {/* modulo de historial */}
                < Route path="" element={<>
                    <Header cuotas={true} />
                    <CuotasSidebar />
                    <MenuHistorial />
                </>}
                />
                < Route path="historial" element={<>
                    <CuotasSidebar />
                    <Historial />
                </>}
                />
                < Route path="crear_registro" element={<>
                    <Header title={<h2>Historial - Crear Registro</h2>} />
                    <CuotasSidebar />
                    <CrearRegistro />
                </>}
                />
                < Route path="editar_registro" element={<>
                    <Header title={<h2>Historial - Editar Registro</h2>} />
                    <CuotasSidebar />
                    <EditarRegistro />
                </>}
                />
                < Route path="cargar_registros" element={<>
                    <Header title={<h2>Historial - Cargar Registros</h2>} />
                    <CuotasSidebar />
                    <CargarRegistros />
                </>}
                />
                {/* gestion de trabajadores */}
                < Route path="gestion_trabajadores" element={<>
                    <Header title={<h2>Gestión de trabajadores</h2>} />
                    <CuotasSidebar />
                    <GestionTrabajadores />
                </>}
                />
                < Route path="crear_trabajador" element={<>
                    <Header title={<h2>Gestión de Trabajadores - Crear Trabajador</h2>} />
                    <CuotasSidebar />
                    <CrearTrabajador />
                </>}
                />
                < Route path="ver_trabajador" element={<>
                    <Header title={<h2>Gestión de Trabajadores - Ver Trabajador</h2>} />
                    <CuotasSidebar />
                    <VerTrabajador />
                </>}
                />
                < Route path="editar_trabajador" element={<>
                    <Header title={<h2>Gestión de Trabajadores - Editar Trabajador</h2>} />
                    <CuotasSidebar />
                    <EditarTrabajador />
                </>}
                />
                < Route path="ver_contratos" element={<>
                    <Header title={<h2>Gestión de Trabajadores - Ver Contratos</h2>} />
                    <CuotasSidebar />
                    <VerContratos />
                </>}
                />
                < Route path="asignar_contrato" element={<>
                    <Header title={<h2>Gestión de Trabajadores - Asignar Contrato</h2>} />
                    <CuotasSidebar />
                    <AsignarContrato />
                </>}
                />
                < Route path="editar_contrato" element={<>
                    <Header title={<h2>Gestión de Trabajadores - Editar Contrato</h2>} />
                    <CuotasSidebar />
                    <EditarContrato />
                </>}
                />
                {/* Cálculo de cuotas pendientes de pago */}
                < Route path="menu_calculos" element={<>
                    <Header title={<h2>Cálculo de Cuotas Pendientes</h2>} />
                    <CuotasSidebar />
                    <MenuCalculos />
                </>}
                />
                {/* integraciones */}
                < Route path="integraciones" element={<>
                    <Header title={<h2>Integraciones</h2>} />
                    <CuotasSidebar />
                    <Integraciones />
                </>}
                />
                < Route path="crear_integracion" element={<>
                    <Header title={<h2>Crear Integración</h2>} />
                    <CuotasSidebar />
                    <CrearIntegracion />
                </>}
                />
                < Route path="ver_integracion" element={<>
                    <Header title={<h2>Ver Integración</h2>} />
                    <CuotasSidebar />
                    <VerIntegracion />
                </>}
                />
                {/* ordenes de pago */}
                < Route path="seleccion_trabajador" element={<>
                    <Header title={<h2>Seleccionar Trabajador</h2>} />
                    <CuotasSidebar />
                    <SeleccionTrabajador />
                </>}
                />
                < Route path="ordenes_pago" element={<>
                    <Header title={<h2>Ordenes de Pago</h2>} />
                    <CuotasSidebar />
                    <OrdenesDePago />
                </>}
                />
                < Route path="crear_orden_pago" element={<>
                    <Header title={<h2>Crear Orden de Pago</h2>} />
                    <CuotasSidebar />
                    <CrearOrdenPago />
                </>}
                />
                < Route path="ver_orden_pago" element={<>
                    <Header title={<h2>Ver Orden de Pago</h2>} />
                    <CuotasSidebar />
                    <VerOrdenPago />
                </>}
                />
                {/* modulo de reintegros */}
                < Route path="reintegros" element={<>
                    <Header title={<h2>Reintegros</h2>} />
                    <CuotasSidebar />
                    <Reintegros />
                </>}
                />
                < Route path="crear_reintegro" element={<>
                    <Header title={<h2>Crear Reintegro</h2>} />
                    <CuotasSidebar />
                    <CrearReintegro />
                </>}
                />
                < Route path="editar_reintegro" element={<>
                    <Header title={<h2>Editar Reintegro</h2>} />
                    <CuotasSidebar />
                    <EditarReintegro />
                </>}
                />
                < Route path="ver_reintegro" element={<>
                    <Header title={<h2>Ver Reintegro</h2>} />
                    <CuotasSidebar />
                    <VerReintegro />
                </>}
                />
                {/* modulo de convenios */}
                < Route path="convenios" element={<>
                    <Header title={<h2>Convenios</h2>} />
                    <CuotasSidebar />
                    <Convenios />
                </>}
                />
                < Route path="crear_convenio" element={<>
                    <Header title={<h2>Crear Convenio</h2>} />
                    <CuotasSidebar />
                    <CrearConvenio />
                </>}
                />
                < Route path="editar_convenio" element={<>
                    <Header title={<h2>Editar Convenio</h2>} />
                    <CuotasSidebar />
                    <EditarConvenio />
                </>}
                />
                < Route path="ver_convenio" element={<>
                    <Header title={<h2>Ver Detalle Convenio</h2>} />
                    <CuotasSidebar />
                    <VerConvenio />
                </>}
                />
                < Route path="ver_pagos_convenio" element={<>
                    <Header title={<h2>Pagos de Convenio</h2>} />
                    <CuotasSidebar />
                    <VerPagosConvenio />
                </>}
                />

                {/* modulo de certificaciones */}
                < Route path="certificaciones" element={<>
                    <Header title={<h2>Certificaciones</h2>} />
                    <CuotasSidebar />
                    <MenuCertificaciones />
                </>}
                />
                < Route path="crear_plantilla" element={<>
                    <Header title={<h2>Crear Plantilla</h2>} />
                    <CuotasSidebar />
                    <CrearPlantilla />
                </>}
                />
                < Route path="editar_plantilla" element={<>
                    <Header title={<h2>Editar Plantilla</h2>} />
                    <CuotasSidebar />
                    <EditarPlantilla />
                </>}
                />
                < Route path="ver_plantilla" element={<>
                    <Header title={<h2>Ver Plantilla</h2>} />
                    <CuotasSidebar />
                    <VerPlantilla />
                </>}
                />
                < Route path="crear_certificacion" element={<>
                    <Header title={<h2>Crear Certificación</h2>} />
                    <CuotasSidebar />
                    <CrearCertificacion />
                </>}
                />
                < Route path="editar_certificacion" element={<>
                    <Header title={<h2>Editar Certificación</h2>} />
                    <CuotasSidebar />
                    <EditarCertificacion />
                </>}
                />
                < Route path="ver_certificacion" element={<>
                    <Header title={<h2>Ver Certificación</h2>} />
                    <CuotasSidebar />
                    <VerCertificacion />
                </>}
                />
                {/* periodos */}
                < Route path="periodos" element={<>
                    <Header title={<h2>Periodos</h2>} />
                    <CuotasSidebar />
                    <Periodos />
                </>}
                />
                {/* logs */}
                < Route path="logs" element={<>
                    <Header title={<h2>Logs</h2>} />
                    <CuotasSidebar />
                    <CuotasLogs />
                </>}
                />
            </Routes>

        </>
    );
};

export default CuotasRoutes;