import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { ToggleButton } from 'primereact/togglebutton';
import { Checkbox } from 'primereact/checkbox';
import { requestOptions } from '../misc/requestOptions';

function ConfirmarNomina(props) {

    const { id, toast, setEstadoNomina } = props;
    const [display, setDisplay] = useState(false);
    const [loading, setLoading] = useState(false);
    const [correlativo_cheques, setCorrelativoCheques] = useState('');
    const [correlativo_acred, setCorrelativoAcred] = useState('');
    const [corrEdit, setCorrEdit] = useState(false);
    const [checked, setChecked] = useState(false);

    const hide = () => {
        setCorrEdit(false);
        setChecked(false);
        setDisplay(false);
    };

    const obtener_correlativos = () => {
        setDisplay(true);
        fetch(process.env.REACT_APP_API_URL_CORRELATIVOS)
            .then((response) => response.json())
            .then((data) => {
                if (data.error || data.message)
                    toast.current.show({ severity: data.severity, summary: data.message || data.error });
                const x = data.data;
                if (x) {
                    setCorrelativoCheques((x.correlativo_cheques + 1) || 1);
                    setCorrelativoAcred((x.correlativo_acred + 1) || 1);
                }
            });
    }

    const confirmNomina = () => {
        setDisplay(false);
        setLoading(true);
        fetch((process.env.REACT_APP_API_URL_CONFIRMAR_NOMINA),
            requestOptions('POST', {
                idnomina: id,
                correlativo_cheques: correlativo_cheques - 1,
                correlativo_acred: correlativo_acred - 1
            }))
            .then((response) => response.json())
            .then(async (data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                if (!data.error) setEstadoNomina();
                setLoading(false);
            });
    }

    const footer = (
        <div className='mt-1'>
            <Button label="Aprobar" className='font-weight-bold' icon="pi pi-check" disabled={!correlativo_cheques || !correlativo_acred || !checked} onClick={confirmNomina} />
            <Button label="Cancelar" icon="pi pi-times" onClick={hide} />
        </div>
    );

    return (
        <div className='text-center mt-4'>
            <Dialog header="Aprobación de nómina" visible={display}
                footer={footer} resizable={false} draggable={false} onHide={hide} >
                <div className='toggle_edit text-center'>
                    <ToggleButton checked={corrEdit} onChange={() => setCorrEdit(!corrEdit)}
                        offLabel="Continuar correlativos" offIcon="pi pi-check"
                        onLabel="Digitar correlativos manuales" onIcon="pi pi-pencil" />
                </div>
                <div className="field input_corr">
                    <label htmlFor="corr_cheques" className='mb-0'>Inicio correlativo cheques</label>
                    <InputText disabled={!corrEdit} id="corr_cheques" type="text"
                        keyfilter={/[\d]/} value={correlativo_cheques} required autoFocus
                        onChange={(e) => setCorrelativoCheques(e.target.value)} />
                </div>
                <div className="field input_corr">
                    <label htmlFor="corr_acred" className='mr-3 mb-0'>Inicio correlativo acreditamiento</label>
                    <InputText disabled={!corrEdit} id='corr_acred' type="text"
                        keyfilter={/[\d]/} value={correlativo_acred} required
                        onChange={(e) => setCorrelativoAcred(e.target.value)} />
                </div>
                <div className="check-confirm-nomina">
                    <Checkbox inputId="confirmar-nom" className='chbox-confirmnom' checked={checked} onChange={e => setChecked(e.checked)} required />
                    <label className='my-0 font-weight-bold' htmlFor="confirmar-nom">
                        <span style={{ color: 'red', marginRight: '2px' }}>*</span>Confirmo que el proceso de nómina ha sido revisado correctamente.
                    </label>
                </div>
            </Dialog>
            <Button onClick={() => obtener_correlativos()} loading={loading} className='p-button-warning p-button-rounded p-button-raised font-weight-bold' icon='pi pi-check-circle' label='Aprobar nómina' />
        </div>
    );
}

export default ConfirmarNomina;