import '../../calculo_cuotas.css';
import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { locale, addLocale } from 'primereact/api';
import data from '../../../diccionario.json';
import {
    generoBody, afiliacionTrabajadorBody,
    afiliacionTrabajadorFilter, estadoTrabajadorFilter
} from '../../../constants/datos_trabajador';
import {
    containsFilter, columnaConFiltros,
    fechaBody, renderRedirect, BotonRegresar, mostrarError
} from '../../../constants/bodiesYFiltos';
import { getTrabajadores } from '../../../endpoints/gestion_trabajadores';


locale('es');
addLocale('es', data.es);

export default function GestionTrabajadores() {
    const [filters] = useState({
        Registro: containsFilter,
        CUI: containsFilter,
        Nombre: containsFilter,
        Afiliacion: containsFilter,
        idEstadoTrabajador: containsFilter
    });
    const [trabajadores, setTrabajadores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        setLoading(true);
        // obtener trabajadores
        getTrabajadores().then((resp) => {
            setTrabajadores(resp.data);
            setLoading(false);
        }).catch(err => {
            mostrarError(err, setLoading(false));
        });
        //setLoading(false);
    }, []);

    const redireccionar = () => {
        sessionStorage.setItem('trabajador_seleccionado', JSON.stringify(selected));
        setSelected(null);
        setRedirect(true);
    }

    return (
        <>
            <div className='primario'>
                <div className="grid p-fluid" style={{ paddingTop: '2%' }}>
                    <BotonRegresar absolute={false} />
                    <div className='col-12 md:col-3' style={{ marginLeft: 'auto', marginRight: '0' }}>
                        <Button type="button" icon="pi pi-arrow-right" label="Ver Ordenes de Pago"
                            severity='success'
                            disabled={selected === null}
                            onClick={redireccionar} />
                    </div>

                </div>
                <br />
                <DataTable value={trabajadores} paginator rows={10} paginatorPosition="top"
                    rowsPerPageOptions={[10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                    filters={filters}
                    stateStorage="session" stateKey="dt-gestion_trabajadores"
                    size='small'
                    loading={loading}
                    selectionMode='single'
                    selection={selected}
                    onSelectionChange={(e) => { setSelected(e.value) }}
                    onRowClick={() => { setSelected(null) }}
                >
                    {columnaConFiltros("Registro", "Registro Personal", true, "Buscar por registro")}
                    {columnaConFiltros("CUI", "CUI", true, "Buscar por CUI")}
                    {columnaConFiltros("Nombre", "Nombre", true, "Buscar por nombre")}
                    <Column field="Genero" header="Genero" body={generoBody}></Column>
                    <Column field="FechaNacimiento" header="Fecha de Nacimiento"
                        body={(rowData) => fechaBody(rowData, "FechaNacimiento")}></Column>
                    <Column field="FechaIngreso" header="Fecha de Ingreso"
                        body={(rowData) => fechaBody(rowData, "FechaIngreso")}></Column>
                    <Column field="EdadActual" header="Edad Actual"></Column>
                    <Column field="EdadIngreso" header="Edad de Ingreso"></Column>
                    <Column field="Afiliacion"
                        header="Afiliado a Plan de Prestaciones"
                        filter filterField="Afiliacion"
                        body={afiliacionTrabajadorBody}
                        filterElement={afiliacionTrabajadorFilter}
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showFilterOperator={false}
                        showApplyButton={false} showClearButton={false}>
                    </Column>
                    <Column field="NombreEstado"
                        header="Estado"
                        filter filterField="idEstadoTrabajador"
                        filterElement={estadoTrabajadorFilter}
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showFilterOperator={false}
                        showApplyButton={false} showClearButton={true}>
                    </Column>
                </DataTable>
                <br />
                {renderRedirect(redirect, 'ordenes_pago')}
            </div>
        </>
    );
}
