import './menu_jubilados.css';
import { TabView, TabPanel, } from 'primereact/tabview';
import RegistroAlzas from './alzas/alzas';
import RegistroAcuerdos from './acuerdos/acuerdos';
import ConsultaDatos from './consultas/consultas';
import Bajas from './bajas/bajas';
import CuentasPersona from './cuentas/cuentas';
import BusquedaAvanzada from './busqueda_avanzada/busqueda_avanzada';
import ControlOrfandades from './orfandades/control_orfandades';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

function MenuJubilados() {

    const { /* index, */ ptd, plz, reg } = useParams();
    const rol = +localStorage.getItem('rol');
    const [activeIndex, setActiveIndex] = useState(0);

    const location = useLocation();
    useEffect(() => {
        if (ptd && plz && reg) {
            setActiveIndex(3); // Pestaña de consulta de datos
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const onTabChange = (e) => {
        setActiveIndex(e.index);
    };

    /* const get_style = () => {
        if (rol > 4) {
            return { minWidth: '1300px' };
        }
        return {};
    } */

    return (
        <div className="container-fluid menu__jubilados" /* style={get_style()} */>
            <TabView activeIndex={activeIndex} onTabChange={onTabChange}>
                <TabPanel header="BÚSQUEDA AVANZADA" leftIcon='mr-2 pi pi-users'>
                    <BusquedaAvanzada />
                </TabPanel>
                {
                    rol <= 4 &&
                    <TabPanel header="ALZAS" leftIcon='mr-2 pi pi-user-plus'>
                        <RegistroAlzas />
                    </TabPanel>
                }
                {
                    rol <= 4 &&
                    <TabPanel header="NIVELACIONES" leftIcon='mr-2 pi pi-check'>
                        <RegistroAcuerdos />
                    </TabPanel>
                }
                <TabPanel header="CONSULTA DE DATOS" leftIcon='mr-2 pi pi-eye'>
                    <ConsultaDatos modificable={rol <= 4} ptd={ptd} plz={plz} reg={reg} />
                </TabPanel>
                {
                    rol <= 4 &&
                    <TabPanel header="GESTIÓN DE CUENTAS" leftIcon='mr-2 pi pi-dollar'>
                        <CuentasPersona />
                    </TabPanel>
                }
                {
                    rol <= 4 &&
                    <TabPanel header="BAJAS" leftIcon='mr-2 pi pi-user-minus'>
                        <Bajas />
                    </TabPanel>
                }
                {
                    rol <= 4 &&
                    <TabPanel header="ORFANDADES" leftIcon='mr-2 pi pi-sitemap'>
                        <ControlOrfandades />
                    </TabPanel>
                }
            </TabView>
        </div>
    );
}

export default MenuJubilados;
