import './descuentos.css';
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { ContextMenu } from 'primereact/contextmenu';
import { requestOptions } from '../misc/requestOptions';

function CatalogoDescuentos() {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [descuentoDialog, setDescuentoDialog] = useState(false);
    const [isLoaded, setLoaded] = useState(false);

    const [cuenta_contable, setCuentaContable] = useState('');
    const [status_aux, setStatusAux] = useState(null);
    const [nombreDescuento, setNombre] = useState('');
    const [anotacion, setAnotacion] = useState('');

    const [idcc, setIdcc] = useState(null);
    const [factor, setFactor] = useState(null);
    const [anotacion_db, setAnotacionDB] = useState(null);

    const toast = useRef(null);

    // Para descripción o factor
    const [displayDialog, setDisplayDialog] = useState(null);
    const hide_factor = () => {
        setDisplayDialog(null);
        setIdcc(null);
        setFactor(null);
    }

    const request_data = (showMessage = true) => {
        fetch(process.env.REACT_APP_API_URL_DESCUENTOS_CATALOGO)
            .then((response) => response.json())
            .then((data) => {
                if (showMessage)
                    toast.current.show({ severity: data.severity, summary: data.message || data.error });
                const x = data.data;
                if (x) setData(x);
                setLoaded(true);
            });
    }

    useEffect(() => {
        request_data();
    }, []);

    const add_descuento = () => {
        fetch((process.env.REACT_APP_API_URL_DESCUENTOS_CATALOGO + '/nuevo'),
            requestOptions('POST', {
                idcc: cuenta_contable,
                nombre: nombreDescuento,
                anotacion: anotacion
            }))
            .then((response) => response.json())
            .then((data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                setCuentaContable('');
                setNombre('');
                setAnotacion('');
                setDescuentoDialog(false);
                request_data(false);
            });
    }

    const alter_descuento = (idcc, estado, factor) => {
        fetch((process.env.REACT_APP_API_URL_DESCUENTOS_CATALOGO + '/alter'),
            requestOptions('POST', {
                idcc: idcc,
                estado: estado,
                factor: factor
            }))
            .then((response) => response.json())
            .then((data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                if (factor) hide_factor();
                request_data(false);
            });
    }

    const header = () => {
        return (
            <div className="flex">
                <p className='my-auto'>Catálogo de descuentos</p>
                <Button type='button' label="Crear descuento" icon="pi pi-plus" iconPos='right' severity='warning' raised outlined className="ml-auto font-weight-bold" onClick={() => setDescuentoDialog(true)} />
            </div>
        )
    }

    const dialog_footer = () => {
        return <div>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={() => setDescuentoDialog(false)} />
            <Button disabled={!cuenta_contable || !nombreDescuento/*||true*/} label="Guardar" icon="pi pi-check" className="p-button-text" onClick={add_descuento} />
        </div>
    }

    const factor_footer = () => {
        return <div>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={() => { hide_factor() }} />
            <Button disabled={!factor || !idcc} label="Guardar" icon="pi pi-check" className="p-button-text" onClick={() => alter_descuento(idcc, null, factor)} />
        </div>
    }

    const statusDescuento = (rowData) => {
        return <>
            <span className={`status-badge-nom status-${rowData.status.toLowerCase()} tooltip-button`}>{rowData.status}</span>
        </>;
    }

    // Para click derecho
    const cm = useRef(null);
    const menuModel =
        (status_aux === 'Habilitado' ?
            [{ label: 'Deshabilitar', icon: 'pi pi-fw pi-times', command: () => alter_descuento(idcc, 0, null) }]
            :
            [{ label: 'Habilitar', icon: 'pi pi-fw pi-check', command: () => alter_descuento(idcc, 1, null) }])
            .concat(
                [{ label: 'Modificar factor', icon: 'pi pi-fw pi-pencil', command: () => setDisplayDialog('F') }]
            );
    // const menuModel = auxModel;

    // Para BreadCrumb
    const items = [
        { label: 'NÓMINA', command: () => { navigate("/nominas") } },
        { label: 'DESCUENTOS', command: () => { navigate("/nominas/descuentos") } },
        { label: 'CATÁLOGO', command: () => { navigate("/nominas/descuentos") } }
    ];
    const home = { icon: 'pi pi-home', command: () => { navigate("/") } };

    return (
        <div className='container__catalogo'>
            <Toast ref={toast}></Toast>
            <BreadCrumb model={items} home={home} />
            <ContextMenu model={menuModel} ref={cm} onHide={() => setCuentaContable('')} />
            {
                isLoaded &&
                <div className="card__catalogo">
                    <DataTable value={data} header={header} style={{ borderRadius: '1em' }}
                        onRowDoubleClick={e => { setAnotacionDB(e.data.anotacion); setDisplayDialog('A'); }} contextMenuSelection={idcc}
                        onContextMenuSelectionChange={e => { setStatusAux(e.value.status); setIdcc(e.value.idcc); setFactor(e.value.factor); }}
                        onContextMenu={e => cm.current.show(e.originalEvent)}
                        selectionMode='single' emptyMessage="No se encontró ningún registro." >
                        <Column field="id" header="ID"></Column>
                        <Column field="idcc" header="Cuenta contable"></Column>
                        <Column field="nombre" header="Descuento" ></Column>
                        <Column align={'center'} field="factor" header="Factor" ></Column>
                        <Column header="Estado" body={statusDescuento}></Column>
                    </DataTable>
                    <Dialog visible={descuentoDialog} style={{ width: '400px' }} header="Nuevo descuento" modal className="p-fluid" footer={dialog_footer} onHide={() => setDescuentoDialog(false)} resizable={false}>
                        <div className="field">
                            <label htmlFor="idcc">Cuenta contable</label>
                            <InputText id="idcc" type="text" keyfilter={/[\d]/} value={cuenta_contable} onChange={(e) => setCuentaContable(e.target.value)} required autoFocus />
                        </div>
                        <div className="field">
                            <label htmlFor="nombred">Nombre descuento</label>
                            <InputText id='nombred' type='text' value={nombreDescuento} onChange={(e) => setNombre(e.target.value.toUpperCase())} required />
                        </div>
                        <div className="field">
                            <label htmlFor="anotad">Descripción</label>
                            <InputText id='anotad' type='text' value={anotacion} onChange={(e) => setAnotacion(e.target.value)} placeholder='*Opcional' />
                        </div>
                    </Dialog>
                    <Dialog header="Anotación" headerStyle={{ paddingTop: '8px', paddingBottom: '8px' }} visible={displayDialog === 'A'}
                        position={'center'} modal style={{ minWidth: '20%' }} onHide={() => setDisplayDialog(null)}
                        draggable={true} resizable={false}>
                        <p className="m-0">{anotacion_db}</p>
                    </Dialog>
                    <Dialog header={`${idcc ? idcc : ''} | Modificar factor`} footer={factor_footer} headerStyle={{ paddingTop: '8px', paddingBottom: '8px' }}
                        visible={displayDialog === 'F'} position={'center'} modal
                        onHide={() => { hide_factor() }} draggable={true} resizable={false}>
                        <div className="field container__factor ml-2 mb-1">
                            <label htmlFor='factordb' className='mr-2'>Factor</label>
                            <InputNumber inputId='factordb' type='text' mode='decimal'
                                tooltip={'Por defecto el factor es 1\nDoble: Factor = 2\nTriple: Factor = 3'} showButtons
                                min={1} value={factor} onChange={(e) => setFactor(e.value)} />
                        </div>
                    </Dialog>
                </div>
            }

        </div>
    );
}

export default CatalogoDescuentos;
