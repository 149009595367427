import { TabView, TabPanel, } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { useRef, useState } from 'react';
import { new_loan_route } from '../../constants/backend_routes';
import './formulario_prestamos.css'


const FromularioPrestamos = () => {
    const regex_alfa = /[a-zA-zñÑáÁéÉíÍóÓúÚ0-9 ]/;

    const [startValue, setStartValue] = useState(0);
    const [duration, setDuration] = useState(0);
    const [paymentDay, setPaymentDay] = useState('');
    const [rate, setRate] = useState(0);
    const [personId, setPersonId] = useState('');
    const [startDate, setStartDate] = useState('');
    const [description, setDescription] = useState('');

    const reset_form = () => {
        setStartValue(0);
        setDuration(0);
        setPaymentDay('');
        setRate(0);
        setPersonId(0);
        setStartDate('');
        setDescription('');
    }
    const toast = useRef(null);

    const submitLoan = async (loan) =>{
        const response = await fetch(new_loan_route, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(loan)
        })
        if (!response.ok){
            return false
        } else {
            return true
        }
    }

    function formatDateToYYYYMMDD(date) {
        const year = date.getFullYear();
        // Se agrega 1 al mes porque los meses en JavaScript van de 0 a 11
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
      
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      }

    const confirm = (event) => {
        confirmPopup({
            target: document.getElementById('alzas_confirm'),
            message: '¿Seguro que desea continuar?',
            icon: 'pi pi-question-circle',
            acceptLabel: 'Sí',
            accept: async () => {
                // consumir endpoint
                const fechaInicial = formatDateToYYYYMMDD(startDate)
                const fechaPago = formatDateToYYYYMMDD(paymentDay)
                const success = await submitLoan({
                    start_value: parseFloat(startValue),
                    duration: parseInt(duration),
                    payment_day: fechaPago,
                    rate: parseFloat(rate),
                    person_id: parseInt(personId),
                    start_date: fechaInicial,
                    description: description
                })
                if (success){
                    reset_form();
                    toast.current.show({ severity: 'success', summary: 'Prestamo Registrado.' });
                }else{
                    toast.current.show({ severity: 'error', summary: 'El prestamo no pudo ser registrado'})
                }
            }
        });
        event.preventDefault();
    }
    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <div className='container'>
            <TabView>
                <TabPanel header="Formulario de nuevo prestamo" leftIcon='mr-2 pi pi-user-plus'>
                    <form id='alzas_form' onSubmit={confirm} noValidate={false}>
                        <Toast ref={toast}></Toast>
                        <div className="grid p-fluid formulario">
                            {/* VALOR INICIAL */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter="num" required={true} value={startValue} onChange={(e) => setStartValue(e.target.value)} />
                                        <label>Valor inicial</label>
                                    </span>
                                </div>
                            </div>
                            {/* DURACION */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="number" keyfilter="pint" required={true} value={duration} onChange={(e) => setDuration(e.target.value)} />
                                        <label>Duracion</label>
                                    </span>
                                </div>
                            </div>
                            {/* TASA DE INTERES */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="number" keyfilter="num" required={true} value={rate} onChange={(e) => setRate(e.target.value)} />
                                        <label>Tasa de interes</label>
                                    </span>
                                </div>
                            </div>
                            {/* IDENTIFICACION PERSONA */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={true} value={personId} onChange={(e) => setPersonId(e.target.value)} />
                                        <label>Identificacion de persona</label>
                                    </span>
                                </div>
                            </div>
                            {/* FECHA INICIAL */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-calendar"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <Calendar dateFormat="dd/mm/yy" value={startDate} onChange={(e) => setStartDate(e.value)}></Calendar>
                                        <label >Fecha inicial</label>
                                    </span>
                                </div>
                            </div>
                            {/* FECHA DE PAGO */}
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-calendar"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <Calendar dateFormat="dd/mm/yy" value={paymentDay} onChange={(e) => setPaymentDay(e.value)}></Calendar>
                                        <label >Fecha de pago</label>
                                    </span>
                                </div>
                            </div>
                            {/* DESCRIPCION */}
                            <div className="field col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" keyfilter={regex_alfa} required={true} value={description} onChange={(e) => setDescription(e.target.value)} />
                                        <label>Descripcion</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="confirm" >
                            <Button id='alzas_confirm' type='submit' icon="pi pi-check-circle" label="Registrar" className="mt-2 p-button-raised font-weight-bold" />
                            <ConfirmPopup />
                        </div>
                    </form>
                </TabPanel>
            </TabView>

        </div>

    );
}

export default FromularioPrestamos;