import './logs.css';
import { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { locale, addLocale } from 'primereact/api';
import data from '../diccionario.json';
import { meses } from '../constants/diccionarios';
import { colorFilaError, fueErrorBody, fueErrorFilter, obtenerAnioMes } from './constants';
import { columnaConFiltros, containsFilter, fechaBody, mostrarError } from '../constants/bodiesYFiltos';
import { eliminarLogs, getLogs, getUltimaFechaLogs } from '../endpoints/logs';
locale('es');
addLocale('es', data.es)

export default function CuotasLogs() {
    const [anio, setAnio] = useState(2023);
    const [mes, setMes] = useState({
        nombre: 'Julio',
        numero: 7
    });
    const [logs, setLogs] = useState([]);
    const [filters] = useState({
        Mensaje: containsFilter,
        FueError: containsFilter,
    });

    const toast = useRef(null);

    useEffect(() => {
        // obtener ultima fecha
        getUltimaFechaLogs().then((resp) => {
            var fecha = obtenerAnioMes(resp.data.fechaReciente);
            setAnio(fecha.Anio);
            for (var i = 0; i < meses.length; i++) {
                var month = meses[i];
                if (month.numero === parseInt(fecha.Mes)) {
                    setMes(month);
                    break;
                }
            }

            // obtener logs
            obtenerLogs(fecha);
        }).catch(err => {
            mostrarError(err);
        });
    }, []);

    const obtenerLogs = (AnioMes) => {
        // obtener logs
        getLogs(AnioMes).then((resp) => {
            setLogs(resp.data);
        }).catch(err => {
            mostrarError(err);
        });
    };

    const EliminarLogs = (AnioMes) => {
        confirmDialog({
            message: '¿Está seguro de eliminar los logs para esta fecha?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptEliminarLogs(AnioMes),
        });
    }

    const acceptEliminarLogs = (AnioMes) => {
        eliminarLogs(AnioMes).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                obtenerLogs(AnioMes);
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err, setLogs([]));
        });
    }

    return (
        <>
            <div className='primario'>
                <br />
                {/* PARA LA CONFIRMACIÓN DE ELIMINAR REGISTRO */}
                <Toast ref={toast} />
                <ConfirmDialog />
                <div className="grid p-fluid">
                    <div className='col-12 md:col-2'>
                        <label className="font-bold block mb-2 text-color">Año</label>
                        <InputNumber value={anio} onValueChange={(e) => setAnio(e.value)}
                            mode="decimal" showButtons min={0} useGrouping={false} />
                    </div>
                    <div className='col-12 md:col-2'>
                        <label className="font-bold block mb-2 text-color">Mes</label>
                        <Dropdown
                            value={mes}
                            options={meses}
                            optionLabel="nombre"
                            placeholder="Seleccionar mes"
                            onChange={(e) => setMes(e.value)}
                        />
                    </div>
                    <div className='col-12 md:col-1'>
                        <br />
                        <Button type='button' className='p-button-rounded p-button-success'
                            icon="pi pi-search"
                            onClick={() => obtenerLogs({ Anio: anio, Mes: mes.numero })}
                            tooltip='Buscar logs'
                            style={{ marginTop: '7%' }} />
                    </div>
                    <div className='col-12 md:col-3' style={{ marginLeft: 'auto' }}>
                        <br />
                        <Button type="button" icon="pi pi-trash" label="Eliminar logs de esta fecha"
                            severity="danger" disabled={logs?.length === 0}
                            onClick={() => EliminarLogs({ Anio: anio, Mes: mes.numero })} />
                    </div>
                </div>
                <br />
                <DataTable value={logs} paginator rows={10} paginatorPosition="top"
                    rowsPerPageOptions={[10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                    filters={filters}
                    size='small'
                    rowClassName={colorFilaError}
                >
                    <Column field="FechaHora" header="Fecha y hora"
                        body={(rowData) => fechaBody(rowData, "FechaHora", true)}></Column>
                    {columnaConFiltros("Mensaje", "Mensaje", true, "Buscar por mensaje")}
                    <Column field="FueError"
                        header="Fue Error?"
                        filter filterField="FueError"
                        body={fueErrorBody}
                        filterElement={fueErrorFilter}
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showFilterOperator={false}
                        showApplyButton={false} showClearButton={false}>
                    </Column>
                </DataTable>
                <br />
            </div>
        </>
    );
}
