import '../calculo_cuotas.css';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import { DatosTrabajador } from '../../constants/datos_trabajador';
import { headerForm, fechaBody, renderRedirect, mostrarError, moneyBody, moneyFormat } from '../../constants/bodiesYFiltos';
import { eliminarOrdenPago, getOrdenesDePago } from '../../endpoints/calculo_cuotas/orden_pago';

export default function OrdenesPago() {
    // Datos trabajador
    const [trabajador, setTrabajador] = useState([{}]);

    // Ordenes de pago
    const [ordenesDePago, setOrdenesDePago] = useState([]);

    const [selected, setSelected] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        // obtener el trabajador seleccionado
        var seleccionado = JSON.parse(sessionStorage.getItem('trabajador_seleccionado'));
        setTrabajador([seleccionado]);

        // obtener las ordenes de pago
        getOrdenesDePago({ RegistroTrabajador: seleccionado.Registro }).then((resp) => {
            setOrdenesDePago(resp.data);
        }).catch(err => {
            mostrarError(err);
        });
    }, []);

    const VerOrdenPago = () => {
        if (selected === null)
            return
        sessionStorage.setItem('orden_seleccionada', JSON.stringify(selected));
        setSelected(null);
        setRedirect(true);
    }

    const accept = () => {
        eliminarOrdenPago({ idOrdenPago: selected.idOrdenPago }).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                // quitar el registro
                var del = ordenesDePago.filter(orden => orden.idOrdenPago !== selected.idOrdenPago);
                setOrdenesDePago(del);
                setSelected(null);
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err);
        });
    }

    const EliminarOrden = () => {
        if (!selected)
            return

        confirmDialog({
            message: 'Está seguro de eliminar la orden de pago seleccionada?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept(),
        });
    }

    return (
        <div className='primario-crear-registro'>
            <Toast ref={toast} />
            <ConfirmDialog />
            {/* DATOS DEL TRABAJADOR */}
            <Card header={headerForm("Datos del trabajador", true)} className="card">
                <DatosTrabajador trabajador={trabajador} />
            </Card>
            <br />
            {/* ORDENES DE PAGO */}
            <Card header={headerForm("Ordenes de Pago")} className="card">
                <div className="grid p-fluid">
                    <div className='col-12 md:col-2' style={{ marginLeft: 'auto' }}>
                        <Button type="button" icon="pi pi-eye" label="Ver Orden"
                            disabled={selected === null}
                            onClick={(e) => VerOrdenPago()} />
                    </div>
                    <div className='col-12 md:col-2'>
                        <Button type="button" icon="pi pi-trash" label="Eliminar Orden"
                            severity="danger" disabled={selected === null}
                            onClick={() => EliminarOrden()} />
                    </div>
                    <div className='col-12 md:col-2'>
                        <Link to="/cuotas/crear_orden_pago">
                            <Button type="button" icon="pi pi-money-bill" label="Crear Orden"
                                severity="success" />
                        </Link>
                    </div>
                </div>
                <DataTable value={ordenesDePago} paginator rows={10} paginatorPosition="top"
                    rowsPerPageOptions={[10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                    size='small'
                    selectionMode='single'
                    selection={selected}
                    onSelectionChange={(e) => { setSelected(e.value) }}
                    onRowClick={() => { setSelected(null) }}
                    emptyMessage="Este trabajador no tiene ordenes de pago"
                >
                    <Column field="FechaElaboracion" header="Fecha Elaboracion"
                        body={(rowData) => fechaBody(rowData, 'FechaElaboracion')}></Column>
                    <Column field="InteresesHasta" header="Intereses Hasta"
                        body={(rowData) => fechaBody(rowData, 'InteresesHasta')}></Column>
                    <Column field="TotalCuotaAPagar" header="Total Cuota a Pagar(Q)"
                        body={(rowData) =>
                            rowData.TotalCuotaAPagar &&
                            moneyBody(rowData, 'TotalCuotaAPagar')}></Column>
                    <Column field="TotalIntereses" header="Total Intereses(Q)"
                        body={(rowData) => moneyBody(rowData, 'TotalIntereses')} ></Column>
                    <Column field="TotalAPagar" header="Total a Pagar(Q)"
                        body={(rowData) =>
                            moneyFormat(rowData.TotalCuotaAPagar + rowData.TotalIntereses)
                        }></Column>
                    <Column field="Observacion" header="Observación" ></Column>
                </DataTable>
            </Card>
            {renderRedirect(redirect, 'ver_orden_pago')}
        </div>
    );
}
