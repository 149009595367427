import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';

// ------------------------------------------------- BODIES
export const colorFilaError = (data) => {
    return {
        'bg-danger text-white': data.FueError === 1,
        'bg-cyan-800 text-white': data.FueError === 0
    };
};

export const fueErrorBody = (rowData) => {
    if (rowData.FueError === 1) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='SÍ' severity='danger' />
        </center>;
    } else if (rowData.FueError === 0) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='NO' severity='success' />
        </center>;
    } else {
        return 'Seleccionar'
    }
};

export const fueErrorFilter = (options) => {
    return <Dropdown value={options.value} options={[0, 1]}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={fueErrorItem} placeholder="Cualquiera" className="p-column-filter"
        showClear valueTemplate={fueErrorItem} />;
};

export const fueErrorItem = (option) => {
    if (option === 1) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='Sí' severity='danger' />
        </center>;
    } else if (option === 0) {
        return <center style={{ textTransform: 'uppercase' }}>
            <Tag value='No' severity='success' />
        </center>;
    } else {
        return 'Seleccionar'
    }
};

// ------------------------------------------------- FUNCIONES
export const obtenerAnioMes = (fecha) => {
    var date = new Date(fecha);
    var mes, anio;

    anio = date.getFullYear();
    mes = date.getMonth() + 1;

    if (mes < 10) {
        mes = '0' + mes;
    }

    return { Anio: anio, Mes: mes }
};




