import { requestOptions } from "../requestOptions";
const api = process.env.REACT_APP_API_URL_CUOTAS || "http://localhost:8091/cuotas/"

// ------------------------ ORDEN DE PAGO
export async function getOrdenesDePago(data = {}) {
    const url = api + "orden_pago/get_ordenes_de_pago"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function getPeriodosOrden(data = {}) {
    const url = api + "orden_pago/get_periodos_orden"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function getMesesCobro(data = {}) {
    const url = api + "orden_pago/get_meses_cobro"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function agregarObservacion(data = {}) {
    const url = api + "orden_pago/agregar_observacion"
    const response = await fetch(url, requestOptions("PUT", JSON.stringify(data)));
    return response.json();
}

export async function guardarOrdenPago(data = {}) {
    const url = api + "orden_pago/guardar_orden_de_pago"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function eliminarOrdenPago(data = {}) {
    const url = api + "orden_pago/eliminar_orden_de_pago/" + data.idOrdenPago
    const response = await fetch(url, requestOptions("DELETE", null));
    return response.json();
}

export async function exportarOrdenpago(data = {}) {
    const url = api + "orden_pago/exportar_orden_pago"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response;
}











