import './control_orfandades.css';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { requestOptions } from '../../modulo_nominas/misc/requestOptions';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';

function ControlOrfandades() {

    const [control, setControl] = useState(null);
    const [displayDialog, setDisplayDialog] = useState(null);
    const [loading, setLoading] = useState(null);

    /* Filters */
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(20);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'registro': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'tipo': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'observaciones': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    }
    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }
    const pg_template = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 20, value: 20 },
                { label: 100, value: 100 },
                { label: 'Todos', value: control ? control.length : 0 }
            ];
            return (
                <React.Fragment>
                    <span className="mx-0">Elementos por página: </span>
                    <Dropdown className="mr-4 table__paginator" value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        'CurrentPageReport': (options) => {
            return (
                <span style={{ width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} de {options.totalRecords}
                </span>
            )
        }
    };

    useEffect(() => {
        obtener_control();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toast = useRef(null);

    const obtener_control = () => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL_CONTROL_ORFANDADES)
            .then((response) => response.json())
            .then((data) => {
                const x = data.data;
                if (data.error || !x)
                    toast.current.show({ severity: data.severity, summary: data.error || 'Error: No fue posible obtener el control.' });
                else {
                    setControl(x);
                }
                setLoading(false);
            });
    }

    const request_report = async (idReport, fileType = 'xlsx') => {
        setLoading(idReport);
        try {
            const response = await fetch(process.env.REACT_APP_API_URL_CONTROL_ORFANDADES,
                requestOptions('POST', {
                    reportSelected: idReport,
                    fileType: fileType
                }))
            if (!response.ok) throw new Error('No fue posible generar el reporte.');
            const docBlob = await response.blob();
            // Crear un objeto URL a partir del Blob
            const docUrl = URL.createObjectURL(docBlob);
            // Crear un enlace temporal para descargar el doc
            const a = document.createElement('a');
            a.href = docUrl;
            a.download = `REPORTE_ORFANDAD_${idReport == '*' ? 'COMPLETO' : idReport}`;
            a.click();
            // Liberar la URL del objeto Blob
            URL.revokeObjectURL(docUrl);
            toast.current.show({ severity: 'success', summary: 'Reporte generado.' });
            // resetVars();
        } catch (error) {
            console.error('Error al descargar el reporte.');
            toast.current.show({ severity: 'error', summary: error.toString() });
        }
        setLoading(false);
    }

    const select_tipo_reporte = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Seleccione filtro',
            icon: 'pi pi-info-circle',
            rejectClassName: 'p-button-rounded p-button-secondary p-button-outlined',
            acceptClassName: 'p-button-rounded p-button-secondary p-button-outlined',
            rejectLabel: '18 años',
            acceptLabel: '21 años',
            reject: () => { request_report(18) },
            accept: () => { request_report(21) }
        });
    }

    const row_estado_texto = (rowData) => {
        switch (rowData.estado) {
            case null:
                return <>NO ESTÁ EN NÓMINA</>
            case 1:
                return <>ACTIVO EN NÓMINA</>
            case 0:
                return <>DE BAJA EN NÓMINA</>
            default:
                return <></>;
        }
    }

    const header = (
        <div className="table-header flex">
            <div className='my-auto'>CONTROL DE ORFANDADES</div>
            <div className='ml-auto'>
                <Button icon="pi pi-sort-numeric-down-alt" label="Reporte por edad" className="p-button-secondary mr-4 h-100" loading={loading === 18 || loading === 21} onClick={select_tipo_reporte} />
                <Button icon="pi pi-file-excel" onClick={() => request_report('*')} className='excel_btn_report mr-4 h-100' label='Exportar todo' loading={loading === '*'} />
                <span className="p-input-icon-right">
                    <i className="pi pi-search" />
                    <InputText style={{ width: '100%' }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Buscar" tooltip='PUEDE BUSCAR POR TIPO, REGISTRO O NOMBRE.' />
                </span>
            </div>
        </div>
    );

    /* const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        // console.log(rowData, newValue, field)
        if (newValue && newValue.length > 0) {
            rowData[field] = newValue;
            fetch(process.env.REACT_APP_API_URL_CONTROL_ORFANDADES, requestOptions('PUT', {
                idcontrol: rowData.idcontrol,
                plaza: newValue
            }))
                .then((response) => response.json())
                .then((data) => {
                    toast.current.show({ severity: data.severity, summary: data.message || data.error });
                    data.data && setControl(data.data);
                });
        }
        else
            event.preventDefault();
    }
    const textEditor = (options) => {
        return <InputText type="text" keyfilter="pint" style={{ maxWidth: '50px' }} value={options.value || ''} onChange={(e) => options.editorCallback(e.target.value)} />;
    } */

    return (
        <div className="container-fluid control_orfandades p-3">
            <Toast ref={toast}></Toast>
            <hr />
            {
                loading === true ?
                    <ProgressBar mode="indeterminate" style={{ height: '8px', marginTop: '20px' }} />
                    :
                    <>
                        <DataTable value={control} header={header} paginator paginatorTemplate={pg_template} first={first} rows={rows} onPage={onCustomPage}
                            selectionMode='single' emptyMessage="Sin resultados." paginatorClassName="justify-content-end" className="mt-2 mx-auto"
                            filters={filters} globalFilterFields={['registro', 'nombre', 'tipo', 'observaciones']}
                            onRowDoubleClick={e => { setDisplayDialog(e.data.observaciones || ''); }} /* editMode="cell" */
                            rowClassName={(e) => {
                                if (e.observaciones && e.observaciones.includes("INTERDIC")) return 'yellowrow';
                                switch (e.estado) {
                                    case 0: return 'redrow';
                                    case 1: return 'greenrow';
                                    default: return '';
                                }
                            }}>
                            {/* <Column field="idcontrol" header="ID"></Column> */}
                            <Column field="tipo" header="Tipo"></Column>
                            <Column field="registro" header="Registro"></Column>
                            <Column /* editor={(options) => textEditor(options)} onCellEditComplete={onCellEditComplete} */ field="plaza" header="Plaza"></Column>
                            <Column field="nombre" header="Nombre"></Column>
                            <Column field="fecha_nacimiento" header="F. Nacimiento"></Column>
                            <Column field="edad" header="Edad"></Column>
                            <Column body={row_estado_texto} header="Estado"></Column>
                        </DataTable>
                    </>
            }
            <Dialog header="Observaciones" headerStyle={{ paddingTop: '8px', paddingBottom: '8px' }} visible={displayDialog !== null}
                position={'center'} modal style={{ minWidth: '20%' }} onHide={() => setDisplayDialog(null)}
                draggable={true} resizable={false}>
                <p className="m-0">{displayDialog}</p>
            </Dialog>
            <ConfirmPopup />
        </div>
    );
}

export default ControlOrfandades;
