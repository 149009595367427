import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const TablaHistorialPeriodos = ({ periodos = [], periodoInicial, setPeriodoInicial }) => {
    return (<>
        <DataTable value={periodos ? periodos : []} scrollable scrollHeight="flex"
            tableStyle={{ minWidth: '50rem' }}
            selectionMode='single'
            selection={periodoInicial}
            onSelectionChange={(e) => { setPeriodoInicial(e.value) }}
            onRowClick={() => { setPeriodoInicial(null) }}>
            <Column header="#" body={(rowData, rowIndex) => rowIndex.rowIndex + 1} />
            <Column field="Anio" header="Año" />
            <Column field="MesInicio" header="Mes Inicio" />
            <Column field="MesFinal" header="Mes Final" />
            <Column field="TasaInteres" header="Tasa Interes(%)" />
        </DataTable>
    </>)
}
