import './perfil_usuario.css';
import PerfilUsuario from './perfil';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { get_persona_especifica_route } from '../../constants/backend_routes';

function PerfilUsuarioMoras() {
  const {id_usuario} = useParams() 
  const [userData, setUserData] = useState()
  const [user, setUser] = useState()
  const [loans, setLoans] = useState()

  useEffect(()=>{
    const fetchData = async () => {
      const response = await fetch(`${get_persona_especifica_route}/${id_usuario}`)
      const responseJson = await response.json()
      setUserData(responseJson.persona)
      setUser({
        name: `${responseJson.persona.nombres} ${responseJson.persona.apellidos}`,
        identification: responseJson.persona.numero_dpi,
        ref_number: responseJson.persona.registro,
        location: responseJson.persona.direccion,
        description: responseJson.persona.comentario,
        date: responseJson.persona.fecha_nacimiento,
        start_date: responseJson.persona.fecha_alza,
        email: responseJson.persona.correo,
        phone_number: responseJson.persona.telefono
      })
      setLoans(responseJson.persona.prestamos)
    }
    fetchData()
    console.log(userData)
  }, [])


  return (
    <div className='perfil__usuario container'>
      {loans && user && userData && <PerfilUsuario user={user} userCards={loans} userId={id_usuario} />}
    </div>
  );
}

export default PerfilUsuarioMoras;