import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { requestOptions } from '../misc/requestOptions';

function FirmasReportes(props) {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [isLoaded, setLoaded] = useState(false);

    const toast = useRef(null);

    const request_data = () => {
        fetch(process.env.REACT_APP_API_URL_REPORTE_FIRMAS)
            .then((response) => response.json())
            .then((data) => {
                if (data.error)
                    toast.current.show({ severity: data.severity, summary: data.message || data.error });
                const x = data.data;
                if (x) setData(x);
                setLoaded(true);
            });
    }

    useEffect(() => {
        request_data();
    }, []);

    const modificar_firma = (newData) => {
        fetch(process.env.REACT_APP_API_URL_REPORTE_FIRMAS,
            requestOptions('PUT', {
                idfirma: newData.idfirma,
                nombre: newData.nombre,
                puesto: newData.puesto,
                anotacion: newData.anotacion
            }))
            .then((response) => response.json())
            .then((data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                request_data();
            });
    }

    const header = () => {
        return (
            <div className="flex">
                <p className='my-auto'>Firmas de reportes</p>
            </div>
        )
    }

    // Para BreadCrumb
    const items = [
        { label: 'NÓMINAS', command: () => { navigate("/nominas") } },
        { label: 'FIRMAS', command: () => { navigate("/nominas/firmas") } }
    ];
    const home = { icon: 'pi pi-home', command: () => { navigate("/") } };

    // Para editar campos
    const textEditor = (options) => { return <InputText type="text" /*value={options.value}*/ onChange={(e) => options.editorCallback(e.target.value)} /> }

    return (
        <div className='container__firmas'>
            <Toast ref={toast}></Toast>
            <BreadCrumb model={items} home={home} />
            {
                isLoaded &&
                <div className="card__firmas">
                    <DataTable value={data} header={header} style={{ borderRadius: '1em' }}
                        selectionMode='single' emptyMessage="No se encontró ningún registro."
                        editMode="row" onRowEditComplete={(e) => {
                            // console.log(e.newData)
                            modificar_firma(e.newData);
                        }} >
                        <Column align={'center'} bodyClassName='font-weight-bold' field="idfirma" header="#"></Column>
                        <Column field="registro" header="Registro" editor={(options) => textEditor(options)}></Column>
                        <Column field="nombre" header="Nombre" editor={(options) => textEditor(options)}></Column>
                        <Column field="puesto" bodyStyle={{ fontFamily: 'monospace' }} header="Puesto" editor={(options) => textEditor(options)}></Column>
                        {
                            props.modificable &&
                            <Column align={'center'} rowEditor className='editor-column' header="Modificar" ></Column>
                        }
                    </DataTable>
                </div>
            }

        </div>
    );
}

export default FirmasReportes;
