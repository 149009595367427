import { requestOptions } from "./requestOptions";
const api = process.env.REACT_APP_API_URL_CUOTAS || "http://localhost:8091/cuotas/"

export async function getUltimaFecha() {
    const url = api + "historial/get_ultima_fecha"
    const response = await fetch(url, requestOptions("GET", null));
    return response.json();
}

export async function getRegistrosHistorialFecha(data = {}) {
    const url = api + "historial/get_registros_fecha"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function getRegistrosHistorialTrabajador(data = {}) {
    const url = api + "historial/get_registros_trabajador"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function crearRegistro(data = {}) {
    const url = api + "historial/crear_registro"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function editarRegistro(data = {}) {
    const url = api + "historial/editar_registro"
    const response = await fetch(url, requestOptions("PUT", JSON.stringify(data)));
    return response.json();
}

export async function eliminarRegistro(data = {}) {
    const url = api + "historial/eliminar_registro/" + data.idCuota
    const response = await fetch(url, requestOptions("DELETE", null));
    return response.json();
}

export async function cargarArchivos(data = {}) {
    const url = api + "historial/cargar_archivos"
    const response = await fetch(url, { method: "POST", body: data });
    return response.json();
}

export async function exportarTabla(data = {}) {
    const url = api + "historial/exportar_tabla"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response;
}





