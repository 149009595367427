import './reintegros.css';
import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { useSessionStorage } from 'primereact/hooks';
import { locale, addLocale } from 'primereact/api';

import data from '../diccionario.json';
import { containsFilter, columnaConFiltros, mostrarError, moneyBody } from '../constants/bodiesYFiltos';
import { fechaBody } from '../constants/bodiesYFiltos';
import { eliminarReintegro, getReintegros } from '../endpoints/reintegros';

locale('es');
addLocale('es', data.es);

export default function Reintegros() {
    const [filters] = useState({
        RegistroTrabajador: containsFilter,
    });
    const [reintegros, setReintegros] = useSessionStorage([], 'reintegros');
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const toast = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);

        getReintegros().then((resp) => {
            setReintegros(resp.data);
            setLoading(false);
        }).catch(err => {
            mostrarError(err, setLoading(false), setReintegros([]));
        });

    }, []);

    const redireccionar = (destino) => {
        if (selected === null)
            return
        sessionStorage.setItem('reintegro_seleccionado', JSON.stringify(selected));
        setSelected(null);
        navigate('/cuotas/' + destino)
    }

    const accept = () => {
        eliminarReintegro({ idReintegro: selected.idReintegro }).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                // quitar el registro
                var del = reintegros.filter(registro => registro.idReintegro !== selected.idReintegro);
                setReintegros(del);
                setSelected(null);
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err, setLoading(false), setReintegros([]));
        });
    }

    const EliminarRegistro = () => {
        if (!selected)
            return

        confirmDialog({
            message: '¿Está seguro de eliminar el convenio seleccionado?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept
        });
    }

    return (
        <>
            {/* PARA LA CONFIRMACIÓN DE ELIMINAR REGISTRO */}
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className='primario'>
                <br />
                <div className="grid p-fluid">
                    <div className='col-12 md:col-2' style={{ marginLeft: 'auto' }}>
                        <Button type="button" icon="pi pi-eye" label="Ver Reintegro"
                            severity="success" disabled={selected === null}
                            onClick={() => redireccionar('ver_reintegro')} />
                    </div>
                    <div className='col-12 md:col-2'>
                        <Button type="button" icon="pi pi-pencil" label="Editar Reintegro"
                            severity="warning" disabled={selected === null}
                            onClick={() => redireccionar('editar_reintegro')} />
                    </div>
                    <div className='col-12 md:col-2'>
                        <Button type="button" icon="pi pi-trash" label="Eliminar Reintegro"
                            severity="danger" disabled={selected === null}
                            onClick={EliminarRegistro} />
                    </div>
                    <div className='col-12 md:col-2'>
                        <Link to="/cuotas/crear_reintegro">
                            <Button type="button" icon="pi pi-plus" label="Agregar Reintegro" />
                        </Link>
                    </div>
                </div>
                <br />
                <DataTable value={reintegros} paginator rows={10} paginatorPosition="top"
                    rowsPerPageOptions={[10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                    filters={filters}
                    size='small'
                    loading={loading}
                    selectionMode='single'
                    selection={selected}
                    onSelectionChange={(e) => { setSelected(e.value) }}
                    onRowClick={() => { setSelected(null) }}
                    emptyMessage="No hay reintegros registrados"
                >
                    {columnaConFiltros("RegistroTrabajador", "Registro Trabajador", true, "Buscar por registro")}
                    <Column field="Cantidad" header="Cantidad(Q)"
                        body={(rowData) => moneyBody(rowData, 'Cantidad')}></Column>
                    <Column field="Motivo" header="Motivo"></Column>
                    <Column field="Fecha" header="Fecha"
                        body={(rowData) => fechaBody(rowData, 'Fecha')}></Column>
                    {columnaConFiltros("NumCheque", "Número de Cheque", true, "Buscar por número de cheque")}
                    <Column field="FechaCheque" header="Fecha del cheque"
                        body={(rowData) => fechaBody(rowData, 'FechaCheque')}></Column>
                </DataTable>
                <br />
            </div>
        </>
    );
}
