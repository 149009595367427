import './convenios.css';
import { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';

import { calcularPago, emptyTemplate } from './constants';
import { headerForm, mostrarError } from '../constants/bodiesYFiltos';
import { SeleccionarTrabajador } from '../constants/seleccionar_trabajador';
import { crearConvenio } from '../endpoints/convenios';

export default function CrearConvenio() {
    // Datos trabajador
    const [selectedTrabajador, setSelectedTrabajador] = useState({ CUI: '', Registro: '', Nombre: '' });

    // --------------- Datos del convenio
    const [montoTotal, setMontoTotal] = useState(null);
    const [frecuencia, setFrecuencia] = useState(null);
    const [motivo, setMotivo] = useState('');
    const [actaAutorizacion, setActaAutorizacion] = useState(null);
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);
    const [fechaAutorizacion, setFechaAutorizacion] = useState(null);
    // codeudor
    const [CUICodeudor, setCUICodeudor] = useState(null);
    const [nombreCodeudor, setNombreCodeudor] = useState('');
    const [telefonoCodeudor, setTelefonoCodeudor] = useState(null);

    const [firma, setFirma] = useState(null);
    const fileUploadRef = useRef(null);

    const toast = useRef(null);

    async function CrearConvenio() {
        if (!selectedTrabajador.Registro) {
            alert("Seleccione un trabajador");
            return;
        }

        var obj = {
            MontoTotal: montoTotal,
            Frecuencia: frecuencia,
            Motivo: motivo,
            ActaAutorizacion: actaAutorizacion,
            FechaInicio: fechaInicio,
            FechaFin: fechaFin,
            FechaAutorizacion: fechaAutorizacion,
            CUICodeudor: CUICodeudor,
            NombreCodeudor: nombreCodeudor,
            TelefonoCodeudor: telefonoCodeudor,
            Firma: fileUploadRef.current.getFiles()[0] ? firma : null, // para saber si subió o no imagen
            RegistroTrabajador: selectedTrabajador.Registro
        }
        const formData = new FormData();
        formData.append('file', fileUploadRef.current.getFiles()[0]);
        formData.append('datos', JSON.stringify(obj));

        crearConvenio(formData).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                ResetForm();
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }

        }).catch(err => {
            mostrarError(err);
        });
    }

    function ResetForm() {
        setMontoTotal(null);
        setFrecuencia(null);
        setMotivo('');
        setActaAutorizacion(null);
        setFechaInicio(null);
        setFechaFin(null);
        setFechaAutorizacion(null);
        setCUICodeudor(null);
        setNombreCodeudor('');
        setTelefonoCodeudor(null);
        setFirma(null);
        fileUploadRef.current.clear();
        setSelectedTrabajador({ CUI: '', Registro: '', Nombre: '' });
    }

    const confirm = (event) => {
        event.preventDefault();
        CrearConvenio();
    }

    return (
        <div className='primario-crear-registro'>
            <Toast ref={toast} />
            <form onSubmit={confirm} noValidate={false}>
                <div className="grid p-fluid">
                    <div className='col-12 md:col-6'>
                        <SeleccionarTrabajador
                            selectedTrabajador={selectedTrabajador}
                            setSelectedTrabajador={setSelectedTrabajador} />
                    </div>
                    <div className='col-12 md:col-6'>
                        <Card header={headerForm("Datos del convenio")} className="card">
                            <div className="grid p-fluid mt-1">
                                {/* MONTO TOTAL */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Monto Total (Q) *</label>
                                    <InputNumber required
                                        value={montoTotal} onValueChange={(e) => setMontoTotal(e.value)}
                                        minFractionDigits={2} maxFractionDigits={2} showButtons min={0} />
                                </div>
                                {/* FRECUENCIA */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Frecuencia de pago (Meses) *</label>
                                    <InputNumber required
                                        value={frecuencia} onValueChange={(e) => setFrecuencia(e.value)}
                                        mode="decimal" showButtons min={0} useGrouping={false} />
                                </div>
                            </div>
                            <div className="grid p-fluid mt-1">
                                {/* MOTIVO */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Motivo *</label>
                                    <InputText required value={motivo}
                                        onChange={(e) => setMotivo(e.target.value)} />
                                </div>
                                {/* ACTA AUTORIZACION */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Punto de Acta de Autorización *</label>
                                    <InputNumber required
                                        value={actaAutorizacion} onValueChange={(e) => setActaAutorizacion(e.value)}
                                        mode="decimal" showButtons min={0} useGrouping={false} />
                                </div>
                            </div>
                            <div className="grid p-fluid mt-1">
                                {/* FECHA INICIO */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Fecha de Inicio *</label>
                                    <Calendar required
                                        value={fechaInicio}
                                        onChange={(e) => setFechaInicio(e.target.value)}
                                        showButtonBar showIcon />
                                </div>
                                {/* FECHA FIN */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Fecha de Finalización *</label>
                                    <Calendar required
                                        value={fechaFin}
                                        onChange={(e) => setFechaFin(e.target.value)}
                                        showButtonBar showIcon />
                                </div>
                            </div>
                            <div className="grid p-fluid mt-1">
                                {/* FECHA AUTORIZACION */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Fecha de Autorización</label>
                                    <Calendar
                                        value={fechaAutorizacion}
                                        onChange={(e) => setFechaAutorizacion(e.target.value)}
                                        showButtonBar showIcon />
                                </div>
                                {/* MONTO DE CADA PAGO */}
                                <div className="field col-12 md:col-6 col_toggle">
                                    <label>Monto de cada pago (Q):</label>
                                    <InputNumber
                                        value={calcularPago(fechaFin, fechaInicio, frecuencia, montoTotal)}
                                        minFractionDigits={2} maxFractionDigits={2} min={0} />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div><br />
                <div style={{ width: '100%' }}>
                    <Card header={headerForm("Datos de Codeudor (Opcional)")} className="card">
                        <div className="grid p-fluid mt-1">
                            {/* CUI */}
                            <div className="field col-12 md:col-4 col_toggle">
                                <label>CUI</label>
                                <InputNumber
                                    value={CUICodeudor} onValueChange={(e) => setCUICodeudor(e.value)}
                                    mode="decimal" showButtons min={0} useGrouping={false} />
                            </div>
                            {/* NOMBRE */}
                            <div className="field col-12 md:col-5 col_toggle">
                                <label>Nombre Completo</label>
                                <InputText value={nombreCodeudor}
                                    onChange={(e) => setNombreCodeudor(e.target.value)} />

                            </div>
                            {/* TELEFONO */}
                            <div className="field col-12 md:col-3 col_toggle">
                                <label>Teléfono</label>
                                <InputNumber
                                    value={telefonoCodeudor} onValueChange={(e) => setTelefonoCodeudor(e.value)}
                                    mode="decimal" showButtons min={0} max={99999999} useGrouping={false} />
                            </div>
                        </div>
                        <div className="grid p-fluid mt-1" style={{ minHeight: '215px' }}>
                            {/* SUBIR FIRMA */}
                            <div className="field col-12 md:col-6 col_toggle my-2">
                                <FileUpload ref={fileUploadRef} accept="image/*"
                                    customUpload onSelect={(e) => setFirma(e.files[0].objectURL)}
                                    onClear={() => setFirma(null)} onRemove={() => setFirma(null)}
                                    uploadOptions={{ className: 'display-none' }}
                                    emptyTemplate={emptyTemplate}
                                />
                            </div>
                            {/* IMAGEN */}
                            <div className="field col-12 md:col-6 col_toggle d-flex align-items-center"
                                style={{ height: "215px", textAlign: 'center' }}>
                                <img src={firma} alt={`Vista previa firma`} width={300}
                                    style={{ margin: 'auto' }} />
                            </div>
                        </div>

                        {/* BOTONES */}
                        <br />
                        <div className="grid p-fluid">
                            <div className='col-4' style={{ marginLeft: 'auto', marginBottom: '0', marginTop: '10px' }}>
                                <Button type='submit' icon="pi pi-check"
                                    label="Crear" className='p-button-success' />
                            </div>
                        </div>
                    </Card>
                </div>
            </form>
        </div>
    );
}
