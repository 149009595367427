import './alzas.css';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { useEffect, useRef, useState } from 'react';
import get_bancos from '../../modulo_nominas/misc/get_bancos';
import { Link } from 'react-router-dom';
import { requestOptions } from '../../modulo_nominas/misc/requestOptions';
import { RadioButton } from 'primereact/radiobutton';

function RegistroAlzas() {
    const regex_alfa = /[a-zA-zñÑáÁéÉíÍóÓúÚ ]/;
    const [partida, setPartida] = useState('');
    const [plaza, setPlaza] = useState('');
    const [registro, setRegistro] = useState('');
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [apellido_casada, setApellidoCasada] = useState('');
    const [numero_dpi, setDPI] = useState('');
    const [telefono, setTelefono] = useState('');
    const [correo, setCorreo] = useState('');
    const [direccion, setDireccion] = useState('');
    const [estado_civil, setEstadoCivil] = useState('');
    const [banco, setBanco] = useState('');
    const [cuenta_bancaria, setCuenta] = useState('');
    const [fecha_nacimiento, setFnac] = useState('');
    const [fecha_inicio_pension, setFIP] = useState('');
    const [fecha_alza_nomina, setFAN] = useState('');
    const [genero, setGenero] = useState('');
    const [pension, setPension] = useState('');
    const [dependencia, setDependencia] = useState('');
    const [observacion, setObservacion] = useState('');

    // PARA CONTROL DE ORFANDADES
    const [menor_nombre, setMenorNombre] = useState('');
    const [menor_fnac, setMenorFNac] = useState('');
    const [menor_observacion, setMenorObservacion] = useState('');

    const [menores_array, setMenores] = useState([]);
    const [posibles_mayores, setMayores] = useState([]);
    const [mayor_selected, setMayor] = useState(null);

    const [bancos_list, setBancos] = useState([]);
    useEffect(() => {
        async function fetchBancos() {
            const tmp = await get_bancos('dropdown');
            setBancos(tmp);
        }
        fetchBancos();
    }, []);

    const get_posibles_mayores = () => {
        fetch(process.env.REACT_APP_API_URL_CONTROL_ORFANDADES + '/' + (registro.length > 0 ? registro : '0'))
            .then((response) => response.json())
            .then((data) => {
                if (data.error) toast.current.show({ severity: data.severity, summary: data.error });
                else {
                    const x = data.data;
                    setMayores(x);
                }
            });
    }


    const reset_form = () => {
        setPartida('');
        setPlaza('');
        setRegistro('');
        setNombres('');
        setApellidos('');
        setApellidoCasada('');
        setDPI('');
        setTelefono('');
        setCorreo('');
        setEstadoCivil('');
        setBanco('');
        setCuenta('');
        setFnac('');
        setGenero('');
        setPension('');
        setCuenta('');
        setObservacion('');
        setDireccion('');
        setFAN('');
        setFIP('');
        setMenorNombre('');
        setMenorFNac('');
        setMenorObservacion('');
        setMenores([]);
        setMayores([]);
        setMayor(null);
        setDependencia('');
    }

    const toast = useRef(null);

    const post__request = () => {
        fetch(process.env.REACT_APP_API_URL_ALZAS,
            requestOptions('POST', {
                dpi: numero_dpi,
                registro: registro,
                plaza: plaza,
                nombres: nombres,
                apellidos: apellidos,
                apellido_casada: apellido_casada,
                fecha_nacimiento: fecha_nacimiento.toJSON().slice(0, 10),
                direccion: direccion,
                estado_civil: estado_civil,
                correo: correo,
                fecha_alza: fecha_alza_nomina.toJSON().slice(0, 10),
                inicio_pension: fecha_inicio_pension.toJSON().slice(0, 10),
                genero: genero,
                telefono: telefono,
                comentario: observacion,
                valor_pension: pension,
                partida: partida,
                banco: banco,
                cuenta: cuenta_bancaria,
                menores_tutor: menores_array,
                mayor_selected: mayor_selected,
                dependencia: dependencia
            }))
            .then((response) => response.json())
            .then((data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                if (!data.error)
                    reset_form();
            });
    }

    const confirm = (event) => {
        confirmPopup({
            target: document.getElementById('alzas_confirm'),
            message: '¿Seguro que desea continuar?',
            icon: 'pi pi-question-circle',
            acceptLabel: 'Sí',
            accept: () => {
                post__request();
            }
        });
        event.preventDefault();
    }

    return (
        <form id='alzas_form' onSubmit={confirm} noValidate={false}>
            <Toast ref={toast}></Toast>
            <div className="grid p-fluid alzas">
                {/* PARTIDA */}
                <div className="field col-12 md:col-3">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-key"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" keyfilter="pint" required={true} value={partida} onChange={(e) => setPartida(e.target.value)} />
                            <label>Partida</label>
                        </span>
                    </div>
                </div>
                {/* PLAZA */}
                <div className="field col-12 md:col-3">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-key"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" keyfilter="pint" required={true} value={plaza} onChange={(e) => setPlaza(e.target.value)} />
                            <label >Plaza</label>
                        </span>
                    </div>
                </div>
                {/* REGISTRO */}
                <div className="field col-12 md:col-3">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-key"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" keyfilter="pint" required={true} value={registro} onChange={(e) => setRegistro(e.target.value)} />
                            <label >Registro</label>
                        </span>
                    </div>
                </div>
                {/* DPI */}
                <div className="field col-12 md:col-3">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-id-card"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" keyfilter="pint" maxLength={13} required={true} value={numero_dpi} onChange={(e) => setDPI(e.target.value)} />
                            <label >Número de CUI</label>
                        </span>
                    </div>
                </div>
                {/* NOMBRES */}
                <div className="field col-12 md:col-4">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-user"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" keyfilter={regex_alfa} required={true} value={nombres} onChange={(e) => setNombres(e.target.value.toUpperCase())} />
                            <label >Nombres</label>
                        </span>
                    </div>
                </div>
                {/* APELLIDOS */}
                <div className="field col-12 md:col-4">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-user"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" keyfilter={regex_alfa} required={true} value={apellidos} onChange={(e) => setApellidos(e.target.value.toUpperCase())} />
                            <label >Apellidos</label>
                        </span>
                    </div>
                </div>
                {/* APELLIDO CASADA */}
                <div className="field col-12 md:col-4">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-users"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" keyfilter={regex_alfa} required={false} value={apellido_casada} onChange={(e) => setApellidoCasada(e.target.value.toUpperCase())} />
                            <label >Apellido de casada</label>
                        </span>
                    </div>
                </div>
                {/* TELÉFONO */}
                <div className="field col-12 md:col-3">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-phone"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" keyfilter="pint" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                            <label>Teléfono</label>
                        </span>
                    </div>
                </div>
                {/* CORREO */}
                <div className="field col-12 md:col-3">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-envelope"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" keyfilter="email" value={correo} onChange={(e) => setCorreo(e.target.value.toLowerCase())} />
                            <label >Correo</label>
                        </span>
                    </div>
                </div>
                {/* DIRECCIÓN */}
                <div className="field col-12 md:col-6">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-home"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" value={direccion} onChange={(e) => setDireccion(e.target.value.toUpperCase())} />
                            <label >Dirección</label>
                        </span>
                    </div>
                </div>
                {/* ESTADO CIVIL */}
                <div className="field col-12 md:col-3">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-heart"></i>
                        </span>
                        <span className="p-float-label">
                            <Dropdown value={estado_civil} options={[{ label: 'SOLTERO', value: 'SOLTERO' }, { label: 'CASADO', value: 'CASADO' }, { label: 'VIUDO', value: 'VIUDO' }]} onChange={(e) => setEstadoCivil(e.value)} placeholder="Seleccione estado civil" />
                            <label >Estado Civil</label>
                        </span>
                    </div>
                </div>
                {/* GÉNERO */}
                <div className="field col-12 md:col-3">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-tag"></i>
                        </span>
                        <span className="p-float-label">
                            <Dropdown value={genero} options={[{ label: 'MASCULINO', value: 'M' }, { label: 'FEMENINO', value: 'F' }]} onChange={(e) => setGenero(e.value)} placeholder="Seleccione sexo" />
                            <label >Género</label>
                        </span>
                    </div>
                </div>
                {/* FECHA NACIMIENTO */}
                <div className="field col-12 md:col-3">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-calendar"></i>
                        </span>
                        <span className="p-float-label">
                            <Calendar dateFormat="dd/mm/yy" value={fecha_nacimiento} required onChange={(e) => setFnac(e.value)}></Calendar>
                            <label >Fecha de nacimiento</label>
                        </span>
                    </div>
                </div>
                {/* PENSIÓN */}
                <div className="field col-12 md:col-3">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-money-bill"></i>
                        </span>
                        <span className="p-float-label">
                            <InputNumber required={true} value={pension} onValueChange={(e) => setPension(e.value)} mode="currency" currency="GTQ" min={0} />
                            <label >Monto de pensión</label>
                        </span>
                    </div>
                </div>
                {/* BANCO */}
                <div className="field col-12 md:col-3">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-dollar"></i>
                        </span>
                        <span className="p-float-label">
                            <Dropdown required={true} value={banco} options={bancos_list} onChange={(e) => setBanco(e.value)} placeholder="Seleccione banco" />
                            <label >Forma de pago</label>
                        </span>
                    </div>
                </div>
                {/* CUENTA */}
                <div className="field col-12 md:col-3">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-wallet"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText type="text" keyfilter={/[\d-]/} required={banco !== 3} disabled={banco === 3} value={cuenta_bancaria} onChange={(e) => setCuenta(e.target.value)} />
                            <label >Número de cuenta</label>
                        </span>
                    </div>
                </div>
                {/* FECHA INICIO PENSIÓN */}
                <div className="field col-12 md:col-3">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-calendar"></i>
                        </span>
                        <span className="p-float-label">
                            <Calendar dateFormat="dd/mm/yy" required={true} value={fecha_inicio_pension} onChange={(e) => setFIP(e.value)}></Calendar>
                            <label >Fecha de inicio de pensión</label>
                        </span>
                    </div>
                </div>
                {/* FECHA ALZA NÓMINA */}
                <div className="field col-12 md:col-3">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-calendar"></i>
                        </span>
                        <span className="p-float-label">
                            <Calendar dateFormat="dd/mm/yy" required={true} value={fecha_alza_nomina} onChange={(e) => setFAN(e.value)}></Calendar>
                            <label >Fecha de alza de nómina</label>
                        </span>
                    </div>
                </div>
                {/* DEPENDENCIA */}
                <div className="field col-12 md:col-3">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-briefcase"></i>
                        </span>
                        <span className="p-float-label">
                            <InputTextarea maxLength={100} rows={1} required={false} value={dependencia} autoResize={true} onChange={(e) => setDependencia(e.target.value.toUpperCase())} />
                            <label >Dependencia</label>
                        </span>
                    </div>
                </div>
                {/* OBSERVACIÓN */}
                <div className="field col-12 md:col-4">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-book"></i>
                        </span>
                        <span className="p-float-label">
                            <InputTextarea maxLength={200} rows={1} required={false} value={observacion} autoResize={true} onChange={(e) => setObservacion(e.target.value.toUpperCase())} />
                            <label >Anotaciones</label>
                        </span>
                    </div>
                </div>
                {/* NOTA */}
                <div className="field col-12 md:col-5 py-0">
                    <p className='my-0 font-weight-light'>*Nota: para registrar descuentos o bonificación salarial, puede hacerlo dentro del módulo de nóminas en:&nbsp;
                        <Link style={{ color: '#3863a0', fontWeight: 'bold' }} to="/nominas/gestion-descuentos" target="_blank">Descuentos &#10132; Gestionar</Link>.
                    </p>
                </div>
                {
                    partida == '4307' &&
                    <div className='mx-auto field col-10 mb-0'>
                        <Fieldset legend="REGISTRO DE MENORES (SI ES TUTOR)" toggleable collapsed>
                            <div className="flex">
                                <div className="grid p-fluid mt-1 mr-0 w-100">
                                    <div className="field col-12 md:col-5 col_toggle">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-user"></i>
                                            </span>
                                            <span className="p-float-label">
                                                <InputText type="text" keyfilter={regex_alfa} value={menor_nombre} onChange={(e) => setMenorNombre(e.target.value.toUpperCase())} />
                                                <label>Nombre completo</label>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-3 col_toggle">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-calendar"></i>
                                            </span>
                                            <span className="p-float-label">
                                                <Calendar dateFormat="dd/mm/yy" value={menor_fnac} onChange={(e) => setMenorFNac(e.value)}></Calendar>
                                                <label >Fecha de nacimiento</label>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-4 col_toggle">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-pencil"></i>
                                            </span>
                                            <span className="p-float-label">
                                                <InputText maxLength={100} value={menor_observacion} onChange={(e) => setMenorObservacion(e.target.value.toUpperCase())} tooltip='EJEMPLO: ESTADO DE INTERDICCIÓN' />
                                                <label >Observaciones</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="field col-12 md:col-1 col_toggle text-center mt-1" style={{ width: 'min-content' }}>
                                    <Button className='p-button-rounded p-button-warning p-button-icon-only' icon='pi pi-check' tooltip='Agregar'
                                        onClick={() => {
                                            menores_array.push({ nombre: menor_nombre, fecha_nacimiento: menor_fnac.toJSON().slice(0, 10), observaciones: menor_observacion });
                                            setMenorNombre('');
                                            setMenorFNac('');
                                            setMenorObservacion('');
                                        }}
                                        disabled={!menor_nombre || !menor_fnac} />
                                </div>
                            </div>
                            <div className='container my-1 font-weight-bold'>
                                {
                                    menores_array.map((menor_item, i) =>
                                        <li key={i}>{menor_item.nombre} <span style={{ color: 'grey', fontWeight: '900' }}>|</span> {menor_item.fecha_nacimiento} <span style={{ color: 'grey', fontWeight: '900' }}>|</span> {menor_item.observaciones}</li>
                                    )
                                }
                            </div>
                        </Fieldset>
                        <Fieldset className='mt-5' legend="ASIGNACIÓN DE PLAZA (SI ERA MENOR)" toggleable collapsed={true}
                            onExpand={() => { menores_array.length === 0 && get_posibles_mayores() }}>
                            <div className='container my-1 font-weight-bold'>
                                {
                                    menores_array.length === 0 &&
                                    posibles_mayores.map((mayor_item) =>
                                        <div key={`rb_${mayor_item.idcontrol}`} className="field-radiobutton frb">
                                            <RadioButton inputId={`rb_${mayor_item.idcontrol}`} value={mayor_item.idcontrol} onChange={(e) => setMayor(e.value)} checked={mayor_selected == mayor_item.idcontrol} />
                                            <label className='ml-2' htmlFor={`rb_${mayor_item.idcontrol}`}>{mayor_item.nombre} <span style={{ color: 'grey', fontWeight: '900' }}>|</span> {mayor_item.fecha_nacimiento}</label>
                                        </div>
                                    )
                                }
                            </div>
                        </Fieldset>
                    </div>

                }
            </div>
            {/* CONFIRMAR */}
            <div className="confirm" >
                <Button disabled={menor_nombre} id='alzas_confirm' type='submit' icon="pi pi-check-circle" label="Registrar" className="mt-2 p-button-raised font-weight-bold" />
                <ConfirmPopup />
            </div>
        </form >
    );
}

export default RegistroAlzas;
