import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { InputSwitch } from 'primereact/inputswitch';
import { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';

function CargaArchivoDescuentos(props) {

    const rol = +localStorage.getItem('rol');

    const { toast } = props;

    const [importedData, setImportedData] = useState([]);
    const [importedCols, setImportedCols] = useState([{ field: '', header: '' }]);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [checked, setChecked] = useState(false);
    const [descuento_selected, setDescuento] = useState(null);
    const [descuentos_list, setDescuentos] = useState([]);
    const [vaciado_completo, setVaciadoSwitch] = useState(false);

    useEffect(() => {
        get_descuentos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const get_descuentos = () => {
        fetch(process.env.REACT_APP_API_URL_DESCUENTOS_CATALOGO)
            .then((response) => response.json())
            .then((data) => {
                if (data.error)
                    toast.current.show({ severity: data.severity, summary: data.error });
                const x = data.data;
                if (x) {
                    let tmp = [];
                    for (let i = 0; i < x.length; i++) {
                        const d = x[i];
                        if (d.status !== 'Deshabilitado') {
                            if (rol == 10) {
                                if (d.anotacion == 'CUENTA CORRIENTE')
                                    tmp.push({ label: d.nombre, value: d.id });
                            } else {
                                tmp.push({ label: d.nombre, value: d.id });
                            }
                        }

                    }
                    setDescuentos(tmp);
                }
            });
    }

    const clear = () => {
        setLoaded(false);
        setChecked(false);
        setImportedData([]);
        setImportedCols([{ field: '', header: '' }]);
        setDescuento(null);
        setVaciadoSwitch(false);
    }

    const iniciarCarga = () => {
        setLoading(true);
        setLoaded(false);
    }

    const process_xhr = (response, loaded) => {
        const jr = JSON.parse(response);
        toast.current.show({ life: 10000, severity: jr.severity, summary: jr.message || jr.error });
        setLoading(false);
        setLoaded(loaded);
        if (!loaded)
            alert(jr.error || 'Ocurrió un error durante la carga del archivo. Contacte al programador.');
    }

    const get_plantilla = () => {
        return [
            ['partida', 'plaza', 'registro', 'valor']
            /* ,['4303', '123', '456', 123.45],
            [], ['*SE DEBE USAR LA PRIMERA HOJA DEL DOCUMENTO.'], ['SI SE DESEA DEJAR DE DESCONTAR ENTONCES SE COLOCA VALOR 0 EN LA CELDA QUE CORRESPONDA.'],
            [], ['SIEMPRE VAN VALORES POSITIVOS.'],
            [], ['*ES NECESARIO ELIMINAR TODAS LAS FILAS INFORMATIVAS DE ESTA PLANTILLA, SOLO DEBE CARGAR LA INFORMACIÓN CORRESPONDIENTE.'] */
        ];
    }

    const downloadPlantilla = () => {
        import('xlsx').then(XLSX => {
            const data = get_plantilla();
            const ws = XLSX.utils.aoa_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'CARGA DESCUENTOS');
            // Genera un blob a partir del libro de Excel
            const blob = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
            // Crea un enlace para descargar el archivo
            const url = URL.createObjectURL(new Blob([blob]));
            const a = document.createElement('a');
            a.href = url;
            a.download = `PLANTILLA_DESCUENTOS.xlsx`;
            a.click();
            URL.revokeObjectURL(url);
        });
    }


    const importExcel = (e) => {
        const file = e.files[0];

        import('xlsx').then(xlsx => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, { type: 'array' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = xlsx.utils.sheet_to_json(ws, { header: 1 });

                // Prepare DataTable
                const cols = data[0];
                data.shift();

                let _importedCols = cols.map(col => ({ field: col.toString(), header: col.toString().toUpperCase() }));
                let _importedData = data.map(d => {
                    return cols.reduce((obj, c, i) => {
                        obj[c] = d[i];
                        return obj;
                    }, {});
                });

                setImportedCols(_importedCols);
                setImportedData(_importedData);
                // console.log(_importedData)
                exportPdf(_importedData, _importedCols.map(col => ({ title: col.header, dataKey: col.field })));
                // exportPdf(_importedData, Object.keys(_importedData[0]).map(col => ({ title: col, dataKey: col })));
            };
            reader.readAsArrayBuffer(file);
        });
        process_xhr(e.xhr.response, true);
    }

    /* PARA EXPORTAR A PDF */
    const exportPdf = (data, cols) => {
        const fname = `ReporteCarga`;
        const title = `REPORTE DE CARGA`;
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                // const encabezado = `REPORTE DE CARGA`;
                const doc = new jsPDF.default(0, 0);
                let textX = doc.internal.pageSize.getWidth() / 2; // Centrar horizontalmente
                doc.setFont('helvetica', 'bold'); // Establecer la fuente en negrita
                doc.text(title, textX, 20, { align: 'center' });
                doc.setFontSize(12);
                doc.text(descuentos_list.find(d => d.value == descuento_selected).label, textX, 28, { align: 'center' });
                doc.setFont('helvetica', 'normal'); // Restaurar el estilo de fuente normal
                doc.autoTable({
                    body: data,
                    columns: cols,
                    startY: 35,
                    theme: 'striped',
                    margin: { left: 20, right: 20 },
                });
                doc.setFontSize(12);
                doc.text(new Date().toLocaleString(), doc.internal.pageSize.getWidth() - 50, doc.internal.pageSize.height - 10);
                doc.save(fname + '.pdf');
            })
        })
    }


    return (
        <div className="container__fileUpload">
            <div className="card fileUpload p-3">
                <div className='text-dark flex' style={{ justifyContent: 'space-between' }}>
                    <h3 className='pb-3' style={{ letterSpacing: '4px', fontWeight: 'bold' }}>CARGA DE DESCUENTOS</h3>
                    <div className='sw_edit'>
                        <p className='mr-1' style={{ letterSpacing: '1px' }}>VACIADO COMPLETO</p>
                        <InputSwitch className='' tooltip='SI SE ACTIVA COLOCA EN CERO TODA LA COLUMNA PREVIO A CARGAR.' checked={vaciado_completo} onChange={(e) => setVaciadoSwitch(e.value)} />
                    </div>
                </div>
                <div className='mb-3 pt-1 pb-2'>
                    <Dropdown value={descuento_selected} options={descuentos_list} style={{ width: '600px', maxWidth: '100%', backgroundColor: '#EEEEEE' }}
                        onChange={(e) => setDescuento(e.value)} placeholder="Seleccione descuento a cargar" />
                </div>
                <div className="flex align-items-center">
                    <FileUpload disabled={!checked || !descuento_selected} chooseOptions={{ label: 'Cargar Excel', icon: 'pi pi-file-excel', className: 'p-button-success' }}
                        mode="basic" name="excelDescuentos" auto accept=".xlsx"
                        url={`${process.env.REACT_APP_API_URL_FILE_UPLOAD}/cargaDescuentos${(vaciado_completo ? '/vaciado' : '')}/${descuento_selected}`}
                        onBeforeSend={(e) => e.xhr.setRequestHeader('Authorization', localStorage.getItem('username') || '')}
                        onProgress={iniciarCarga} onUpload={importExcel}
                        onError={(e) => { process_xhr(e.xhr.response, false); }}
                    />
                    <Button type="button" label="Descargar plantilla" icon="pi pi-download" onClick={downloadPlantilla} className="p-button-info ml-2" />
                    <Button type="button" visible={loaded} label="Limpiar" icon="pi pi-times" onClick={clear} className="p-button-danger ml-2" />
                </div>
                <div className="flex align-items-center pt-3">
                    <Checkbox inputId="confirmar-carga" className='mr-2' checked={checked || loaded} onChange={e => setChecked(e.checked)} required />
                    <label className='mb-0 font-weight-bold text-dark' htmlFor="confirmar-carga">
                        <span style={{ color: 'red', marginRight: '2px' }}>*</span>Confirmo de revisado el archivo a cargar y acepto la responsabilidad de su contenido para su aplicación en el sistema.
                    </label>
                </div>
                {
                    loading ?
                        <ProgressBar mode="indeterminate" style={{ height: '10px', margin: '40px 20px 20px 20px' }} color='green' />
                        :
                        importedData.length > 0 &&
                        <DataTable value={importedData} className='mt-4' emptyMessage="Sin datos." paginator paginatorPosition='top' rows={10} resizableColumns
                            columnResizeMode="expand" selectionMode="single" >
                            {
                                importedCols.map((col, index) => <Column key={index} field={col.field} header={col.header} />)
                            }
                        </DataTable>
                }
            </div >
        </div >
    );

}

export default CargaArchivoDescuentos;