import { InputNumber } from 'primereact/inputnumber';

export const CalculosTotales = ({ totalCuotaAPagar, totalIntereses }) => {
    return (<>
        {/* TOTAL CUOTA A PAGAR */}
        <div className="field col-12 md:col-3 col_toggle" >
            <label>Total de cuota a pagar (Q)</label>
            <InputNumber readOnly
                value={totalCuotaAPagar}
                minFractionDigits={2} maxFractionDigits={2} min={0} />
        </div>
        {/* TOTAL DE INTERESES */}
        <div className="field col-12 md:col-3 col_toggle" >
            <label>Total de intereses (Q)</label>
            <InputNumber readOnly
                value={totalIntereses}
                minFractionDigits={2} maxFractionDigits={2} min={0} />
        </div>
        {/* TOTAL A PAGAR */}
        <div className="field col-12 md:col-3 col_toggle" >
            <label>Total a pagar (Q)</label>
            <InputNumber readOnly
                value={totalIntereses + totalCuotaAPagar}
                minFractionDigits={2} maxFractionDigits={2} min={0} />
        </div>
    </>)
}
