import '../certificaciones.css';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

import { columnaConFiltros, containsFilter, mostrarError, renderRedirect } from '../../constants/bodiesYFiltos';
import { ultimaModificacionBody } from '../constants';
import { getPlantillas, eliminarPlantilla } from '../../endpoints/plantillas';

import { locale, addLocale } from 'primereact/api';
import data from '../../diccionario.json';
locale('es');
addLocale('es', data.es);

export default function Plantillas() {
    const [redirect, setRedirect] = useState(false);
    const [selected, setSelected] = useState(null);
    const [filters] = useState({
        Nombre: containsFilter
    });
    const [plantillas, setPlantillas] = useState([]);
    const [destino, setDestino] = useState('');
    const toast = useRef(null);

    useEffect(() => {
        // obtener listado de plantillas
        obtenerPlantillas();
    }, []);

    const obtenerPlantillas = () => {
        getPlantillas().then((resp) => {
            setPlantillas(resp.data);
        }).catch(err => {
            mostrarError(err);
        });
    }

    const redireccionar = (destino) => {
        if (selected === null)
            return
        sessionStorage.setItem('plantilla_seleccionada', selected.idPlantilla);
        setDestino(destino);
        setRedirect(true);
    }

    const acceptEliminar = () => {
        eliminarPlantilla({ idPlantilla: selected.idPlantilla }).then((resp) => {
            if (resp.severity === "success") {
                toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                // quitar la plantilla
                var del = plantillas.filter(plantilla => plantilla.idPlantilla !== selected.idPlantilla);
                setPlantillas(del);
                setSelected(null);
            } else {
                toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
            }
        }).catch(err => {
            mostrarError(err);
        });
    }

    const EliminarPlantilla = () => {
        if (selected === null)
            return

        confirmDialog({
            message: '¡No se podrá eliminar esta plantilla si ya está siendo utilizada por certificaciones!' +
                '¿Desea Continuar?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: acceptEliminar
        });
    }


    return (
        <>
            {/* PARA LA CONFIRMACIÓN DE ELIMINAR REGISTRO */}
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className='primario_cert'>
                <div className="grid p-fluid" style={{ paddingTop: '0.5%' }}>
                    <div className='col-12 md:col-2'
                        style={{ marginRight: '0', marginLeft: 'auto', marginTop: '0.5%' }}>
                        <Button type="button" icon="pi pi-eye" label="Ver plantilla"
                            tooltipOptions={{ position: 'bottom' }}
                            className='p-button-secondary' disabled={selected === null}
                            onClick={() => redireccionar('ver_plantilla')} />
                    </div>
                    <div className='col-12 md:col-2' style={{ marginTop: '0.5%' }}>
                        <Button type="button" icon="pi pi-pencil" label="Editar plantilla"
                            className='p-button-warning' disabled={selected === null}
                            onClick={() => redireccionar('editar_plantilla')} />
                    </div>
                    <div className='col-12 md:col-2' style={{ marginTop: '0.5%', float: 'right' }}>
                        <Button type="button" icon="pi pi-times"
                            label='Eliminar plantilla'
                            tooltipOptions={{ position: 'bottom' }}
                            className='p-button-danger' disabled={selected === null}
                            onClick={EliminarPlantilla} />

                    </div>
                    <div className='col-12 md:col-2' style={{ marginTop: '0.5%' }}>
                        <Link to="/cuotas/crear_plantilla">
                            <Button type="button" icon="pi pi-plus" label="Crear plantilla" />
                        </Link>
                    </div>
                </div>
                <br />
                <DataTable value={plantillas} paginator rows={10} paginatorPosition="top"
                    rowsPerPageOptions={[10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                    filters={filters}
                    size='small'
                    selectionMode="single"
                    selection={selected}
                    onSelectionChange={(e) => { setSelected(e.value) }}
                    onRowClick={() => { setSelected(null) }}
                >
                    {columnaConFiltros("Nombre", "Nombre de Plantilla", true, "Buscar por nombre")}
                    <Column field="UltimaModificacion" header="Última Modificación" body={ultimaModificacionBody}></Column>
                </DataTable>
                {renderRedirect(redirect, destino)}
                <br />
            </div>
        </>
    );
}
