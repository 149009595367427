import { estados_trabajador } from "../constants/estados_trabajador";

export default function mostrarFechaEstado(status, tiempo) {
    var idEstadoTrabajador = 1;
    if(status) idEstadoTrabajador = status.idEstadoTrabajador;
    for (var i = 0; i < estados_trabajador.length; i++) {
        var estado = estados_trabajador[i];
        if (estado.idEstadoTrabajador === idEstadoTrabajador) {
            return tiempo === 'inicio' ?
                estado.RequiereFechaInicio : estado.RequiereFechaFin
        }
    }
}