import './historial.css';
import React, { useRef, useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Dropdown } from 'primereact/dropdown';

import { emptyTemplate } from './constants';
import { headerForm, mostrarError } from '../constants/bodiesYFiltos';
import { cargarArchivos } from '../endpoints/historial';

export default function CargarRegistros() {
    const toast = useRef(null);
    const [mode, setMode] = useState('determinate');
    const [totalSize, setTotalSize] = useState(0);
    const [tipoArchivo, setTipoArchivo] = useState({ Id: 1, Texto: "Historial de trabajador con cuotas código 24" });
    const [salida, setSalida] = useState('');
    const fileUploadRef = useRef(null);

    const onTemplateSelect = (e) => {
        setTotalSize(0);
        setMode('determinate');
        setSalida('');
        setTotalSize(0);
    };

    const subirArchivo = async (event) => {
        setMode('indeterminate');
        setTotalSize(0);
        setSalida('Procesando registros del archivo...')
        const formData = new FormData();
        formData.append('file', event.files[0]);
        formData.append('TipoArchivo', tipoArchivo.Id);

        try {
            cargarArchivos(formData).then((resp) => {
                if (resp.severity === "success") {
                    toast.current.show({ severity: "success", summary: 'Info', detail: resp.message });
                    setSalida(resp.message);
                    setTotalSize(1000000);
                    fileUploadRef.current.clear();
                    setMode('determinate');
                    sessionStorage.setItem('recargar_historial', true);
                } else {
                    toast.current.show({ severity: "error", summary: 'Error', detail: resp.error });
                    setSalida('Ocurrió un error, verificar que el archivo sea del tipo correcto');
                    setMode('determinate');
                    console.log(resp.error);
                }
            }).catch(err => {
                mostrarError(err, () => {
                    setMode('determinate');
                    setSalida("Ocurrió un error...");
                });
            });
        } catch (error) {
            console.error('Error al enviar el archivo:', error);
        }
    };

    return (
        <div className='primario-crear-convenio'>
            <div className="grid p-fluid">
                <div className='col-12 md:col-7'>
                    <Card
                        header={headerForm("Seleccionar archivo", true)} className="card"
                        style={{ minHeight: '500px' }}>
                        <Toast ref={toast} />

                        <div className="flex align-items-center selector-tipo-archivo">
                            <label >Tipo de archivo:</label>
                            <Dropdown
                                value={tipoArchivo}
                                options={[
                                    { Id: 0, Texto: "Archivo mensual con cuotas código 24" },
                                    { Id: 1, Texto: "Historial de trabajador con cuotas código 24" },
                                    { Id: 2, Texto: "Historial de trabajador con cuotas código 14" }
                                ]}
                                placeholder="Tipo"
                                optionLabel="Texto"
                                onChange={(e) => setTipoArchivo(e.value)}
                                style={{ marginLeft: '5px', maxWidth: '80%' }}
                            />
                        </div>
                        <br />
                        <FileUpload ref={fileUploadRef} name="demo[]" accept=".xls, .xlsx"
                            onSelect={onTemplateSelect}
                            customUpload
                            emptyTemplate={emptyTemplate}
                            uploadHandler={subirArchivo}
                        />

                    </Card>
                </div>
                <div className='col-12 md:col-5'>
                    <Card
                        header={headerForm("Salida")} className="card"
                        style={{ minHeight: '500px' }}>
                        <div style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                            <div className="flex align-items-center gap-3 ml-auto">
                                <span>Progreso: </span>
                                <ProgressBar value={totalSize} showValue={false} style={{ width: '10rem', height: '12px' }}
                                    mode={mode}></ProgressBar>
                            </div>
                        </div>
                        <br />
                        <InputTextarea rows={13} cols={30} value={salida} />
                    </Card>
                </div>
            </div>
        </div>
    );
}
