import { useNavigate, useParams } from 'react-router-dom';
import BuscarNomina from '../buscar_nomina/busqueda';
import './reporteria.css';
import { BreadCrumb } from 'primereact/breadcrumb';
import { TabPanel, TabView } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import get_bancos from '../misc/get_bancos';
import { requestOptions } from '../misc/requestOptions';

function Reporteria() {
    const navigate = useNavigate();
    const { nominaSelected } = useParams();

    const items = [
        { label: 'NÓMINA', command: () => { navigate("/nominas") } },
        { label: 'REPORTERÍA', command: () => { navigate("/nominas/reporteria") } },
        { label: 'SELECCIONAR NÓMINA', command: () => { navigate("/nominas/reporteria") } }
    ].concat(nominaSelected ? { label: nominaSelected } : []);
    const home = { icon: 'pi pi-home', command: () => { navigate("/") } };

    const [bancos_list, setBancos] = useState([]);
    useEffect(() => {
        if (nominaSelected) {
            obtener_listado_reportes();
            async function fetchBancos() {
                const tmp = await get_bancos('radiobutton');
                setBancos(tmp);
            }
            fetchBancos();
        }
        // console.log(reportList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nominaSelected]);

    const [rango, setRango] = useState(1000);
    const [reportSelected, setReportSelected] = useState(null);
    const [reportList, setReportList] = useState([]);
    const [bankSelected, selectBank] = useState(null);
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);

    /* Para reporte de anulaciones */
    const [formulario, setFormulario] = useState('');
    const [fecha, setFecha] = useState('');
    const [folios, setFolios] = useState('');
    const [correlativos, setCorrelativos] = useState('');


    const obtener_listado_reportes = () => {
        fetch(process.env.REACT_APP_API_URL_REPORTERIA + '/obtener-listado')
            .then((response) => response.json())
            .then((data) => {
                const x = data.data;
                if (data.error || !x)
                    toast.current.show({ severity: data.severity, summary: data.error || 'Error: No fue posible obtener el listado de reportes disponibles.' });
                else {
                    let tmp = [];
                    for (let i = 0; i < x.length; i++) {
                        const element = x[i];
                        tmp.push({
                            label: element.nombre,
                            value: element.id,
                            tabpanel: element.categoria
                        });
                    }
                    setReportList(tmp);
                }
            });
    }

    const getFname = (idReport) => {
        const fname = reportList.filter(function (element) { return element.value === idReport; })[0];
        const bankname = bancos_list.find(banco => banco.value === bankSelected);
        return `${fname.label || 'Reporte'}${bankname ? ('-' + bankname.label) : ''}`;
    }

    const request_report = async (fileType, idReport = reportSelected) => {
        setLoading(true);
        try {
            const response = await fetch(process.env.REACT_APP_API_URL_REPORTERIA,
                requestOptions('POST', {
                    idnomina: nominaSelected,
                    reportSelected: idReport,
                    bankSelected: bankSelected,
                    fileType: fileType,
                    encabezadoAnulacion: { formulario, fecha, folios, correlativos },
                    rango: rango
                }))

            if (response.ok) {
                const pdfBlob = await response.blob();
                // Crear un objeto URL a partir del Blob
                const pdfUrl = URL.createObjectURL(pdfBlob);
                // Crear un enlace temporal para descargar el PDF
                const a = document.createElement('a');
                a.href = pdfUrl;
                a.download = getFname(idReport);
                a.click();
                // Liberar la URL del objeto Blob
                URL.revokeObjectURL(pdfUrl);
                toast.current.show({ severity: 'success', summary: 'Reporte generado.' });
                // resetVars();    
            }
            else {
                response.json()
                    .then((data) => {
                        toast.current.show({ severity: 'error', summary: data.error || 'No fue posible generar el reporte.' });
                    });
            }
        } catch (error) {
            console.error('Error al descargar el reporte.');
            toast.current.show({ severity: 'error', summary: error.toString() });
        }
        setLoading(false);
    }

    const select_tipo_archivo = (id) => {
        confirmPopup({
            target: document.getElementById(id),
            message: 'Seleccione tipo de archivo',
            icon: 'pi pi-info-circle',
            acceptClassName: 'pdf_btn_report',
            acceptIcon: 'pi pi-file-pdf',
            rejectClassName: 'excel_btn_report',
            rejectIcon: 'pi pi-file-excel',
            acceptLabel: 'PDF',
            rejectLabel: 'Excel',
            accept: () => { request_report('pdf') },
            reject: () => { request_report('xlsx') }
        });
    }

    const resetVars = () => {
        setReportSelected(null);
        selectBank(null);
        setFormulario('');
        setFecha('');
        setFolios('');
        setCorrelativos('');
    }

    const progressbar = (
        <ProgressBar mode="indeterminate" style={{ height: '8px' }} color='green' />
    );

    const bankSelector = (mx = null) => {
        const radiobuttons = (<div className={`field ${mx ? mx : ''} col-12 md:col-5 frb__container`}>
            {bancos_list.map((banco, i) => {
                return <div key={`rb_${i}`} className="field-radiobutton frb">
                    <RadioButton inputId={`rb_${banco.label}`} value={banco.value} onChange={(e) => selectBank(e.value)} checked={bankSelected === banco.value} />
                    <label style={{ whiteSpace: 'nowrap' }} htmlFor={`rb_${banco.label}`}>{banco.label}</label>
                </div>
            })}
        </div>)
        return radiobuttons;
    };

    return (
        <div className="menu__repoteria">
            <Toast ref={toast}></Toast>
            <BreadCrumb model={items} home={home} />
            <BuscarNomina estado={2} />
            {
                nominaSelected &&
                <TabView onBeforeTabChange={() => { resetVars(); }} className='tabview__reporteria' >
                    <TabPanel header="Bancos">
                        <div className="grid p-fluid grid__reporteria">
                            <div className='field col-12 md:col-4'>
                                <Dropdown value={reportSelected}
                                    options={reportList.filter(function (element) { return element.tabpanel === 'BANCOS'; })}
                                    onChange={(e) => setReportSelected(e.value)} placeholder="Seleccione reporte" />
                            </div>
                            {bankSelector()}
                            <div className='field col-12 md:col-3'>
                                <Button disabled={!reportSelected || !bankSelected}
                                    type='button' className='p-button-rounded p-button-raised p-button-secondary btn__report ml-2' icon="pi pi-download"
                                    label='Descargar reporte' loading={loading} onClick={() => request_report('')} />
                            </div>
                            <div className='field col-12'>
                                {loading && progressbar}
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Descuentos">
                        <div className="grid p-fluid grid__reporteria">
                            <div className='field col-12 md:col-6'>
                                <Dropdown value={reportSelected}
                                    options={reportList.filter(function (element) { return element.tabpanel === 'DESCUENTOS'; })}
                                    onChange={(e) => setReportSelected(e.value)} placeholder="Seleccione reporte" />
                            </div>
                            <div className='field col-12 md:col-6 pl-5'>
                                <Button id='bt2' disabled={!reportSelected} type='button' className='p-button-rounded p-button-raised p-button-secondary btn__report'
                                    icon="pi pi-download" label='Descargar reporte' loading={loading} onClick={() => select_tipo_archivo('bt2')} />
                                <ConfirmPopup />
                            </div>
                            <div className='field col-12'>
                                {loading && progressbar}
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="General">
                        <div className="grid p-fluid grid__reporteria">
                            <div className={`field col-12 md:col-${reportSelected != 11 ? '6' : '4'}`}>
                                <Dropdown value={reportSelected}
                                    options={reportList.filter(function (element) { return element.tabpanel === 'NÓMINA'; }).sort((a, b) => a.label.localeCompare(b.label))}
                                    onChange={(e) => setReportSelected(e.value)} placeholder="Seleccione reporte" />
                            </div>
                            {
                                reportSelected == 11 &&
                                <div className="field col-12 md:col-5 flex mt-0 w-auto">
                                    <label htmlFor='rangoinput' className='mx-2 my-auto text-nowrap'>Rango deseado</label>
                                    <InputNumber style={{ maxWidth: '150px' }} inputId='rangoinput' mode='decimal' showButtons
                                        min={0} step={100} value={rango} onChange={(e) => setRango(e.value)} />
                                </div>
                            }
                            <div className='field col-12 md:col-3 text-center w-auto mx-3'>
                                <Button disabled={!reportSelected} type='button' className='p-button-rounded p-button-raised p-button-secondary btn__report'
                                    icon="pi pi-download" label='Descargar reporte' loading={loading} onClick={() => request_report('')} />
                            </div>
                            <div className='field col-12'>
                                {loading && progressbar}
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Anulaciones">
                        <div className="grid p-fluid grid__reporteria">
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-book"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText required type='text' value={formulario} onChange={(e) => setFormulario(e.target.value.toUpperCase())} />
                                        <label>Formulario</label>
                                    </span>
                                </div>
                            </div>
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-calendar"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText required type='text' value={fecha} onChange={(e) => setFecha(e.target.value.toUpperCase())} />
                                        <label>Fecha</label>
                                    </span>
                                </div>
                            </div>
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-bookmark"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText required type='text' value={folios} onChange={(e) => setFolios(e.target.value.toUpperCase())} />
                                        <label>Folios</label>
                                    </span>
                                </div>
                            </div>
                            <div className="field col-12 md:col-3">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-sort-numeric-down"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText required type='text' value={correlativos} onChange={(e) => setCorrelativos(e.target.value.toUpperCase())} />
                                        <label>Correlativos</label>
                                    </span>
                                </div>
                            </div>
                            {bankSelector('mx-auto')}
                            <div className='field col-12 text-center'>
                                <Button disabled={!bankSelected} type='button' className='p-button-rounded p-button-raised p-button-secondary btn__report'
                                    icon="pi pi-download" label='Generar reporte de anulación' loading={loading} onClick={() => { request_report('', 6); }} />
                            </div>
                            {
                                loading &&
                                <div className='field col-12'>
                                    {progressbar}
                                </div>
                            }
                        </div>
                    </TabPanel>
                    <TabPanel header="Archivos de pago">
                        <div className="grid p-fluid grid__reporteria">
                            <div className='field col-12 md:col-6'>
                                <Dropdown value={bankSelected}
                                    options={bancos_list.concat({ label: 'VACIADO DE CHEQUES', value: 3.1 })}
                                    onChange={(e) => { selectBank(e.value); }} placeholder="Seleccione archivo" />
                            </div>
                            <div className='field col-12 md:col-6 pl-5'>
                                <Button disabled={!bankSelected} type='button' className='p-button-rounded p-button-raised p-button-secondary btn__report'
                                    icon="pi pi-download" label='Descargar archivo' loading={loading} onClick={() => { request_report('', 3); }} />
                            </div>
                            <div className='field col-12'>
                                {loading && progressbar}
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
            }
        </div>
    );
}

export default Reporteria;
