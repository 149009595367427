import { Button } from 'primereact/button';
import CargaArchivoDescuentos from '../descuentos/file_upload';
import { useState } from 'react';
import { requestOptions } from '../misc/requestOptions';

function GuardarNomina(props) {

    const { id, toast, setActiveIndex } = props;
    const [loading, setLoading] = useState(false);

    const saveNomina = () => {
        setLoading(true);
        fetch((process.env.REACT_APP_API_URL_GUARDAR_NOMINA),
            requestOptions('POST', { idnomina: id }))
            .then((response) => response.json())
            .then(async (data) => {
                setLoading(false);
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                if (!data.error) setActiveIndex();
            });
    }

    return (
        <div className='mt-6'>
            <CargaArchivoDescuentos toast={toast} />
            <div className='btn__nextSetp'>
                <Button onClick={saveNomina} loading={loading} className='p-button-warning p-button-rounded p-button-raised font-weight-bold' icon='pi pi-check-circle' label='Guardar nómina' />
            </div>
        </div >
    );

}

export default GuardarNomina;