import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressBar } from 'primereact/progressbar';
import { ToggleButton } from 'primereact/togglebutton';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import AutorizacionDialog from '../autorizacion_dialog/autoriza_dialog';
import { Calendar } from 'primereact/calendar';
import { Tooltip } from 'primereact/tooltip';
import { requestOptions } from '../misc/requestOptions';

function EstadosNomina(props) {

    const navigate = useNavigate();
    const { id } = useParams();
    const [bitacora, setBitacora] = useState([]);
    const [loading, setLoading] = useState(false);
    const [autorizaDialog, setDialog] = useState(null);
    const [idEstado, setIdEstado] = useState(null);
    const [fecha_calendario, setFecha] = useState('');

    const get_bitacora = (showMessage = true) => {
        showMessage && setLoading(true);
        fetch((process.env.REACT_APP_API_URL_BITACORA_NOMINA),
            requestOptions('POST', { idnomina: id }))
            .then((response) => response.json())
            .then((data) => {
                if (showMessage)
                    props.toast.current.show({ severity: data.severity, summary: data.message || data.error });
                data.data && setBitacora(data.data);
                setLoading(false);
            });
    }

    const input_calendario = (idestado) => {
        return <>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                    <i className="pi pi-calendar"></i>
                </span>
                <Calendar placeholder='Ingrese fecha' dateFormat="dd/mm/yy" required={true} value={fecha_calendario}
                    onChange={(e) => { setFecha(e.value); update_estado(idestado, true, e.value); }}></Calendar>
            </div>
        </>
    }

    const update_estado = (idestado, auth = false, fecha = null) => {
        setIdEstado(idestado);
        if (!auth && (idestado >= 7)) {
            setDialog('Autorizar cambio de estado');
        }
        else {
            fetch((process.env.REACT_APP_API_URL_BITACORA_NOMINA),
                requestOptions('PUT', {
                    idnomina: id,
                    idestado: idestado,
                    fecha: fecha ? fecha.toJSON().slice(0, 10) : ''
                }))
                .then((response) => response.json())
                .then((data) => {
                    props.toast.current.show({ severity: data.severity, summary: data.message || data.error });
                    setDialog(null);
                    get_bitacora(false);
                });
        }
    }

    useEffect(() => {
        get_bitacora();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const header = (
        <div className='text-center'>Bitácora de estados</div>
    );

    const estadoBadge = (rowData) => {
        return <span className={`status-badge-nom status-${rowData.idestadonomina}`}>{rowData.descripcion}</span>;
    }
    const completarEstado = (rowData) => {
        // Nota: el estado 5 es "Pagada", no debe alterarse en la base de datos
        return (
            <>
                {(!rowData.fecha && rowData.idestadonomina == 5) &&
                    <Tooltip target={`#estado5`} autoHide={false}>
                        {/* <Calendar inline value={fecha_calendario} onChange={(e) => { setFecha(e.value); update_estado(rowData.idestadonomina); }}></Calendar> */}
                        {input_calendario(5)}
                    </Tooltip>
                }
                <ToggleButton id={`estado${rowData.idestadonomina}`} checked={rowData.fecha}
                    onChange={() => { (rowData.idestadonomina != 5 && !rowData.fecha) && update_estado(rowData.idestadonomina) }}
                    onLabel="Sí" offLabel="No" onIcon="pi pi-check" offIcon="pi pi-times" style={{ width: '6em' }} aria-label="Confirmation" />
            </>
        );
    }

    return (
        <div style={{ maxWidth: '700px', margin: 'auto' }}>
            {
                autorizaDialog &&
                <AutorizacionDialog
                    header={autorizaDialog}
                    continueOperation={() => update_estado(idEstado, true)}
                    cancelOperation={() => setDialog(null)}
                    toast={props.toast}
                />
            }
            {
                loading ?
                    <ProgressBar mode="indeterminate" style={{ height: '8px', marginTop: '200px' }} />
                    :
                    <>
                        <DataTable value={bitacora} header={header} selectionMode='single' emptyMessage="Sin resultados." className="mt-6">
                            <Column align={'center'} bodyClassName={'font-weight-light'} field="idestadonomina" header="#"></Column>
                            <Column header="Estado" body={estadoBadge}></Column>
                            <Column field="fecha" header="Fecha" ></Column>
                            {/* <Column field="comentario" header="Comentario" ></Column> */}
                            <Column align={'center'} style={{ minWidth: 'fit-content' }} header="Completado" body={completarEstado}></Column>
                        </DataTable>
                        <div className='btn__nextSetp'>
                            <Button onClick={() => navigate("/nominas")} className='p-button-rounded p-button-raised font-weight-bold' icon='pi pi-check-circle' label='Regresar a Menú Nóminas' />
                        </div>
                    </>
            }
        </div>
    );
}

export default EstadosNomina;
