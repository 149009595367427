import './App.css';
import React, { useEffect } from 'react';

import SidebarComponent from './sidebar/sidebar';

import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/nova-alt/theme.css'

import { useNavigate } from "react-router-dom";
import Rutas from './routes/routes';
import Login from './auth/login/login';
import { logout } from './auth/logout';


const App = () => {

  const navigate = useNavigate();

  useEffect(() => {
    // Para validar tiempo de sesión
    const expires = localStorage.getItem('expires');
    if (expires) {
      const date_expires = new Date(expires);
      const today = new Date();
      if (date_expires < today) {
        logout();
        navigate("/login", { relative: true });
      }
    } else {
      logout();
      navigate("/login", { relative: true });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className='App'>
      {
        !localStorage.getItem('token')
          ? <Login /> :
          <>
            <SidebarComponent />
            <Rutas />
          </>
      }
    </div >
  );
}

export default App;
