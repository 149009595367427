import { requestOptions } from "./requestOptions";
const api = process.env.REACT_APP_API_URL_CUOTAS || "http://localhost:8091/cuotas/"

export async function getReintegros() {
    const url = api + "reintegros/get_reintegros"
    const response = await fetch(url, requestOptions("GET", null));
    return response.json();
}

export async function crearReintegro(data = {}) {
    const url = api + "reintegros/crear_reintegro"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function getPeriodosReintegro(data = {}) {
    const url = api + "reintegros/get_periodos_reintegro"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function editarReintegro(data = {}) {
    const url = api + "reintegros/editar_reintegro"
    const response = await fetch(url, requestOptions("PUT", JSON.stringify(data)));
    return response.json();
}

export async function eliminarReintegro(data = {}) {
    const url = api + "reintegros/eliminar_reintegro/" + data.idReintegro
    const response = await fetch(url, requestOptions("DELETE", null));
    return response.json();
}

export async function exportarReintegro(data = {}) {
    const url = api + "reintegros/exportar_reintegro"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response;
}





