import './listado.css';
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Chip } from 'primereact/chip';
import AutorizacionDialog from '../autorizacion_dialog/autoriza_dialog';
import { requestOptions } from '../misc/requestOptions';
import { ContextMenu } from 'primereact/contextmenu';

const ListadoNominas = (props) => {

    const toast = useRef(null);
    const navigate = useNavigate();
    const pathname = useLocation().pathname;

    const [param, setParam] = useState(null);
    const [nomina, setNominaSelected] = useState(null);
    const [loading, setLoading] = useState(null);
    const [nominasList, setNominasList] = useState([]);
    const [autorizaDialog, setDialog] = useState(null);
    const [typeOperation, setOperation] = useState(null);
    const [idNomina, setIdNomina] = useState(null);

    // Para click derecho
    const cm = useRef(null);
    const [_nom, setNom] = useState('');
    const menuModel = [
        { label: 'Ir a reportería', icon: 'pi pi-fw pi-chart-line', command: () => navigate(`/nominas/reporteria/${_nom}`) },
        { label: 'Ver documentos', icon: 'pi pi-fw pi-folder-open', command: () => navigate(`/nominas/gestion-documentos/${_nom}`) }
    ];

    useEffect(() => {
        const { requestOptions } = props;
        if (pathname.includes('/nominas/gestion-documentos')) { setParam(4); setNominaSelected(null); navigate('/nominas/gestion-documentos'); }
        else if (pathname.includes('/nominas/reporteria')) { setParam(3); setNominaSelected(null); navigate('/nominas/reporteria'); }
        else if (pathname.includes('/nominas/buscar')) setParam(2);
        else if (pathname.includes('/nominas')) setParam(1);
        fetch(process.env.REACT_APP_API_URL_BUSCAR_NOMINA, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setNominasList(data.data);
            }); // eslint-disable-next-line
    }, [loading, props.requestOptions]);

    const operation_request = (_id) => {
        setLoading(typeOperation + _id);
        fetch(process.env.REACT_APP_API_URL_ELIMINAR_NOMINA,
            requestOptions('POST', { id: _id, type: typeOperation }))
            .then((response) => response.json())
            .then((data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                setLoading(null);
            });
    }

    const confirmOperation = (id, type) => {
        setIdNomina(id);
        switch (type) {
            case 'RESET':
                setOperation('RESET');
                setDialog('Reiniciar nómina');
                break;
            case 'DELETE':
                setOperation('DELETE');
                setDialog('Eliminar nómina');
                break;
            default:
                break;
        }
    }

    const hideAutorizaDialog = () => {
        setIdNomina(null);
        setDialog(null);
    }

    const disabledOption = (status) => {
        let dis = { delete: false, select: false };
        if (status === -1) {
            dis.delete = true;
            dis.select = true;
        }
        return dis;
    }

    const statusBody = (rowData) => {
        return <span className={`status-badge-nom status-${rowData.idestadonomina}`}>{rowData.status}</span>;
    }

    const tipoBody = (rowData) => {
        return <Chip className='chip_nomina rounded' label={rowData.nombre} />;
        // return <span className={`status-badge-nom status-liquido `}>{rowData.nombre}</span>;
    }

    const bancosBody = (rowData) => {
        let image_path, label;
        switch (rowData.banco_idbanco) {
            case null:
                return <div className='multiple-banco p-chip'>
                    <Chip className='mr-1 p-0 align-middle' image={`${param !== 1 ? '../' : ''}gytc.png`} />
                    <Chip className='mr-1 p-0 align-middle' image={`${param !== 1 ? '../' : ''}banrural_c.jpg`} />
                    <Chip className='p-0 align-middle' image={`${param !== 1 ? '../' : ''}cheque.png`} />
                </div>;
            case 1:
                image_path = `${param !== 1 ? '../' : ''}gytc.png`;
                label = 'G&T ACRED.';
                break;
            case 2:
                image_path = `${param !== 1 ? '../' : ''}banrural_c.jpg`;
                label = 'BANRURAL';
                break;
            case 3:
                image_path = `${param !== 1 ? '../' : ''}cheque.png`;
                label = 'CHEQUES';
                break;
            default:
                return;
        }
        return <Chip className='chip_banco align-middle' image={image_path} label={label} />;
    }

    const optionBody = (rowData) => {
        return (<div style={{ display: 'flex' }}>
            <Button id={'DELETE' + rowData.id} onClick={() => confirmOperation(rowData.id, 'DELETE')} disabled={disabledOption(rowData.idestadonomina).delete} className='p-button-rounded p-button-raised p-button-danger p-button-icon-only mr-2' icon='pi pi-times' tooltip='Eliminar' loading={loading === 'DELETE' + rowData.id} />
            <Button id={'RESET' + rowData.id} onClick={() => confirmOperation(rowData.id, 'RESET')} disabled={disabledOption(rowData.idestadonomina).delete} className='p-button-rounded p-button-raised p-button-warning p-button-icon-only mr-2' icon='pi pi-replay' tooltip='Reiniciar' loading={loading === 'RESET' + rowData.id} />
            <Button id={rowData.id} onClick={() => selectNomina({ data: rowData })} disabled={disabledOption(rowData.idestadonomina).select} className='p-button-rounded p-button-raised p-button-primary p-button-icon-only' icon='pi pi-arrow-right' tooltip='Seleccionar' />
        </div >);
    }

    const selectionBody = (rowData) => {
        return (<div className='menu__selection'>
            <RadioButton value={rowData.id} name="nomina" onChange={(e) => selectNomina({ data: { id: e.value, status: rowData.status } })} checked={nomina === rowData.id} />
        </div >);
    }

    const selectNomina = (rowData) => {
        if (!disabledOption(rowData.data.idestadonomina).select) {
            if (param === 3) {
                setNominaSelected(rowData.data.id);
                navigate('/nominas/reporteria/' + rowData.data.id);
            }
            else if (param === 4) {
                setNominaSelected(rowData.data.id);
                navigate('/nominas/gestion-documentos/' + rowData.data.id);
            }
            else {
                navigate("/nominas/" + rowData.data.id);
            }
        }
    }


    const header = (
        <div className="table-header">
            NÓMINAS
        </div>
    );
    const footer = nominasList.length > 0 ? `Se ${nominasList.length > 1 ? `encontraron ${nominasList.length} nóminas existentes` : `encontró ${nominasList.length} nómina existente`} en ${param === 1 ? "el presente mes o por concluir" : "la búsqueda"}.` : `No se encontró ninguna nómina en ${param === 1 ? "el presente mes" : "la búsqueda"}.`;

    return (
        <div className="datatable-nominas-list">
            <Toast ref={toast}></Toast>
            <ContextMenu model={menuModel} ref={cm} onHide={() => { setNom(''); }} />
            {
                autorizaDialog &&
                <AutorizacionDialog
                    header={autorizaDialog}
                    continueOperation={() => operation_request(idNomina)}
                    cancelOperation={() => hideAutorizaDialog()}
                    toast={toast}
                />
            }
            <div className="card__listado">
                <DataTable value={nominasList} header={header} selectionMode='single' onRowClick={(param === 3 || param === 4) ? selectNomina : null} onRowDoubleClick={selectNomina} footer={footer} emptyMessage="Sin resultados."
                    onContextMenuSelectionChange={(e) => { setNom(e.value.id); }}
                    onContextMenu={e => cm.current.show(e.originalEvent)}>
                    {
                        (param === 3 || param === 4) &&
                        <Column style={{ paddingLeft: '6px', paddingRight: '6px' }} align={'center'} header="Selección" body={selectionBody}></Column>
                    }
                    <Column style={{ paddingLeft: '8px', paddingRight: '8px' }} field="id" header="ID" align={'center'} ></Column>
                    <Column style={{ paddingLeft: '8px', paddingRight: '8px' }} field="month" header="Mes" align={'center'} ></Column>
                    <Column style={{ paddingLeft: '8px', paddingRight: '8px' }} field="year" header="Año" align={'center'} ></Column>
                    <Column body={tipoBody} header="Tipo de nómina" style={{ whiteSpace: 'nowrap' }} ></Column>
                    <Column field="observacion" bodyClassName={'font-weight-light'} header="Descripción" ></Column>
                    <Column header="Banco" style={{ whiteSpace: 'nowrap' }} bodyClassName='py-0' body={bancosBody}></Column>
                    <Column field="fecha_pago" style={{ whiteSpace: 'nowrap' }} header="Fecha de pago"></Column>
                    <Column header="Estado" body={statusBody}></Column>
                    {
                        (param === 1 || param === 2) &&
                        <Column bodyStyle={{ width: '100px' }} header="Menú" body={optionBody}></Column>
                    }
                </DataTable>
            </div>
        </div>
    );
}

export default ListadoNominas;
