import './historial.css';
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Link } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { renderRedirect } from '../constants/bodiesYFiltos';

export default function MenuHistorial() {
    const [redirect, setRedirect] = useState(false);
    const [agregarRegistrosModeVisible, setAgregarRegistrosModeVisible] = useState(false);

    const porFecha = () => {
        sessionStorage.setItem('modo_historial', 0);
        setRedirect(true);
    }

    const porTrabajador = () => {
        sessionStorage.setItem('modo_historial', 1);
        setRedirect(true);
    }

    return (
        <div className='menu-historial'>
            <div className="mt-7 grid p-fluid">
                <Card className="card">
                    <h1 style={{ textAlign: 'center' }}>Seleccionar tipo de historial</h1>
                    <div style={{ width: '80%', margin: 'auto', marginTop: '5%', marginBottom: '5%' }}>
                        <Button type="button" icon="pi pi-calendar" label="Historial de cuotas por año y mes"
                            onClick={porFecha} size='large' />
                    </div>
                    <div style={{ width: '80%', margin: 'auto', marginTop: '5%', marginBottom: '5%' }}>
                        <Button type="button" icon="pi pi-user" label="Historial de cuotas por trabajador"
                            onClick={porTrabajador} size='large' />
                    </div>
                    <div style={{ width: '80%', margin: 'auto', marginTop: '5%', marginBottom: '5%' }}>
                        <Button type="button" icon="pi pi-plus" label="Agregar registros"
                            onClick={(e) => {
                                setAgregarRegistrosModeVisible(true);
                                sessionStorage.setItem('modo_historial', 0);
                            }}
                            size='large' severity='warning' />
                        <Dialog header="Agregar Registros" visible={agregarRegistrosModeVisible}
                            style={{ width: '55vw' }}
                            onHide={() => setAgregarRegistrosModeVisible(false)}
                        >
                            <h3 style={{ textAlign: 'center' }}>
                                ¿Cómo desea agregar nuevos registros?
                            </h3>
                            <div className="grid p-fluid" style={{ marginTop: '5%' }}>
                                <div className='col-6'>
                                    <Link to="/cuotas/crear_registro">
                                        <Button type="button" icon="pi pi-pencil" label="Forma Manual" />
                                    </Link>
                                </div>
                                <div className='col-6'>
                                    <Link to="/cuotas/cargar_registros">
                                        <Button type="button" icon="pi pi-file" label="Cargar Archivo XLS" />
                                    </Link>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </Card>
            </div>
            {renderRedirect(redirect, 'historial')}
        </div>
    );
}
