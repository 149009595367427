import { requestOptions } from "./requestOptions";
const api = process.env.REACT_APP_API_URL_CUOTAS || "http://localhost:8091/cuotas/"

export async function getTrabajadores(data = {}) {
    const url = api + "gestion_trabajadores/get_trabajadores"
    const response = await fetch(url, requestOptions("GET", null));
    return response.json();
}

export async function crearTrabajador(data = {}) {
    const url = api + "gestion_trabajadores/crear_trabajador"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function editarTrabajador(data = {}) {
    const url = api + "gestion_trabajadores/editar_trabajador"
    const response = await fetch(url, requestOptions("PUT", JSON.stringify(data)));
    return response.json();
}

export async function eliminarTrabajador(data = {}) {
    const url = api + "gestion_trabajadores/eliminar_trabajador/" + data.Registro
    const response = await fetch(url, requestOptions("DELETE", null));
    return response.json();
}

export async function exportarTabla(data = {}) {
    const url = api + "gestion_trabajadores/exportar_tabla"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response;
}

// -------------------------------------------------------- CONTRATOS
export async function getPuestosTrabajo(data = {}) {
    const url = api + "gestion_trabajadores/get_puestos_trabajo"
    const response = await fetch(url, requestOptions("GET", null));
    return response.json();
}

export async function getDependenciasUnidades(data = {}) {
    const url = api + "gestion_trabajadores/get_dependencias_unidades"
    const response = await fetch(url, requestOptions("GET", null));
    return response.json();
}

export async function getContratos(data = {}) {
    const url = api + "gestion_trabajadores/get_contratos"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function crearContrato(data = {}) {
    const url = api + "gestion_trabajadores/crear_contrato"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response.json();
}

export async function editarContrato(data = {}) {
    const url = api + "gestion_trabajadores/editar_contrato"
    const response = await fetch(url, requestOptions("PUT", JSON.stringify(data)));
    return response.json();
}

export async function eliminarContrato(data = {}) {
    const url = api + "gestion_trabajadores/eliminar_contrato/" + data.idContratoTrabajador
    const response = await fetch(url, requestOptions("DELETE", null));
    return response.json();
}

export async function exportarContratos(data = {}) {
    const url = api + "gestion_trabajadores/exportar_contratos"
    const response = await fetch(url, requestOptions("POST", JSON.stringify(data)));
    return response;
}





