import './partidas.css';
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { FilterMatchMode } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { requestOptions } from '../misc/requestOptions';

function CatalogoPartidas(props) {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [partidaDialog, setPartidaDialog] = useState(false);
    const [isLoaded, setLoaded] = useState(false);

    const [cuenta_contable, setCuentaContable] = useState('');
    const [nombrePartida, setNombre] = useState('');
    const [anotacion, setAnotacion] = useState('');

    const toast = useRef(null);

    /* Paginator */
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(15);
    /* Filters */
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    }
    const pg_template = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 15, value: 15 },
                { label: 30, value: 30 },
                { label: 60, value: 60 }
            ];
            return (
                <React.Fragment>
                    <span className="mx-0">Elementos por página: </span>
                    <Dropdown className="mr-4 table__paginator" value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        'CurrentPageReport': (options) => {
            return (
                <span style={{ width: '120px', textAlign: 'right' }}>
                    {options.first} - {options.last} de {options.totalRecords}
                </span>
            )
        }
    };
    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }


    const request_data = (showMessage = true) => {
        fetch(process.env.REACT_APP_API_URL_PARTIDAS_CATALOGO)
            .then((response) => response.json())
            .then((data) => {
                if (showMessage)
                    toast.current.show({ severity: data.severity, summary: data.message || data.error });
                const x = data.data;
                if (x) setData(x);
                setLoaded(true);
            });
    }

    useEffect(() => {
        request_data();
    }, []);

    const add_partida = () => {
        fetch((process.env.REACT_APP_API_URL_PARTIDAS_CATALOGO + '/nuevo'),
            requestOptions('POST', {
                idcc: cuenta_contable,
                nombre: nombrePartida,
                anotacion: anotacion
            }))
            .then((response) => response.json())
            .then((data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                setCuentaContable('');
                setNombre('');
                setAnotacion('');
                setPartidaDialog(false);
                request_data(false);
            });
    }

    const header = () => {
        return (
            <div className="flex">
                <p className='my-auto'>Partidas</p>
                <div className='ml-auto'>
                    <span className="p-input-icon-right">
                        <i className="pi pi-search" />
                        <InputText style={{ width: '100%' }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Buscar" />
                    </span>
                </div>
                {
                    props.agregar &&
                    <Button type='button' label="Crear partida" icon="pi pi-plus" iconPos='right' severity='warning' raised outlined className="ml-auto font-weight-bold" onClick={() => setPartidaDialog(true)} />
                }
            </div>
        )
    }

    const dialog_footer = () => {
        return <div>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={() => setPartidaDialog(false)} />
            <Button disabled={!cuenta_contable || !nombrePartida} label="Guardar" icon="pi pi-check" className="p-button-text" onClick={add_partida} />
        </div>
    }

    // Para BreadCrumb
    const items = [
        { label: 'NÓMINAS', command: () => { navigate("/nominas") } },
        { label: 'PARTIDAS', command: () => { navigate("/nominas/partidas") } }
    ];
    const home = { icon: 'pi pi-home', command: () => { navigate("/") } };

    // Para descripción
    const [displayDescription, setDisplayDescription] = useState(null);

    return (
        <div className='container__partidas'>
            <Toast ref={toast}></Toast>
            <BreadCrumb model={items} home={home} />
            {
                isLoaded &&
                <div className="card__partidas">
                    <DataTable value={data} header={header} onRowDoubleClick={e => setDisplayDescription(e.data.anotacion)}
                        paginator paginatorTemplate={pg_template} first={first} rows={rows} onPage={onCustomPage}
                        filters={filters} globalFilterFields={['idcc', 'nombre']}
                        stripedRows selectionMode='single' emptyMessage="No se encontró ningún registro." >
                        <Column align={'center'} bodyStyle={{ fontWeight: 'bold' }} field="idcc" header="Cuenta contable"></Column>
                        <Column alignHeader={'center'} field="nombre" header="Nombre" ></Column>
                    </DataTable>
                    <Dialog visible={partidaDialog} style={{ width: '400px' }} header="Nueva partida" modal className="p-fluid" footer={dialog_footer} onHide={() => setPartidaDialog(false)} resizable={false}>
                        <div className="field">
                            <label htmlFor="idcc">Cuenta contable</label>
                            <InputText id="idcc" type="text" keyfilter={/[\d]/} value={cuenta_contable} onChange={(e) => setCuentaContable(e.target.value)} required autoFocus />
                        </div>
                        <div className="field">
                            <label htmlFor="nombrep">Nombre partida</label>
                            <InputText id='nombrep' type='text' value={nombrePartida} onChange={(e) => setNombre(e.target.value)} required />
                        </div>
                        <div className="field">
                            <label htmlFor="anotap">Anotación</label>
                            <InputText id='anotap' type='text' placeholder='*Opcional' value={anotacion} onChange={(e) => setAnotacion(e.target.value)} />
                        </div>
                    </Dialog>
                    <Dialog header="Anotación" headerStyle={{ paddingTop: '8px', paddingBottom: '8px' }} visible={displayDescription !== null} position={'center'} modal style={{ minWidth: '20%' }} onHide={() => setDisplayDescription(null)}
                        draggable={true} resizable={false}>
                        <p className="m-0">{displayDescription}</p>
                    </Dialog>
                </div>
            }

        </div>
    );
}

export default CatalogoPartidas;
