import React from 'react';
import { Card } from 'primereact/card';
import MiModal from './modal';
import { useState } from 'react';
import { Button } from 'primereact/button';

function LoanCard({ loan }) {
    const [prestamo, setPrestamo] = useState({})

    let img = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';
    let usuario = loan.persona;
    let monto = loan.cantidad;
    let mensualidad = loan.siguiente_cuota;
    let plazo = loan.duracion;
    let moroso = loan.mora_faltante > 0 ? 'Si' : 'No';
    let codigo = loan.clave;

    let capital_faltante = loan.capital_faltante;
    let interes_faltante = loan.interes_faltante;
    let mora_faltante = loan.mora_faltante;
    let fecha_inicio = loan.fecha_inicio;
    let tasa = loan.tasa;
    

    const img_style = { borderRadius: '2px 2px 0 0', height: '150px', maxWidth: '100%' };
    const img_perfil = <img src={img} alt='img_busqueda_prestamos' style={img_style} />


    return (
        <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <Card header={img_perfil} >
                <h2>{usuario}</h2>
                <p>Codigo: {codigo}</p>
                <p>Monto: {monto}</p>
                <p>Mensualidad: {mensualidad}</p>
                <p>Plazo: {plazo}</p>
                <p>Es moroso: {moroso}</p>
                <p>Capital Faltante: {capital_faltante}</p>
                <p>Interes Faltante: {interes_faltante}</p>
                <p>Mora Faltante: {mora_faltante}</p>
                <p>Fecha Inicial: {fecha_inicio}</p>
                <p>Tasa de Interes: {tasa}</p>
                <Button label="Ver Prestamo" icon="pi pi-check" onClick={() => {window.location.href = `/moras/detalle_prestamo/${loan.clave}`}} style={{marginBottom: '10px'}}/>
                <MiModal informacion={loan} />
            </Card>
        </div>
    );
};

export default LoanCard;