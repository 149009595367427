import './consultas.css';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { Image } from 'primereact/image';
import { useEffect, useRef, useState } from 'react';
import get_bancos from '../../modulo_nominas/misc/get_bancos';
import { Link, useNavigate } from 'react-router-dom';
import { requestOptions } from '../../modulo_nominas/misc/requestOptions';


function ConsultaDatos(props) {
    const regex_alfa = /[a-zA-zñÑáÁéÉíÍóÓúÚ ]/;
    const [busqueda, setBusqueda] = useState('ppr');
    const [idpersona, setIdPersona] = useState(null);
    const [partida, setPartida] = useState('');
    const [plaza, setPlaza] = useState('');
    const [registro, setRegistro] = useState('');
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [apellido_casada, setApellidoCasada] = useState('');
    const [numero_dpi, setDPI] = useState('');
    const [telefono, setTelefono] = useState('');
    const [correo, setCorreo] = useState('');
    const [direccion, setDireccion] = useState('');
    const [estado_civil, setEstadoCivil] = useState('');
    const [banco, setBanco] = useState('');
    const [cuenta_bancaria, setCuenta] = useState('');
    const [fecha_nacimiento, setFnac] = useState('');
    const [fecha_inicio_pension, setFIP] = useState('');
    const [fecha_alza_nomina, setFAN] = useState('');
    const [genero, setGenero] = useState('');
    const [pension, setPension] = useState('');
    const [observacion, setObservacion] = useState('');
    const [id_acuerdo, setAcuerdo] = useState(null);
    const [fecha_dictamen, setFD] = useState('');
    const [fecha_traslado, setFT] = useState('');
    const [fecha_nivelacion, setFN] = useState('');
    const [foto, setFoto] = useState('');
    const [estado, setEstado] = useState('');
    const [dependencia, setDependencia] = useState('');

    const [loading, setLoading] = useState(false);
    const [editable, setEditable] = useState(false);
    const [isLoaded, setLoaded] = useState(false);

    const [bancos_list, setBancos] = useState([]);
    async function fetchBancos() {
        const tmp = await get_bancos('dropdown');
        setBancos(tmp);
    }

    const { modificable, ptd, plz, reg } = props;
    const navigate = useNavigate();
    useEffect(() => {
        if (ptd && plz && reg) {
            setPartida(ptd);
            setPlaza(plz);
            setRegistro(reg);
            request_data(ptd, plz, reg);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ptd, plz, reg]);

    const reset_form = () => {
        setBusqueda('');
        setIdPersona(null);
        setPartida('');
        setPlaza('');
        setRegistro('');
        setNombres('');
        setApellidos('');
        setApellidoCasada('');
        setDPI('');
        setTelefono('');
        setCorreo('');
        setEstadoCivil('');
        setBanco('');
        setCuenta('');
        setFnac('');
        setGenero('');
        setPension('');
        setObservacion('');
        setDireccion('');
        setFAN('');
        setFIP('');
        setAcuerdo(null);
        setFD('');
        setFT('');
        setFN('');
        setEstado('');
        setFoto('');
        setDependencia('');
        setLoading(false);
        setEditable(false);
        setLoaded(false);
        navigate('/jubilados');
    }

    const toast = useRef(null);

    const request_data = async (_ptd = null, _plz = null, _reg = null) => {
        setLoading(true);
        if (idpersona) reset_form();
        await fetchBancos();
        fetch(process.env.REACT_APP_API_URL_CONSULTAR_PERSONA,
            requestOptions('POST', {
                partida: _ptd || partida,
                plaza: _plz || plaza,
                registro: _reg || registro,
                dpi: numero_dpi,
                busqueda: busqueda,
                foto: true
            }))
            .then((response) => response.json())
            .then((data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                const x = data.data;
                if (x) {
                    x.idpersona && setIdPersona(x.idpersona);
                    x.partida && setPartida(x.partida);
                    x.plaza && setPlaza(x.plaza);
                    x.registro && setRegistro(x.registro);
                    x.nombres && setNombres(x.nombres);
                    x.apellidos && setApellidos(x.apellidos);
                    x.apellido_casada && setApellidoCasada(x.apellido_casada);
                    x.numero_dpi && setDPI(x.numero_dpi);
                    x.telefono && setTelefono(x.telefono);
                    x.correo && setCorreo(x.correo);
                    x.estado_civil && setEstadoCivil(x.estado_civil);
                    x.banco_idbanco && setBanco(x.banco_idbanco);
                    x.numero_cuenta && setCuenta(x.numero_cuenta);
                    x.fecha_nacimiento && setFnac(new Date(x.fecha_nacimiento.substring(0, 19)));
                    x.genero && setGenero(x.genero);
                    x.valor_pension && setPension(x.valor_pension);
                    x.comentario && setObservacion(x.comentario);
                    x.direccion && setDireccion(x.direccion);
                    x.fecha_alza && setFAN(new Date(x.fecha_alza.substring(0, 19)));
                    x.inicio_pension && setFIP(new Date(x.inicio_pension.substring(0, 19)));
                    x.dependencia && setDependencia(x.dependencia);
                    x.idacuerdo && setAcuerdo(x.idacuerdo);
                    x.fecha_dictamen && setFD(new Date(x.fecha_dictamen.substring(0, 19)));
                    x.fecha_traslado && setFT(new Date(x.fecha_traslado.substring(0, 19)));
                    x.fecha_nivelacion && setFN(new Date(x.fecha_nivelacion.substring(0, 19)));
                    x.foto_dpi && setFoto(x.foto_dpi);
                    setEstado(x.estado);
                    setLoaded(true);
                }
                setLoading(false);
            });
    }

    const format_date = (date) => {
        if (date)
            return date.toJSON().slice(0, 10);
        return null;
    }

    const update_data = async () => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL_MODIFICAR_PERSONA,
            requestOptions('POST', {
                idpersona: idpersona,
                dpi: numero_dpi,
                nombres: nombres,
                apellidos: apellidos,
                apellido_casada: apellido_casada,
                fecha_nacimiento: format_date(fecha_nacimiento),
                direccion: direccion,
                estado_civil: estado_civil,
                correo: correo,
                fecha_alza: format_date(fecha_alza_nomina),
                inicio_pension: format_date(fecha_inicio_pension),
                genero: genero,
                telefono: telefono,
                comentario: observacion,
                valor_pension: pension,
                banco: banco,
                cuenta: cuenta_bancaria,
                id_acuerdo: id_acuerdo,
                dependencia: dependencia,
                fecha_dictamen: format_date(fecha_dictamen),
                fecha_traslado: format_date(fecha_traslado),
                fecha_nivelacion: format_date(fecha_nivelacion)
            }))
            .then((response) => response.json())
            .then((data) => {
                toast.current.show({ severity: data.severity, summary: data.message || data.error });
                setLoading(false);
                setEditable(false);
                // if (!data.error) reset_form();
            });
    }

    const confirm_update = (event) => {
        confirmPopup({
            target: document.getElementById('consultas_confirm'),
            message: '¿Seguro que desea modificar los datos ya revisados?',
            icon: 'pi pi-question-circle',
            acceptLabel: 'Sí',
            accept: () => {
                update_data();
            }
        });
        event.preventDefault();
    }

    return (
        <form id='datos_form' onSubmit={confirm_update} noValidate={false}>
            <Toast ref={toast}></Toast>
            <div className="grid p-fluid datos">
                <div className='col-12 md:col-6'>
                    <Fieldset legend="Búsqueda por partida, plaza y registro" toggleable collapsed={!isLoaded && busqueda !== 'ppr'} onToggle={() => setBusqueda('ppr')}>
                        <div className="grid p-fluid mt-1">
                            {/* PARTIDA */}
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText disabled={isLoaded} type="text" keyfilter="pint" value={partida} onChange={(e) => setPartida(e.target.value)} />
                                        <label>Partida</label>
                                    </span>
                                </div>
                            </div>
                            {/* PLAZA */}
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText disabled={isLoaded} type="text" keyfilter="pint" value={plaza} onChange={(e) => setPlaza(e.target.value)} />
                                        <label >Plaza</label>
                                    </span>
                                </div>
                            </div>
                            {/* REGISTRO */}
                            <div className="field col-12 md:col-4 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText disabled={isLoaded} type="text" keyfilter="pint" value={registro} onChange={(e) => setRegistro(e.target.value)} />
                                        <label >Registro</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Fieldset>
                </div>

                <div className='col-12 md:col-3'>
                    <Fieldset legend="Búsqueda por DPI" toggleable collapsed={!isLoaded && busqueda !== 'dpi'} onToggle={() => setBusqueda('dpi')}>
                        <div className="grid p-fluid mt-1">
                            {/* DPI */}
                            <div className="field col-12 md:col-12 col_toggle">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-id-card"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText disabled={isLoaded && !editable} type="text" keyfilter="pint" value={numero_dpi} required={true} onChange={(e) => setDPI(e.target.value)} />
                                        <label >Número de CUI</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <p className='font-weight-light small m-0'>*La búsqueda por DPI no contempla los casos donde una misma persona posee más plazas.</p>
                    </Fieldset>
                </div>

                <div className='col-12 md:col-3'>
                    <div className='consulta_botones'>
                        <Button type='button' disabled={(busqueda === 'ppr' && (!partida || !plaza || !registro)) || (busqueda === 'dpi' && !numero_dpi) || !busqueda} className='p-button-rounded p-button-warning' icon="pi pi-search" loading={loading} onClick={() => request_data()} tooltip='Buscar persona' />
                        <Button type='button' className='p-button-rounded p-button-secondary' icon="pi pi-replay" onClick={reset_form} tooltip='Limpiar búsqueda' />
                        <div className='sw_edit'>
                            <p className='mr-1'>Modificable:</p>
                            <InputSwitch disabled={!isLoaded || !modificable} checked={editable} onChange={(e) => setEditable(e.value)} />
                        </div>
                    </div>
                    {
                        foto &&
                        <div className='py-2 text-center' >
                            <Image className='foto_dpi' src={`data:image/plain;base64,${foto}`} alt="Foto DPI" height="150" preview />
                        </div>
                    }
                </div>


                {
                    isLoaded &&
                    <>
                        {/* NOMBRES */}
                        <div className="field col-12 md:col-4">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-user"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText disabled={!editable} type="text" keyfilter={regex_alfa} required={true} value={nombres} onChange={(e) => setNombres(e.target.value.toUpperCase())} />
                                    <label >Nombres</label>
                                </span>
                            </div>
                        </div>
                        {/* APELLIDOS */}
                        <div className="field col-12 md:col-4">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-user"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText disabled={!editable} type="text" keyfilter={regex_alfa} required={true} value={apellidos} onChange={(e) => setApellidos(e.target.value.toUpperCase())} />
                                    <label >Apellidos</label>
                                </span>
                            </div>
                        </div>
                        {/* APELLIDO CASADA */}
                        <div className="field col-12 md:col-4">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-users"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText disabled={!editable} type="text" keyfilter={regex_alfa} required={false} value={apellido_casada} onChange={(e) => setApellidoCasada(e.target.value.toUpperCase())} />
                                    <label >Apellido de casada</label>
                                </span>
                            </div>
                        </div>
                        {/* TELÉFONO */}
                        <div className="field col-12 md:col-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-phone"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText disabled={!editable} type="text" keyfilter="pint" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                                    <label>Teléfono</label>
                                </span>
                            </div>
                        </div>
                        {/* CORREO */}
                        <div className="field col-12 md:col-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-envelope"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText disabled={!editable} type="text" keyfilter="email" value={correo} onChange={(e) => setCorreo(e.target.value.toLowerCase())} />
                                    <label >Correo</label>
                                </span>
                            </div>
                        </div>
                        {/* DIRECCIÓN */}
                        <div className="field col-12 md:col-6">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-home"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText disabled={!editable} type="text" value={direccion} onChange={(e) => setDireccion(e.target.value)} />
                                    <label >Dirección</label>
                                </span>
                            </div>
                        </div>
                        {/* ESTADO CIVIL */}
                        <div className="field col-12 md:col-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-heart"></i>
                                </span>
                                <span className="p-float-label">
                                    <Dropdown disabled={!editable} value={estado_civil} options={[{ label: 'SOLTERO', value: 'SOLTERO' }, { label: 'CASADO', value: 'CASADO' }, { label: 'VIUDO', value: 'VIUDO' }]} onChange={(e) => setEstadoCivil(e.value)} placeholder="Seleccione estado civil" />
                                    <label >Estado Civil</label>
                                </span>
                            </div>
                        </div>
                        {/* GÉNERO */}
                        <div className="field col-12 md:col-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-tag"></i>
                                </span>
                                <span className="p-float-label">
                                    <Dropdown disabled={!editable} value={genero} options={[{ label: 'MASCULINO', value: 'M' }, { label: 'FEMENINO', value: 'F' }]} onChange={(e) => setGenero(e.value)} placeholder="Seleccione sexo" />
                                    <label >Género</label>
                                </span>
                            </div>
                        </div>
                        {/* FECHA NACIMIENTO */}
                        <div className="field col-12 md:col-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-calendar"></i>
                                </span>
                                <span className="p-float-label">
                                    <Calendar disabled={!editable} dateFormat="dd/mm/yy" value={fecha_nacimiento} onChange={(e) => setFnac(e.value)}></Calendar>
                                    <label >Fecha de nacimiento</label>
                                </span>
                            </div>
                        </div>
                        {/* PENSIÓN */}
                        <div className="field col-12 md:col-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-money-bill"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputNumber disabled={!editable} required={true} value={pension} onValueChange={(e) => setPension(e.value)} mode="currency" currency="GTQ" min={0} />
                                    <label >Monto de pensión</label>
                                </span>
                            </div>
                        </div>
                        {/* BANCO */}
                        <div className="field col-12 md:col-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-wallet"></i>
                                </span>
                                <span className="p-float-label">
                                    <Dropdown disabled={true} required={true} value={banco} options={bancos_list} onChange={(e) => setBanco(e.value)} placeholder="Seleccione banco" />
                                    <label >Forma de pago</label>
                                </span>
                            </div>
                        </div>
                        {/* CUENTA */}
                        <div className="field col-12 md:col-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-dollar"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText disabled={true} type="text" required={banco !== 3} value={cuenta_bancaria} onChange={(e) => setCuenta(e.target.value)} />
                                    <label >Cuenta Bancaria</label>
                                </span>
                            </div>
                        </div>
                        {/* FECHA INICIO PENSIÓN */}
                        <div className="field col-12 md:col-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-calendar"></i>
                                </span>
                                <span className="p-float-label">
                                    <Calendar disabled={!editable} dateFormat="dd/mm/yy" required={true} value={fecha_inicio_pension} onChange={(e) => setFIP(e.value)}></Calendar>
                                    <label >Fecha de inicio de pensión</label>
                                </span>
                            </div>
                        </div>
                        {/* FECHA ALZA NÓMINA */}
                        <div className="field col-12 md:col-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-calendar"></i>
                                </span>
                                <span className="p-float-label">
                                    <Calendar disabled={!editable} dateFormat="dd/mm/yy" required={true} value={fecha_alza_nomina} onChange={(e) => setFAN(e.value)}></Calendar>
                                    <label >Fecha de alza de nómina</label>
                                </span>
                            </div>
                        </div>
                        {/* DEPENDENCIA */}
                        <div className="field col-12 md:col-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-book"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputTextarea disabled={!editable} rows={1} required={false} value={dependencia} autoResize={true} onChange={(e) => setDependencia(e.target.value.toUpperCase())} />
                                    <label >Dependencia</label>
                                </span>
                            </div>
                        </div>
                        {/* OBSERVACIÓN */}
                        <div className="field col-12 md:col-4">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-book"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputTextarea disabled={!editable} rows={1} required={false} value={observacion} autoResize={true} onChange={(e) => setObservacion(e.target.value.toUpperCase())} />
                                    <label >Anotaciones</label>
                                </span>
                            </div>
                        </div>
                        {/* ESTADO */}
                        <div className="field col-12 md:col-2">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-flag"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText disabled={true} style={{ backgroundColor: (estado ? '#009900' : '#e97977'), fontWeight: 'bold' }} type="text" value={estado ? 'ACTIVO' : 'INACTIVO'} />
                                    <label >Estado</label>
                                </span>
                            </div>
                        </div>
                        {/* NOTA */}
                        <div className="field col-12 md:col-3 w-auto mx-auto py-0">
                            <Link style={{ color: '#3863a0', fontWeight: 'bold', margin: 'auto' }} to={`/nominas/gestion-descuentos/${partida}/${plaza}/${registro}`} target="_blank">&#10132; Ver bonificación y descuentos</Link>
                            <br />
                            <Link style={{ color: '#3863a0', fontWeight: 'bold', margin: 'auto' }} to={`/nominas/libro-salarios/${partida}/${plaza}/${registro}`} target="_blank">&#10132; Consultar libro de salarios</Link>
                        </div>
                        {id_acuerdo !== null &&
                            <>
                                {/* ACUERDO */}
                                <div className="field col-12 md:col-3">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-arrow-right"></i>
                                        </span>
                                        <span className="p-float-label">
                                            <InputText maxLength={25} disabled={!editable} type="text" value={id_acuerdo || ''} onChange={(e) => setAcuerdo(e.target.value.toUpperCase())} />
                                            <label>Número de Acuerdo</label>
                                        </span>
                                    </div>
                                </div>
                                {/* FECHA DICTAMEN AUDITORÍA */}
                                <div className="field col-12 md:col-3">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-calendar"></i>
                                        </span>
                                        <span className="p-float-label">
                                            <Calendar disabled={!editable} dateFormat="dd/mm/yy" value={fecha_dictamen} onChange={(e) => setFD(e.value)}></Calendar>
                                            <label>Fecha dictamen auditoría</label>
                                        </span>
                                    </div>
                                </div>
                                {/* FECHA TRASLADO JAPP */}
                                <div className="field col-12 md:col-3">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-calendar"></i>
                                        </span>
                                        <span className="p-float-label">
                                            <Calendar disabled={!editable} dateFormat="dd/mm/yy" value={fecha_traslado} onChange={(e) => setFT(e.value)}></Calendar>
                                            <label>Fecha traslado JAPP</label>
                                        </span>
                                    </div>
                                </div>
                                {/* FECHA NIVELACIÓN */}
                                <div className="field col-12 md:col-3">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-calendar"></i>
                                        </span>
                                        <span className="p-float-label">
                                            <Calendar disabled={!editable} dateFormat="dd/mm/yy" value={fecha_nivelacion} onChange={(e) => setFN(e.value)}></Calendar>
                                            <label >Fecha de nivelación</label>
                                        </span>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
            </div>
            {/* CONFIRMAR */}
            {
                editable ?
                    <div className="confirm" >
                        <Button id='consultas_confirm' type='submit' icon="pi pi-save" label="Modificar datos" className="p-button-raised mt-2 font-weight-bold" loading={loading} />
                        <ConfirmPopup />
                    </div>
                    : <></>
            }

        </form>
    );
}

export default ConsultaDatos;
