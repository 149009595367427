import React from "react";
import { Card } from 'primereact/card'
import { Button } from 'primereact/button';

function Prestamo({ loan, paymentCards }) {

    return (
        <div>
            <h3>Informacion de Prestamo</h3>
            <Card title="Descripcion del Prestamo" style={{ width: '100%' }}>
                <div className="p-card-content">
                    <h3>{loan.partida}</h3>
                    <p>Cantidad: {loan.cantidad}</p>
                    <p>Capital Faltante: {loan.capital_faltante}</p>
                    <p>Dia Pago: {loan.dia_pago}</p>
                    <p>Duracion: {loan.duracion}</p>
                    <p>Estado: {loan.estado}</p>
                    <p>Fecha Inicio: {loan.fecha_inicio}</p>
                    <p>Interes Faltante: {loan.interes_faltante}</p>
                    <p>Mora Faltante: {loan.mora_faltante}</p>
                    <p>Persona: {loan.persona}</p>
                </div>
            </Card>
            <br />
            <h3>Prestamos</h3>
            <div className="row text-center">
                {paymentCards.map((card) => (
                    <div key={card.id} className="col-lg-4 col-md-6 col-sm-12 mb-5">
                        <Card key={card.id} title={card.title} style={{ width: '100%', marginTop: '20px' }}>
                            <div>
                                <p>Fecha: {card.fecha}</p>
                                <p>Capital: {card.capital}</p>
                                <p>Interes: {card.interes}</p>
                                <p>Mora: {card.mora}</p>
                                <p>Pago Moroso: {card.pago_moroso ? '✅' : '❌'}</p>
                                <p>Sumatoria: {card.sumatoria}</p>
                            </div>
                        </Card>
                    </div>
                ))}
                <div>
                    <Button label="Modificar prestamo" icon="pi pi-check" onClick={() => { window.location.href = `/moras/modificacion_prestamo/${loan.clave}` }} />
                </div>
            </div>
        </div>
    )

}

export default Prestamo
